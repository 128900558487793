// import React, { useState } from 'react';

// const PaymentHandle = (props) => {

//     return <p>Hello</p>
// }
// export  default  PaymentHandle;

import React, {Component} from 'react';
import AsyncStorage from '@callstack/async-storage';
// import '../assets/css/innerpages_customstyle.css';
import $ from 'jquery';
import queryString from 'query-string';
import Header_component from '../includes/Header_component';

export default class PaymentHandle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
    };
    let url = this.props.location.search;
    let params = queryString.parse(url);
    console.log('params', params);
    //$('.processing_div').show();
  }

  show_retry_payment(data) {
    $('.modal_renew_payment').show();
    this.props.planchoose(data);
  }
  componentDidMount() {
    $('.processing_div').show();
    // var that = this;
    // $(document).on('click', '.retry_invoice_div', function () {
    //   var $self = $(this);
    //   var planid = $self.data('planid');
    //   var busid = $self.data('busid');
    //   var intval = $self.data('intval');
    //   var edate = $self.data('edate');
    //   console.log('planid', planid);
    //   console.log('busid', busid);
    //   console.log('intval', intval);
    //   AsyncStorage.setItem('bis_id', busid);
    //   setTimeout(() => {
    //     that.props.planchoose(planid, busid, intval, edate);
    //   }, 2000);
    // });
  }
  render() {
    return (
      <>
        <div id="body" className="hold-transition  accent-custom">
          <div className="wrapper">
            <Header_component
              data={this.update.bind(this)}
              cart_data={this.cart_update.bind(this)}
              trigger={this.without_refresh.bind(this)}
              title="true"
            />
          </div>
        </div>
      </>
    );
  }
}
