import React, { useEffect, useState, Component } from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';

const Paraphrase = (props) => {



    useEffect(() => {
        document.getElementById("spinner").style.display = "none";

        const input_data = {
            input_text: "Hai jeni, How are you ?",
            output_count: 10,
            beam_count: 20
        };

        axios.post("http://18.117.3.205:5000/paraphrase", { input_data })
            .then(res => console.log({ res }))
            .catch(err => console.log({ err }))

    }, [])

    return (
        <div style={{ "text-align": "center" }}>
            <input type="text" placeholder="Enter your Message here" />
            <button>Generate-Phrase</button>
        </div>
    );
}

export default Paraphrase;