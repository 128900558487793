import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as page from './components/pages';
import useScript from './components/utils/useScript';
import './components/assets/css/font.css';
import './components/assets/font-awesome-5.13.0/css/all.css';
import './index.css';
import {initializeFirebase} from './push-notification';

export default function App() {
  useScript('https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js');
  useScript('https://js.stripe.com/v3/');

  useEffect(() => {
    initializeFirebase();
    sessionStorage.clear();
    const spinner = document.getElementById('spinner');

    if (spinner && !spinner.hasAttribute('hidden')) {
      spinner.setAttribute('hidden', 'true');
    }
  }, []);

   

  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={page.SignUp} />
          <Route path="/SignUp" exact component={page.SignUp} />
          <Route path="/SignUp-Emailcode" exact component={page.SignUpEmailcode} />
          <Route path="/SignUp-setpassword" exact component={page.SignUpsetpassword} />
          <Route path="/SignIn" exact component={page.SignIn} />
          <Route path="/Addon_login" exact component={page.Addon_login} />
          <Route path="/create-profile" exact component={page.CreateProfile} />
          <Route path="/createProfileServiceProvider" exact component={page.createProfileServiceProvider} />
          <Route path="/create-profile1" exact component={page.CreateProfile1} />
          <Route path="/create-profile2" exact component={page.CreateProfile2} />
          <Route path="/create-profile3" exact component={page.CreateProfile3} />
          <Route path="/forgot-password" exact component={page.ForgotPassword} />
          <Route path="/forgot-password-message" exact component={page.ForgotPasswordMessage} />
          <Route path="/reset-password" exact component={page.ResetPassword} />
          <Route path="/board" exact component={page.Onboard} />
          <Route path="/board1" exact component={page.Onboard1} />
          {/*<Route path="/video" exact component={page.Video} />
          <Route path="/audio" exact component={page.Audio} />
           <Route path="/audio_rec" exact component={page.Audio_rec} /> 
          <Route path="/intel" exact component={page.Intel} />*/}
          <Route
            path="/attach_message/:business_id/:unique_id/:contact_id/:user_id"
            exact
            component={page.Attach_receiver}
          />
          {/*<Route path="/video_demo" exact component={page.Video_demo} />
          <Route path="/vedio_new_test" exact component={page.vedio_new_test} />*/}
          <Route path="/manage_account" exact component={page.Manageaccount} />
          <Route path="/invoice_details" exact component={page.Invoice_details} />
          <Route path="/profile_settings" exact component={page.Profile_settings} />
          <Route path="/profile_settings1" exact component={page.Profile_settings1} />
          <Route path="/receiver_phonepin" exact component={page.Receiver_phonepin} />
          <Route path="/receiver_securitypreferences" exact component={page.Receiver_securitypreferences} />
          <Route path="/receiver_standardpin" exact component={page.Receiver_standardpin} />
          <Route path="/receiver_dynamicpin" exact component={page.Receiver_dynamicpin} />
          <Route path="/receiver_forgot_pin_reset" exact component={page.Receiver_forgot_pin_reset} />
          <Route path="/receiver_manageaccount" exact component={page.Receiver_manageaccount} />
          <Route path="/pdf_viewer" exact component={page.PDF_Viewer} />
          <Route path="/qrcode_form" exact component={page.Qrcode_Form} />
          
          <Route path="/custom_signin" exact component={page.Custom_SignIn} />
          <Route path="/custom_signup" exact component={page.Custom_SignUp} />
          <Route path="/pack_invoice" exact component={page.Pack_Invoice} />
          <Route path="/dashboard" exact component={page.Dashboard} />
          <Route path="/dashboard1" exact component={page.Dashboard1} />
          <Route path="/account_dashboard" exact component={page.Account_Dashboard} />
          <Route path="/payment_handle" exact component={page.PaymentHandle} />
          <Route path="/renew-profile" exact component={page.RenewProfile} />
          <Route path="/gumroad" exact component={page.Gumroad} />
          <Route path="/paraphrase" exact component={page.Paraphrase} />
          <Route path="/notificationredirect" exact component={page.NotificationRedirect} />
          <Route path="/apps" exact component={page.Apps} />
          <Route path="/appsnew" exact component={page.AppsNew} />
          <Route path="/appsdetails" exact component={page.AppsDetails} />
          <Route path="/qrcode" exact component={page.QrCode} />
          <Route path="/nftapp" exact component={page.NftApp} />
          <Route path="/ftapp" exact component={page.FtApp} />
          <Route path="/androidapp" exact component={page.AndroidApp} />
          <Route path="/iosapp" exact component={page.IosApp} />
          <Route path="/paraphraseapp" exact component={page.ParaphraseApp} />
          
        </Switch>
        <Route />
      </Router>
    </>
  );
}
