import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/innerpages_customstyle.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import redtie_logo from '../assets/img/redtie_logo.png';
import no_image from '../assets/img/no_image.png';
import transparent from '../assets/img/transparent.png';
const $ = () => window.$;
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

export default class CreateProfile3 extends Component {
  // export default function CreateProfile3() {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    this.logout = this.logout.bind(this);
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      user_id: '',
      business_id: '',
      user_email: '',
      business_name: '',
      errors: [],
      firsttime: '',
      run: false,
      business_info_array: [],
      temp_business_id: '',
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  logout() {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });
    AsyncStorage.getItem('business_name').then((business_name) => {
      if (business_name) {
        this.setState({business_name});
        console.log('business_name');
        console.log(business_name);
      }
    });
    AsyncStorage.getItem('temp_business_id').then((temp_business_id) => {
      if (temp_business_id) {
        this.setState({temp_business_id});
        console.log('temp_business_id');
        console.log(temp_business_id);
      }
    });
    
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    setTimeout(() => {

      const businessdetils = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/info', businessdetils)
        .then((res) => {
          //   console.log(res.data);
          if (res.data.status === 'success') {
           console.log("BDtts",res?.data?.result?.business);
            this.setState({business_info_array: res?.data?.result})
          }
        })
        .catch((error) => {
          console.log(error);
        });

      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            if (res?.data?.result?.length === 1) {
              this.setState({firsttime: 'true'});
            }
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                //   console.log(res.data);
                if (res.data.status === 'success') {
                  if (res.data.result.business.payment_status === 'payment_failed') {
                    console.log('phone empty');
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data.result.business.phone_number.length > 0 &&
                    res.data.result.business.business_name.length > 0
                  ) {
                  }
                  if (
                    res.data.result.business.phone_number.length > 0 &&
                    res.data.result.business.business_name.length === 0
                  ) {
                    console.log('phone not empty');
                    console.log(res.data.result.business.phone_number);
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile2');
                  }
                  if (
                    res.data.result.business.phone_number.length == 0 &&
                    res.data.result.business.business_name.length == 0
                  ) {
                    console.log(res.data.result.business.phone_number);
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    // AsyncStorage.setItem("business_name", res.data.result[0].business_name);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile1');
                  }
                  // this.setState({business_name1: res.data.result.business.business_name})
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (res.data.status === 'no_record') {
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              this.props.history.push('/create-profile');
            }
          }
        });
    }, 100);
  }
  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
      console.log('updated');
    }
  };
  render() {
    const initial = this.state.user_email.substring(1, 0);
    const firstinitial = initial.toUpperCase();
    // console.log(firstinitial);
    if (this.state.loggedIn === false) {
      return <Redirect to="/signin" />;
    }

    return (
      <>
        <Helmet>
          <title>Redtie - Create Workspace</title>
        </Helmet>
        <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
          <div className="wrapper">
            {/* Navbar */}
            <Header_component data={this.update.bind(this)} cart_data={this.cart_update.bind(this)} />
            {/* /.navbar */}
            {/* Main Sidebar Container */} {/*Can be used when needed*/}
            <aside className="main-sidebar sidebar-light-gray-dark elevation-3" style={{display: 'none'}}>
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-2 pt-3 mb-3 d-flex flex-wrap text-center elevation-1">
                  <div className="image w-100 pl-0 pb-2">
                    <img src={no_image} className="img-circle elevation-2" alt="User Image" />
                  </div>
                  <div className="info w-100">
                    <h6 className="d-block font-weight-semibold text-white">
                      <span>{/*Alexander Pierce*/}alexander....@gmail.com</span>{' '}
                      <i className="fas fa-angle-down crsr_pntr user_menutgl f_sz18 postop_2" />
                    </h6>
                  </div>
                  <div className="user_menu w-100 all_link bg-white" style={{display: 'none'}}>
                    <a href className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-user-edit" title="Edit Account" />
                      </span>
                    </a>
                    <a href className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-file-invoice-dollar" title="Billing" />
                      </span>
                    </a>
                    <a href className="mx-4 my-3 d-inline-block fmaroon">
                      <span className="fmaroon">
                        <i className="fas fa-sign-out-alt" title="Logout" />
                      </span>
                    </a>
                  </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class
       with font-awesome or any other icon font library */}
                    <li className="nav-item">
                      <a href className="nav-link active">
                        {/*<i class="nav-icon far fa-building"></i>*/}
                        <img src="../dist/img/transparent.png" className="m_createprofile" />
                        <p>Create Workspace</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href className="nav-link">
                        {/*<i class="nav-icon fas fa-chart-line"></i>*/}
                        <img src="../dist/img/transparent.png" className="m_board isdisabled" />
                        <p>Board</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href className="nav-link">
                        {/*<i class="nav-icon fas fa-sliders-h"></i>*/}
                        <img src="../dist/img/transparent.png" className="m_settings isdisabled" />
                        <p>Settings</p>
                      </a>
                    </li>
                  </ul>
                </nav>
                {/* /.sidebar-menu */}
              </div>
              {/* /.sidebar */}
            </aside>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <div className="header_pagetitle d-block d-sm-block d-md-none">
                        <div className="text-center pt-2">{/* <h4>Congratulations!</h4> */}</div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="all_contentcntr_600">
                        <div className="all_contentflex_div mt-0 mt-sm-3 mb-5 align-items-center brdr_radius_div">
                          <div className="col-12 col-sm-12 col-md-12 px-0 brdr_radius_div">
                            <div className="all_content_div mt-3" style={{display: this.state.temp_business_id === "" ? 'block':'none'}}>
                              <div className="card w-100 mb-0 bx_shadow_sm">
                                <h3 className="successtxt text-center mt-4 mb-4 p-3 lnhgt_title">
                                  Congratulations! <br />
                                  Your Workspace for
                                  <br /> <span className="forange">{this.state.business_name}</span>
                                  <br /> is ready.
                                </h3>
                                <div className="text-center mb-5">
                                  <a
                                    onClick={() => {
                                      AsyncStorage.setItem('tour_firsttime', this.state.firsttime);
                                      AsyncStorage.setItem('temp_business_id', '');
                                    }}
                                    href="/dashboard"
                                    className="btn btn-lg btnpdng_md btn-orange">
                                    Get Started
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="all_content_div mt-3" style={{display: this.state.temp_business_id !== "" ? 'block':'none'}}>
                              <div className="card w-100 mb-0 bx_shadow_sm">
                                <h3 className="successtxt text-center mt-4 mb-4 p-3 lnhgt_title">
                                Congratulations! <br />
                                  Your Workspace for
                                  <br />{' '}
                                  <span className="forange">
                                  {this.state.business_info_array?.business?.business_name}
                                  </span>
                                  <br /> has been upgraded to{' '}
                                  <span className="fgreen4 font-weight-semibold">{this.state.business_info_array?.business?.plan_id?.plan_name}</span>.
                                </h3>
                                <div className="text-center mb-5">
                                <a
                                    onClick={() => {
                                      AsyncStorage.setItem('tour_firsttime', this.state.firsttime);
                                      AsyncStorage.setItem('temp_business_id', '');
                                    }}
                                    href="/dashboard"
                                    className="btn btn-lg btnpdng_md btn-orange">
                                    Get Started
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* Main Footer */}
            <footer className="main-footer">
              {/* Default to the left */}
              <strong>
                Copyright © 2020{' '}
                <a href className="fmaroon">
                  Redtie
                </a>
                .
              </strong>{' '}
              All rights reserved.
            </footer>
          </div>

          {/* ./wrapper */}
          {/* REQUIRED SCRIPTS */}
          {/* jQuery */}
          {/**/}
          {/* Bootstrap 4 */}
          {/* AdminLTE App */}
        </div>
      </>
    );
  }
}
