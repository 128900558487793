import React, {useEffect, Component} from 'react';
import * as ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AsyncStorage from '@callstack/async-storage';
import Select from 'react-select';
import 'react-phone-input-2/lib/style.css';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import $ from 'jquery';
import BusinessVerification from './BusinessVerification';
import BusinessVerificationButton from './BusinessVerificationButton';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
import MicRecorder from 'mic-recorder-to-mp3';
import VideoRecorder from 'react-video-recorder';
import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import {ToastContainer, toast, Flip} from 'react-toastify';
import {first} from 'lodash';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import Linkify from 'react-linkify';
import ReactTooltip from 'react-tooltip';
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import DateRangePicker from 'react-daterange-picker';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';
// import html2canvas from "html2canvas";
import XLSX from 'xlsx';
import {jsPDF} from 'jspdf';
import DataTable, {createTheme} from 'react-data-table-component';
import qr_code from '../assets/img/app/qr_code.jpg';
import nft from '../assets/img/app/nft.jpg';
import ft from '../assets/img/app/ft.jpg';
import android from '../assets/img/app/android.jpg';
import app_store from '../assets/img/app/app_store.jpg';
import paraphrase from '../assets/img/app/paraphrase.png';
var QRCode = require('qrcode.react');
var moment = require('moment');
var current_step_id = 0;
var placeholder_cmb = document.createElement('div');
placeholder_cmb.className = 'placeholder_cmb';
let Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
let docAttachmentsCount_cmb = 0;
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;
const APP_EMAIL_ENDPOINT = process.env.REACT_APP_EMAIL_ENDPOINT;
const CHAT_APP_ENDPOINT = process.env.REACT_APP_CHAT_APP_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const Mp3Recorder = new MicRecorder({bitRate: 128});
//test
var TagLstDtatable = '';
var ArchvHstryDtatble = '';
var emaildumpDtatble = '';
var TemDtatable = '';
var url_business_id = '';
var entrypntdatatbl = '';
var firsttime_entrypntdatatbl = true;
var stepsdatatbl = '';
var firsttime_stepsdatatbl = true;
var templatedatatbl = '';
var firsttime_templatedatatbl = true;
var wfcontactsdatatbl = '';
var firsttime_wfcontactsdatatbl = true;
var upload_count = 0;
var upload_counts = [];
var nft_key_count = 0;
const myUploadProgress = (myFileId) => (progress) => {
  let percentage = Math.floor((progress.loaded * 100) / progress.total);
  var finpercentage = percentage + '%';
  $('.progres_cnt' + myFileId + '').css({width: '' + finpercentage + ''});
  $('.progres_cnt' + myFileId + '').text(finpercentage);
};
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
};

var tdata = [];

const tcolumns = [
  {
    name: 'File',
    selector: 'File',
    sortable: true,
    left: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    left: true,
  },
  {
    name: 'Uploaded on',
    selector: 'Uploaded on',
    sortable: true,
    left: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    left: true,
  },
];

const ftcolumns = [
  {
    name: 'Receiver',
    selector: 'Receiver',
    sortable: true,
    left: true,
  },
  {
    name: 'Sent',
    selector: 'Sent',
    sortable: true,
    left: true,
  },
  {
    name: 'Accepted',
    selector: 'Accepted',
    sortable: true,
    left: true,
  },
  {
    name: 'No of Tokens',
    selector: 'No of Tokens',
    sortable: true,
    left: true,
  },
];

const stateDefinitions = {
  available: {
    color: null,
    label: 'Available',
  },
  enquire: {
    color: '#ffd200',
    label: 'Enquire',
  },
  booking: {
    color: '#3BEFDF',
    label: 'Booking',
  },
  waiting: {
    color: '#FF4D4D',
    label: 'Waiting',
  },
  registration: {
    color: '#B0B0F7',
    label: 'Registration',
  },
  delivery: {
    color: '#EFAD9F',
    label: 'Delivery',
  },
  testing: {
    color: '#66ff66',
    label: 'Testing',
  },
  upgrade: {
    color: '#BB6FCD',
    label: 'Upgrade',
  },
  trip: {
    color: '#5066F2',
    label: 'Trip',
  },
  renovation: {
    color: '#F3B230',
    label: 'Renovation',
  },
  cleaning: {
    color: '#ff4d4d',
    label: 'Cleaning',
  },
  carSale: {
    color: '#8bd2da',
    label: 'Car Sale',
  },
  newCar: {
    color: '#40bf80',
    label: 'New Car',
  },
  unavailable: {
    // selectable: false,
    color: '#ff80ff',
    label: 'Unavailable',
  },
};
var today = new Date();
var end_date;
var start_date;
// var jsPDF;
// var XLSX;

function validate(name, cardNumber, expirationdate, cvv) {
  const errors = [];
  if (name.length === 0) {
    errors.push("Name on card can't be empty");
    return errors;
  }

  if (cardNumber.length === 0) {
    errors.push("CardNumber can't be empty");
    return errors;
  }

  if (expirationdate.length === 0) {
    errors.push("Expiration Date can't be empty");
    return errors;
  }
  if (cvv.length === 0) {
    errors.push("Cvv can't be empty");
    return errors;
  }

  return errors;
}
var gapi = window.gapi;
var CLIENT_ID = process.env.REACT_APP_V4_CAL_CLIENT_ID;
var CLIENT_SECRET = process.env.REACT_APP_V4_CAL_CLIENT_SECRET;
var API_KEY_gapi = process.env.REACT_APP_V4_CAL_API_KEY;
var DISCOVERY_DOCS = process.env.REACT_APP_V4_CAL_DOCS;
var SCOPE = process.env.REACT_APP_V4_CAL_SCOPE;
const editorConfiguration = {
  // plugins: [ Essentials, Bold, Italic, Paragraph ],
  // toolbar: [ 'bold', 'italic' ],
  link: {
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
};
class AppsDetails extends Component {
  constructor(props) {
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          '|',
          'blockQuote',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo',
        ],
      },

      language: 'en',
    };
    super(props);
    // this.delete_start_point = this.delete_start_point.bind(this);
    this.start_fn = this.start_fn.bind(this);
    this.archivehistory_tgl = this.archivehistory_tgl.bind(this);
    this.summaryalert_tgl = this.summaryalert_tgl.bind(this);
    this.autotxtrespnse_tgl = this.autotxtrespnse_tgl.bind(this);
    this.autotextresponsemedia_tgl = this.autotextresponsemedia_tgl.bind(this);
    this.autotextresponsemedia_del = this.autotextresponsemedia_del.bind(this);
    this.autotextresponsemedia_save = this.autotextresponsemedia_save.bind(this);
    this.voicemsg_tgl = this.voicemsg_tgl.bind(this);
    this.voice_message = this.voice_message.bind(this);
    this.autovoiceresponse_tgl = this.autovoiceresponse_tgl.bind(this);
    this.autovoiceresp = this.autovoiceresp.bind(this);
    this.bdaygreetings_tgl = this.bdaygreetings_tgl.bind(this);
    this.profemailaddr_edit = this.profemailaddr_edit.bind(this);
    this.webhookshistory_tgl1 = this.webhookshistory_tgl1.bind(this);
    this.webhookshistory_tgl2 = this.webhookshistory_tgl2.bind(this);
    this.addwebhook_clk = this.addwebhook_clk.bind(this);
    this.addwebhook_close = this.addwebhook_close.bind(this);
    this.apicreate_clk = this.apicreate_clk.bind(this);
    this.tagcreate_clk = this.tagcreate_clk.bind(this);
    this.addinttag_close = this.addinttag_close.bind(this);
    this.psphrase_addtagname = this.psphrase_addtagname.bind(this);
    this.psphrase_closeaddtagname = this.psphrase_closeaddtagname.bind(this);
    this.qrcodecreate_clk = this.qrcodecreate_clk.bind(this);
    this.addqrcode_close = this.addqrcode_close.bind(this);
    this.qrcodehistory_tgl1 = this.qrcodehistory_tgl1.bind(this);
    this.qrcodehistory_tgl2 = this.qrcodehistory_tgl2.bind(this);
    this.phrasecreate_clk = this.phrasecreate_clk.bind(this);
    this.addintphrase_close = this.addintphrase_close.bind(this);
    this.psqrcode_addtagname = this.psqrcode_addtagname.bind(this);
    this.psqrcode_closeaddtagname = this.psqrcode_closeaddtagname.bind(this);
    this.gmailconnect_clk = this.gmailconnect_clk.bind(this);
    this.qrcodesendmsgattach_clk = this.qrcodesendmsgattach_clk.bind(this);
    this.archiveMode = this.archiveMode.bind(this);
    this.ViewArchiveOnOff = this.ViewArchiveOnOff.bind(this);
    this.onClickTagTxtboxAdder = this.onClickTagTxtboxAdder.bind(this);
    this.onClickTagTxtboxSubmit = this.onClickTagTxtboxSubmit.bind(this);
    this.EditTagNameClick = this.EditTagNameClick.bind(this);
    this.DelTag = this.DelTag.bind(this);
    this.chkInboundtag = this.chkInboundtag.bind(this);
    this.saveSmryalrt = this.saveSmryalrt.bind(this);
    this.chkAllTem = this.chkAllTem.bind(this);
    this.receiveAlrtChk = this.receiveAlrtChk.bind(this);
    this.reSetSmryalrt = this.reSetSmryalrt.bind(this);
    this.AutoTxtPlsclick = this.AutoTxtPlsclick.bind(this);
    this.AutoTxtSaveclick = this.AutoTxtSaveclick.bind(this);
    this.SaveVoiceGrrtng = this.SaveVoiceGrrtng.bind(this);
    this.AtoVcRspnsSaveClick = this.AtoVcRspnsSaveClick.bind(this);
    this.SaveHngMsgwithout = this.SaveHngMsgwithout.bind(this);
    this.DelVoiceGrrtng = this.DelVoiceGrrtng.bind(this);
    this.atoVcRspnLevMsgDelClk = this.atoVcRspnLevMsgDelClk.bind(this);
    this.DelHngMsgwithout = this.DelHngMsgwithout.bind(this);
    this.saveBrthgrtng = this.saveBrthgrtng.bind(this);
    this.DeltBrthgrtng = this.DeltBrthgrtng.bind(this);
    this.VoiceTypeClick = this.VoiceTypeClick.bind(this);
    this.generateVoice_play = this.generateVoice_play.bind(this);
    this.generateVoice_pause = this.generateVoice_pause.bind(this);
    this.ResetVoiceGenClk = this.ResetVoiceGenClk.bind(this);
    this.voiceGnrteTextSave = this.voiceGnrteTextSave.bind(this);
    this.voiceGnrteTextDelete = this.voiceGnrteTextDelete.bind(this);
    this.AutoTxtTtlDel = this.AutoTxtTtlDel.bind(this);
    this.UnChkInbndTg = this.UnChkInbndTg.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onchangebusiness_info_name = this.onchangebusiness_info_name.bind(this);
    this.onchangebusiness_info_details = this.onchangebusiness_info_details.bind(this);
    this.business_update = this.business_update.bind(this);
    this.onChangeprefix = this.onChangeprefix.bind(this);
    this.loadmore = this.loadmore.bind(this);
    this.regloadmore = this.regloadmore.bind(this);
    this.prefixsearch = this.prefixsearch.bind(this);
    this.regionsearch = this.regionsearch.bind(this);
    this.onChangeregion = this.onChangeregion.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.num_validate = this.num_validate.bind(this);
    this.first_level_wf = [];
    this.timer = null;
    this.savetimer = null;
    this.state = {
      errors: [],
      success: [],
      business_List: [],
      business_id: '',
      user_id: '',
      user_email: '',
      business_info_name: '',
      business_email_address: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      business_email_name: '',
      business_info_phone: '',
      business_info_details: '',
      business_info_array: [],
      archiveHstry_detail: [],
      tagList: [],
      tagTxtBox: [''],
      TagMsgClss: '',
      TagMsg: '',
      EditTagName: '',
      EditTagId: '',
      SaveTagBtnDsble: false,
      EditTagBtnDsble: false,
      TeamMbrLst: [],
      SummaryAlerts: [],
      TmRcvdalrt: '',
      smryTeamIds: [],
      chkdTemMbrsIds: [],
      TemIdsMebrArry: [],
      TemIdsMebrArry_chat: [],
      VwArchiewDisbl: true,
      AutoTxtArea: [''],
      AutotxtMxRspnsVlue: '',
      isRecording: false,
      VoiceGrtngblobURL: '',
      isBlocked: false,
      VoiceGrtngRecUrl: '',
      ViceGrtMsg: '',
      ViceGrtcls: '',
      LeavAutoVceMsgVle: '',
      LeavAutoVoiceMxmRspnsVlu: '',
      AtoViceResMsg: '',
      AtoViceRescls: '',
      HangMsgValue: '',
      birthdyTxtValue: '',
      bithGrtngTime: '12:00 AM',
      bithGrtngTimeZone: 'GMT-1200',
      brtGrtMsg: '',
      brtGrtcls: '',
      bithGrtngTimeSelt: '',
      bithGrtngTimeZoneSelt: '',
      TxtVoiceGrtngvlue: '',
      TxtTypeVoiceUrl: '',
      VoiceLngRegn: 'en-US',
      getVoiceTypeMlrFml: '',
      GrntVoiveLngRegin: [],
      VoceLstBsOnLanguge: [],
      generatePlayDisble: true,
      voiceGnrteResetdisble: true,
      VoiceGrtngRecDeltDisble: true,
      VoiceGrtngTxtTypDeltDisble: true,
      leaveMsgDelDisble: true,
      hangupMsgDelDisble: true,
      brthdyGrtngDelDisble: true,
      VoiceClksId: '',
      AutotxtListUniqId: '',
      AtoTxtClsNm: '',
      AtoTxtMsg: '',
      AtoTxDelClkDisble: true,
      max_chars_profilename: 32,
      chars_left_profilename: 32,
      max_chars_description: 200,
      chars_left_description: 200,
      business_info_name_blur: false,
      business_info_details_blur: false,
      unsupportedtext: '',
      unsupportedtext_unique_id: '',
      qr_title: '',
      qr_description: '',
      qr_message: '',
      qr_filtered_tag_list: [],
      qr_filtered_tag_list_up: [],
      qr_newtag: '',
      qr_download_link: '',
      qr_addtag_save: false,
      qr_code_list: [],
      qr_code_history: [],
      qr_code_image_edit: '',
      qr_code_id_edit: '',
      message_edit: false,
      QRGrtMsg: '',
      QRGrtcls: '',
      chat_filtered_tag_list: [],
      chat_newtag: '',
      chat_addtag_save: false,
      ChatGrtMsg: '',
      ChatGrtcls: '',
      EmlAlrtMsg: '',
      EmlAlrtCls: '',
      chat_grtmsg: '',
      chat_replymsg: '',
      chat_newcontactmsg: '',
      chat_existcontactmsg: '',
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 14 / 12,
      },
      croppedImage: '',
      prfImag: '',
      active_edit_id: '',
      cmb_notesedit: false,
      savingprogress: '',
      notesValue2_cmb: '',
      messageAttachd_cmb: false,
      attchmnt_audioId_cmb: [],
      attchmnt_videoId_cmb: [],
      attchmnt_FileDocId_cmb: [],
      attchmnt_UrlIdDets_cmb: [],
      DcfilNm_cmb: '',
      attchmnt_audioIdUrl_cmb: [],
      Rnunique_id_cmb: '',
      atcharryDet_cmb: [],
      lodingicon_cmb: '',
      businessprofileplandetails: {},
      attachmentlimit_count: 10,
      attachOrderIncrs_cmb: 1,
      attachmentdoc_count_cmb: 0,
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      slackGrtcls: '',
      slackGrtMsg: '',
      run: false,

      stepIndex: 0,
      booster_pack_list: [],
      boosterpack_active_id: '',
      price_quantity_list: [],
      pack_quantity_message: 1,
      pack_quantity_contact: 1,
      packcart_list: [],
      order_data_count: '',
      order_doller: '',
      order_amount: '',
      card_name: '',
      card_number: '',
      exp_date: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      cardList: [],
      existcard_id: '',
      exist_cart_id: '',
      isLoading: false,
      cartmsg: '',
      cartcls: '',
      pack_history_list: [],
      cart_updated_count: 0,
      //workflow
      workflow_history_data: {},
      workflow_name: '',
      workflow_description: '',
      workflow_list_array: [],
      workflow_status_data: {},
      workflow_entry_list_array: [],
      workflow_edit_data: {},
      workflow_edit_name: '',
      workflow_editenable: false,
      send_filtered_tag_list_up: [],
      tagcnt_list: [],
      compsrch_txt: '',
      cttagactiv_id: '',
      tags_contact_count: 0,
      contactscount_tag: 0,
      contactscount_tag_elsif: 0,
      tag_list: [],
      inbound_cond: '',
      inbound_content: '',
      edit_entrypnt_tags: false,
      edit_entrypnt_tags_data: {},
      edit_ifentrypnt_tags: false,
      edit_ifentrypnt_tags_data: {},
      edit_entrypnt_inbound_data: {},
      edit_entrypnt_inbound: false,
      disable_tag: false,
      disable_contact: false,
      disable_inbound: false,
      contacts: [],
      contacts_archive: [],
      contact_id: [],
      search_content: '',
      edit_entrypnt_contacts: false,
      edit_entrypnt_contacts_data: {},

      //WF_STEPS
      firstlevel_click: false,
      workflow_step_id_create: '',
      Workflow_step_if_else_data: '',
      workflow_steps_list_array: [],
      edit_startpnt_msg: false,
      edit_startpnt_msg_data: {},
      edit_startpnt_msg_data_steps: '',
      disable_send_msg: false,
      workflow_msg: '',
      chars_left_wfmsg: 300,
      max_chars_wfmsg: 300,

      workflow_delay_count: '',
      edit_startpnt_delay: false,
      edit_startpnt_delay_steps: '',
      edit_startpnt_elseif_steps: '',
      edit_startpnt_elseif_steps_ifelse_condition: '',
      edit_startpnt_delay_data: {},
      workflow_delay_time: '',
      disable_start_delay: false,

      inbound_content_ifelse: '',
      inbound_cond_ifelse: '',
      edit_step_inbound_data: {},
      edit_step_inbound_data_steps: '',
      edit_step_inbound: false,
      workflow_entry_contact_array: [],
      workflow_entry_contact_data: [],
      workflow_steps_contact_array: [],
      workflow_steps_contact_array_data: [],

      workflow_contacts_list_arr: [],
      workflow_contacts_list_data: [],
      workflow_contacts_list_type: '',

      //Templates
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      max_chars_templ_msg: 300,
      templ_msg_update_details: {},
      templ_message_edit: false,

      //Auto_text attachments
      active_class_attachment: '',

      //date picker
      dates: null,
      value: '',
      states: '',
      setYear: new Date().getFullYear(),
      setMonth: new Date().getMonth(),
      date: new Date(),
      start: '',
      end: '',
      showNewMonth: true,
      archive_search_keyword: '',
      archive_search_type: 'all',
      archive_search_contact: '',
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      scResultContactList: [],
      contacts_available_archived: [],
      show_download_all: false,
      //Email dump
      dump_members: 3,
      dump_emails: [''],
      emaildump_Hstry_detail: [],

      //contact select
      checked: [],
      dropDownValue: [],
      user_views_list_arr: [],
      company_list: [],

      //NFT:
      nft_details: {},
      nft_token: {},
      nft_token_file_extension: '',
      nft_tokens: [],
      table_view: false,
      nft_name: '',
      nft_decription: '',
      nft_file_name: '',
      nft_file_url: '',
      nft_thumb_file_url: '',
      nft_keys: '',
      nft_file_type: '',
      user_details: {},
      nft_minted_tokens: [],
      nft_minted_sent_tokens: [],
      nft_minted_accepted_tokens: [],
      nft_mint_click: false,
      nft_minted_tokens_lists: [],
      nft_sent_tokens_lists: [],
      nft_accept_tokens_lists: [],
      nft_available_cnt: 0,
      nft_sent_cnt: 0,
      nft_accept_cnt: 0,
      nft_available_load_more: true,
      nft_sent_load_more: true,
      nft_accept_load_more: true,
      nft_load_more: false,
      nft_total_count: 0,
      webhookdetails: [],
      webhookdetails_count: 0,
      ft_table_details: [],
      ft_load_more: false,
      ft_load_more_start: '0',
      ft_load_more_button: true,
      plivolist: [],
      rplivolist: [],
      prefix: '',
      plivonumber: '',
      limit: 7,
      number_click: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.packcart_list) !== JSON.stringify(this.state.packcart_list)) {
      this.setState({cart_updated_count: this.state.cart_updated_count + 1});
      console.log('cart_updated_count', this.state.cart_updated_count);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.notesValue2_cmb !== nextState.notesValue2_cmb) {
      // this.autoupdateNotes2_cmb();
      return false;
    } else {
      return true;
    }
  }
  without_refresh = () => {
    this.setState({
      errors: [],
      success: [],
      business_List: [],
      business_id: '',
      user_id: '',
      user_email: '',
      business_info_name: '',
      business_email_address: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      business_email_name: '',
      business_info_phone: '',
      business_info_details: '',
      business_info_array: [],
      archiveHstry_detail: [],
      tagList: [],
      tagTxtBox: [''],
      TagMsgClss: '',
      TagMsg: '',
      EditTagName: '',
      EditTagId: '',
      SaveTagBtnDsble: false,
      EditTagBtnDsble: false,
      TeamMbrLst: [],
      SummaryAlerts: [],
      TmRcvdalrt: '',
      smryTeamIds: [],
      chkdTemMbrsIds: [],
      TemIdsMebrArry: [],
      TemIdsMebrArry_chat: [],
      VwArchiewDisbl: true,
      AutoTxtArea: [''],
      AutotxtMxRspnsVlue: '',
      isRecording: false,
      VoiceGrtngblobURL: '',
      isBlocked: false,
      VoiceGrtngRecUrl: '',
      ViceGrtMsg: '',
      ViceGrtcls: '',
      LeavAutoVceMsgVle: '',
      LeavAutoVceMsgVle_unique_id: '',
      LeavAutoVoiceMxmRspnsVlu: '',
      AtoViceResMsg: '',
      AtoViceRescls: '',
      HangMsgValue: '',
      birthdyTxtValue: '',
      birthdyTxtValue_unique_id: '',
      bithGrtngTime: '12:00 AM',
      bithGrtngTimeZone: 'GMT-1200',
      brtGrtMsg: '',
      brtGrtcls: '',
      bithGrtngTimeSelt: '',
      bithGrtngTimeZoneSelt: '',
      TxtVoiceGrtngvlue: '',
      TxtTypeVoiceUrl: '',
      VoiceLngRegn: 'en-US',
      getVoiceTypeMlrFml: '',
      GrntVoiveLngRegin: [],
      VoceLstBsOnLanguge: [],
      generatePlayDisble: true,
      voiceGnrteResetdisble: true,
      VoiceGrtngRecDeltDisble: true,
      VoiceGrtngTxtTypDeltDisble: true,
      leaveMsgDelDisble: true,
      hangupMsgDelDisble: true,
      brthdyGrtngDelDisble: true,
      VoiceClksId: '',
      AutotxtListUniqId: '',
      AtoTxtClsNm: '',
      AtoTxtMsg: '',
      AtoTxDelClkDisble: true,
      max_chars_profilename: 32,
      chars_left_profilename: 32,
      max_chars_description: 200,
      chars_left_description: 200,
      business_info_name_blur: false,
      business_info_details_blur: false,
      unsupportedtext: '',
      qr_title: '',
      qr_description: '',
      qr_message: '',
      qr_filtered_tag_list: [],
      qr_filtered_tag_list_up: [],
      qr_newtag: '',
      qr_download_link: '',
      qr_addtag_save: false,
      qr_code_list: [],
      qr_code_history: [],
      qr_code_image_edit: '',
      qr_code_id_edit: '',
      message_edit: false,
      QRGrtMsg: '',
      QRGrtcls: '',
      chat_filtered_tag_list: [],
      chat_newtag: '',
      chat_addtag_save: false,
      ChatGrtMsg: '',
      ChatGrtcls: '',
      EmlAlrtMsg: '',
      EmlAlrtCls: '',
      chat_grtmsg: '',
      chat_replymsg: '',
      chat_newcontactmsg: '',
      chat_existcontactmsg: '',
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 14 / 12,
      },
      croppedImage: '',
      prfImag: '',
      active_edit_id: '',
      cmb_notesedit: false,
      savingprogress: '',
      notesValue2_cmb: '',
      messageAttachd_cmb: false,
      attchmnt_audioId_cmb: [],
      attchmnt_videoId_cmb: [],
      attchmnt_FileDocId_cmb: [],
      attchmnt_UrlIdDets_cmb: [],
      DcfilNm_cmb: '',
      attchmnt_audioIdUrl_cmb: [],
      Rnunique_id_cmb: '',
      atcharryDet_cmb: [],
      lodingicon_cmb: '',
      businessprofileplandetails: {},
      attachmentlimit_count: 0,
      attachOrderIncrs_cmb: 1,
      attachmentdoc_count_cmb: 0,
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      slackGrtcls: '',
      slackGrtMsg: '',
      run: false,
      stepIndex: 0,
      booster_pack_list: [],
      boosterpack_active_id: '',
      price_quantity_list: [],
      pack_quantity_message: 1,
      pack_quantity_contact: 1,
      packcart_list: [],
      order_data_count: '',
      order_doller: '',
      order_amount: '',
      card_name: '',
      card_number: '',
      exp_date: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      cardList: [],
      existcard_id: '',
      exist_cart_id: '',
      isLoading: false,
      cartmsg: '',
      cartcls: '',
      pack_history_list: [],
      cart_updated_count: 0,
      //workflow
      workflow_history_data: {},
      workflow_name: '',
      workflow_description: '',
      workflow_list_array: [],
      workflow_status_data: {},
      workflow_entry_list_array: [],
      workflow_edit_data: {},
      workflow_edit_name: '',
      workflow_editenable: false,
      send_filtered_tag_list_up: [],
      tagcnt_list: [],
      compsrch_txt: '',
      cttagactiv_id: '',
      tags_contact_count: 0,
      contactscount_tag: 0,
      contactscount_tag_elsif: 0,
      tag_list: [],
      inbound_cond: '',
      inbound_content: '',
      edit_entrypnt_tags: false,
      edit_entrypnt_tags_data: {},
      edit_ifentrypnt_tags: false,
      edit_ifentrypnt_tags_data: {},
      edit_entrypnt_inbound_data: {},
      edit_entrypnt_inbound: false,
      disable_tag: false,
      disable_contact: false,
      disable_inbound: false,
      contacts: [],
      contacts_archive: [],
      contact_id: [],
      search_content: '',
      edit_entrypnt_contacts: false,
      edit_entrypnt_contacts_data: {},

      //WF_STEPS
      firstlevel_click: false,
      workflow_step_id_create: '',
      Workflow_step_if_else_data: '',
      workflow_steps_list_array: [],
      edit_startpnt_msg: false,
      edit_startpnt_msg_data: {},
      edit_startpnt_msg_data_steps: '',
      disable_send_msg: false,
      workflow_msg: '',
      chars_left_wfmsg: 300,
      max_chars_wfmsg: 300,

      workflow_delay_count: '',
      edit_startpnt_delay: false,
      edit_startpnt_delay_steps: '',
      edit_startpnt_elseif_steps: '',
      edit_startpnt_elseif_steps_ifelse_condition: '',
      edit_startpnt_delay_data: {},
      workflow_delay_time: '',
      disable_start_delay: false,

      inbound_content_ifelse: '',
      inbound_cond_ifelse: '',
      edit_step_inbound_data: {},
      edit_step_inbound_data_steps: '',
      edit_step_inbound: false,
      workflow_entry_contact_array: [],
      workflow_entry_contact_data: [],
      workflow_steps_contact_array: [],
      workflow_steps_contact_array_data: [],

      workflow_contacts_list_arr: [],
      workflow_contacts_list_data: [],
      workflow_contacts_list_type: '',

      //Templates
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      max_chars_templ_msg: 300,
      templ_msg_update_details: {},
      templ_message_edit: false,

      //date picker
      dates: null,
      value: '',
      states: '',
      setYear: new Date().getFullYear(),
      setMonth: new Date().getMonth(),
      date: new Date(),
      start: '',
      end: '',
      showNewMonth: true,
      archive_search_keyword: '',
      archive_search_type: 'all',
      archive_search_contact: '',
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      scResultContactList: [],
      contacts_available_archived: [],
      show_download_all: false,

      //Email dump
      dump_members: 3,
      dump_emails: [''],
      emaildump_Hstry_detail: [],

      //contact select
      checked: [],
      dropDownValue: [],
      user_views_list_arr: [],
      company_list: [],
      //NFT:
      nft_details: {},
      nft_token: {},
      nft_token_file_extension: '',
      nft_tokens: [],
      table_view: false,
      nft_name: '',
      nft_decription: '',
      nft_file_name: '',
      nft_file_url: '',
      nft_thumb_file_url: '',
      nft_keys: '',
      nft_file_type: '',
      nft_minted_tokens: [],
      nft_minted_sent_tokens: [],
      nft_minted_accepted_tokens: [],
      nft_mint_click: false,
      nft_minted_tokens_lists: [],
      nft_sent_tokens_lists: [],
      nft_accept_tokens_lists: [],
      nft_available_cnt: 0,
      nft_sent_cnt: 0,
      nft_accept_cnt: 0,
      nft_available_load_more: true,
      nft_sent_load_more: true,
      nft_accept_load_more: true,
      nft_load_more: false,
      nft_total_count: 0,
      webhookdetails: [],
      webhookdetails_count: 0,
      ft_table_details: [],
      ft_load_more: false,
      ft_load_more_start: '0',
      ft_load_more_button: true,
      plivolist: [],
      rplivolist: [],
      prefix: '',
      plivonumber: '',
      limit: 7,
      number_click: false,
    });
    // this.start_fn();

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        url_business_id = business_id;
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });

    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    const pack_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    setTimeout(() => {
      $('.receiptnft_div').attr({style: 'display:none'});
      let url = this.props.location.search;
      let params = queryString.parse(url);
      console.log('params', params);

      if (params.integrations_list_qrcode) {
        $('.main_content_div').hide();
        $('.qr_code_div').show();
        this.props.history.push('/appsdetails');
      } else if (params.nft_lists) {
        $('.main_content_div').hide();
        $('.nft_div').show();
        // $('.nav_links_prof').removeClass('active');
        // $('.nav_links_prof_tabs').removeClass('active show');
        // $('#custom-tabs-general-tab').removeClass('active');
        // $('#custom-tabs-nft-tab').addClass('active');
        // $('#custom-tabs-general').removeClass('active show');
        // $('#custom-tabs-nft').addClass('active show');
        // if (params.tab === 'available') {
        //   $('#custom-tabs-nftavailable-tab').trigger('click');
        // } else if (params.tab === 'sent') {
        //   $('#custom-tabs-nftinprocess-tab').trigger('click');
        // } else if (params.tab === 'accept') {
        //   $('#custom-tabs-nftsent-tab').trigger('click');
        // }
        this.props.history.push('/appsdetails');
      } else if (params.ft_lists) {
        $('.main_content_div').hide();
        $('.ft_div').show();
        // $('.nav_links_prof').removeClass('active');
        // $('.nav_links_prof_tabs').removeClass('active show');
        // $('#custom-tabs-general-tab').removeClass('active');
        // $('#custom-tabs-ft-tab').addClass('active');
        // $('#custom-tabs-general').removeClass('active show');
        // $('#custom-tabs-ft').addClass('active show');
        this.props.history.push('/appsdetails');
      } else if (params.nft_cont) {
        $('.nav_links_prof').removeClass('active');
        $('.nav_links_prof_tabs').removeClass('active show');
        $('#custom-tabs-general-tab').removeClass('active');
        $('#custom-tabs-nft-tab').addClass('active');
        $('#custom-tabs-general').removeClass('active show');
        $('#custom-tabs-nft').addClass('active show');
        this.props.history.push('/appsdetails');
      }

      this.closeReceipt();
      this.nftDetails();
      this.NftCount();
      this.ft_table_data();
      this.qrcode_listclick();
      $('#ft_token_name').val('');
      $('#ft_token_symbol').val('');
      $('#ft_token_number').val('');
      $('.ftrequest_btn').show();
      $('.ftrequest_confirm_btn').hide();
      $('.ftrequest_confirm_message').hide();
      console.log('worlflow1');
    }, 100);

    setTimeout(() => {
      const tagLst_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      console.log(tagLst_details);
      axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({tagList: res.data.result, tag_list: res.data.result});

          // TagLstDtatable = $('#inttags_tbl').DataTable({
          //   paging: true,
          //   lengthChange: false,
          //   ordering: false,
          //   searching: false,
          //   //"responsive": true,
          // });
        }
      });
    }, 100);

    setTimeout(() => {
      const business_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios.get(API_CALL_ENDPOINT + '/business/info', business_details).then((res) => {
        console.log('/business/infosss3', res.data);
        $('#nft_token_name').val('');
        $('#nft_token_symbol').val('');
        $('#token_limits').val('');
        $('.key_vals').val('');
        $('.append_divs').remove();
        $('#keyrequired0').prop('checked', false);
        // if ($('#custom-tabs-workspace_id-tab').hasClass('active')) {
        //   if (res.data.result?.busines_evaluation_details?.status == 'pending') {
        //     toast.dark(
        //       'Thank you for your patience, please note that your workspace is under verification. We will get back to you upon verification via email.',
        //       {
        //         position: 'top-right',
        //         transition: Flip,
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //       },
        //     );
        //     return false;
        //   } else if (
        //     !res.data.result?.busines_evaluation_details ||
        //     res.data.result?.busines_evaluation_details?.status != 'approved' ||
        //     res.data.result?.busines_evaluation_details?.otp_status != 'verified'
        //   ) {
        //     $('.business_evaluation_popup').trigger('click');
        //   }
        // }
        if (res.data.result) {
          this.setState({
            business_info_array: res.data?.result,
          });
        }
      });

      const contactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
        .then((res) => {
          this.setState({
            contacts: res.data?.result?.contacts,
            contacts_archive: res.data?.result?.contacts,
          });

          var conList = [];
          for (var i in res.data?.result?.contacts) {
            if (res.data?.result?.contacts[i].text_archive !== 'false') {
              // console.log(res.data?.result?.contacts[i]._id);
              var name =
                res.data?.result?.contacts[i].first_name == 'Guest'
                  ? '+91 ' +
                    res.data?.result?.contacts[i].phone_number
                      .toString()
                      .substring(1)
                      .replace(/[^\dA-Z]/g, '')
                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                      .trim()
                  : res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
              conList.push({
                name: name,
                value: res.data?.result?.contacts[i]._id,
                tags: res.data?.result?.contacts[i].tags,
                contact: res.data?.result?.contacts[i],
              });
            }
          }
          this.setState({dropDownValue: conList});
        })
        .catch((error) => {});
    }, 100);
  };
  componentDidMount() {
    this.start_fn();

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        url_business_id = business_id;
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });

    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    const user_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
      if (res.data.result) {
        this.setState({
          user_details: res.data.result.user,
        });
      }
    });
    const pack_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    setTimeout(() => {
      let url = this.props.location.search;
      let params = queryString.parse(url);
      console.log('params', params);
      if (params.integrations_list_qrcode) {
        $('.main_content_div').hide();
        $('.qr_code_div').show();
        this.props.history.push('/appsdetails');
      } else if (params.nft_lists) {
        $('.main_content_div').hide();
        $('.nft_div').show();
        // $('.nav_links_prof').removeClass('active');
        // $('.nav_links_prof_tabs').removeClass('active show');
        // $('#custom-tabs-general-tab').removeClass('active');
        // $('#custom-tabs-nft-tab').addClass('active');
        // $('#custom-tabs-general').removeClass('active show');
        // $('#custom-tabs-nft').addClass('active show');
        // if (params.tab === 'available') {
        //   $('#custom-tabs-nftavailable-tab').trigger('click');
        // } else if (params.tab === 'sent') {
        //   $('#custom-tabs-nftinprocess-tab').trigger('click');
        // } else if (params.tab === 'accept') {
        //   $('#custom-tabs-nftsent-tab').trigger('click');
        // }
        this.props.history.push('/appsdetails');
      } else if (params.ft_lists) {
        $('.main_content_div').hide();
        $('.ft_div').show();
        // $('.nav_links_prof').removeClass('active');
        // $('.nav_links_prof_tabs').removeClass('active show');
        // $('#custom-tabs-general-tab').removeClass('active');
        // $('#custom-tabs-ft-tab').addClass('active');
        // $('#custom-tabs-general').removeClass('active show');
        // $('#custom-tabs-ft').addClass('active show');
        this.props.history.push('/appsdetails');
      } else if (params.nft_cont) {
        $('.nav_links_prof').removeClass('active');
        $('.nav_links_prof_tabs').removeClass('active show');
        $('#custom-tabs-general-tab').removeClass('active');
        $('#custom-tabs-nft-tab').addClass('active');
        $('#custom-tabs-general').removeClass('active show');
        $('#custom-tabs-nft').addClass('active show');
        this.props.history.push('/appsdetails');
      }

      this.closeReceipt();
      this.nftDetails();
      this.NftCount();
      this.ft_table_data();
      this.qrcode_listclick();
    }, 100);

    setTimeout(() => {
      const card_List = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/stripe/cards/list', card_List)
        .then((res) => {
          console.log(res.data.result);

          if (res.data.result) {
            this.setState({cardList: res.data.result.cards});
          } else {
            console.log('no cards found');
            this.setState({cardList: []});
            document.getElementById('choosecrd').style.display = 'none';
          }
        })
        .catch((error) => {
          console.log(error);
        });
      const tagLst_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      console.log(tagLst_details);
      axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({tagList: res.data.result, tag_list: res.data.result});

          TagLstDtatable = $('#inttags_tbl').DataTable({
            paging: true,
            lengthChange: false,
            ordering: false,
            searching: false,
            //"responsive": true,
          });
        }
      });
    }, 100);

    setTimeout(() => {
      const assigned_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(assigned_details);
      axios.get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState(() => ({
            TeamMbrLst: res.data.result.assigned_user,
          }));

          var jnAry = [];
          for (var i in res.data.result.assigned_user) {
            jnAry.push(res.data.result.assigned_user[i].user_info[0]._id);
          }

          this.setState({TemIdsMebrArry: jnAry});
          this.setState({TemIdsMebrArry_chat: jnAry});

          TemDtatable = $('#profteammember').DataTable({
            paging: true,
            lengthChange: false,
            ordering: false,
            searching: false,
          });
          $('#emailprofteammember').DataTable({
            paging: true,
            lengthChange: false,
            ordering: false,
            searching: false,
          });
        }
      });
    }, 100);

    setTimeout(() => {
      const VoiceLanguage_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      console.log(VoiceLanguage_details);
      axios.get(API_CALL_ENDPOINT + '/voice/voice_list', VoiceLanguage_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({GrntVoiveLngRegin: res.data.result.voice_list});

          for (var i in res.data.result.voice_list) {
            if (res.data.result.voice_list[i]._id == 'en-US') {
              console.log(res.data.result.voice_list[i].voice_list);
              this.setState({VoceLstBsOnLanguge: res.data.result.voice_list[i].voice_list});
            }
          }
        }
      });
    }, 100);

    setTimeout(() => {
      const AutotxtRes_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      console.log(AutotxtRes_details);
      axios.get(API_CALL_ENDPOINT + '/business/autotext/list', AutotxtRes_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({AutoTxtArea: res.data.result});
        }
      });
    }, 100);

    setTimeout(() => {
      const businessList = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          console.log(res.data);
          // console.log(res.data.result[0].phone_number.length)
          if (res.data.status === 'success') {
            this.setState({business_List: res.data.result});
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                console.log('/business/info5', res.data);
                //   console.log(res.data);
                if (res.data.status === 'success') {
                  if (
                    res.data?.result?.business.payment_status === 'payment_failed' &&
                    res.data?.result?.business.status !== 'active' &&
                    res.data?.result?.business.status !== 'suspended'
                  ) {
                    console.log('phone empty');
                    AsyncStorage.setItem('business_id', res.data?.result?.business._id);
                    AsyncStorage.setItem('plan_id', res.data?.result?.business.plan_id);
                    AsyncStorage.setItem('user_id', res.data?.result?.business.user_id);

                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data?.result?.business.phone_number.length === 0 &&
                    res.data?.result?.business.business_name.length === 0
                  ) {
                    // console.log("phone empty")
                    // AsyncStorage.setItem("business_id", res.data.result[0]._id);
                    // AsyncStorage.setItem("plan_id", res.data.result[0].plan_id);
                    this.props.history.push('/create-profile1');
                  }
                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length === 0
                  ) {
                    // console.log("phone not empty")
                    // console.log(res.data.result[0].phone_number)
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    // AsyncStorage.setItem("business_id", res.data.result[0]._id);
                    // AsyncStorage.setItem("plan_id", res.data.result[0].plan_id);
                    this.props.history.push('/create-profile2');
                  }
                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length > 0
                  ) {
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    // AsyncStorage.setItem("business_id", res.data.result[0]._id);
                    // console.log("phone not empty")
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (res.data.status === 'no_record') {
            // window.location.reload(true);
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              // window.location.reload(true);
              this.props.history.push('/create-profile');
            }
          }
        });
      const business_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios.get(API_CALL_ENDPOINT + '/business/info', business_details).then((res) => {
        console.log('/business/info6', res.data);
        if (res.data.result) {
          this.setState({
            business_info_array: res.data?.result,
            business_name1: res.data?.result?.business.business_name,
            business_color_code: res.data?.result?.business.color_code,
            prfImag: res?.data?.result?.business?.profile_image,
          });
          if (res.data.result.business.phone_number) {
            const prefix = res.data.result.business.phone_number.substring(1, 0);

            if (prefix == '1' || prefix == '0') {
              res.data.result.business.phone_number = res.data.result.business.phone_number.substring(1);
              res.data.result.business.phone_number = res.data.result.business.phone_number
                .replace(/[^\dA-Z]/g, '')
                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                .trim();
              this.setState({business_info_phone: res.data.result.business.phone_number});
            } else {
              res.data.result.business.phone_number = res.data.result.business.phone_number
                .replace(/[^\dA-Z]/g, '')
                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                .trim();

              this.setState({business_info_phone: res.data.result.business.phone_number});
            }
          }
          this.setState(() => ({
            prfImag: res.data.result.business.profile_image,
            business_info_name: res.data.result.business.business_name,
            business_email_address: res?.data?.result?.business?.email_address,
            business_email_name: res?.data?.result?.business?.email_address.substring(
              0,
              res?.data?.result?.business?.email_address.lastIndexOf('@'),
            ),
            chars_left_profilename: this.state.max_chars_profilename - res.data.result.business.business_name.length,
            // business_info_phone:res.data.result.business.phone_number,
            business_info_details: res.data.result.business.description,
            chars_left_description: this.state.max_chars_description - res.data.result.business.description.length,
            SummaryAlerts: res.data.result.business.summary_alert,
            smryTeamIds: res.data.result.business.summary_alert.team_id,
            TmRcvdalrt: res.data.result.business.summary_alert.receive_alert,
          }));

          if (res.data.result.business.summary_alert.status == 'on') {
            $('.summaryalert_tgldiv').show();
            $('#summaryalert').prop('checked', true);
          }

          if (res.data.result.business.summary_alert.status == 'off') {
            $('.summaryalert_tgldiv').hide();
          }
          if (res.data.result.business.view_archive == 'on') {
            $('#archivemessages').prop('checked', true);
          }

          if (res.data.result.business.auto_text_response.status == 'on') {
            $('#autotextresponse').prop('checked', true);
            $('.autotxtrespnse_tgldiv').show();
            this.setState({
              AutotxtMxRspnsVlue: res.data.result.business.auto_text_response.maximum_response,
              AtoTxDelClkDisble: false,
            });
            $('#autoTxtrespnsDel_id').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
          }

          if (res.data.result.business.auto_text_response.status == 'off') {
            $('#autotextresponse').prop('checked', false);
          }

          if (res.data.result.business.voice_greetings.status == 'on') {
            $('#voicemsg').prop('checked', true);
            $('.voicemsg_tgldiv').show();
            if (res.data.result.business.voice_greetings.type == 'record') {
              this.setState({
                VoiceGrtngblobURL: res.data.result.business.voice_greetings.greeting_url,
                VoiceGrtngRecDeltDisble: false,
              });
              $('#audioPlayVoiceGrtng').show();
              $('#audioStrtVoiceGrtng').hide();
              $('#voice_record').prop('checked', true);
              $('div.voicedesc').hide();
              $('#voicemsg1').show();
            }

            if (res.data.result.business.voice_greetings.type == 'text') {
              this.setState({
                TxtTypeVoiceUrl: res.data.result.business.voice_greetings.greeting_url,
                generatePlayDisble: false,
                TxtVoiceGrtngvlue: res.data.result.business.voice_greetings.text,
                VoiceLngRegn: res.data.result.business.voice_greetings.language_region,
                voiceGnrteResetdisble: false,
                VoiceGrtngTxtTypDeltDisble: false,
              });

              $('#voice_typetxt').prop('checked', true);
              $('div.voicedesc').hide();
              $('#voicemsg2').show();

              for (var i in this.state.GrntVoiveLngRegin) {
                if (this.state.GrntVoiveLngRegin[i]._id == res.data.result.business.voice_greetings.language_region) {
                  console.log(this.state.GrntVoiveLngRegin[i].voice_list);
                  this.setState({VoceLstBsOnLanguge: this.state.GrntVoiveLngRegin[i].voice_list});
                }
              }
              $(
                res.data.result.business.voice_greetings.voice
                  ? '.' + res.data.result.business.voice_greetings.voice
                  : '.Justin',
              ).prop('checked', true);
              this.setState({VoiceClksId: res.data.result.business.voice_greetings.voice});
            }
          }

          if (res.data.result.business.voice_greetings.status == 'off') {
            $('#voicemsg').prop('checked', false);
          }
          if (res.data.result.business.auto_text_unsupport.status == 'off') {
            $('#autotextresponsemedia').prop('checked', false);
            $('.autotxtrespnsemedia_tgldiv').hide();
          } else {
            $('#autotextresponsemedia').prop('checked', true);
            $('.autotxtrespnsemedia_tgldiv').show();
            this.setState({
              unsupportedtext: res.data.result.business.auto_text_unsupport.text_response,
              unsupportedtext_unique_id: res.data.result.business.auto_text_unsupport.unique_id,
            });
          }

          if (res.data.result.business.auto_voice_response.status == 'on') {
            $('#autovoiceresponse').prop('checked', true);
            $('.autovoiceresponse_tgldiv').show();

            if (res.data.result.business.auto_voice_response.type == 'leave_message') {
              this.setState({
                LeavAutoVceMsgVle: res.data.result.business.auto_voice_response.text_response,
                LeavAutoVceMsgVle_unique_id: res.data.result.business.auto_voice_response.unique_id,
                LeavAutoVoiceMxmRspnsVlu: res.data.result.business.auto_voice_response.maximum_response,
                leaveMsgDelDisble: false,
              });
              $('#autovoicemsg1').show();
              $('#autovoicemsg').prop('checked', true);
            }

            if (res.data.result.business.auto_voice_response.type == 'hangup_message') {
              this.setState({
                HangMsgValue: res.data.result.business.auto_voice_response.maximum_response,
                hangupMsgDelDisble: false,
              });
              $('#autovoicemsg2').show();
              $('#autovoicehangup').prop('checked', true);
            }
          }

          if (res.data.result.business.auto_voice_response.status == 'off') {
            $('#autovoiceresponse').prop('checked', false);
          }

          if (res.data.result.business.birthday.status == 'on') {
            $('#bdaygreetings').prop('checked', true);
            $('.bdaygreetings_tgldiv').show();
            this.setState({
              birthdyTxtValue: res.data.result.business.birthday.text,
              birthdyTxtValue_unique_id: res.data.result.business.birthday.unique_id,
              bithGrtngTimeSelt: res.data.result.business.birthday.local_time,
              bithGrtngTime: res.data.result.business.birthday.local_time,
              bithGrtngTimeZoneSelt: res.data.result.business.birthday.offset,
              bithGrtngTimeZone: res.data.result.business.birthday.offset,
              brthdyGrtngDelDisble: false,
            });
          }

          if (res.data.result.business.birthday.status == 'off') {
            $('#bdaygreetings').prop('checked', false);
          }

          var archiveMdStatus = res.data.result.business.archive.text;

          if (archiveMdStatus == 'on') {
            $('#archivemode').prop('checked', true);
            this.setState({VwArchiewDisbl: false});
          }
          if (archiveMdStatus == 'off') {
            $('#archivemode').prop('checked', false);
            this.setState({VwArchiewDisbl: true});
          }
          if (res.data.result.business.email_dump?.status == 'on') {
            $('#dailyemaildump').prop('checked', true);
            this.setState({
              dump_emails:
                res.data.result.business.email_dump?.email_address?.length !== 0
                  ? res.data.result.business.email_dump?.email_address
                  : [''],
            });
          } else {
            $('#dailyemaildump').prop('checked', false);
            this.setState({
              dump_emails:
                res.data.result.business.email_dump?.email_address?.length !== 0
                  ? res.data.result.business.email_dump?.email_address
                  : [''],
            });
          }

          var TmIdDtls = res.data.result.business.summary_alert.team_id;
          for (var j in TmIdDtls) {
            $('.smryTmIds' + TmIdDtls[j]).prop('checked', true);
          }
          var TmIdDtls_email = res.data.result.business.integration_email.team_id;
          console.log('email integration', TmIdDtls_email);
          for (var g in TmIdDtls_email) {
            // console.log('email integration', TmIdDtls_email[g]);
            $('.emailteam' + TmIdDtls_email[g]).prop('checked', true);
          }

          var TmIdDtls_chat = res.data.result.business.chat_widget.team_id;
          for (var p in TmIdDtls_chat) {
            $('.chatTmIds_' + TmIdDtls_chat[p]).prop('checked', true);
          }
          var TagDtls_chat = res.data.result.business.chat_widget.tags;
          // this.setState({chat_filtered_tag_list: TagDtls_chat});
          for (var g = 0; g < TagDtls_chat.length; g++) {
            // for (var g in TagDtls_chat) {
            console.log('to be selected inside list', TagDtls_chat[g]);
            $('.chatWidget_tags option').filter(`[value=${TagDtls_chat[g]}]`).attr('selected', 'selected');
          }
          this.setState({
            chat_grtmsg: res.data.result.business.chat_widget.greeting,
            chat_replymsg: res.data.result.business.chat_widget.reply_message,
            chat_newcontactmsg: res.data.result.business.chat_widget.new_contact_message,
            chat_existcontactmsg: res.data.result.business.chat_widget.existing_contact_message,
          });
        }
      });
      const cart_list = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list).then((res) => {
        console.log('/booster/cart/list', res.data);
        if (res.data.result) {
          let price_quantity_list = [...this.state.price_quantity_list];
          let order_amount = 0;
          let order_doller = 0;
          let exist_cart_id = [];
          for (let i = 0; i < res.data?.result?.length; i++) {
            const item = res.data.result[i];

            let selectedQlist = price_quantity_list.find((x) => x._id === item.master_pack_id);
            if (selectedQlist?.quantity) {
              selectedQlist.quantity = item.pack_quantity;
            }

            var removeIndex = price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
            price_quantity_list.splice(removeIndex, 1);
            price_quantity_list = [...price_quantity_list, selectedQlist];
            // order_amount = order_amount.push("'" + res.data?.result[i]?.order_amount + "'");

            order_amount += res.data?.result[i]?.order_amount;
            order_doller += parseInt(res.data?.result[i]?.order_doller);
            // order_amount = order_amount.join(', ');
            // exist_cart_id = exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
            exist_cart_id = [...exist_cart_id, res.data?.result[i]?._id];
            // exist_cart_id = exist_cart_id.join(', ');
          }
          console.log('price_quantity_list', price_quantity_list);
          this.setState({
            price_quantity_list,
            order_amount,
            exist_cart_id,
            order_doller,
          });
          // for (var i in res.data?.result) {
          //   if (res.data?.result[i]?.pack_type === 'message') {
          //     this.setState({
          //       pack_quantity_message: res.data?.result[i]?.pack_quantity,
          //       order_data_count: res.data?.result[i]?.order_data_count,
          //       order_doller: res.data?.result[i]?.order_doller,
          //       order_amount: res.data?.result[i]?.order_amount,
          //       exist_cart_id: res.data?.result[i]?._id,
          //     });
          //   }
          // }

          this.setState({packcart_list: res.data.result});
        } else if (res.data.status == 'no_record') {
        }
      });

      const contactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
        .then((res) => {
          if (res.data.status === 'no_record') {
            // document.getElementById('contact_nil').style.display = 'block';
          }
          this.setState({
            contacts: res.data?.result?.contacts,
            contacts_archive: res.data?.result?.contacts,
          });
          // var available_data = this.state.contacts_available_archived
          // for (var i in res.data?.result?.contacts) {
          //   if (res.data?.result?.contacts[i].text_archive !== 'false') {
          //     available_data.push(res.data?.result?.contacts[i]._id)
          //   }
          //   this.setState({contacts_available_archived: available_data})
          //   console.log("this.state.contacts_available_archived",this.state.contacts_available_archived)
          // }
          var conList = [];
          for (var i in res.data?.result?.contacts) {
            if (res.data?.result?.contacts[i].text_archive !== 'false') {
              // console.log(res.data?.result?.contacts[i]._id);
              var name =
                res.data?.result?.contacts[i].first_name == 'Guest'
                  ? '+91 ' +
                    res.data?.result?.contacts[i].phone_number
                      .toString()
                      .substring(1)
                      .replace(/[^\dA-Z]/g, '')
                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                      .trim()
                  : res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
              conList.push({
                name: name,
                value: res.data?.result?.contacts[i]._id,
                tags: res.data?.result?.contacts[i].tags,
                contact: res.data?.result?.contacts[i],
              });
            }
          }
          this.setState({dropDownValue: conList});
        })
        .catch((error) => {});
      const companylist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/company/list', companylist)
        .then((res) => {
          //console.log("Companylist", res.data);
          if (res.data.status === 'success') {
            this.setState({company_list: res.data.result});
          }
          if (res.data.status === 'no_record') {
            this.setState({company_list: []});
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              this.setState({company_list: []});
            }
          }
        });
    }, 100);
    $('.updcontactsel')
      .change(function () {
        $(this)
          .find('option:selected')
          .each(function () {
            var optionValue = $(this).attr('value');
            if (optionValue) {
              $('.updct')
                .not('.' + optionValue)
                .hide();
              $('.' + optionValue).show();
            } else {
              $('.updct').hide();
            }
          });
      })
      .change();

    $('.planback_clk').click(function () {
      $('.choosepackdtls_div').show();
      $('.creditcarddtls_div').hide();
    });
    $('#stepinbound').on('change', function () {
      if ($(this).val() === 'no_specification') {
        $('#inbvalue').val('');
      }
    });
    $(document).on('click', '.addmore_nft_keys', function () {
      nft_key_count++;
      var content =
        '<div class="w-100 d-flex flex-row flex-wrap pb-4 append_divs keys' +
        nft_key_count +
        '"><div class="col-12 col-sm-12 col-md-6 col-lg-6"><input type="text" class="form-control key_vals" data-id="' +
        nft_key_count +
        '" placeholder="Key" /></div><div class="col-5 col-sm-5 col-md-4 col-lg-4"><div class="icheck-darkgrey d-inline-block pt-1 pt-md-3"><input type="checkbox" id="keyrequired' +
        nft_key_count +
        '"><label for="keyrequired' +
        nft_key_count +
        '">Required</label></div></div><div class="col-7 col-sm-7 col-md-2 col-lg-2 pr-0 pt-1 pt-md-3"><a><i class="fas fa-trash-alt mt-2 mr-3 f_sz16 crsr_pntr fmaroon remove_keys" data-id="' +
        nft_key_count +
        '"></i></a></div></div>';
      $('.nftrequest_div').append(content);
    });
    $(document).on('click', '.remove_keys', function () {
      var $self = $(this);
      var id = $self.data('id');
      $('.keys' + id + '').remove();
    });
    $('.nftrequest_btn').on('click', function () {
      var continue_vals = '1';
      var nft_token_name = $('#nft_token_name').val();
      var nft_token_symbol = $('#nft_token_symbol').val();
      var token_limits = $('#token_limits').val();
      if (nft_token_name.length == 0) {
        toast.dark('Please enter the Token Name.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (nft_token_symbol.length == 0) {
        toast.dark('Please enter the Token Symbol.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      $('.key_vals')
        .each(function (index) {
          console.log($('#keyrequired' + $(this).data('id') + '').is(':checked'));
          if ($(this).val().length == 0 && $(this).data('id') !== 0) {
            continue_vals = '0';
            $(this).focus();
            toast.dark('Please enter the key value.', {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return false;
          }
        })
        .promise()
        .done(function () {
          if (continue_vals == '1') {
            $('.nftrequest_btn').hide();
            $('.btn_confirmation').show();
            $('.nftrequest_confirm_btn').show();
          }
        });
    });

    //NFT
    var that = this;
    $(document).on('click', '.nftavailable_receiptview_clk', function () {
      var $self = $(this);
      var id = $self.data('id');
      var vtype = $self.data('type');
      console.log('ididid', id);
      console.log('typetype', vtype);
      const token_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: that.state.user_id,
          business_id: that.state.business_id,
          flag: 'single',
          tran_hash: id,
        },
      };
      // console.log('token_list_params', token_list_params);
      axios
        .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            console.log('nftres.datasss', res.data.result[0]);
            console.log('nftres.vtype', vtype);
            that.setState({nft_token: res.data.result[0]});
            $('.nftmintedlist_div').hide();
            if (vtype === 'minted') {
              $('.receiptnft_div').show();
            } else if (vtype === 'sent') {
              $('.sentreceiptnft_div').show();
            } else if (vtype === 'accepted') {
              $('.inprocessreceiptnft_div').show();
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
            }
          }
        });
    });
    $(document).on('keyup', '.minted_nft_search', function () {
      var $self = $(this);
      var type = $self.data('type');
      var searchval = $self.val();
      console.log($self.val());
      console.log(type);
      var nft_type = 'available';
      if (type === 'minted') {
        nft_type = 'available';
      }
      if (type === 'sent') {
        nft_type = 'sent';
      }
      if (type === 'accepted') {
        nft_type = 'accepted';
      }
      that.setState({
        nft_available_cnt: 0,
        nft_sent_cnt: 0,
        nft_accept_cnt: 0,
        nft_available_load_more: true,
        nft_sent_load_more: true,
        nft_accept_load_more: true,
      });
      const token_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: that.state.user_id,
          business_id: that.state.business_id,
          flag: 'all',
          search_text: searchval,
          tab: nft_type,
          // tran_hash: res.data.result.Tran_Hash,
        },
      };
      var arraval = [];
      console.log('token_list_params', token_list_params);
      axios
        .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            for (var i = 0; i < res.data.result.length; i++) {
              if (res.data.result[i].status === type) {
                arraval.push(res.data.result[i]);
              }
            }
            // console.log('nftres.datasss1', res.data.result);
            if (type === 'minted') {
              that.setState({
                nft_minted_tokens_lists: arraval,
              });
              if (arraval.length < 20) {
                that.setState({
                  nft_available_load_more: false,
                });
              }
            } else if (type === 'accepted') {
              that.setState({
                nft_accept_tokens_lists: arraval,
              });
              if (arraval.length < 20) {
                that.setState({
                  nft_accept_load_more: false,
                });
              }
            } else if (type === 'sent') {
              that.setState({
                nft_sent_tokens_lists: arraval,
              });
              if (arraval.length < 20) {
                that.setState({
                  nft_sent_load_more: false,
                });
              }
            }
            that.setState({
              nft_token_file_extension: '',
              table_view: false,
              nft_tokens: res.data.result,
            });
          } else if (res.data.status === 'no_record') {
            if (type === 'minted') {
              that.setState({
                nft_minted_tokens_lists: arraval,
                nft_available_load_more: false,
              });
            } else if (type === 'accepted') {
              that.setState({
                nft_accept_tokens_lists: arraval,
                nft_accept_load_more: false,
              });
            } else if (type === 'sent') {
              that.setState({
                nft_sent_tokens_lists: arraval,
              });
            }
            that.setState({
              nft_token_file_extension: '',
              table_view: false,
              nft_tokens: res.data.result,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              if (type === 'minted') {
                that.setState({
                  nft_minted_tokens_lists: arraval,
                  nft_available_load_more: false,
                });
              } else if (type === 'accepted') {
                that.setState({
                  nft_accept_tokens_lists: arraval,
                  nft_accept_load_more: false,
                });
              } else if (type === 'sent') {
                that.setState({
                  nft_sent_tokens_lists: arraval,
                  nft_sent_load_more: false,
                });
              }
              that.setState({
                nft_minted_tokens_lists: arraval,
                nft_token_file_extension: '',
                table_view: false,
              });
              $('.receiptnft_div').hide();
              $('.sentreceiptnft_div').hide();
              $('.inprocessreceiptnft_div').hide();
              $('.nftmintedlist_div').show();
              $('.nftcreate_div').hide();
            }
          }
        });
    });
    $(document).on('click', '.nft_minted_li', function () {
      var $self = $(this);
      var type = $self.data('name');
      that.closeReceipt(type);
      $('.minted_nft_search').val('');
    });

    $(document).on('click', '.nftavailable_copy_clk', function () {
      var $self = $(this);
      var id = $self.data('id');
      that.mintingNextStep(2);
      $('#nft_name_minted').val($self.data('nft_name'));
      $('#nft_decription_minted').val($self.data('nft_description'));
      var uptodtype = $self.data('upload_type');
      that.setState({
        nft_file_type: uptodtype,
      });
      //alert($self.data('file_name'));
      $('#nft_file_name_minted').val($self.data('file_name'));
      $('#nft_file_url_minted').val($self.data('file_url'));
      $('#nft_thumb_file_url_minted').val($self.data('thumbnail_file_url'));
      if (uptodtype.length !== 0) {
        if (uptodtype === 'image/gif') {
          $('.chooseupload_nft_minted').val('choosegif');
          $('#uploaded_image_giff_minted').show();
          $('#uploaded_img_src_giff_minted').attr('src', $self.data('file_url'));
        } else {
          $('.chooseupload_nft_minted').val('chooseimage');
          $('#uploaded_image_minted').show();
          $('#uploaded_image_minted_src').attr('src', $self.data('file_url'));
        }
        $('.chooseupload_nft_minted').trigger('change');
      }
    });
    $(document).on('click', '.revert_nft', function () {
      var $self = $(this);
      var id = $self.data('id');
      var cid = $self.data('cid');
      var type = $self.data('type');
      console.log('ididid', id);
      console.log('typetype', cid);
      var revert_params = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: that.state.user_id,
        business_id: that.state.business_id,
        token_id: id,
        contact_id: cid,
        type: type,
      };
      // console.log('token_list_params', token_list_params);
      axios
        .post(API_CALL_ENDPOINT + '/blockchain/nft/changestatus', revert_params)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            that.closeReceipt();
            toast.dark(res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          //console.log(res.data.message);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              console.log(error.response.data.error.message);
              that.closeReceipt();
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    });
    var append = 0;

    $(document).on('click', '.close_del', function () {
      $('.close').trigger('click');
    });

    $(document).on('click', '.webhook_update', function () {
      var $self = $(this);
      var id = $('#webhook_id').val();
      var url = $('#webhooks_updated_url').val();
      if (url.length == 0) {
        toast.dark('Please enter the webhook url', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        $self.focus();
        return false;
      } else if (that.isValidURL(url) === false) {
        toast.dark('Please enter a valid webhook url', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        $self.focus();
        return false;
      } else {
        $('.webhook_update').addClass('webhook_saving');
        $('.webhook_update').addClass('isdisabled');
        $('.webhook_saving').removeClass('webhook_update');
        $('.webhook_save').text('Processing..');

        const webhoosdata = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: that.state.user_id,
          business_id: that.state.business_id,
          webhook_id: id,
          webhook_url: url,
        };

        axios
          .post(API_CALL_ENDPOINT + '/business/webhook/update', webhoosdata)
          .then((res) => {
            if (res.data.status === 'success') {
              $('.webhook_saving').addClass('webhook_update');
              $('.webhook_update').removeClass('webhook_saving');
              $('.webhook_update').text('Update');
              $('.webhook_update').removeClass('isdisabled');
              that.addwebhook_close();
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error.reason === 'Invalid Id') {
              }
            }
          });
      }

      console.log('id', id);
      console.log('url', url);
    });
    $(document).on('click', '.addwebhook_clk', function () {
      $('.webhookadd_div').hide();
      $('.webhooklist_div').hide();
      $('.addwebhook_div').show();
    });
    $('.chooseupload').on('change', function () {
      var choosenval = $(this).val();
      if (choosenval === 'chooseimage') {
        $('.chooseimage').show();
        $('.choosegif').hide();
        $('.chooseaudio').hide();
      } else if (choosenval === 'chooseaudio') {
        $('.chooseaudio').show();
        $('.choosegif').hide();
        $('.chooseimage').hide();
      } else if (choosenval === 'choosegif') {
        $('.choosegif').show();
        $('.chooseimage').hide();
        $('.chooseaudio').hide();
      } else {
        $('.choosegif').hide();
        $('.chooseimage').hide();
        $('.chooseaudio').hide();
      }
    });

    $('.chooseupload_nft_minted').on('change', function () {
      var choosenval = $(this).val();
      if (choosenval === 'chooseimage') {
        $('.chooseimage').show();
        $('.choosegif').hide();
        $('.chooseaudio').hide();
      } else if (choosenval === 'chooseaudio') {
        $('.chooseaudio').show();
        $('.choosegif').hide();
        $('.chooseimage').hide();
      } else if (choosenval === 'choosegif') {
        $('.choosegif').show();
        $('.chooseimage').hide();
        $('.chooseaudio').hide();
      } else {
        $('.choosegif').hide();
        $('.chooseimage').hide();
        $('.chooseaudio').hide();
      }
    });

    $('.audio_select').change(function () {
      if ($(this).is(':checked')) {
        var selected = $(this).val();
        if (selected == 1) {
          $('#nftvoicemsg1').show();
          $('#nftvoicemsg2').hide();
        } else if (selected == 2) {
          $('#nftvoicemsg1').hide();
          $('#nftvoicemsg2').show();
        }
      }
    });

    // $('.audio_select').on('click', function () {
    //   var selectedVal = '';
    //   var selected = $(".audio_select input[type='radio']:checked");
    //   console.log("selectedselected",selected);
    //   if (selected == 1) {
    //     $('#nftvoicemsg1').show();
    //     $('#nftvoicemsg2').hide();
    //   } else if (selected == 2) {
    //     $('#nftvoicemsg1').hide();
    //     $('#nftvoicemsg2').show();
    //   }
    // });

    /****Business Id */
    $(document).on('click', '#custom-tabs-workspace_id-tab', function () {
      if (!that.state?.business_info_array?.busines_evaluation_details) {
        $('.business_evaluation_popup').trigger('click');
        return false;
      } else if (that.state?.business_info_array?.busines_evaluation_details?.otp_status == 'not_verified') {
        $('.business_evaluation_popup').trigger('click');

        return false;
      } else if (
        that.state?.business_info_array?.busines_evaluation_details?.status == 'not_verified' ||
        that.state?.business_info_array?.busines_evaluation_details?.status == 'rejected'
      ) {
        $('.business_evaluation_popup').trigger('click');

        return false;
      } else if (that.state?.business_info_array?.busines_evaluation_details?.status == 'pending') {
        toast.dark(
          'Thank you for your patience, please note that your workspace is under verification. We will get back to you upon verification via email.',
          {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
        return false;
      }
    });
    /*************Workflow****************/
    $(document).on('click', '.workflowcreate_clk', function () {
      if (!that.state?.business_info_array?.busines_evaluation_details) {
        $('.business_evaluation_popup').trigger('click');
        return false;
      } else if (that.state?.business_info_array?.busines_evaluation_details?.otp_status == 'not_verified') {
        $('.business_evaluation_popup').trigger('click');
        return false;
      } else if (
        that.state?.business_info_array?.busines_evaluation_details?.status == 'not_verified' ||
        that.state?.business_info_array?.busines_evaluation_details?.status == 'rejected'
      ) {
        $('.business_evaluation_popup').trigger('click');
        return false;
      } else if (that.state?.business_info_array?.busines_evaluation_details?.status == 'pending') {
        toast.dark(
          'Thank you for your patience, please note that your workspace is under verification. We will get back to you upon verification via email.',
          {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
        return false;
      } else {
        $('.workflowcreate_div').hide();
        $('.addworkflow_div').show();
        $('.addentrypoints_div').hide();
        $('.workflowlist_div').hide();
      }

      // $('.addworkflow_div').hide();
    });

    // $('.startautomate_clk').on('click', function () {

    //   // $('.addworkflow_div').hide();

    // });

    $('.addentrypt_clk').on('click', function () {
      $('.workflowcreate_div').hide();
      $('.addworkflow_div').hide();
      $('.addentrypoints_div').hide();
      $('.workflowlist_div').show();
      // $('.addworkflow_div').hide();
    });
    $('.wfentrypt_contactstgl').click(function () {
      $('.wfentrypt_contactstgl_div').slideToggle('slow');
      // Alternative animation for example
      // slideToggle("fast");
    });

    $('.viewarchive_tgl').click(function () {
      $('.viewarchive_tgldiv').slideToggle();
      //$(".archivemessages_tgldiv").hide();
    });

    $('.viewarchivesearch_tgl').click(function () {
      $('.viewarchivesearch_tgldiv').slideToggle();
      //$(".archivemessages_tgldiv").hide();
    });
    $('#default_dateval').on('click', function () {
      $('.dashchartfiltercalendardrpdwn_pos').toggle();
    });
    $('.dailyemaildumphistory_tgl').click(function () {
      $('.dailyemaildumphistory_tgldiv').slideToggle();
      //$(".archivemessages_tgldiv").hide();
    });
    today = new Date();
    end_date = today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    var start = new Date('1/1/' + new Date().getFullYear());
    start_date = start.getMonth() + 1 + '-' + start.getDate() + '-' + start.getFullYear();
    var default_dateval = moment(start).format('MMM DD, yyyy') + ' - ' + moment(today).format('MMM DD, yyyy');
    $('#default_dateval').val(default_dateval);
    this.setState({
      start: moment(start).format('MMM DD, yyyy'),
      end: moment(today).format('MMM DD, yyyy'),
    });
  }

  redirectTo = (url) => {
    if (url != undefined) {
      this.props.redirectToPage(url);
    }
  };

  VoiceGrtnaudio_start = () => {
    $('#audioStrtVoiceGrtng').hide();
    $('#audioStopVoiceGrtng').show();

    navigator.mediaDevices.getUserMedia(
      {audio: true},
      () => {
        console.log('Permission Granted');
        this.setState({isBlocked: false});
      },
      () => {
        console.log('Permission Denied');
        this.setState({isBlocked: true});
      },
    );

    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({isRecording: true});
        })
        .catch((e) => console.error(e));
    }
  };

  VoiceGrtnaudio_stop = () => {
    $('#audioStopVoiceGrtng').hide();
    $('#audioPlayVoiceGrtng').show();

    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, Date.now() + '.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        const VoiceGrtngblobURL = URL.createObjectURL(file);
        this.setState({VoiceGrtngblobURL, isRecording: false});

        var filename = new Date().toISOString();

        const data = new FormData();

        data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        data.append('secret', 'R1eqD2twI3E4');
        data.append('user_id', this.state.user_id);
        data.append('business_id', this.state.business_id);
        data.append('file', file);
        data.append('reference', 'text');

        axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
          console.log(res);
          console.log(res.data.message);
          console.log(res.data.result.files);

          if (res.data.status == 'success') {
            var file_url = res.data.result.files[0].file_url;
            var file_id = res.data.result.files[0]._id;

            console.log(file_url);

            this.setState({VoiceGrtngRecUrl: file_url});
          }
        });
      })
      .catch((e) => console.log(e));
  };

  VoiceGrtnaudio_play = () => {
    $('#audioPlayVoiceGrtng').hide();
    $('#audioPauseVoiceGrtng').show();
    var playx = document.getElementById('voiceGretngRcrd');
    playx.play();
    playx.onended = function () {
      $('#audioPauseVoiceGrtng').hide();
      $('#audioPlayVoiceGrtng').show();
    };
  };

  VoiceGrtnaudio_pause = (e) => {
    var pausex = document.getElementById('voiceGretngRcrd');
    pausex.pause();
    $('#audioPauseVoiceGrtng').hide();
    $('#audioPlayVoiceGrtng').show();
  };

  SaveVoiceGrrtng = () => {
    console.log('R-Bot voiceGreeting Save');

    if (this.state.VoiceGrtngRecUrl != '') {
      $('#VoiceGrrtngRecSv_id').html('Saving...');
      $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled');
      var VoiceGrtng_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        voice_greetings_status: 'on',
        setup: 'voice_greetings',
        voice_greetings_type: 'record',
        greeting_url: this.state.VoiceGrtngRecUrl,
      };

      console.log(VoiceGrtng_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState(() => ({
              ViceGrtMsg: res.data.message,
              ViceGrtcls: 'alert alert-success',
              VoiceGrtngRecDeltDisble: false,
              TxtVoiceGrtngvlue: '',
              voiceGnrteResetdisble: true,
              VoiceLngRegn: 'en-US',
              TxtTypeVoiceUrl: '',
              generatePlayDisble: true,
            }));

            for (var i in this.state.GrntVoiveLngRegin) {
              if (this.state.GrntVoiveLngRegin[i]._id == 'en-US') {
                console.log(this.state.GrntVoiveLngRegin[i].voice_list);
                this.setState({VoceLstBsOnLanguge: this.state.GrntVoiveLngRegin[i].voice_list});
              }
            }

            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
              $('#VoiceGrrtngRecSv_id').html('Save');
              $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              ViceGrtMsg: res.data.error.message,
              ViceGrtcls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
              $('#VoiceGrrtngRecSv_id').html('Save');
              $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                ViceGrtMsg: error.response.data.error.message,
                ViceGrtcls: 'alert alert-danger',
              }));

              setTimeout(() => {
                this.setState(() => ({
                  ViceGrtMsg: '',
                  ViceGrtcls: '',
                }));
                $('#VoiceGrrtngRecSv_id').html('Save');
                $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
              }, 5000);
            }
          }
        });
    } else {
      this.setState(() => ({
        ViceGrtMsg: 'Please record after save',
        ViceGrtcls: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          ViceGrtMsg: '',
          ViceGrtcls: '',
        }));
      }, 5000);
    }
  };

  DelVoiceGrrtng = () => {
    var VoiceGrtng_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      voice_greetings_status: 'off',
      setup: 'voice_greetings',
      voice_greetings_type: 'record',
    };

    console.log(VoiceGrtng_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            ViceGrtMsg: 'R-Bot Voice Greetings Delete Successfully',
            ViceGrtcls: 'alert alert-success',
            VoiceGrtngRecDeltDisble: true,
            VoiceGrtngblobURL: '',
          }));
          $('#audioStrtVoiceGrtng').show();
          $('#audioPlayVoiceGrtng').hide();
          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            ViceGrtMsg: res.data.error.message,
            ViceGrtcls: 'alert alert-danger',
          }));
          $('#audioStrtVoiceGrtng').show();
          $('#audioPlayVoiceGrtng').hide();
          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              ViceGrtMsg: error.response.data.error.message,
              ViceGrtcls: 'alert alert-danger',
            }));
            $('#audioStrtVoiceGrtng').show();
            $('#audioPlayVoiceGrtng').hide();
            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  TxtVoiceGrtngOnChg = (event) => {
    console.log(event.target.value);
    this.setState({TxtVoiceGrtngvlue: event.target.value});
  };

  audio_start_cmb = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(() => {
          $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
          $('.audioRecordLoading_cmb').show();

          Mp3Recorder.start()
            .then(() => {
              this.setState({isRecording_cmb: true});
            })
            .catch((e) => {
              this.setState({isRecording_cmb: false});
              $('.sendmsgattach_clkAudio2_cmb').show();
              $('.audioRecordLoading_cmb').hide();
              toast.dark(
                'Sorry, this browser does not support audio recording. Please try using a different browser.',
                {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                },
              );
              // const errors = [];
              // errors.push(
              //   'Sorry, this browser does not support audio recording. Please try using a different browser.',
              // );
              // this.setState({errors});

              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
              // alert('Sorry this browser does not support audio recording yet');
              console.log(e);
            });
        })
        .catch((err) => {
          toast.dark('Sorry, this browser does not support audio recording. Please try using a different browser.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // const errors = [];
          // errors.push('Sorry, this browser does not support audio recording. Please try using a different browser.');
          // this.setState({errors});

          // setTimeout(() => {
          //   this.setState({errors: []});
          // }, 3000);
          console.log(err);
        });
    } else {
      console.log('Sorry, this browser does not support audio recording. Please try using a different browser.');
    }
  };

  audio_start_cmb_nft = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(() => {
          $('.sendmsgattach_clkAudio2_cmb_nft').attr('style', 'display:none');
          $('.audioRecordLoading_cmb_nft').show();

          Mp3Recorder.start()
            .then(() => {
              this.setState({isRecording_cmb: true});
            })
            .catch((e) => {
              this.setState({isRecording_cmb: false});
              $('.sendmsgattach_clkAudio2_cmb').show();
              $('.audioRecordLoading_cmb').hide();
              toast.dark(
                'Sorry, this browser does not support audio recording. Please try using a different browser.',
                {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                },
              );
              // const errors = [];
              // errors.push(
              //   'Sorry, this browser does not support audio recording. Please try using a different browser.',
              // );
              // this.setState({errors});

              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
              // alert('Sorry this browser does not support audio recording yet');
              console.log(e);
            });
        })
        .catch((err) => {
          toast.dark('Sorry, this browser does not support audio recording. Please try using a different browser.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // const errors = [];
          // errors.push('Sorry, this browser does not support audio recording. Please try using a different browser.');
          // this.setState({errors});

          // setTimeout(() => {
          //   this.setState({errors: []});
          // }, 3000);
          console.log(err);
        });
    } else {
      console.log('Sorry, this browser does not support audio recording. Please try using a different browser.');
    }
  };

  audio_stop_cmb_nft = () => {
    $('.audioRecordLoading_cmb_nft').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv2_cmb').hide();
    //$('.attachLoading_cmb').show();
    //$('.attchLstShow_cmb').show();
    this.setState({lodingicon_cmb: 'fas fa-microphone-alt bg_audio'});

    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({blobURL, isRecording_cmb: false});

        var filename = new Date().toISOString();

        const data = new FormData();

        data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        data.append('secret', 'R1eqD2twI3E4');
        data.append('user_id', this.state.user_id);
        data.append('business_id', this.state.business_id);
        data.append('file', blob, filename);
        data.append('reference', 'text');

        axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
          console.log(res);
          console.log(res.data.message);
          console.log(res.data?.result?.files);

          if (res.data.status == 'success') {
            var file_url = '';
            var file_id = '';
            var file_path_audio = '';
            var second_file_path = '';
            for (var i in res.data?.result?.files) {
              console.log(res.data?.result?.files[i].file_url);
              file_url += res.data?.result?.files[i].file_url;
              file_id += res.data?.result?.files[i]._id;
              file_path_audio += res.data?.result?.files[i].file_path;
              second_file_path += res.data?.result?.files[i].second_file_path;
              var joined_audioId = this.state.attchmnt_audioId_cmb.concat(res.data?.result?.files[i]._id);
              this.setState({attchmnt_audioId_cmb: joined_audioId});

              var joined_audioIdUrl = this.state.attchmnt_audioIdUrl_cmb.concat({
                _id: res.data?.result?.files[i]._id,
                file_path: res.data?.result?.files[i].file_path,
                audio_id: res.data?.result?.files[i]._id,
                audio_url: res.data?.result?.files[i].file_url,
              });
              this.setState({attchmnt_audioIdUrl_cmb: joined_audioId});
            }

            console.log(file_url);

            console.log(this.state.atcharryDet_cmb);

            $('.attachLoading_cmb').hide();
            const audio_details = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              attachment: file_id,
              attachment_type: 'audio',
              unique_id:
                this.state.message_edit === false
                  ? Rnunique_id_cmb
                  : this.state.Rnunique_id_cmb
                  ? this.state.Rnunique_id_cmb
                  : Rnunique_id_cmb,
              order: '' + this.state.attachOrderIncrs_cmb + '',
            };

            axios
              .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  this.setState({messageAttachd_cmb: true});
                  $('#fileAudiosvBtn').hide();
                  this.setState({
                    atcharryDet_cmb: [
                      {
                        _id: file_id,
                        file_path: file_path_audio,
                        second_file_path: second_file_path,
                        audio_id: file_id,
                        audio_url: file_url,
                        attach_id: res.data?.result?._id,
                      },
                      ...this.state.atcharryDet_cmb,
                    ],
                  });
                  var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                  this.setState({attachOrderIncrs_cmb: OrderIncr});
                  $('.attachment_expanded_nft').show();
                  // $('.attachment_colapsed_'+file_id).hide();
                  // $('.attachment_expanded').hide();
                  // $('.attachment_expanded_'+file_id).show();
                  console.log('Testing Logs', this.state.atcharryDet_cmb);
                }
                if (res.data.error) {
                  $('.attachment_expanded_nft').show();
                  // $('.attachment_colapsed_'+res.data?.result?.files[0]._id).hide();
                  //  $('.attachment_expanded').hide();
                  // $('.attachment_expanded_'+res.data?.result?.files[0]._id).show();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error) {
                    $('.attachment_colapsed').show();
                    // $('.attachment_colapsed_'+res.data?.result?.files[0]._id).hide();
                    $('.attachment_expanded').hide();
                    // $('.attachment_expanded_'+res.data?.result?.files[0]._id).show();
                  }
                }
              });
          }
        });
      })
      .catch((e) => console.log(e));
  };

  audio_stop_cmb = () => {
    $('.audioRecordLoading_cmb').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv2_cmb').hide();
    $('.attachLoading_cmb').show();
    $('.attchLstShow_cmb').show();
    this.setState({lodingicon_cmb: 'fas fa-microphone-alt bg_audio'});

    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({blobURL, isRecording_cmb: false});

        var filename = new Date().toISOString();

        const data = new FormData();

        data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        data.append('secret', 'R1eqD2twI3E4');
        data.append('user_id', this.state.user_id);
        data.append('business_id', this.state.business_id);
        data.append('file', blob, filename);
        data.append('reference', 'text');

        axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
          console.log(res);
          console.log(res.data.message);
          console.log(res.data?.result?.files);

          if (res.data.status == 'success') {
            var file_url = '';
            var file_id = '';
            var file_path_audio = '';
            var second_file_path = '';
            for (var i in res.data?.result?.files) {
              console.log(res.data?.result?.files[i].file_url);
              file_url += res.data?.result?.files[i].file_url;
              file_id += res.data?.result?.files[i]._id;
              file_path_audio += res.data?.result?.files[i].file_path;
              second_file_path += res.data?.result?.files[i].second_file_path;
              var joined_audioId = this.state.attchmnt_audioId_cmb.concat(res.data?.result?.files[i]._id);
              this.setState({attchmnt_audioId_cmb: joined_audioId});

              var joined_audioIdUrl = this.state.attchmnt_audioIdUrl_cmb.concat({
                _id: res.data?.result?.files[i]._id,
                file_path: res.data?.result?.files[i].file_path,
                audio_id: res.data?.result?.files[i]._id,
                audio_url: res.data?.result?.files[i].file_url,
              });
              this.setState({attchmnt_audioIdUrl_cmb: joined_audioId});
            }

            console.log(file_url);

            console.log(this.state.atcharryDet_cmb);

            $('.attachLoading_cmb').hide();
            const audio_details = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              attachment: file_id,
              attachment_type: 'audio',
              unique_id:
                this.state.message_edit === false
                  ? Rnunique_id_cmb
                  : this.state.Rnunique_id_cmb
                  ? this.state.Rnunique_id_cmb
                  : Rnunique_id_cmb,
              order: '' + this.state.attachOrderIncrs_cmb + '',
            };

            axios
              .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  this.setState({messageAttachd_cmb: true});
                  $('#fileAudiosvBtn').hide();
                  this.setState({
                    atcharryDet_cmb: [
                      {
                        _id: file_id,
                        file_path: file_path_audio,
                        second_file_path: second_file_path,
                        audio_id: file_id,
                        audio_url: file_url,
                        attach_id: res.data?.result?._id,
                      },
                      ...this.state.atcharryDet_cmb,
                    ],
                  });
                  var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                  this.setState({attachOrderIncrs_cmb: OrderIncr});
                  $('.attachment_colapsed').show();
                  // $('.attachment_colapsed_'+file_id).hide();
                  $('.attachment_expanded').hide();
                  // $('.attachment_expanded_'+file_id).show();
                }
                if (res.data.error) {
                  $('.attachment_colapsed').show();
                  // $('.attachment_colapsed_'+res.data?.result?.files[0]._id).hide();
                  $('.attachment_expanded').hide();
                  // $('.attachment_expanded_'+res.data?.result?.files[0]._id).show();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error) {
                    $('.attachment_colapsed').show();
                    // $('.attachment_colapsed_'+res.data?.result?.files[0]._id).hide();
                    $('.attachment_expanded').hide();
                    // $('.attachment_expanded_'+res.data?.result?.files[0]._id).show();
                  }
                }
              });
          }
        });
      })
      .catch((e) => console.log(e));
  };

  isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res !== null;
  }

  num_validate(plivo_number) {
    // we are going to store errors for all fields
    // in a signle array
    const errors = [];
    if (plivo_number.length === 0) {
      toast.dark('Please select a number to proceed.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({isLoading: false});
      return false;
    } else {
      return true;
    }
  }

  prefixsearch() {
    const numberlist = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '0',
        limit: '7',
        pattern: this.state.prefix,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({plivolist: res.data.result.phone_numbers});
        console.log(this.state.plivolist);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  }

  onChangeregion(e) {
    // const values = [...e.target.selectedOptions].map((opt) => opt.value);
    this.setState({region: e.target.value});
    setTimeout(() => {
      this.regionsearch();
    }, 500);
  }
  regionsearch() {
    var vals = this.state.region.split(',');
    const numberlist = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: vals[1],
        start: '0',
        limit: '7',
        region: vals[0],
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        // console.log(res.data.result.phone_numbers);
        this.setState({rplivolist: res.data.result?.phone_numbers});
        // console.log(this.state.rplivolist.length);
        if (this.state.rplivolist.length === 0) {
          const errors = [];
          errors.push("This region doesn't have any phone numbers, please try by choosing the area code");
          this.setState({errors});
          console.log(this.state.errors);
          setTimeout(() => {
            this.setState({errors: []});
            // this.props.history.push('/')
            // document.getElementById('error').style.display='none'
          }, 3000);
        }
        console.log(this.state.rplivolist);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  }

  onsubmit() {
    this.setState({isLoading: true});
    console.log(this.state.plivonumber);
    const {plivonumber} = this.state;
    var validate = this.num_validate(plivonumber);
    if (validate) {
      if (this.state.test_mode === 'on') {
        toast.dark('This might take few minutes, please hold-on.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const businessupdate = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: plivonumber,
          setup: 'phone',
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              this.setState({isLoading: false});
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setTimeout(() => {
                this.setState({errors: []});
                window.location.reload();
              }, 2000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        toast.dark('This might take few minutes, please hold-on.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const numberbuy = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: plivonumber,
          type: 'change',
        };
        console.log(numberbuy);
        axios
          .post(API_CALL_ENDPOINT + '/plivo/number/buy', numberbuy)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.plivonumber);
              const businessupdate = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                phone_number: plivonumber,
                setup: 'phone',
              };
              axios
                .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status === 'success') {
                    this.setState({isLoading: false});
                    toast.dark(res.data.message, {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setTimeout(() => {
                      this.setState({errors: []});
                      window.location.reload();
                    }, 2000);
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    toast.dark(error.response.data.error.message, {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    this.setState({isLoading: false});
                  }
                });
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({isLoading: false});
            }
          });
      }
    }
  }

  locationsearch() {
    document.getElementById('numbersearch').style.display = 'none';
    document.getElementById('plivonumbers').style.display = 'none';
    document.getElementById('regionsearch').style.display = 'block';
    document.getElementById('plivoregionnumbers').style.display = 'block';
  }
  regloadmore() {
    this.setState({limit: this.state.limit + 5});
    var vals = this.state.region.split(',');
    setTimeout(() => {
      if (vals[0] !== '') {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: vals[1],
            start: '0',
            limit: this.state.limit,
            region: vals[0],
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result.phone_numbers});
            console.log(this.state.rplivolist);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (error.response.data.status === 'conflict') {
                toast.dark(error.response.data.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      } else {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            // console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result?.phone_numbers});
            // console.log(this.state.rplivolist);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (error.response.data.status === 'conflict') {
                toast.dark(error.response.data.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      }
    }, 1000);
  }

  redirectToPage = (value) => {
    this.props.history.push(value);
  };

  loadmore = () => {
    this.setState({limit: this.state.limit + 5});
    setTimeout(() => {
      if (this.state.prefix) {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            pattern: this.state.prefix,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (error.response.data.status === 'conflict') {
                toast.dark(error.response.data.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      } else {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (error.response.data.status === 'conflict') {
                toast.dark(error.response.data.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      }
    }, 1000);
  };

  editwebhooks = (id, url) => {
    console.log('id', id);
    console.log('url', url);
    var $self = $(this);
    $('.edit_webhooks_div').show();
    $('.webhooklist_div').hide();
    $('#webhooks_updated_url').val(url);
    $('#webhook_id').val(id);
  };

  deletewebhooks = (id, url) => {
    console.log('id', id);
    console.log('url', url);
    $('#webhook_id').val(id);
  };

  onchangebusiness_info_name(e) {
    this.setState({
      business_info_name: e.target.value,
      chars_left_profilename: this.state.max_chars_profilename - e.target.value.length,
      business_info_name_blur: true,
    });
  }
  onchangebusiness_info_details(e) {
    this.setState({
      business_info_details: e.target.value,
      chars_left_description: this.state.max_chars_description - e.target.value.length,
      business_info_details_blur: true,
    });
  }
  start_fn = () => {
    //Initialize Select2 Elements
    $('.select2').select2();
    $('.js-select2').select2({
      placeholder: 'Pick states',
      theme: 'bootstrap4',
    });
    //Initialize Select2 Elements
    $('.select2bs4').select2({
      theme: 'bootstrap4',
    });
  };

  archivehistory_tgl = () => {
    $('.archivehistory_tgldiv').slideToggle();
    $('.archivemessages_tgldiv').hide();
  };

  summaryalert_tgl = () => {
    if ($('#summaryalert').is(':checked')) {
      $('.summaryalert_tgldiv').show();

      const smryalrt_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        summary: 'on',
        setup: 'summary',
        receive_alert: this.state.TmRcvdalrt,
      };

      console.log(smryalrt_Details);
      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', smryalrt_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    } else {
      $('.summaryalert_tgldiv').hide();

      const smryalrt_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        summary: 'off',
        setup: 'summary',
        receive_alert: this.state.TmRcvdalrt,
      };

      console.log(smryalrt_Details);
      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', smryalrt_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    }
  };

  autotxtrespnse_tgl = () => {
    if ($('#autotextresponse').is(':checked')) {
      $('.autotxtrespnse_tgldiv').show();
    } else {
      $('.autotxtrespnse_tgldiv').hide();
      const autotextresponse_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        auto_text: 'off',
        setup: 'auto_text',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', autotextresponse_details).then((res) => {
        if (res.data.status == 'success') {
          console.log(res);
          // console.log(res.data.message);
          // window.location.reload(true);
        }
      });
    }
  };
  autotextresponsemedia_tgl = () => {
    if ($('#autotextresponsemedia').is(':checked')) {
      $('.autotxtrespnsemedia_tgldiv').show();
    } else {
      $('.autotxtrespnsemedia_tgldiv').hide();
      const autotextresponsemedia_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        auto_text_unsupport_message: 'off',
        setup: 'auto_text_unsupport',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', autotextresponsemedia_details).then((res) => {
        if (res.data.status == 'success') {
          console.log(res);
          // console.log(res.data.message);
          // window.location.reload(true);
        }
      });
    }
  };
  autotextresponsemedia_del = () => {
    const autotextresponsemedia_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      auto_text_unsupport_message: 'off',
      setup: 'auto_text_unsupport',
    };

    axios.post(API_CALL_ENDPOINT + '/business/profile/settings', autotextresponsemedia_details).then((res) => {
      if (res.data.status == 'success') {
        this.setState({atcharryDet_cmb: []});
        // if (this.state.atcharryDet_cmb.length == 0) {
        $('.attchLstShow_cmb').hide();
        // }
        console.log(res);
        $('.autotxtrespnsemedia_tgldiv').hide();
        $('#autotextresponsemedia').prop('checked', false);
        this.setState({
          unsupportedtext: '',
          unsupportedtext_unique_id: '',
        });
        // console.log(res.data.message);
        // window.location.reload(true);
      }
    });
  };
  onChangeautotxtunsup = (e) => {
    this.setState({unsupportedtext: e.target.value});
  };
  autotextresponsemedia_save = () => {
    if (this.state.unsupportedtext == '') {
      this.setState(() => ({
        AtoTxtMsg: 'R-Bot response message cannot be blank.',
        AtoTxtClsNm: 'alert alert-danger',
      }));
      setTimeout(() => {
        this.setState(() => ({
          AtoTxtMsg: '',
          AtoTxtClsNm: '',
        }));
      }, 5000);
    }
    // else if(ValidNum){
    else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const autotextresponsemedia_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        auto_text_unsupport_message: 'on',
        text_response: this.state.unsupportedtext,
        unique_id: this.state.atcharryDet_cmb.length != 0 ? Rnunique_id_cmb : '',
        setup: 'auto_text_unsupport',
      };

      axios
        .post(API_CALL_ENDPOINT + '/business/profile/settings', autotextresponsemedia_details)
        .then((res) => {
          if (res.data.status == 'success') {
            this.setState(() => ({
              unsupportedtext_unique_id: Rnunique_id_cmb,
              AtoTxtMsg: res.data.message,
              AtoTxtClsNm: 'alert alert-success',
            }));

            const AutotxtRes_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            console.log(AutotxtRes_details);
            axios.get(API_CALL_ENDPOINT + '/business/autotext/list', AutotxtRes_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({AutoTxtArea: res.data.result});
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              AtoTxtMsg: res.data.error.message,
              AtoTxtClsNm: 'alert alert-danger',
            }));
            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                AtoTxtMsg: error.response.data.error.message,
                AtoTxtClsNm: 'alert alert-danger',
              }));
              setTimeout(() => {
                this.setState(() => ({
                  AtoTxtMsg: '',
                  AtoTxtClsNm: '',
                }));
              }, 5000);
            }
          }
        });
    }
  };
  voicemsg_tgl = () => {
    if ($('#voicemsg').is(':checked')) {
      $('.voicemsg_tgldiv').show();
    } else {
      $('.voicemsg_tgldiv').hide();
      const voicemsg_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        voice_greetings_status: 'off',
        setup: 'voice_greetings',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', voicemsg_details).then((res) => {
        if (res.data.status == 'success') {
          console.log(res);
          // console.log(res.data.message);
          // window.location.reload(true);
        }
      });
    }
  };

  voice_message = (event) => {
    var test = event.target.value;

    $('div.voicedesc').hide();
    $('#voicemsg' + test).show();
  };

  autovoiceresponse_tgl = () => {
    if ($('#autovoiceresponse').is(':checked')) {
      $('.autovoiceresponse_tgldiv').show();
    } else {
      $('.autovoiceresponse_tgldiv').hide();
      const autovoiceresponse_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        auto_voice: 'off',
        setup: 'auto_voice',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', autovoiceresponse_details).then((res) => {
        if (res.data.status == 'success') {
          console.log(res);
          // console.log(res.data.message);
          // window.location.reload(true);
        }
      });
    }
  };

  autovoiceresp = (event) => {
    var test = event.target.value;

    $('div.autovoicedesc').hide();
    $('#autovoicemsg' + test).show();
  };

  bdaygreetings_tgl = () => {
    if ($('#bdaygreetings').is(':checked')) {
      $('.bdaygreetings_tgldiv').show();
    } else {
      $('.bdaygreetings_tgldiv').hide();
      const bdaygreetings_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        birthday_status: 'off',
        setup: 'birthday',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', bdaygreetings_details).then((res) => {
        if (res.data.status == 'success') {
          console.log(res);
          // console.log(res.data.message);
          // window.location.reload(true);
        }
      });
    }
  };

  profemailaddr_edit = () => {
    $('.profemailaddr_dsplydiv').hide();
    $('.profemailaddr_upddiv').show();
  };

  webhookshistory_tgl1 = () => {
    $('.webhookshistory_tgldiv1').slideToggle();
  };

  webhooksdelete = (id) => {
    alert(id);
  };

  webhookshistory_tgl2 = () => {
    $('.webhookshistory_tgldiv2').slideToggle();
  };

  addwebhook_clk = () => {
    $('.webhookadd_div').hide();
    $('.webhooklist_div').hide();
    $('.addwebhook_div').show();
    $('.edit_webhooks_div').hide();
  };

  addwebhook_close = () => {
    $('.webhooklist_div').show();
    $('.addwebhook_div').hide();
    $('.edit_webhooks_div').hide();
    $('.remove_webhook_urls').remove();
    $('.webhook_urls').val('');
  };

  apicreate_clk = () => {
    $('.apicreate_div').hide();
    $('.apilist_div').show();
  };

  tagcreate_clk = () => {
    $('#tagFrm').html('Add Tag');
    $('.tagcreate_div').hide();
    $('.addtag_div').show();
    $('.taglist_div').hide();
  };

  addinttag_close = () => {
    $('.taglist_div').show();
    $('.addtag_div').hide();

    this.setState(() => ({
      EditTagName: '',
      EditTagId: '',
    }));
  };

  psphrase_addtagname = () => {
    $('.psphrase_entertagname_drpdwn_div').hide();
    $('.psphrase_entertagname_txtbx_div').show();
  };

  psphrase_closeaddtagname = () => {
    $('.psphrase_entertagname_txtbx_div').hide();
    $('.psphrase_entertagname_drpdwn_div').show();
  };

  qrcodecreate_clk = () => {
    this.setState(() => ({
      QRGrtMsg: '',
      QRGrtcls: '',
      qr_title: '',
      qr_description: '',
      qr_message: '',
      qr_filtered_tag_list: [],
      qr_filtered_tag_list_up: [],
      qr_newtag: '',
      qr_addtag_save: false,
      atcharryDet_cmb: [],
      message_edit: false,
      qr_code_image_edit: '',
      qr_code_id_edit: '',
    }));
    Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
    $('.qr_codedisplay').hide();
    $('.generate_qr_btn').show();
    $('.attchLstShow_cmb').hide();
    $('.qrcodecreate_div').hide();
    $('.addqrcode_div').show();
    $('.qrcodelist_div').hide();
  };

  addqrcode_close = () => {
    this.setState(() => ({
      QRGrtMsg: '',
      QRGrtcls: '',
      qr_title: '',
      qr_description: '',
      qr_message: '',
      qr_filtered_tag_list: [],
      qr_filtered_tag_list_up: [],
      qr_newtag: '',
      qr_addtag_save: false,
      atcharryDet_cmb: [],
      message_edit: false,
      qr_code_image_edit: '',
      qr_code_id_edit: '',
    }));
    if (this.state.qr_code_list.length >= 1) {
      $('.qrcodelist_div').show();
      $('.addqrcode_div').hide();
    } else {
      $('.qrcodecreate_div').show();
      $('.addqrcode_div').hide();
      $('.qrcodelist_div').hide();
    }
  };

  qrcodehistory_tgl1 = (qrcode_det) => {
    const qr_history_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        qr_code: qrcode_det.qr_code,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/text/qrcode/logs', qr_history_details)
      .then((res) => {
        if (res.data) {
          console.log('info', res.data);
          this.setState({qr_code_history: res.data.result});
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
    $('#qr_his_' + qrcode_det._id).slideToggle();
  };

  qrcodehistory_tgl2 = () => {
    $('.qrcodehistory_tgldiv2').slideToggle();
  };

  phrasecreate_clk = () => {
    $('.phrasecreate_div').hide();
    $('.addphrase_div').show();
    $('.phraselist_div').hide();
  };

  addintphrase_close = () => {
    $('.phraselist_div').show();
    $('.addphrase_div').hide();
  };

  psqrcode_addtagname = () => {
    $('.psqrcode_entertagname_drpdwn_div').hide();
    $('.psqrcode_entertagname_txtbx_div').show();
    this.setState({qr_addtag_save: true});
  };

  psqrcode_closeaddtagname = () => {
    $('.psqrcode_entertagname_txtbx_div').hide();
    $('.psqrcode_entertagname_drpdwn_div').show();
    this.setState({qr_addtag_save: false});
  };

  gmailconnect_clk = () => {
    $('.gmail_notconnected_div').hide();
    $('.gmail_connected_div').show();
  };

  qrcodesendmsgattach_clk = () => {
    $('.qrcodesendmsg_attachmentdiv').show();
  };

  ft_table_data = () => {
    this.setState({ft_load_more: true});
    const archive_history = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        limit: '10',
        start: this.state.ft_load_more_start,
      },
    };
    if (this.state.ft_load_more_start === '0') {
      $('#ftsent_tbl tbody').html('');
    }

    axios.get(API_CALL_ENDPOINT + '/blockchain/fungibletoken/transactions', archive_history).then((res) => {
      console.log(res.data);
      var ftarray = [];
      var incval = 0;
      if (res.data.status == 'success') {
        if (res.data.result.length < 10) {
          this.setState({ft_load_more_button: false});
        }

        $.each(res.data.result, function (index, values) {
          // var newdata = {
          //   id: values._id,
          //   Receiver: values?.contacts[0]?.first_name + ' ' + values?.contacts[0]?.last_name,
          //   Sent: values?.created_date,
          //   Accepted: values?.accepted_date,
          //   'No of Tokens': values?.amount,
          // };ftsent_tbl
          // ftarray.push(newdata);
          if (values.status === 'completed') {
            var data =
              '<tr><td>' +
              values?.contacts[0]?.first_name +
              ' ' +
              values?.contacts[0]?.last_name +
              '</td><td>' +
              moment(values?.sent_date).format('MM/DD/YYYY') +
              '</td><td>' +
              moment(values?.accepted_date).format('MM/DD/YYYY') +
              '</td><td><span className="fgreen4 font-weight-bold">' +
              values?.amount +
              '</span></td></tr>';
          } else {
            var data =
              '<tr><td>' +
              values?.contacts[0]?.first_name +
              ' ' +
              values?.contacts[0]?.last_name +
              '</td><td>' +
              moment(values?.sent_date).format('MM/DD/YYYY') +
              '</td><td></td><td><span className="fgreen4 font-weight-bold">' +
              values?.amount +
              '</span></td></tr>';
          }

          $('#ftsent_tbl tbody').append(data);
        });
        console.log('ftarray', ftarray);

        this.setState({
          ft_table_details: res.data.result,
          ft_load_more: false,
          ft_load_more_start: parseInt(this.state.ft_load_more_start) + parseInt('10'),
        });
      }
    });
  };

  archiveMode = () => {
    if ($('#archivemode').is(':checked')) {
      this.setState({VwArchiewDisbl: false});

      const archivemode_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        archive: 'on',
        setup: 'archive',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', archivemode_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);

          ArchvHstryDtatble.destroy();
          const archive_history = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };

          axios.get(API_CALL_ENDPOINT + '/business/archive/history', archive_history).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState({archiveHstry_detail: res.data.result});
              ArchvHstryDtatble = $('#archivehistory_tbl').DataTable({
                paging: true,
                lengthChange: false,
                ordering: false,
                searching: false,
              });

              var archiveMdStatus = res.data.result[0].archive_status;

              if (archiveMdStatus == 'on') {
                $('#archivemode').prop('checked', true);
                this.setState({VwArchiewDisbl: false});
              }
              if (archiveMdStatus == 'off') {
                $('#archivemode').prop('checked', false);
                this.setState({VwArchiewDisbl: true});
              }
            }
          });
        }
      });
    } else {
      const archivemode_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        archive: 'off',
        setup: 'archive',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', archivemode_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);

          ArchvHstryDtatble.destroy();
          const archive_history = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };

          axios.get(API_CALL_ENDPOINT + '/business/archive/history', archive_history).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState({archiveHstry_detail: res.data.result});
              ArchvHstryDtatble = $('#archivehistory_tbl').DataTable({
                paging: true,
                lengthChange: false,
                ordering: false,
                searching: false,
              });

              var archiveMdStatus = res.data.result[0].archive_status;

              if (archiveMdStatus == 'on') {
                $('#archivemode').prop('checked', true);
                this.setState({VwArchiewDisbl: false});
              }
              if (archiveMdStatus == 'off') {
                $('#archivemode').prop('checked', false);
                this.setState({VwArchiewDisbl: true});
              }
            }
          });
        }
      });

      $('#archivemessages').prop('checked', false);

      const archiveView_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        view_archive: 'off',
        setup: 'view_archive',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', archiveView_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    }
  };

  ViewArchiveOnOff = () => {
    if ($('#archivemessages').is(':checked')) {
      const archiveView_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        view_archive: 'on',
        setup: 'view_archive',
      };

      console.log(archiveView_Details);

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', archiveView_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    } else {
      this.setState({scResultContactList: []});
      $('.viewarchive_tgldiv').hide();
      const archiveView_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        view_archive: 'off',
        setup: 'view_archive',
      };

      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', archiveView_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    }
  };

  onClickTagTxtboxAdder(event) {
    console.log(this.state.tagTxtBox.length);
    this.setState({
      tagTxtBox: [...this.state.tagTxtBox, ''],
    });
  }

  onChangeTagInput(index, event) {
    let tagTxtBox = [...this.state.tagTxtBox];
    tagTxtBox[index] = event.target.value;
    this.setState({tagTxtBox});
  }

  onClickDelInput(index) {
    let tagTxtBox = [...this.state.tagTxtBox];
    tagTxtBox.splice(index, 1);
    this.setState({tagTxtBox});
  }

  onClickTagTxtboxSubmit(event) {
    this.setState({SaveTagBtnDsble: true});
    const filteredValues = this.state.tagTxtBox.filter((value) => value);
    const Tag_names = filteredValues.toString();

    $('#tag_saveBtb').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled');
    if (Tag_names == '') {
      this.setState(() => ({
        TagMsg: 'Please enter tag name',
        TagMsgClss: 'alert alert-danger',
        tagTxtBox: [''],
      }));

      setTimeout(() => {
        this.setState(() => ({
          TagMsg: '',
          TagMsgClss: '',
          tagTxtBox: [''],
          SaveTagBtnDsble: false,
        }));
        $('#tag_saveBtb').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
      }, 5000);
    } else {
      const createtag = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tag_name: Tag_names,
        tag_source: 'contacts',
      };
      console.log('createtag', createtag);
      axios
        .post(API_CALL_ENDPOINT + '/tags/create', createtag)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.setState(() => ({
              TagMsg: res.data.message,
              TagMsgClss: 'alert alert-success',
              tagTxtBox: [''],
            }));

            TagLstDtatable.destroy();

            const tagLst_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            console.log(tagLst_details);
            axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({tagList: res.data.result, tag_list: res.data.result});

                TagLstDtatable = $('#inttags_tbl').DataTable({
                  paging: true,
                  lengthChange: false,
                  ordering: false,
                  searching: false,
                  //"responsive": true,
                });
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
                tagTxtBox: [''],
                SaveTagBtnDsble: false,
              }));

              $('.taglist_div').show();
              $('.addtag_div').hide();
              $('#tag_saveBtb').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
            }, 5000);
          }

          if (res.data.status === 'conflict') {
            this.setState(() => ({
              TagMsg: res.data.message,
              TagMsgClss: 'alert alert-danger',
              tagTxtBox: [''],
            }));

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
                tagTxtBox: [''],
                SaveTagBtnDsble: false,
              }));
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              TagMsg: res.data.error.message,
              TagMsgClss: 'alert alert-danger',
              tagTxtBox: [''],
            }));

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
                tagTxtBox: [''],
                SaveTagBtnDsble: false,
              }));

              $('.taglist_div').show();
              $('.addtag_div').hide();
              $('#tag_saveBtb').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              this.setState(() => ({
                TagMsg: error.response.data.message,
                TagMsgClss: 'alert alert-danger',
                tagTxtBox: [''],
              }));

              setTimeout(() => {
                this.setState(() => ({
                  TagMsg: '',
                  TagMsgClss: '',
                  tagTxtBox: [''],
                  SaveTagBtnDsble: false,
                }));
              }, 5000);
            }

            if (error.response.data.error) {
              this.setState(() => ({
                TagMsg: error.response.data.error.message,
                TagMsgClss: 'alert alert-danger',
                tagTxtBox: [''],
              }));

              setTimeout(() => {
                this.setState(() => ({
                  TagMsg: '',
                  TagMsgClss: '',
                  tagTxtBox: [''],
                  SaveTagBtnDsble: false,
                }));

                $('.taglist_div').show();
                $('.addtag_div').hide();
                $('#tag_saveBtb').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
              }, 5000);
            }
          }
        });
    }
  }

  getTagname = (event) => {
    console.log(event.target.id);
    console.log(event.target.dataset.txt);
    $('#tagFrm').html('Edit Tag');

    this.setState({
      EditTagName: event.target.dataset.txt,
      EditTagId: event.target.id,
    });

    $('.tagcreate_div').hide();
    $('.addtag_div').show();
    $('.taglist_div').hide();
  };

  EditTagOnChng = (event) => {
    console.log(event.target.value);

    this.setState({
      EditTagName: event.target.value,
    });
  };

  EditTagNameClick = () => {
    this.setState({EditTagBtnDsble: true});
    $('#tag_update').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled');

    if (this.state.EditTagName === '') {
      this.setState(() => ({
        TagMsg: 'Please enter the Tag name',
        TagMsgClss: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          TagMsg: '',
          TagMsgClss: '',
          EditTagBtnDsble: false,
        }));

        $('#tag_update').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
      }, 5000);
    } else {
      const updatetag_details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tag_name: this.state.EditTagName,
        tag_id: this.state.EditTagId,
      };
      console.log(updatetag_details);
      axios
        .post(API_CALL_ENDPOINT + '/tags/update', updatetag_details)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.setState(() => ({
              TagMsg: res.data.message,
              TagMsgClss: 'alert alert-success',
            }));

            TagLstDtatable.destroy();

            const tagLst_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            console.log(tagLst_details);
            axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({tagList: res.data.result, tag_list: res.data.result});

                TagLstDtatable = $('#inttags_tbl').DataTable({
                  paging: true,
                  lengthChange: false,
                  ordering: false,
                  searching: false,
                  //"responsive": true,
                });
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
                EditTagBtnDsble: false,
                EditTagName: '',
                EditTagId: '',
              }));

              $('.taglist_div').show();
              $('.addtag_div').hide();
              $('#tag_update').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              TagMsg: res.data.error.message,
              TagMsgClss: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
                EditTagBtnDsble: false,
                EditTagName: '',
                EditTagId: '',
              }));

              $('.taglist_div').show();
              $('.addtag_div').hide();
              $('#tag_update').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                TagMsg: error.response.data.error.message,
                TagMsgClss: 'alert alert-danger',
              }));

              setTimeout(() => {
                this.setState(() => ({
                  TagMsg: '',
                  TagMsgClss: '',
                  EditTagBtnDsble: false,
                  EditTagName: '',
                  EditTagId: '',
                }));

                $('.taglist_div').show();
                $('.addtag_div').hide();
                $('#tag_update').attr('class', 'btn btn-black crsr_pntr m-2 btnpdng_sm');
              }, 5000);
            }
          }
        });
    }
  };

  DelTag = (event) => {
    console.log(event.target.id);

    const deltag_details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tag_id: event.target.id,
    };
    console.log(deltag_details);
    axios
      .post(API_CALL_ENDPOINT + '/tags/delete', deltag_details)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState(() => ({
            TagMsg: res.data.message,
            TagMsgClss: 'alert alert-success',
          }));

          TagLstDtatable.destroy();

          const tagLst_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };

          console.log(tagLst_details);
          axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState({tagList: res.data.result, tag_list: res.data.result});

              TagLstDtatable = $('#inttags_tbl').DataTable({
                paging: true,
                lengthChange: false,
                ordering: false,
                searching: false,
                //"responsive": true,
              });
            }
          });

          setTimeout(() => {
            this.setState(() => ({
              TagMsg: '',
              TagMsgClss: '',
              EditTagBtnDsble: false,
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            TagMsg: res.data.error.message,
            TagMsgClss: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              TagMsg: '',
              TagMsgClss: '',
              EditTagBtnDsble: false,
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              TagMsg: error.response.data.error.message,
              TagMsgClss: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
                EditTagBtnDsble: false,
              }));
            }, 5000);
          }
        }
      });
  };

  chkInboundtag = (event) => {
    console.log(event.target.id);

    $('#' + event.target.id).prop('checked', true);

    const inBndSetUs_details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tag_id: event.target.id,
      inbound_tag: 'true',
    };
    console.log('createtag', inBndSetUs_details);
    axios
      .post(API_CALL_ENDPOINT + '/tags/inbound/update', inBndSetUs_details)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState(() => ({
            TagMsg: res.data.message,
            TagMsgClss: 'alert alert-success',
          }));
          TagLstDtatable.destroy();
          const tagLst_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };

          console.log(tagLst_details);
          axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState({tagList: res.data.result, tag_list: res.data.result});

              TagLstDtatable = $('#inttags_tbl').DataTable({
                paging: true,
                lengthChange: false,
                ordering: false,
                searching: false,
                //"responsive": true,
              });
            }
          });

          setTimeout(() => {
            this.setState(() => ({
              TagMsg: '',
              TagMsgClss: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            TagMsg: res.data.error.message,
            TagMsgClss: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              TagMsg: '',
              TagMsgClss: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              TagMsg: error.response.data.error.message,
              TagMsgClss: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
              }));
            }, 5000);
          }
        }
      });
  };

  UnChkInbndTg = (event) => {
    console.log(event.target.id);
    $('#' + event.target.id).prop('checked', false);

    const inBndSetUs_details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tag_id: event.target.id,
      inbound_tag: 'false',
    };
    console.log('createtag', inBndSetUs_details);
    axios
      .post(API_CALL_ENDPOINT + '/tags/inbound/update', inBndSetUs_details)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState(() => ({
            TagMsg: res.data.message,
            TagMsgClss: 'alert alert-success',
          }));

          TagLstDtatable.destroy();
          const tagLst_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };

          console.log(tagLst_details);
          axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState({tagList: res.data.result, tag_list: res.data.result});

              TagLstDtatable = $('#inttags_tbl').DataTable({
                paging: true,
                lengthChange: false,
                ordering: false,
                searching: false,
                //"responsive": true,
              });
            }
          });

          setTimeout(() => {
            this.setState(() => ({
              TagMsg: '',
              TagMsgClss: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            TagMsg: res.data.error.message,
            TagMsgClss: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              TagMsg: '',
              TagMsgClss: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              TagMsg: error.response.data.error.message,
              TagMsgClss: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                TagMsg: '',
                TagMsgClss: '',
              }));
            }, 5000);
          }
        }
      });
  };

  chkAllTem = () => {
    if ($('#allteam').is(':checked')) {
      for (var i in this.state.TemIdsMebrArry) {
        $('.smryTmIds' + this.state.TemIdsMebrArry[i]).prop('checked', true);
      }
    } else {
      for (var i in this.state.TemIdsMebrArry) {
        $('.smryTmIds' + this.state.TemIdsMebrArry[i]).prop('checked', false);
      }
    }
  };

  chkAllTem_email = () => {
    if ($('#emailallteam').is(':checked')) {
      for (var i in this.state.TemIdsMebrArry) {
        $('.emailteam' + this.state.TemIdsMebrArry[i]).prop('checked', true);
      }
    } else {
      for (var i in this.state.TemIdsMebrArry) {
        $('.emailteam' + this.state.TemIdsMebrArry[i]).prop('checked', false);
      }
    }
  };

  receiveAlrtChk = (event) => {
    console.log(event.target.dataset.id);
    this.setState({TmRcvdalrt: event.target.dataset.id});
  };

  saveSmryalrt = () => {
    console.log(this.state.TemIdsMebrArry);
    var tmArry = [];
    for (var i in this.state.TemIdsMebrArry) {
      if ($('.smryTmIds' + this.state.TemIdsMebrArry[i]).is(':checked')) {
        var chdTmids = $('.smryTmIds' + this.state.TemIdsMebrArry[i]).attr('id');
        tmArry.push(chdTmids);
      }
    }

    console.log('Checked Team List: ' + tmArry);
    var chkTmIdDe = tmArry.filter((value) => value);
    var chkTmIdDetails = chkTmIdDe.toString();

    const smryalrt_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      summary: 'on',
      setup: 'summary',
      receive_alert: this.state.TmRcvdalrt,
      team_id: chkTmIdDetails,
    };

    console.log(smryalrt_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', smryalrt_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            SmryAlrtMsg: res.data.message,
            SmryAlrtCls: 'alert alert-success',
          }));

          setTimeout(() => {
            this.setState(() => ({
              SmryAlrtMsg: '',
              SmryAlrtCls: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            SmryAlrtMsg: res.data.error.message,
            SmryAlrtCls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              SmryAlrtMsg: '',
              SmryAlrtCls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              SmryAlrtMsg: error.response.data.error.message,
              SmryAlrtCls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                SmryAlrtMsg: '',
                SmryAlrtCls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  reSetSmryalrt = () => {
    for (var i in this.state.TemIdsMebrArry) {
      $('.smryTmIds' + this.state.TemIdsMebrArry[i]).prop('checked', false);
    }

    this.setState({TmRcvdalrt: 'week'});

    const smryalrt_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      summary: 'on',
      setup: 'summary',
      receive_alert: 'week',
      team_id: '',
    };

    console.log(smryalrt_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', smryalrt_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            SmryAlrtMsg: res.data.message,
            SmryAlrtCls: 'alert alert-success',
          }));

          setTimeout(() => {
            this.setState(() => ({
              SmryAlrtMsg: '',
              SmryAlrtCls: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            SmryAlrtMsg: res.data.error.message,
            SmryAlrtCls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              SmryAlrtMsg: '',
              SmryAlrtCls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              SmryAlrtMsg: error.response.data.error.message,
              SmryAlrtCls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                SmryAlrtMsg: '',
                SmryAlrtCls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  AutoTxtPlsclick(event) {
    console.log(this.state.AutoTxtArea.length);
    this.setState({
      AutoTxtArea: [...this.state.AutoTxtArea, ''],
    });
    this.hide_emoji();
  }

  AutoTxtDelonClick(index, _id) {
    let AutoTxtArea = [...this.state.AutoTxtArea];
    AutoTxtArea.splice(index, 1);
    this.setState({AutoTxtArea});

    if (_id) {
      var autotxtDel_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        autotext_id: _id,
      };

      console.log(autotxtDel_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/autotext/delete', autotxtDel_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState(() => ({
              AtoTxtMsg: res.data.message,
              AtoTxtClsNm: 'alert alert-success',
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              AtoTxtMsg: res.data.error.message,
              AtoTxtClsNm: 'alert alert-danger',
            }));
            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                AtoTxtMsg: error.response.data.error.message,
                AtoTxtClsNm: 'alert alert-danger',
              }));
              setTimeout(() => {
                this.setState(() => ({
                  AtoTxtMsg: '',
                  AtoTxtClsNm: '',
                }));
              }, 5000);
            }
          }
        });
    }
    this.hide_emoji();
  }

  AutoTxtOnChng(index, _id, event) {
    if (_id) {
      let AutoTxtArea = [...this.state.AutoTxtArea];
      AutoTxtArea[index] = {text_response: event.target.value, _id: _id};
      this.setState({AutoTxtArea});
    } else {
      let AutoTxtArea = [...this.state.AutoTxtArea];

      AutoTxtArea[index] = event.target.value;
      this.setState({AutoTxtArea});
    }
    this.hide_emoji();
  }

  AutoTxtMaxRspnsOnChg = (event) => {
    var value = event.target.value.replace(/^0+/, '');
    this.setState({AutotxtMxRspnsVlue: value});
  };

  AutoTxtSaveclick() {
    var regex = /^[0-9]+$/;
    var ValidNum = regex.test(this.state.AutotxtMxRspnsVlue);

    if (this.state.AutoTxtArea[0] === '') {
      this.setState(() => ({
        AtoTxtMsg: 'R-Bot response message cannot be blank.',
        AtoTxtClsNm: 'alert alert-danger',
      }));
      setTimeout(() => {
        this.setState(() => ({
          AtoTxtMsg: '',
          AtoTxtClsNm: '',
        }));
      }, 5000);
    }
    // else if(ValidNum){
    else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      console.log(this.state.atcharryDet_cmb);
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(this.state.AutoTxtArea);
      const filteredValues = this.state.AutoTxtArea.filter((value) => value);
      var AryTxtRespn = [];
      var i;
      for (i = 0; i < this.state.AutoTxtArea.length; i++) {
        var obj = {};
        if (this.state.AutoTxtArea[i]._id) {
          obj['id'] = this.state.AutoTxtArea[i]._id;
          obj['text_response'] = this.state.AutoTxtArea[i].text_response;
          obj['unique_id'] = this.state.atcharryDet_cmb.length != 0 ? Rnunique_id_cmb : '';
        } else if (this.state.AutoTxtArea[i] != '') {
          obj['id'] = this.state.AutotxtListUniqId;
          obj['text_response'] = this.state.AutoTxtArea[i];
          obj['unique_id'] = this.state.atcharryDet_cmb.length != 0 ? Rnunique_id_cmb : '';
        }
        AryTxtRespn.push(obj);
      }

      var AryTxtRespnToObj = JSON.stringify(AryTxtRespn);
      console.log(typeof AryTxtRespn);
      console.log(AryTxtRespn);

      console.log(AryTxtRespnToObj);

      var autoTxtsav_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        setup: 'auto_text',
        auto_text: 'on',
        maximum_response: this.state.AutotxtMxRspnsVlue,
        text_response_array: AryTxtRespnToObj,
      };

      console.log(autoTxtsav_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/profile/settings', autoTxtsav_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          // Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
          if (res.data.status == 'success') {
            this.setState(() => ({
              AtoTxtMsg: res.data.message,
              AtoTxtClsNm: 'alert alert-success',
              AtoTxDelClkDisble: false,
            }));

            const AutotxtRes_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            console.log(AutotxtRes_details);
            axios.get(API_CALL_ENDPOINT + '/business/autotext/list', AutotxtRes_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({AutoTxtArea: res.data.result});
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              AtoTxtMsg: res.data.error.message,
              AtoTxtClsNm: 'alert alert-danger',
            }));
            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                AtoTxtMsg: error.response.data.error.message,
                AtoTxtClsNm: 'alert alert-danger',
              }));
              setTimeout(() => {
                this.setState(() => ({
                  AtoTxtMsg: '',
                  AtoTxtClsNm: '',
                }));
              }, 5000);
            }
          }
        });
    }
  }

  AutoTxtTtlDel = () => {
    var TextareLngth = this.state.AutoTxtArea.length;
    var MinusTextareLngth = TextareLngth - parseInt(1);
    var i;
    for (i = 0; i < TextareLngth; i++) {
      var autotxtDel_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        autotext_id: this.state.AutoTxtArea[i]._id,
      };

      console.log(autotxtDel_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/autotext/delete', autotxtDel_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          $('#get_data_for_inbound').val('');
          if (res.data.status == 'success') {
            this.setState(() => ({
              AtoTxtMsg: res.data.message,
              AtoTxtClsNm: 'alert alert-success',
              AutoTxtArea: [''],
              AutotxtMxRspnsVlue: '',
              AtoTxDelClkDisble: true,
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
            this.setState({atcharryDet_cmb: []});
            $('.attchLstShow_cmb').hide();
            // if (this.state.atcharryDet_cmb.length == 0) {

            // }
          }

          if (res.data.error) {
            this.setState(() => ({
              AtoTxtMsg: res.data.error.message,
              AtoTxtClsNm: 'alert alert-danger',
            }));
            setTimeout(() => {
              this.setState(() => ({
                AtoTxtMsg: '',
                AtoTxtClsNm: '',
              }));
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                AtoTxtMsg: error.response.data.error.message,
                AtoTxtClsNm: 'alert alert-danger',
              }));
              setTimeout(() => {
                this.setState(() => ({
                  AtoTxtMsg: '',
                  AtoTxtClsNm: '',
                }));
              }, 5000);
            }
          }
        });

      var autoTxtOff_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        setup: 'auto_text',
        auto_text: 'off',
      };

      console.log(autoTxtOff_Details);
      axios.post(API_CALL_ENDPOINT + '/business/profile/settings', autoTxtOff_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
        }
      });
    }
  };

  SaveVoiceGrrtng = () => {
    console.log('R-Bot voiceGreeting Save');

    if (this.state.VoiceGrtngRecUrl != '') {
      $('#VoiceGrrtngRecSv_id').html('Saving...');
      $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled');
      var VoiceGrtng_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        voice_greetings_status: 'on',
        setup: 'voice_greetings',
        voice_greetings_type: 'record',
        greeting_url: this.state.VoiceGrtngRecUrl,
      };

      console.log(VoiceGrtng_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState(() => ({
              ViceGrtMsg: res.data.message,
              ViceGrtcls: 'alert alert-success',
              VoiceGrtngRecDeltDisble: false,
              TxtVoiceGrtngvlue: '',
              voiceGnrteResetdisble: true,
              VoiceLngRegn: 'en-US',
              TxtTypeVoiceUrl: '',
              generatePlayDisble: true,
            }));

            for (var i in this.state.GrntVoiveLngRegin) {
              if (this.state.GrntVoiveLngRegin[i]._id == 'en-US') {
                console.log(this.state.GrntVoiveLngRegin[i].voice_list);
                this.setState({VoceLstBsOnLanguge: this.state.GrntVoiveLngRegin[i].voice_list});
              }
            }

            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
              $('#VoiceGrrtngRecSv_id').html('Save');
              $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              ViceGrtMsg: res.data.error.message,
              ViceGrtcls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
              $('#VoiceGrrtngRecSv_id').html('Save');
              $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                ViceGrtMsg: error.response.data.error.message,
                ViceGrtcls: 'alert alert-danger',
              }));

              setTimeout(() => {
                this.setState(() => ({
                  ViceGrtMsg: '',
                  ViceGrtcls: '',
                }));
                $('#VoiceGrrtngRecSv_id').html('Save');
                $('#VoiceGrrtngRecSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
              }, 5000);
            }
          }
        });
    } else {
      this.setState(() => ({
        ViceGrtMsg: 'Please record after save',
        ViceGrtcls: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          ViceGrtMsg: '',
          ViceGrtcls: '',
        }));
      }, 5000);
    }
  };

  DelVoiceGrrtng = () => {
    var VoiceGrtng_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      voice_greetings_status: 'off',
      setup: 'voice_greetings',
      voice_greetings_type: 'record',
    };

    console.log(VoiceGrtng_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            ViceGrtMsg: 'R-Bot Voice Greetings Delete Successfully',
            ViceGrtcls: 'alert alert-success',
            VoiceGrtngRecDeltDisble: true,
            VoiceGrtngblobURL: '',
          }));
          $('#audioStrtVoiceGrtng').show();
          $('#audioPlayVoiceGrtng').hide();
          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            ViceGrtMsg: res.data.error.message,
            ViceGrtcls: 'alert alert-danger',
          }));
          $('#audioStrtVoiceGrtng').show();
          $('#audioPlayVoiceGrtng').hide();
          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              ViceGrtMsg: error.response.data.error.message,
              ViceGrtcls: 'alert alert-danger',
            }));
            $('#audioStrtVoiceGrtng').show();
            $('#audioPlayVoiceGrtng').hide();
            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  TxtVoiceGrtngOnChg = (event) => {
    console.log(event.target.value);
    this.setState({TxtVoiceGrtngvlue: event.target.value});
  };
  requestFungibleTokenConfirm = () => {
    var ft_token_name = $('#ft_token_name').val();
    var ft_token_symbol = $('#ft_token_symbol').val();
    var ft_token_number = $('#ft_token_number').val();
    if (ft_token_name.length === 0) {
      toast.dark('Please enter the Token Name.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      $('#ft_token_name').focus();
      return false;
    }
    if (ft_token_symbol.length === 0) {
      toast.dark('Please enter the Token Symbol.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      $('#ft_token_symbol').focus();
      return false;
    }
    if (ft_token_number.length === 0) {
      toast.dark('Please enter the No of Tokens.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      $('#ft_token_number').focus();
      return false;
    }
    var fungible_token_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'fungible_token',
      token_name: ft_token_name,
      token_symbol: ft_token_symbol,
      token_limit: ft_token_number,
    };

    console.log(fungible_token_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', fungible_token_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          $('.ftrequest').hide();
          this.getBusinessDetails();
          $('#ft_token_name').val('');
          $('#ft_token_symbol').val('');
          $('#ft_token_number').val('');
          $('.ftrequest_btn').show();
          $('.ftrequest_confirm_btn').hide();
          $('.ftrequest_confirm_message').hide();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
          }
        }
      });
  };

  requestFungibleToken = () => {
    var ft_token_name = $('#ft_token_name').val();
    var ft_token_symbol = $('#ft_token_symbol').val();
    var ft_token_number = $('#ft_token_number').val();
    if (ft_token_name.length === 0) {
      toast.dark('Please enter the Token Name.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      $('#ft_token_name').focus();
      return false;
    }
    if (ft_token_symbol.length === 0) {
      toast.dark('Please enter the Token Symbol.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      $('#ft_token_symbol').focus();
      return false;
    }
    if (ft_token_number.length === 0) {
      toast.dark('Please enter the No of Tokens.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      $('#ft_token_number').focus();
      return false;
    }
    $('.ftrequest_btn').hide();
    $('.ftrequest_confirm_btn').show();
    $('.ftrequest_confirm_message').show();
  };

  viewReceipt = (event, type) => {
    const token_list_params = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        flag: 'single',
        tran_hash: event,
      },
    };
    console.log('token_list_params', token_list_params);
    axios
      .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
      .then((res) => {
        if (res.data.status === 'success') {
          console.log('nftres.datasss', res.data.result[0]);
          this.setState({nft_token: res.data.result[0], nft_token_file_extension: ''});
          $('.nftmintedlist_div').hide();
          if (type === 'minted') {
            $('.receiptnft_div').show();
          } else if (type === 'sent') {
            $('.sentreceiptnft_div').show();
          } else if (type === 'accept') {
            $('.inprocessreceiptnft_div').show();
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
          }
        }
      });
  };

  NftCount = () => {
    const token_list_params = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '//blockchain/nft/analytics', token_list_params)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({
            nft_total_count: res.data.result.total_nft_count,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
            this.setState({
              nft_total_count: 0,
            });
          }
        }
      });
  };

  closeReceipt = (event) => {
    this.setState({nft_load_more: true});
    var nft_type = 'available';
    if ($('#custom-tabs-nftavailable-tab').hasClass('active')) {
      nft_type = 'available';
    }
    if ($('#custom-tabs-nftinprocess-tab').hasClass('active')) {
      nft_type = 'sent';
    }
    if ($('#custom-tabs-nftsent-tab').hasClass('active')) {
      nft_type = 'accepted';
    }
    var tab = nft_type;
    if (event == 'Available' || event == 'available' || event == '') {
      tab = 'available';
      $('#custom-tabs-nftavailable-tab').addClass('active');
      $('#custom-tabs-nftinprocess-tab').removeClass('active');
      $('#custom-tabs-nftsent-tab').removeClass('active');
      $('#custom-tabs-nftavailable').addClass('active show');
      $('#custom-tabs-nftinprocess').removeClass('active show');
      $('#custom-tabs-nftsent').removeClass('active show');
    }
    if (event == 'Sent' || event == 'sent') {
      tab = 'sent';
      $('#custom-tabs-nftavailable-tab').removeClass('active');
      $('#custom-tabs-nftinprocess-tab').addClass('active');
      $('#custom-tabs-nftsent-tab').removeClass('active');
      $('#custom-tabs-nftavailable').removeClass('active show');
      $('#custom-tabs-nftinprocess').addClass('active show');
      $('#custom-tabs-nftsent').removeClass('active show');
    }
    if (event == 'Accepted' || event == 'accepted') {
      tab = 'accepted';
      $('#custom-tabs-nftavailable-tab').removeClass('active');
      $('#custom-tabs-nftinprocess-tab').removeClass('active');
      $('#custom-tabs-nftsent-tab').addClass('active');
      $('#custom-tabs-nftavailable').removeClass('active show');
      $('#custom-tabs-nftinprocess').removeClass('active show');
      $('#custom-tabs-nftsent').addClass('active show');
    }
    $('.addnft_div').hide();
    const token_list_params = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        flag: 'all',
        tab: tab,
        // tran_hash: res.data.result.Tran_Hash,
      },
    };
    var tdataarr = [];
    var tdataarrsent = [];
    var tdataarraccept = [];

    axios
      .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
      .then((res) => {
        if (res.data.status === 'success') {
          console.log('nftres.datasss1', res.data.result);
          this.setState({
            nft_tokens: res.data.result,
            nft_token_file_extension: '',
            table_view: false,
            nft_load_more: false,
            nft_available_load_more: true,
            nft_sent_load_more: true,
            nft_accept_load_more: true,
          });
          $('.receiptnft_div').hide();
          $('.sentreceiptnft_div').hide();
          $('.inprocessreceiptnft_div').hide();
          $('.nftmintedlist_div').show();
          $('.addnft_div').hide();
          $('#nft_name_minted').val('');
          $('#nft_decription_minted').val('');
          $('#nft_file_name_minted').val('');
          $('#nft_file_url_minted').val('');
          $('.chooseupload_nft_minted').val('');
          $('.chooseupload_nft_minted').trigger('change');
          $('#uploaded_image_minted').hide();
          $('#uploaded_image_giff_minted').hide();

          for (var i = 0; i < res.data.result.length; i++) {
            if (res.data.result[i].status === 'minted') {
              tdataarr.push(res.data.result[i]);
            } else if (res.data.result[i].status === 'accepted') {
              tdataarraccept.push(res.data.result[i]);
            } else if (res.data.result[i].status === 'sent') {
              tdataarrsent.push(res.data.result[i]);
            }
          }
          if (tdataarr.length === 0) {
            $('#minted_search').hide();
          } else {
            $('#minted_search').show();
          }
          if (tdataarraccept.length === 0) {
            $('#accept_search').hide();
          } else {
            $('#accept_search').show();
          }
          if (tdataarrsent.length === 0) {
            $('#sent_search').hide();
          } else {
            $('#sent_search').show();
          }
          if (tdataarr.length < 20) {
            this.setState({nft_available_load_more: false});
          }
          if (tdataarrsent.length < 20) {
            this.setState({nft_sent_load_more: false});
          }
          if (tdataarraccept.length < 20) {
            this.setState({nft_accept_load_more: false});
          }
          this.setState({
            nft_minted_tokens_lists: tdataarr,
            nft_accept_tokens_lists: tdataarraccept,
            nft_sent_tokens_lists: tdataarrsent,
            nft_available_cnt: 0,
            nft_sent_cnt: 0,
            nft_accept_cnt: 0,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
            this.setState({
              nft_minted_tokens_lists: tdataarr,
              nft_accept_tokens_lists: tdataarraccept,
              nft_sent_tokens_lists: tdataarrsent,
              nft_load_more: false,
              nft_available_cnt: 0,
              nft_sent_cnt: 0,
              nft_accept_cnt: 0,
            });
            $('.receiptnft_div').hide();
            if (this.state.nft_total_count !== 0) {
              $('.nftmintedlist_div').show();
            }
          }
        }
        this.NftCount();
      });
  };

  LoadMoreNft = (event) => {
    var nft_type = 'available';
    var startval = 0;
    var searchval = '';
    this.setState({nft_load_more: true});
    if ($('#custom-tabs-nftavailable-tab').hasClass('active')) {
      nft_type = 'available';
      startval = this.state.nft_available_cnt;
      startval = parseInt(startval) + 20;
      this.setState({nft_available_cnt: startval});
      if ($('.nft_minted_search').val().length !== 0) {
        searchval = $('.nft_minted_search').val();
      }
    }
    if ($('#custom-tabs-nftinprocess-tab').hasClass('active')) {
      nft_type = 'sent';
      startval = this.state.nft_sent_cnt;
      startval = parseInt(startval) + 20;
      this.setState({nft_sent_cnt: startval});
      if ($('.nft_sent_search').val().length !== 0) {
        searchval = $('.nft_sent_search').val();
      }
    }
    if ($('#custom-tabs-nftsent-tab').hasClass('active')) {
      nft_type = 'accepted';
      startval = this.state.nft_accept_cnt;
      startval = parseInt(startval) + 20;
      this.setState({nft_accept_cnt: startval});
      if ($('.nft_accept_search').val().length !== 0) {
        searchval = $('.nft_accept_search').val();
      }
    }
    $('.addnft_div').hide();
    const token_list_params = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        flag: 'all',
        tab: nft_type,
        limit: '20',
        start: startval,

        // tran_hash: res.data.result.Tran_Hash,
      },
    };
    console.log('searchval', searchval);
    if (searchval.length != 0) {
      token_list_params.params.search_text = searchval;
    }
    var tdataarr = this.state.nft_minted_tokens_lists;
    var tdataarrsent = this.state.nft_sent_tokens_lists;
    var tdataarraccept = this.state.nft_accept_tokens_lists;
    var mintedarr = [];
    var sentarr = [];
    var acceptarr = [];
    axios
      .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
      .then((res) => {
        if (res.data.status === 'success') {
          console.log('nftres.datasss1', res.data.result);
          this.setState({nft_tokens: res.data.result, nft_token_file_extension: '', table_view: false});
          $('.receiptnft_div').hide();
          $('.sentreceiptnft_div').hide();
          $('.inprocessreceiptnft_div').hide();
          $('.nftmintedlist_div').show();
          $('.addnft_div').hide();
          $('#nft_name_minted').val('');
          $('#nft_decription_minted').val('');
          $('#nft_file_name_minted').val('');
          $('#nft_file_url_minted').val('');
          $('.chooseupload_nft_minted').val('');
          $('.chooseupload_nft_minted').trigger('change');
          $('#uploaded_image_minted').hide();
          $('#uploaded_image_giff_minted').hide();

          for (var i = 0; i < res.data.result.length; i++) {
            if (res.data.result[i].status === 'minted') {
              tdataarr.push(res.data.result[i]);
              mintedarr.push(res.data.result[i]);
            } else if (res.data.result[i].status === 'accepted') {
              tdataarraccept.push(res.data.result[i]);
              acceptarr.push(res.data.result[i]);
            } else if (res.data.result[i].status === 'sent') {
              tdataarrsent.push(res.data.result[i]);
              sentarr.push(res.data.result[i]);
            }
          }
          if (tdataarr.length === 0) {
            $('#minted_search').hide();
          } else {
            $('#minted_search').show();
          }
          if (tdataarraccept.length === 0) {
            $('#accept_search').hide();
          } else {
            $('#accept_search').show();
          }
          if (tdataarrsent.length === 0) {
            $('#sent_search').hide();
          } else {
            $('#sent_search').show();
          }
          if ($('#custom-tabs-nftavailable-tab').hasClass('active')) {
            if (mintedarr.length < 20) {
              this.setState({nft_available_load_more: false});
            }
          }
          if ($('#custom-tabs-nftinprocess-tab').hasClass('active')) {
            if (sentarr.length < 20) {
              this.setState({nft_sent_load_more: false});
            }
          }
          if ($('#custom-tabs-nftsent-tab').hasClass('active')) {
            if (acceptarr.length < 20) {
              this.setState({nft_accept_load_more: false});
            }
          }
          this.setState({
            nft_minted_tokens_lists: tdataarr,
            nft_accept_tokens_lists: tdataarraccept,
            nft_sent_tokens_lists: tdataarrsent,
            nft_load_more: false,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
            this.setState({
              nft_minted_tokens_lists: tdataarr,
              nft_accept_tokens_lists: tdataarraccept,
              nft_sent_tokens_lists: tdataarrsent,
              nft_load_more: false,
              nft_available_load_more: false,
              nft_sent_load_more: false,
              nft_accept_load_more: false,
            });
            $('.receiptnft_div').hide();
          }
          if ($('#custom-tabs-nftavailable-tab').hasClass('active')) {
            startval = this.state.nft_available_cnt;
            startval = parseInt(startval) - 20;
            this.setState({nft_available_cnt: startval});
          }
          if ($('#custom-tabs-nftinprocess-tab').hasClass('active')) {
            startval = this.state.nft_sent_cnt;
            startval = parseInt(startval) - 20;
            this.setState({nft_sent_cnt: startval});
          }
          if ($('#custom-tabs-nftsent-tab').hasClass('active')) {
            startval = this.state.nft_accept_cnt;
            startval = parseInt(startval) - 20;
            this.setState({nft_accept_cnt: startval});
          }
        }
      });
  };

  nftDetails = () => {
    const nft_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/nft/list', nft_details)
      .then((res) => {
        if (res.data.result) {
          console.log('nftres.data', res.data.result.nft);
          this.setState({nft_details: res.data.result.nft});
          if (res.data.result.nft.token_name === '' && res.data.result.nft.token_symbol === '') {
            $('#nft_step_1').show();
            $('#nft_step_3').hide();
            $('#nft_step_2').hide();
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
          }
        }
      });
  };

  contractNextStep = (step) => {
    if (step === 1) {
    } else if (step === 2) {
      $('#nft_step_1').hide();
      $('#nft_step_3').hide();
      $('#nft_step_2').show();
      $('#nftmint').addClass('active');
      $('#nftconfirm').removeClass('active');
      $('.receiptnft_div').hide();
      $('.sentreceiptnft_div').hide();
      $('.inprocessreceiptnft_div').hide();
      $('.nftmintedlist_div').hide();
      $('.nftcreate_div').show();
    } else if (step === 3) {
      var nft_name = $('#nft_name').val();
      var nft_decription = $('#nft_decription').val();
      var nft_file_name = $('#nft_file_name').val();
      var nft_file_url = $('#nft_file_url').val();
      var nft_thumb_file_url = $('#nft_thumb_file_url').val();
      var that = this;
      var continue_vals = '1';
      var keyarr = [];
      if (nft_name.length === 0) {
        toast.dark('Please enter the NFT name.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (nft_decription.length === 0) {
        toast.dark('Please enter the NFT Decription.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (nft_file_url.length === 0) {
        toast.dark('Please upload a file', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      $('.cnftkeyvalues')
        .each(function (index) {
          console.log($('#keyrequired' + $(this).data('id') + '').is(':checked'));
          if ($(this).data('required') && $(this).val().length == 0) {
            continue_vals = '0';
            $(this).focus();
            toast.dark('Please enter the key value.', {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return false;
          } else {
            var keyvalues = {key: $(this).data('key'), value: $(this).val()};
            keyarr.push(keyvalues);
          }
        })
        .promise()
        .done(function () {
          if (continue_vals == '1') {
            var keyvals = keyarr;
            console.log('keyarrkeyarrkeyarr', keyarr);
            $('#nft_step_1').hide();
            $('#nft_step_2').hide();
            $('#nft_step_3').show();
            $('#nftconfirm').addClass('active');
            that.setState({
              nft_name: nft_name,
              nft_decription: nft_decription,
              nft_file_name: nft_file_name,
              nft_file_url: nft_file_url,
              nft_thumb_file_url: nft_thumb_file_url,
              nft_keys: keyvals,
            });
          }
        });
    } else if (step === 4) {
      $('.nftconfirm_receipt_clk').hide();
      $('.nftconfirm_receipt_clk_diabled').show();
      if (!$('#nftagree').is(':checked')) {
        toast.dark('Please agree to the terms of service', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        $('.nftconfirm_receipt_clk').show();
        $('.nftconfirm_receipt_clk_diabled').hide();
        return false;
      }
      // $('.nftconfirm_receipt_clk').addClass('isdisabled');

      var nftmint_params = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        file_url: this.state.nft_file_url,
        thumbnail_file_url: this.state.nft_thumb_file_url,
        nft_name: this.state.nft_name,
        nft_description: this.state.nft_decription,
        file_name: this.state.nft_file_name,
        upload_type: this.state.nft_file_type,
        token_key: JSON.stringify(this.state.nft_keys),
      };
      axios
        .post(API_CALL_ENDPOINT + '/blockchain/nft/mint', nftmint_params)
        .then((res) => {
          console.log(res.data);
          this.nftDetails();
          if (res.data.status === 'success') {
            this.setState({nft_mint_click: false});
            this.getBusinessDetails();
            $('.nftconfirm_receipt_clk').show();
            $('.nftconfirm_receipt_clk_diabled').hide();
            $('#nftagree').prop('checked', false);
            $('.nftkeyvalues').val('');

            const token_list_params = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                flag: 'single',
                tran_hash: res.data.result.Tran_Hash,
              },
            };
            console.log('token_list_params', token_list_params);
            axios
              .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
              .then((res) => {
                if (res.data.status === 'success') {
                  console.log('nftres.datasss', res.data.result[0]);
                  this.setState({
                    nft_token: res.data.result[0],
                    nft_token_file_extension: '',
                    nft_available_cnt: 0,
                    nft_sent_cnt: 0,
                    nft_accept_cnt: 0,
                  });
                  $('.nftcreate_div').hide();
                  $('.receiptnft_div').show();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.status == 'no_record') {
                  }
                }
              });
          }

          //console.log(res.data.message);
        })
        .catch((error) => {});
    }
  };

  mintingNextStep = (step) => {
    if (step === 1) {
    } else if (step === 2) {
      this.setState({
        table_view: true,
      });
      $('#nftminted_step_1').show();
      $('#nftminted_step_2').hide();
      $('.nftcreate_div').hide();
      $('.receiptnft_div').hide();
      $('.sentreceiptnft_div').hide();
      $('.inprocessreceiptnft_div').hide();
      $('.nftmintedlist_div').hide();
      $('.addnft_div').show();
      $('.nftconfirm_minted').removeClass('active');
    } else if (step === 3) {
      var nft_name = $('#nft_name_minted').val();
      var nft_decription = $('#nft_decription_minted').val();
      var nft_file_name = $('#nft_file_name_minted').val();
      var nft_file_url = $('#nft_file_url_minted').val();
      var nft_thumb_file_url = $('#nft_thumb_file_url_minted').val();
      var that = this;
      var continue_vals = '1';
      var keyarr = [];
      console.log('nft_name', nft_name);
      console.log('nft_decription', nft_decription);
      console.log('nft_file_name', nft_file_name);
      console.log('nft_file_url', nft_file_url);
      console.log('nft_thumb_file_url', nft_thumb_file_url);
      if (nft_name.length === 0) {
        toast.dark('Please enter the NFT name.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (nft_decription.length === 0) {
        toast.dark('Please enter the NFT Decription.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (nft_file_url.length === 0) {
        toast.dark('Please upload a file', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      $('.nftkeyvalues')
        .each(function (index) {
          console.log($('#keyrequired' + $(this).data('id') + '').is(':checked'));
          if ($(this).data('required') && $(this).val().length == 0) {
            continue_vals = '0';
            $(this).focus();
            toast.dark('Please enter the key value.', {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return false;
          } else {
            var keyvalues = {key: $(this).data('key'), value: $(this).val()};
            keyarr.push(keyvalues);
          }
        })
        .promise()
        .done(function () {
          if (continue_vals == '1') {
            var keyvals = keyarr;
            console.log('keyarrkeyarrkeyarr', keyarr);
            $('#nftminted_step_1').hide();
            $('#nftminted_step_2').show();
            $('.nftconfirm_minted').addClass('active');
            that.setState({
              nft_name: nft_name,
              nft_decription: nft_decription,
              nft_file_name: nft_file_name,
              nft_file_url: nft_file_url,
              nft_thumb_file_url: nft_thumb_file_url,
              nft_keys: keyvals,
            });
          }
        });
    } else if (step === 4) {
      $('.nftconfirm_receipt_clk').hide();
      $('.nftconfirm_receipt_clk_diabled').show();
      if (!$('#nftagree_minted').is(':checked')) {
        toast.dark('Please agree to the terms of service', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        $('.nftconfirm_receipt_clk').show();
        $('.nftconfirm_receipt_clk_diabled').hide();
        return false;
      }
      var nftmint_params = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        file_url: this.state.nft_file_url,
        thumbnail_file_url: this.state.nft_thumb_file_url,
        nft_name: this.state.nft_name,
        nft_description: this.state.nft_decription,
        file_name: this.state.nft_file_name,
        upload_type: this.state.nft_file_type,
        token_key: JSON.stringify(this.state.nft_keys),
      };
      axios
        .post(API_CALL_ENDPOINT + '/blockchain/nft/mint', nftmint_params)
        .then((res) => {
          console.log(res.data);
          this.nftDetails();
          if (res.data.status === 'success') {
            this.setState({nft_mint_click: false, nft_available_cnt: 0, nft_sent_cnt: 0, nft_accept_cnt: 0});
            this.getBusinessDetails();
            $('.nftconfirm_receipt_clk').show();
            $('.nftconfirm_receipt_clk_diabled').hide();
            $('#nftagree_minted').prop('checked', false);
            $('.nftkeyvalues').val('');
            const token_list_params = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                flag: 'single',
                tran_hash: res.data.result.Tran_Hash,
              },
            };
            console.log('token_list_params', token_list_params);
            axios
              .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
              .then((res) => {
                if (res.data.status === 'success') {
                  console.log('nftres.datasss', res.data.result[0]);
                  this.setState({nft_token: res.data.result[0], nft_token_file_extension: ''});
                  $('.addnft_div').hide();
                  $('.receiptnft_div').show();
                  $('#nft_name_minted').val('');
                  $('#nft_decription_minted').val('');
                  $('#nft_file_name_minted').val('');
                  $('#nft_file_url_minted').val('');
                  $('#nft_thumb_file_url_minted').val('');
                  $('.chooseupload_nft_minted').val('');
                  $('.chooseupload_nft_minted').trigger('change');
                  $('#uploaded_image_minted').hide();
                  $('#uploaded_image_giff_minted').hide();
                  $('.nftconfirm_receipt_clk').show();
                  $('.nftconfirm_receipt_clk_diabled').hide();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.status == 'no_record') {
                  }
                }
              });
          }

          //console.log(res.data.message);
        })
        .catch((error) => {});
    }
  };

  nftFunctionalities = (val, type) => {
    if (type === 'view_receipt') {
      var id = val.token_hash;
      var vtype = val.status;
      console.log('ididid', id);
      console.log('typetype', vtype);
      const token_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          flag: 'single',
          tran_hash: id,
        },
      };
      // console.log('token_list_params', token_list_params);
      axios
        .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            console.log('nftres.datasss', res.data.result[0]);
            console.log('nftres.vtype', vtype);
            this.setState({nft_token: res.data.result[0]});
            $('.nftmintedlist_div').hide();
            if (vtype === 'minted') {
              $('.receiptnft_div').show();
            } else if (vtype === 'sent') {
              $('.sentreceiptnft_div').show();
            } else if (vtype === 'accepted') {
              $('.inprocessreceiptnft_div').show();
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
            }
          }
        });
    } else if (type === 'copy') {
      var $self = $(this);
      var id = $self.data('id');
      this.mintingNextStep(2);
      $('#nft_name_minted').val(val.nft_name);
      $('#nft_decription_minted').val(val.nft_description);
      var uptodtype = val.upload_type;
      this.setState({
        nft_file_type: uptodtype,
      });
      //alert($self.data('file_name'));
      $('#nft_file_name_minted').val(val.file_name);
      $('#nft_file_url_minted').val(val.file_url);
      $('#nft_thumb_file_url_minted').val(val.thumbnail_file_url);
      if (uptodtype.length !== 0) {
        if (uptodtype === 'image/gif') {
          $('.chooseupload_nft_minted').val('choosegif');
          $('#uploaded_image_giff_minted').show();
          $('#uploaded_img_src_giff_minted').attr('src', val.file_url);
        } else {
          $('.chooseupload_nft_minted').val('chooseimage');
          $('#uploaded_image_minted').show();
          $('#uploaded_image_minted_src').attr('src', val.file_url);
        }
        $('.chooseupload_nft_minted').trigger('change');
      }
    } else if (type === 'delete') {
      var id = val._id;
      if (typeof val?.sent_to !== 'undefined' && val?.sent_to?.length !== 0) {
        var cid = val.sent_to;
      } else {
        var cid = '';
      }
      var revert_params = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        token_id: id,
        contact_id: cid,
        type: type,
      };
      // console.log('token_list_params', token_list_params);
      axios
        .post(API_CALL_ENDPOINT + '/blockchain/nft/changestatus', revert_params)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.closeReceipt();
            toast.dark(res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          //console.log(res.data.message);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              console.log(error.response.data.error.message);
              this.closeReceipt();
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    } else if (type === 'revert') {
      var id = val._id;
      if (typeof val?.sent_to !== 'undefined' && val?.sent_to?.length !== 0) {
        var cid = val.sent_to;
      } else {
        var cid = '';
      }
      var revert_params = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        token_id: id,
        contact_id: cid,
        type: type,
      };
      // console.log('token_list_params', token_list_params);
      axios
        .post(API_CALL_ENDPOINT + '/blockchain/nft/changestatus', revert_params)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.closeReceipt();
            toast.dark(res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          //console.log(res.data.message);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              console.log(error.response.data.error.message);
              this.closeReceipt();
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
    console.log('out', val);
    console.log('type', type);
  };

  progressbarCount = () => {
    var numItems = $('.added_class').length;
    return numItems;
  };

  fileGetDetails_cmb_imagesnft = (event) => {
    var upload_counts = [];
    if (event.target.files.length !== 0) {
      var file_length = event.target.files.length;
      $('.corSticky').show();
      $('.corSticky_container').show();
      $('.chooseimage ').hide();
      $('.upload_div').hide();
      $('.nft_next_btn ').hide();
      $('.nft_next_btn_disabled').show();
      var progrescount = this.progressbarCount();
      if (progrescount == 0) {
        $('#attachement_progress_div').html('');
      }

      var i;
      if (upload_counts.length == 0) {
        $('#attachement_progress_div').html('');
        $('#attachement_progress_div').css({height: '65px'});
      }
      if (file_length == 1) {
        $('#attachement_progress_div').css({height: '65px'});
      } else if (file_length == 2) {
        $('#attachement_progress_div').css({height: '130px'});
      } else if (file_length >= 3) {
        $('#attachement_progress_div').css({height: '200px'});
      }
      var i;

      var flDc_id = [];
      var flDc_detils = [];
      var flDc_type = [];
      var filename = [];
      var configs = [];
      var filess = event.target.files;
      var idval;
      var FIle_Documentid_cmb = [];

      for (i = 0; i < file_length; i++) {
        console.log('event.target.files[i].type', event.target.files[i].type);
        if (
          event.target.files[i].type !== 'image/png' &&
          event.target.files[i].type !== 'image/x-png' &&
          event.target.files[i].type !== 'image/gif' &&
          event.target.files[i].type !== 'image/jpeg' &&
          event.target.files[i].type !== 'image/pipeg' &&
          event.target.files[i].type !== 'image/tiff' &&
          event.target.files[i].type !== 'image/svg+xml'
        ) {
          toast.dark('Sorry, this file is invalid. Please try using a different file.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.corSticky').hide();
          $('.corSticky_container').hide();
          $('.chooseimage').show();
          $('.upload_div').show();
          $('.nft_next_btn ').show();
          $('.nft_next_btn_disabled').hide();
          return false;
        }

        var filetype = event.target.files[i].type;
        this.setState({nft_file_type: filetype});
        // console.log("start",upload_count);
        upload_counts.push(upload_count);
        const file_detailsd = new FormData();
        filename.push(event.target.files[i].name);
        var filenametext = event.target.files[i].name;
        var finamecount = 40;
        var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        } else {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">${filenamefinal}<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        }
        file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        file_detailsd.append('secret', 'R1eqD2twI3E4');
        file_detailsd.append('user_id', this.state.user_id);
        file_detailsd.append('business_id', this.state.business_id);
        file_detailsd.append('file', event.target.files[i]);
        file_detailsd.append('reference', 'text');
        file_detailsd.append('source', 'admin');
        idval = i;
        var configuration = {
          onUploadProgress: myUploadProgress(upload_count),
        };
        console.log('event.target.files.length', event.target.files.length);
        console.log('file_detailsd', file_detailsd);
        console.log('upload_count', upload_count);
        if (event.target.files.length != 0) {
          this.colapse_all_attachments();
          console.log('First I value: ' + i);
          var k = 1;
          axios
            .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
            .then((res) => {
              console.log(res);
              console.log(res.data.message);

              if (res.data.status == 'success') {
                $('.nft_img_uploads').val(null);
                $('#uploaded_img_src').attr('src', '');
                $('.corSticky').hide();
                $('.corSticky_container').hide();
                $('.chooseimage').show();
                $('.upload_div').show();
                $('.nft_next_btn ').show();
                $('.nft_next_btn_disabled').hide();
                this.colapse_all_attachments();
                console.log('File_URL :' + res.data?.result?.files[0].file_url);
                console.log('ID_num :' + res.data?.result?.files[0]._id);
                var file_name = res.data?.result?.files[0].display_name;
              }
              if (res?.data?.error?.error_type == 'invalid_file_type') {
                const errors = [];
                errors.push('Sorry, this file is invalid. Please try using a different file.');
              }
              $('#uploaded_img_src').attr('src', res.data?.result?.files[0].thumbnail_file_urll);
              $('#uploaded_image').show();
              $('#nft_file_name').val(filenametext);
              $('#nft_file_url').val(res.data?.result?.files[0].file_url);
              $('#nft_thumb_file_url').val(res.data?.result?.files[0].thumbnail_file_url);
            })
            .catch((error) => {
              if (error.response) {
                if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;

                  toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                    position: 'top-right',
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                $('#file-input3')[0].value = '';
              }
            });
        }
        //  upload_count++;
      }
    }
  };

  fileGetDetails_cmb_imagesnftgiff = (event) => {
    if (event.target.files.length !== 0) {
      var file_length = event.target.files.length;
      $('.corSticky').show();
      $('.corSticky_container').show();
      $('.choosegif').hide();
      $('.upload_div').hide();
      $('.nft_next_btn ').hide();
      $('.nft_next_btn_disabled').show();
      var progrescount = this.progressbarCount();
      if (progrescount == 0) {
        $('#attachement_progress_div').html('');
      }

      var i;
      if (upload_counts.length == 0) {
        $('#attachement_progress_div').html('');
        $('#attachement_progress_div').css({height: '65px'});
      }
      if (file_length == 1) {
        $('#attachement_progress_div').css({height: '65px'});
      } else if (file_length == 2) {
        $('#attachement_progress_div').css({height: '130px'});
      } else if (file_length >= 3) {
        $('#attachement_progress_div').css({height: '200px'});
      }
      var i;

      var flDc_id = [];
      var flDc_detils = [];
      var flDc_type = [];
      var filename = [];
      var configs = [];
      var filess = event.target.files;
      var idval;
      var FIle_Documentid_cmb = [];
      console.log('File Format', filess);
      for (i = 0; i < file_length; i++) {
        if (event.target.files[i].type !== 'image/gif') {
          toast.dark('Sorry, this file is invalid. Please try using a different file.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.corSticky').hide();
          $('.corSticky_container').hide();
          $('.choosegif ').show();
          $('.upload_div').show();
          $('.nft_next_btn ').show();
          $('.nft_next_btn_disabled').hide();
          return false;
        }
        // console.log("start",upload_count);
        upload_counts.push(upload_count);
        var filetype = event.target.files[i].type;
        this.setState({nft_file_type: filetype});
        const file_detailsd = new FormData();
        filename.push(event.target.files[i].name);
        var filenametext = event.target.files[i].name;
        var finamecount = 40;
        var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        } else {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">${filenamefinal}<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        }
        file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        file_detailsd.append('secret', 'R1eqD2twI3E4');
        file_detailsd.append('user_id', this.state.user_id);
        file_detailsd.append('business_id', this.state.business_id);
        file_detailsd.append('file', event.target.files[i]);
        file_detailsd.append('reference', 'text');
        file_detailsd.append('source', 'admin');
        idval = i;
        var configuration = {
          onUploadProgress: myUploadProgress(upload_count),
        };
        if (event.target.files.length != 0) {
          this.colapse_all_attachments();
          console.log('First I value: ' + i);
          var k = 1;
          axios
            .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
            .then((res) => {
              console.log(res);
              console.log(res.data.message);

              if (res.data.status == 'success') {
                $('.nft_img_uploads').val(null);
                $('#uploaded_img_src_giff_minted').attr('src', '');
                $('.corSticky').hide();
                $('.corSticky_container').hide();
                $('.choosegif').show();
                $('.upload_div').show();
                $('.nft_next_btn').show();
                $('.nft_next_btn_disabled').hide();
                this.colapse_all_attachments();
                console.log('File_URL :' + res.data?.result?.files[0].file_url);
                console.log('ID_num :' + res.data?.result?.files[0]._id);
                var file_name = res.data?.result?.files[0].display_name;
              }
              if (res?.data?.error?.error_type == 'invalid_file_type') {
                const errors = [];
                errors.push('Sorry, this file is invalid. Please try using a different file.');
              }
              $('#file-input4')[0].value = '';
              $('#uploaded_img_src_giff_minted').attr('src', res.data?.result?.files[0].thumbnail_file_url);
              $('#uploaded_image_giff').show();
              $('#nft_file_name').val(filenametext);
              $('#nft_file_url').val(res.data?.result?.files[0].file_url);
              $('#nft_thumb_file_url').val(res.data?.result?.files[0].thumbnail_file_url);
            })
            .catch((error) => {
              if (error.response) {
                if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;

                  toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                    position: 'top-right',
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                $('#file-input3')[0].value = '';
              }
            });
        }
        //  upload_count++;
      }
    }
  };

  fileGetDetails_cmb_imagesnft_mint = (event) => {
    ///var upload_counts = [];
    console.log('eventevent', event);
    if (event.target.files.length !== 0) {
      var file_length = event.target.files.length;
      console.log('file_length', file_length);
      $('.corSticky').show();
      $('.corSticky_container').show();
      $('.chooseimage').hide();
      $('.upload_div').hide();
      $('.nft_next_btn ').hide();
      $('.nft_next_btn_disabled').show();
      var progrescount = this.progressbarCount();
      if (progrescount == 0) {
        $('#attachement_progress_div').html('');
      }

      var i;
      if (upload_counts.length == 0) {
        $('#attachement_progress_div').html('');
        $('#attachement_progress_div').css({height: '65px'});
      }
      if (file_length == 1) {
        $('#attachement_progress_div').css({height: '65px'});
      } else if (file_length == 2) {
        $('#attachement_progress_div').css({height: '130px'});
      } else if (file_length >= 3) {
        $('#attachement_progress_div').css({height: '200px'});
      }
      var i;

      var flDc_id = [];
      var flDc_detils = [];
      var flDc_type = [];
      var filename = [];
      var configs = [];
      var filess = event.target.files;
      var idval;
      var FIle_Documentid_cmb = [];

      for (i = 0; i < file_length; i++) {
        console.log('event.target.files[i].type', event.target.files[i].type);
        if (
          event.target.files[i].type !== 'image/png' &&
          event.target.files[i].type !== 'image/x-png' &&
          event.target.files[i].type !== 'image/gif' &&
          event.target.files[i].type !== 'image/jpeg' &&
          event.target.files[i].type !== 'image/pipeg' &&
          event.target.files[i].type !== 'image/tiff' &&
          event.target.files[i].type !== 'image/svg+xml'
        ) {
          toast.dark('Sorry, this file is invalid. Please try using a different file.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.corSticky').hide();
          $('.corSticky_container').hide();
          $('.chooseimage').show();
          $('.upload_div').show();
          $('.nft_next_btn ').show();
          $('.nft_next_btn_disabled').hide();
          return false;
        }
        var filetype = event.target.files[i].type;
        this.setState({nft_file_type: filetype});
        // console.log("start",upload_count);
        upload_counts.push(upload_count);
        const file_detailsd = new FormData();
        filename.push(event.target.files[i].name);
        var filenametext = event.target.files[i].name;
        var finamecount = 40;
        var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        } else {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">${filenamefinal}<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        }
        file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        file_detailsd.append('secret', 'R1eqD2twI3E4');
        file_detailsd.append('user_id', this.state.user_id);
        file_detailsd.append('business_id', this.state.business_id);
        file_detailsd.append('file', event.target.files[i]);
        file_detailsd.append('reference', 'text');
        file_detailsd.append('source', 'admin');
        idval = i;
        var configuration = {
          onUploadProgress: myUploadProgress(upload_count),
        };
        // console.log("event.target.files.length",event.target.files.length);
        // console.log("file_detailsd",file_detailsd);
        // console.log("upload_count",upload_count);
        if (event.target.files.length != 0) {
          this.colapse_all_attachments();
          console.log('First I value: ' + i);
          var k = 1;
          axios
            .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
            .then((res) => {
              console.log(res);
              console.log(res.data.message);

              if (res.data.status == 'success') {
                $('.nft_img_uploads').val(null);
                $('#uploaded_image_minted_src').attr('src', '');
                $('.corSticky').hide();
                $('.corSticky_container').hide();
                $('.chooseimage').show();
                $('.upload_div').show();
                $('.nft_next_btn ').show();
                $('.nft_next_btn_disabled').hide();
                this.colapse_all_attachments();
                console.log('File_URL :' + res.data?.result?.files[0].file_url);
                console.log('ID_num :' + res.data?.result?.files[0]._id);
                var file_name = res.data?.result?.files[0].display_name;
              }
              if (res?.data?.error?.error_type == 'invalid_file_type') {
                const errors = [];
                errors.push('Sorry, this file is invalid. Please try using a different file.');
              }
              $('#file-input3')[0].value = '';
              $('#uploaded_image_minted_src').attr('src', res.data?.result?.files[0].thumbnail_file_url);
              $('#uploaded_image_minted').show();
              $('#nft_file_name_minted').val(filenametext);
              $('#nft_file_url_minted').val(res.data?.result?.files[0].file_url);
              $('#nft_thumb_file_url_minted').val(res.data?.result?.files[0].thumbnail_file_url);
            })
            .catch((error) => {
              if (error.response) {
                if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;

                  toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                    position: 'top-right',
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                $('#file-input3')[0].value = '';
              }
            });
        }
        //  upload_count++;
      }
    }
  };

  fileGetDetails_cmb_imagesnftgiff_mint = (event) => {
    if (event.target.files.length !== 0) {
      var file_length = event.target.files.length;
      $('.corSticky').show();
      $('.corSticky_container').show();
      $('.choosegif ').hide();
      $('.upload_div').hide();
      $('.nft_next_btn ').hide();
      $('.nft_next_btn_disabled').show();
      var progrescount = this.progressbarCount();
      if (progrescount == 0) {
        $('#attachement_progress_div').html('');
      }

      var i;
      if (upload_counts.length == 0) {
        $('#attachement_progress_div').html('');
        $('#attachement_progress_div').css({height: '65px'});
      }
      if (file_length == 1) {
        $('#attachement_progress_div').css({height: '65px'});
      } else if (file_length == 2) {
        $('#attachement_progress_div').css({height: '130px'});
      } else if (file_length >= 3) {
        $('#attachement_progress_div').css({height: '200px'});
      }
      var i;

      var flDc_id = [];
      var flDc_detils = [];
      var flDc_type = [];
      var filename = [];
      var configs = [];
      var filess = event.target.files;
      var idval;
      var FIle_Documentid_cmb = [];
      console.log('File Format', filess);
      for (i = 0; i < file_length; i++) {
        if (event.target.files[i].type !== 'image/gif') {
          toast.dark('Sorry, this file is invalid. Please try using a different file.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.corSticky').hide();
          $('.corSticky_container').hide();
          $('.choosegif').show();
          $('.upload_div').show();
          $('.nft_next_btn ').show();
          $('.nft_next_btn_disabled').hide();
          return false;
        }
        // console.log("start",upload_count);
        upload_counts.push(upload_count);
        var filetype = event.target.files[i].type;
        this.setState({nft_file_type: filetype});
        const file_detailsd = new FormData();
        filename.push(event.target.files[i].name);
        var filenametext = event.target.files[i].name;
        var finamecount = 40;
        var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        } else {
          $('#attachement_progress_div').html(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">${filenamefinal}<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        }
        file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        file_detailsd.append('secret', 'R1eqD2twI3E4');
        file_detailsd.append('user_id', this.state.user_id);
        file_detailsd.append('business_id', this.state.business_id);
        file_detailsd.append('file', event.target.files[i]);
        file_detailsd.append('reference', 'text');
        file_detailsd.append('source', 'admin');
        idval = i;
        var configuration = {
          onUploadProgress: myUploadProgress(upload_count),
        };
        if (event.target.files.length != 0) {
          this.colapse_all_attachments();
          console.log('First I value: ' + i);
          var k = 1;
          axios
            .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
            .then((res) => {
              console.log(res);
              console.log(res.data.message);

              if (res.data.status == 'success') {
                $('.nft_img_uploads').val(null);
                $('#uploaded_img_src_giff_minted').attr('src', '');
                $('.corSticky').hide();
                $('.corSticky_container').hide();
                $('.choosegif').show();
                $('.upload_div').show();
                $('.nft_next_btn ').show();
                $('.nft_next_btn_disabled').hide();
                this.colapse_all_attachments();
                console.log('File_URL :' + res.data?.result?.files[0].file_url);
                console.log('ID_num :' + res.data?.result?.files[0]._id);
                var file_name = res.data?.result?.files[0].display_name;
              }
              if (res?.data?.error?.error_type == 'invalid_file_type') {
                const errors = [];
                errors.push('Sorry, this file is invalid. Please try using a different file.');
              }
              $('#uploaded_image_giff_minted').show();
              $('#uploaded_img_src_giff_minted').attr('src', res.data?.result?.files[0].thumbnail_file_url);
              $('#nft_file_name_minted').val(filenametext);
              $('#nft_file_url_minted').val(res.data?.result?.files[0].file_url);
              $('#nft_thumb_file_url_minted').val(res.data?.result?.files[0].thumbnail_file_url);
            })
            .catch((error) => {
              if (error.response) {
                if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;

                  toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                    position: 'top-right',
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                $('#file-input3')[0].value = '';
              }
            });
        }
        //  upload_count++;
      }
    }
  };
  contractRequest = () => {
    var nft_token_name = $('#nft_token_name').val();
    var nft_token_symbol = $('#nft_token_symbol').val();
    var token_limits = $('#token_limits').val();
    var continue_vals = '1';
    var keyarr = [];
    if (nft_token_name.length == 0) {
      toast.dark('Please enter the Token Name.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (nft_token_symbol.length == 0) {
      toast.dark('Please enter the Token Symbol.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    var that = this;
    $('.key_vals')
      .each(function (index) {
        console.log($('#keyrequired' + $(this).data('id') + '').is(':checked'));
        if ($(this).val().length == 0 && $(this).data('id') !== 0) {
          continue_vals = '0';
          $(this).focus();
          toast.dark('Please enter the key value.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        } else {
          if ($(this).val().length !== 0) {
            var keyvalues = {key: $(this).val(), required: $('#keyrequired' + $(this).data('id') + '').is(':checked')};
            keyarr.push(keyvalues);
          }
        }
      })
      .promise()
      .done(function () {
        if (continue_vals == '1') {
          var keyvals = JSON.stringify(keyarr);

          console.log('arrayvalue', keyvals);
          var contractreq_params = {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: that.state.user_id,
            business_id: that.state.business_id,
            setup: 'nft',
            token_name: nft_token_name,
            token_symbol: nft_token_symbol,
            token_limit: token_limits,
            // token_key: keyvals,
          };
          if (keyarr.length !== 0) {
            contractreq_params['token_key'] = keyvals;
          }
          // console.log("contractreq_params",contractreq_params);
          // return false;
          axios
            .post(API_CALL_ENDPOINT + '/business/profile/settings', contractreq_params)
            .then((res) => {
              $('#nft_token_name').val('');
              $('#nft_token_symbol').val('');
              $('#token_limits').val('');
              console.log(res);
              that.nftDetails();
              $('.nftrequest_btn').show();
              $('.btn_confirmation').hide();
              $('.nftrequest_confirm_btn').hide();
              //console.log(res.data.message);
            })
            .catch((error) => {});
        }
      });
  };

  handleBlur() {
    $('#voiceGenerate_loding').show();
    $('#id_generateVoiceplay').hide();

    var voiceType_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      language: this.state.VoiceLngRegn,
      voice: this.state.VoiceClksId,
      voice_message: this.state.TxtVoiceGrtngvlue,
    };

    console.log(voiceType_Details);
    axios.post(API_CALL_ENDPOINT + '/voice/generate', voiceType_Details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        $('#voiceGenerate_loding').hide();
        $('#id_generateVoiceplay').show();
        console.log(res.data.result.file);
        this.setState({
          TxtTypeVoiceUrl: res.data.result.file,
          generatePlayDisble: false,
        });
      }
    });
  }
  LngRgnOnChg = (event) => {
    console.log(event.target.value);
    this.setState({
      VoiceLngRegn: event.target.value,
      TxtTypeVoiceUrl: '',
      generatePlayDisble: true,
    });

    if (this.state.getVoiceTypeMlrFml != '') {
      $(this.state.getVoiceTypeMlrFml ? '.' + this.state.getVoiceTypeMlrFml : undefined).prop('checked', false);
    }

    for (var i in this.state.GrntVoiveLngRegin) {
      if (this.state.GrntVoiveLngRegin[i]._id == event.target.value) {
        console.log(this.state.GrntVoiveLngRegin[i].voice_list);
        this.setState({VoceLstBsOnLanguge: this.state.GrntVoiveLngRegin[i].voice_list});
      }
    }
  };

  VoiceTypeClick = (event) => {
    console.log(event.target.value);
    console.log(event.target.id);
    this.setState({VoiceClksId: event.target.id});

    if (this.state.TxtVoiceGrtngvlue == '') {
      $('#' + event.target.id).prop('checked', false);
      this.setState(() => ({
        ViceGrtMsg: 'Type text is required',
        ViceGrtcls: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          ViceGrtMsg: '',
          ViceGrtcls: '',
        }));
      }, 5000);
    } else {
      $('#voiceGenerate_loding').show();
      $('#id_generateVoiceplay').hide();
      this.setState({
        getVoiceTypeMlrFml: event.target.value,
        voiceGnrteResetdisble: false,
      });
      var voiceType_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        language: this.state.VoiceLngRegn,
        voice: event.target.value,
        voice_message: this.state.TxtVoiceGrtngvlue,
      };

      console.log(voiceType_Details);
      axios.post(API_CALL_ENDPOINT + '/voice/generate', voiceType_Details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          $('#voiceGenerate_loding').hide();
          $('#id_generateVoiceplay').show();
          console.log(res.data.result.file);
          this.setState({
            TxtTypeVoiceUrl: res.data.result.file,
            generatePlayDisble: false,
          });
        }
      });
    }
  };

  generateVoice_play = () => {
    $('#id_generateVoiceplay').hide();
    $('#id_generateVoicepause').show();
    var playx = document.getElementById('generateVoiceUrl');
    playx.play();
    playx.onended = function () {
      $('#id_generateVoicepause').hide();
      $('#id_generateVoiceplay').show();
    };
  };

  generateVoice_pause = (e) => {
    var pausex = document.getElementById('generateVoiceUrl');
    pausex.pause();
    $('#id_generateVoicepause').hide();
    $('#id_generateVoiceplay').show();
  };

  upgradeWorkspace = (url) => {
    window.location.href = url;
  };

  ResetVoiceGenClk = () => {
    this.setState({
      TxtVoiceGrtngvlue: '',
      voiceGnrteResetdisble: true,
      VoiceLngRegn: 'en-US',
    });

    $(this.state.VoiceClksId ? '.' + this.state.VoiceClksId : '.Justin').prop('checked', false);

    for (var i in this.state.GrntVoiveLngRegin) {
      if (this.state.GrntVoiveLngRegin[i]._id == 'en-US') {
        console.log(this.state.GrntVoiveLngRegin[i].voice_list);
        this.setState({VoceLstBsOnLanguge: this.state.GrntVoiveLngRegin[i].voice_list});
      }
    }

    var VoiceGrtng_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      voice_greetings_status: 'off',
      setup: 'voice_greetings',
      voice_greetings_type: 'record',
    };

    console.log(VoiceGrtng_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            ViceGrtMsg: 'R-Bot Voice Greetings Reset Successfully',
            ViceGrtcls: 'alert alert-success',
          }));
          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));

            $('#TxtVoiceGrtngvlue_id').val();
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            ViceGrtMsg: res.data.error.message,
            ViceGrtcls: 'alert alert-danger',
          }));
          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              ViceGrtMsg: error.response.data.error.message,
              ViceGrtcls: 'alert alert-danger',
            }));
            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  voiceGnrteTextSave = () => {
    if (this.state.TxtVoiceGrtngvlue == '') {
      this.setState(() => ({
        ViceGrtMsg: 'R-Bot Voice greetings for Inbound Calls message cannot be blank.',
        ViceGrtcls: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          ViceGrtMsg: '',
          ViceGrtcls: '',
        }));
      }, 5000);
    } else if (this.state.TxtTypeVoiceUrl == '') {
      this.setState(() => ({
        ViceGrtMsg: 'Please Choose R-Bot Voice Type',
        ViceGrtcls: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          ViceGrtMsg: '',
          ViceGrtcls: '',
        }));
      }, 5000);
    } else {
      $('#voiceGnrteTextSv_id').html('Saving...');
      $('#voiceGnrteTextSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled');
      var VoiceGrtng_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        setup: 'voice_greetings',
        voice_greetings_status: 'on',
        voice_greetings_type: 'text',
        language_region: this.state.VoiceLngRegn,
        voice: this.state.getVoiceTypeMlrFml,
        voice_greetings_text: this.state.TxtVoiceGrtngvlue,
        greeting_url: this.state.TxtTypeVoiceUrl,
      };

      console.log(VoiceGrtng_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState(() => ({
              ViceGrtMsg: res.data.message,
              ViceGrtcls: 'alert alert-success',
              VoiceGrtngTxtTypDeltDisble: false,
            }));

            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
              $('#voiceGnrteTextSv_id').html('Save');
              $('#voiceGnrteTextSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
              $('#audioPlayVoiceGrtng').hide();
              $('#audioStrtVoiceGrtng').show();
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              ViceGrtMsg: res.data.error.message,
              ViceGrtcls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
              $('#voiceGnrteTextSv_id').html('Save');
              $('#voiceGnrteTextSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                ViceGrtMsg: error.response.data.error.message,
                ViceGrtcls: 'alert alert-danger',
              }));

              setTimeout(() => {
                this.setState(() => ({
                  ViceGrtMsg: '',
                  ViceGrtcls: '',
                }));
                $('#voiceGnrteTextSv_id').html('Save');
                $('#voiceGnrteTextSv_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
              }, 5000);
            }
          }
        });
    }
  };

  voiceGnrteTextDelete = () => {
    this.setState({
      TxtVoiceGrtngvlue: '',
      voiceGnrteResetdisble: true,
      VoiceLngRegn: 'en-US',
    });

    $(this.state.VoiceClksId ? '.' + this.state.VoiceClksId : '.Justin').prop('checked', false);

    for (var i in this.state.GrntVoiveLngRegin) {
      if (this.state.GrntVoiveLngRegin[i]._id == 'en-US') {
        console.log(this.state.GrntVoiveLngRegin[i].voice_list);
        this.setState({VoceLstBsOnLanguge: this.state.GrntVoiveLngRegin[i].voice_list});
      }
    }

    var VoiceGrtng_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      voice_greetings_status: 'off',
      setup: 'voice_greetings',
      voice_greetings_type: 'record',
    };

    console.log(VoiceGrtng_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            ViceGrtMsg: 'R-Bot Voice Greetings Delete Successfully',
            ViceGrtcls: 'alert alert-success',
            VoiceGrtngTxtTypDeltDisble: true,
          }));

          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            ViceGrtMsg: res.data.error.message,
            ViceGrtcls: 'alert alert-danger',
          }));
          setTimeout(() => {
            this.setState(() => ({
              ViceGrtMsg: '',
              ViceGrtcls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              ViceGrtMsg: error.response.data.error.message,
              ViceGrtcls: 'alert alert-danger',
            }));
            setTimeout(() => {
              this.setState(() => ({
                ViceGrtMsg: '',
                ViceGrtcls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  AtoVoicLeaveMSgonChng = (event) => {
    console.log(event.target.value);
    this.setState({LeavAutoVceMsgVle: event.target.value});
  };

  AtoVoicLeaveMSgMxmRspnsOnChang = (event) => {
    console.log(event.target.value);
    var value = event.target.value.replace(/^0+/, '');
    this.setState({LeavAutoVoiceMxmRspnsVlu: value});
  };

  AtoVcRspnsSaveClick = () => {
    var regex = /^[0-9]+$/;
    var ValidNum = regex.test(this.state.LeavAutoVoiceMxmRspnsVlu);

    if (this.state.LeavAutoVceMsgVle == '') {
      this.setState(() => ({
        AtoViceResMsg: 'R-Bot response message cannot be blank.',
        AtoViceRescls: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          AtoViceResMsg: '',
          AtoViceRescls: '',
        }));
      }, 5000);
    }
    // else if(this.state.LeavAutoVoiceMxmRspnsVlu == ""){

    // 	this.setState(() => ({
    // 		AtoViceResMsg:'Please Enter Max No.of Response',
    // 		AtoViceRescls:'alert alert-danger',
    // 	}));

    // 	setTimeout(() => {
    // 		this.setState(() => ({
    // 			AtoViceResMsg:'',
    // 			AtoViceRescls:'',
    // 		}));

    // 	}, 2500);

    // }else if(!ValidNum){

    // 	this.setState(() => ({
    // 		AtoViceResMsg:'Max No. of Response allow only number',
    // 		AtoViceRescls:'alert alert-danger',
    // 	}));

    // 	setTimeout(() => {
    // 		this.setState(() => ({
    // 			AtoViceResMsg:'',
    // 			AtoViceRescls:'',
    // 		}));

    // 	}, 2500);

    // }
    else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      $('#AtoVcRspnsSave_id').html('Saving...');
      $('#AtoVcRspnsSave_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled');

      var VoiceGrtng_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        auto_voice: 'on',
        setup: 'auto_voice',
        auto_voice_type: 'leave_message',
        text_response: this.state.LeavAutoVceMsgVle,
        unique_id: this.state.atcharryDet_cmb.length != 0 ? Rnunique_id_cmb : '',
        maximum_response: this.state.LeavAutoVoiceMxmRspnsVlu,
      };

      console.log(VoiceGrtng_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState(() => ({
              LeavAutoVceMsgVle_unique_id: Rnunique_id_cmb,
              AtoViceResMsg: res.data.message,
              AtoViceRescls: 'alert alert-success',
              leaveMsgDelDisble: false,
              HangMsgValue: '',
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoViceResMsg: '',
                AtoViceRescls: '',
              }));
              $('#AtoVcRspnsSave_id').html('Save');
              $('#AtoVcRspnsSave_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              AtoViceResMsg: res.data.error.message,
              AtoViceRescls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoViceResMsg: '',
                AtoViceRescls: '',
              }));
              $('#AtoVcRspnsSave_id').html('Save');
              $('#AtoVcRspnsSave_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                AtoViceResMsg: error.response.data.error.message,
                AtoViceRescls: 'alert alert-danger',
              }));

              setTimeout(() => {
                this.setState(() => ({
                  AtoViceResMsg: '',
                  AtoViceRescls: '',
                }));
                $('#AtoVcRspnsSave_id').html('Save');
                $('#AtoVcRspnsSave_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
              }, 5000);
            }
          }
        });
    }
  };

  atoVcRspnLevMsgDelClk = () => {
    var VoiceGrtng_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      auto_voice: 'off',
      setup: 'auto_voice',
      auto_voice_type: 'leave_message',
    };

    console.log(VoiceGrtng_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState({atcharryDet_cmb: []});
          // if (this.state.atcharryDet_cmb.length == 0) {
          $('.attchLstShow_cmb').hide();
          // }
          this.setState(() => ({
            AtoViceResMsg: res.data.message,
            AtoViceRescls: 'alert alert-success',
            leaveMsgDelDisble: true,
            LeavAutoVceMsgVle: '',
            LeavAutoVceMsgVle_unique_id: '',
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
              LeavAutoVceMsgVle: '',
              LeavAutoVceMsgVle_unique_id: '',
              LeavAutoVoiceMxmRspnsVlu: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            AtoViceResMsg: res.data.error.message,
            AtoViceRescls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              AtoViceResMsg: error.response.data.error.message,
              AtoViceRescls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoViceResMsg: '',
                AtoViceRescls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  hangmsgOnChang = (event) => {
    console.log(event.target.value);
    var value = event.target.value.replace(/^0+/, '');
    this.setState({HangMsgValue: value});
  };

  SaveHngMsgwithout = () => {
    var regex = /^[0-9]+$/;
    var ValidNum = regex.test(this.state.HangMsgValue);

    // if(this.state.HangMsgValue == ""){

    // 	this.setState(() => ({
    // 		AtoViceResMsg:'Please enter the leave message',
    // 		AtoViceRescls:'alert alert-danger',
    // 	}));

    // 	setTimeout(() => {
    // 		this.setState(() => ({
    // 			AtoViceResMsg:'',
    // 			AtoViceRescls:'',
    // 		}));

    // 	}, 2500);

    // }
    // else if(!ValidNum){

    // 	this.setState(() => ({
    // 		AtoViceResMsg:'Max No. of Response allow only number',
    // 		AtoViceRescls:'alert alert-danger',
    // 	}));

    // 	setTimeout(() => {
    // 		this.setState(() => ({
    // 			AtoViceResMsg:'',
    // 			AtoViceRescls:'',
    // 		}));

    // 	}, 2500);

    // }else{

    $('#SaveHngMsgwithout_id').html('Saving...');
    $('#SaveHngMsgwithout_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled');

    var VoiceGrtng_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      auto_voice: 'on',
      setup: 'auto_voice',
      auto_voice_type: 'hangup_message',
      maximum_response: this.state.HangMsgValue,
    };

    console.log(VoiceGrtng_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            AtoViceResMsg: res.data.message,
            AtoViceRescls: 'alert alert-success',
            hangupMsgDelDisble: false,
            LeavAutoVceMsgVle: '',
            LeavAutoVceMsgVle_unique_id: '',
            LeavAutoVoiceMxmRspnsVlu: '',
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
            }));
            $('#SaveHngMsgwithout_id').html('Save');
            $('#SaveHngMsgwithout_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            AtoViceResMsg: res.data.error.message,
            AtoViceRescls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
            }));
            $('#SaveHngMsgwithout_id').html('Save');
            $('#SaveHngMsgwithout_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              AtoViceResMsg: error.response.data.error.message,
              AtoViceRescls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoViceResMsg: '',
                AtoViceRescls: '',
              }));
              $('#SaveHngMsgwithout_id').html('Save');
              $('#SaveHngMsgwithout_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }
        }
      });

    // }
  };

  DelHngMsgwithout = () => {
    var VoiceGrtng_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      auto_voice: 'off',
      setup: 'auto_voice',
      auto_voice_type: 'hangup_message',
    };

    console.log(VoiceGrtng_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', VoiceGrtng_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState(() => ({
            AtoViceResMsg: res.data.message,
            AtoViceRescls: 'alert alert-success',
            HangMsgValue: '',
            hangupMsgDelDisble: true,
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            AtoViceResMsg: res.data.error.message,
            AtoViceRescls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              AtoViceResMsg: error.response.data.error.message,
              AtoViceRescls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoViceResMsg: '',
                AtoViceRescls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  birthdyTxtOnChg = (event) => {
    console.log(event.target.value);
    this.setState({birthdyTxtValue: event.target.value});
  };

  bithGrtngTimeOnchng = (event) => {
    this.setState({
      bithGrtngTime: event.target.value,
    });
  };
  ist2utc = (t) => {
    let d = new Date(t);
    console.log('d', d.getHours(), d.getMinutes());
    let timezone = this.state.bithGrtngTimeZone.substring(3);
    let addsubtoutc = timezone.substring(0, 1);
    let hrstoutc = timezone.substring(1, 3);
    let mintoutc = timezone.substring(3, 5);
    console.log('addsubtoutc', addsubtoutc);
    console.log('hrstoutc', hrstoutc);
    console.log('mintoutc', mintoutc);
    var x = addsubtoutc == '+' ? d.getHours() - parseInt(hrstoutc) : d.getHours() + parseInt(hrstoutc);
    console.log('x', x);
    var y = addsubtoutc == '+' ? d.getMinutes() - parseInt(mintoutc) : d.getMinutes() + parseInt(mintoutc);
    console.log('y', y);
    d.setHours(x);
    d.setMinutes(y);
    return d;
  };

  bithGrtngTimeZoneOnchng = (event) => {
    console.log(event.target.value);
    this.setState({bithGrtngTimeZone: event.target.value});
  };

  saveBrthgrtng = () => {
    if (this.state.birthdyTxtValue == '') {
      this.setState(() => ({
        brtGrtMsg: 'R-Bot Birthday greetings text message cannot be blank.',
        brtGrtcls: 'alert alert-danger',
      }));

      setTimeout(() => {
        this.setState(() => ({
          brtGrtMsg: '',
          brtGrtcls: '',
        }));
      }, 5000);
    } else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      $('#saveBrthgrtng_id').html('Saving...');
      $('#saveBrthgrtng_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled');
      var utcStart = new Date('1/15/2007 ' + this.state.bithGrtngTime);
      console.log('istStart', utcStart);
      let x = this.ist2utc(utcStart);

      var brthday_Details = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        birthday_status: 'on',
        setup: 'birthday',
        birthday_time_utc: moment(x).format('h:mm A'),
        birthday_time_local: this.state.bithGrtngTime,
        birthday_offset: this.state.bithGrtngTimeZone,
        birthday_text: this.state.birthdyTxtValue,
        unique_id: this.state.atcharryDet_cmb.length != 0 ? Rnunique_id_cmb : '',
      };
      //       console.log(brthday_Details);
      // return;
      console.log(brthday_Details);
      axios
        .post(API_CALL_ENDPOINT + '/business/profile/settings', brthday_Details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState(() => ({
              birthdyTxtValue_unique_id: Rnunique_id_cmb,
              brtGrtMsg: res.data.message,
              brtGrtcls: 'alert alert-success',
              brthdyGrtngDelDisble: false,
            }));

            setTimeout(() => {
              this.setState(() => ({
                brtGrtMsg: '',
                brtGrtcls: '',
              }));
              $('#saveBrthgrtng_id').html('Save');
              $('#saveBrthgrtng_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }

          if (res.data.error) {
            this.setState(() => ({
              brtGrtMsg: res.data.error.message,
              brtGrtcls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                brtGrtMsg: '',
                brtGrtcls: '',
              }));

              $('#saveBrthgrtng_id').html('Save');
              $('#saveBrthgrtng_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                brtGrtMsg: error.response.data.error.message,
                brtGrtcls: 'alert alert-danger',
              }));

              setTimeout(() => {
                this.setState(() => ({
                  brtGrtMsg: '',
                  brtGrtcls: '',
                }));

                $('#saveBrthgrtng_id').html('Save');
                $('#saveBrthgrtng_id').attr('class', 'btn btn-black crsr_pntr btnpdng_sm mr-2');
              }, 5000);
            }
          }
        });
    }
  };

  DeltBrthgrtng = () => {
    var brthday_Details = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      birthday_status: 'off',
      setup: 'birthday',
    };

    console.log(brthday_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', brthday_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState({atcharryDet_cmb: []});
          // if (this.state.atcharryDet_cmb.length == 0) {
          $('.attchLstShow_cmb').hide();
          // }
          this.setState(() => ({
            brtGrtMsg: 'R-Bot Birthday greetings successfully deleted',
            brtGrtcls: 'alert alert-success',
            birthdyTxtValue: '',
            bithGrtngTime: '12:00 AM',
            bithGrtngTimeZone: 'GMT-1200',
            brthdyGrtngDelDisble: true,
          }));

          setTimeout(() => {
            this.setState(() => ({
              brtGrtMsg: '',
              brtGrtcls: '',
            }));
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            brtGrtMsg: res.data.error.message,
            brtGrtcls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              brtGrtMsg: '',
              brtGrtcls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              brtGrtMsg: error.response.data.error.message,
              brtGrtcls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                brtGrtMsg: '',
                brtGrtcls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  business_update = () => {
    var business_update_Details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'info',
      business_name: this.state.business_info_name,
      business_description: this.state.business_info_details,
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', business_update_Details)
      .then((res) => {
        if (res.data.status == 'success') {
          this.setState(() => ({
            AtoViceResMsg: res.data.message,
            AtoViceRescls: 'alert alert-success',
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
            }));
            window.location.reload(true);
          }, 5000);
        }

        if (res.data.error) {
          this.setState(() => ({
            AtoViceResMsg: res.data.error.message,
            AtoViceRescls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              AtoViceResMsg: '',
              AtoViceRescls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              AtoViceResMsg: error.response.data.error.message,
              AtoViceRescls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                AtoViceResMsg: '',
                AtoViceRescls: '',
              }));
            }, 5000);
          }
        }
      });
  };

  getBusinessDetails = (e) => {
    const business_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    console.log(business_details);
    axios
      .get(API_CALL_ENDPOINT + '/business/info', business_details)
      .then((res) => {
        console.log('/business/info8', res.data);
        if (e == 'paraphrase') {
          setTimeout(() => {
            $('html, body').animate(
              {
                scrollTop: $('#para_phrase_div').offset().top,
              },
              2000,
            );
          }, 150);
        }
        if (res.data.result) {
          this.setState({business_info_array: res.data?.result});
        }
      })
      .catch((errr) => {
        console.log('ERROR', errr);
      });
  };

  onFileChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({src: reader.result}));
      reader.readAsDataURL(e.target.files[0]);
    }

    $('.popup_new').attr('style', 'display');

    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0],
    });
    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', e.target.files[0]);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log('file : ', e.target.files[0]);
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({crop});
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({croppedImageUrl});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, 'cropped.jpg');
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type: mime});
    this.setState({croppedImage: croppedImage});
  }

  togglePopup = () => {
    $('.popup_new').attr('style', 'display');
  };

  save_imageCrop = () => {
    $('.popup_new').attr('style', 'display:none');

    console.log(this.state.croppedImage);

    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', this.state.croppedImage);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log(this.state.business_id);
    axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
      console.log(res);

      var file_url = '';

      if (res.data.status == 'success') {
        for (var i in res.data.result.files) {
          console.log(res.data.result.files[i].file_url);
          file_url += res.data.result.files[i].file_url;
        }

        console.log(file_url);
        this.setState({
          prfImag: file_url,
        });

        const business_upDetails = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          setup: 'profile',
          profile_image: file_url,
        };

        axios.post(API_CALL_ENDPOINT + '/business/update', business_upDetails).then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status === 'success') {
            window.location.reload(true);
          }
        });
      }
    });
  };

  close_imageCrop = () => {
    $('.popup_new').hide();
  };
  update_qrcode_details = () => {
    let qr_filtered_tag_list_new = this.state.qr_filtered_tag_list;
    if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (this.state.qr_addtag_save === true) {
      console.log('newtagname', this.state.newtagname);
      const createtag = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tag_name: this.state.qr_newtag,
        tag_source: 'contacts',
      };
      console.log('createtag', createtag);
      axios
        .post(API_CALL_ENDPOINT + '/tags/create', createtag)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            if (qr_filtered_tag_list_new.indexOf(res.data?.result?._id) === -1) {
              qr_filtered_tag_list_new.push(res.data?.result?._id);
              // console.log(qr_filtered_tag_list_new);
            }
            // qr_newtag_id = ',' + res.data?.result?._id;
          } else if (res.data.status === 'conflict') {
            if (qr_filtered_tag_list_new.indexOf(res.data?.result?.details._id) === -1) {
              qr_filtered_tag_list_new.push(res.data?.result?.details._id);
              // console.log(qr_filtered_tag_list_new);
            }
            // qr_newtag_id = ',' + res.data?.result?.details._id;
          }
          const qrcode_upDetails = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            qr_title: this.state.qr_title,
            auto_response: this.state.qr_message,
            description: this.state.qr_description,
            unique_id:
              this.state.atcharryDet_cmb.length != 0
                ? this.state.message_edit === false
                  ? Rnunique_id_cmb
                  : this.state.Rnunique_id_cmb
                  ? this.state.Rnunique_id_cmb
                  : Rnunique_id_cmb
                : '',
            attachment_url: this.state.atcharryDet_cmb.length != 0 ? true : false,
            tags: qr_filtered_tag_list_new.toString(),
            qr_code_id: this.state.qr_code_id_edit,
            qr_code_image: this.state.qr_code_image_edit,
          };
          console.log('inside alert', this.state.message_edit);
          axios
            .post(API_CALL_ENDPOINT + '/business/qrcodes/update', qrcode_upDetails)
            .then((res) => {
              if (res.data.status == 'success') {
                console.log('outside', res.data);
                window.scrollTo(0, 0);
                this.setState(() => ({
                  QRGrtMsg: res.data.message,
                  QRGrtcls: 'alert alert-success',
                }));

                setTimeout(() => {
                  this.setState(() => ({
                    QRGrtMsg: '',
                    QRGrtcls: '',
                    qr_title: '',
                    qr_description: '',
                    qr_message: '',
                    qr_filtered_tag_list: [],
                    qr_filtered_tag_list_up: [],
                    qr_newtag: '',
                    qr_addtag_save: false,
                    atcharryDet_cmb: [],
                    message_edit: false,
                    qr_code_image_edit: '',
                    qr_code_id_edit: '',
                  }));
                  Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
                  this.qrcode_listclick();
                  $('.qrcodelist_div').show();
                  $('.addqrcode_div').hide();
                  $('.qr_codedisplay').hide();
                  $('.generate_qr_btn').show();
                }, 5000);
              } else if (res.data.error) {
                this.setState(() => ({
                  QRGrtMsg: res.data.error.message,
                  QRGrtcls: 'alert alert-danger',
                }));
                window.scrollTo(0, 0);
                setTimeout(() => {
                  this.setState(() => ({
                    QRGrtMsg: '',
                    QRGrtcls: '',
                  }));
                }, 5000);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.error) {
                  this.setState(() => ({
                    QRGrtMsg: error.response.data.error.message,
                    QRGrtcls: 'alert alert-danger',
                  }));
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    this.setState(() => ({
                      QRGrtMsg: '',
                      QRGrtcls: '',
                    }));
                  }, 5000);
                }
              }
            });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              if (qr_filtered_tag_list_new.indexOf(error.response.data?.result?.details._id) === -1) {
                qr_filtered_tag_list_new.push(error.response.data?.result?.details._id);
                // console.log(qr_filtered_tag_list_new);
              }
              // qr_newtag_id = ',' + res.data?.result?.details._id;
            }
          }
        });
    } else {
      const qrcode_upDetails = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        qr_title: this.state.qr_title,
        auto_response: this.state.qr_message,
        description: this.state.qr_description,
        unique_id:
          this.state.atcharryDet_cmb.length != 0
            ? this.state.message_edit === false
              ? Rnunique_id_cmb
              : this.state.Rnunique_id_cmb
              ? this.state.Rnunique_id_cmb
              : Rnunique_id_cmb
            : '',
        attachment_url: this.state.atcharryDet_cmb.length != 0 ? true : false,
        tags: qr_filtered_tag_list_new.toString(),
        qr_code_id: this.state.qr_code_id_edit,
        qr_code_image: this.state.qr_code_image_edit,
      };
      console.log('inside alert', this.state.message_edit);
      axios
        .post(API_CALL_ENDPOINT + '/business/qrcodes/update', qrcode_upDetails)
        .then((res) => {
          if (res.data.status == 'success') {
            console.log('outside', res.data);
            window.scrollTo(0, 0);
            this.setState(() => ({
              QRGrtMsg: res.data.message,
              QRGrtcls: 'alert alert-success',
            }));

            setTimeout(() => {
              this.setState(() => ({
                QRGrtMsg: '',
                QRGrtcls: '',
                qr_title: '',
                qr_description: '',
                qr_message: '',
                qr_filtered_tag_list: [],
                qr_filtered_tag_list_up: [],
                qr_newtag: '',
                qr_addtag_save: false,
                atcharryDet_cmb: [],
                message_edit: false,
                qr_code_image_edit: '',
                qr_code_id_edit: '',
              }));
              Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
              this.qrcode_listclick();
              $('.qrcodelist_div').show();
              $('.addqrcode_div').hide();
              $('.qr_codedisplay').hide();
              $('.generate_qr_btn').show();
            }, 5000);
          } else if (res.data.error) {
            this.setState(() => ({
              QRGrtMsg: res.data.error.message,
              QRGrtcls: 'alert alert-danger',
            }));
            window.scrollTo(0, 0);
            setTimeout(() => {
              this.setState(() => ({
                QRGrtMsg: '',
                QRGrtcls: '',
              }));
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                QRGrtMsg: error.response.data.error.message,
                QRGrtcls: 'alert alert-danger',
              }));
              window.scrollTo(0, 0);
              setTimeout(() => {
                this.setState(() => ({
                  QRGrtMsg: '',
                  QRGrtcls: '',
                }));
              }, 5000);
            }
          }
        });
    }
  };
  save_qrcode_details = () => {
    const canvas = document.getElementById('123456');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const blob = this.dataURLtoBlob(pngUrl);
    var fileOfBlob = new File([blob], 'qr_' + Rnunique_id_cmb + '.png', {type: 'image/png'});
    const data = new FormData();
    let qr_filtered_tag_list_new = this.state.qr_filtered_tag_list;
    data.append('apikey', API_KEY);
    data.append('secret', API_SECRET);
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', fileOfBlob);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log(this.state.business_id);
    axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
      if (res.data.status == 'success') {
        var file_url = '';
        for (var i in res.data.result.files) {
          console.log(res.data.result.files[i].file_url);
          file_url += res.data.result.files[i].file_url;
        }
        if (this.state.qr_addtag_save === true) {
          // console.log("newtagname",this.state.newtagname)
          const createtag = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            tag_name: this.state.qr_newtag,
            tag_source: 'contacts',
          };
          console.log('createtag', createtag);
          axios
            .post(API_CALL_ENDPOINT + '/tags/create', createtag)
            .then((res) => {
              console.log(res.data);
              if (res.data.status === 'success') {
                if (qr_filtered_tag_list_new.indexOf(res.data?.result?._id) === -1) {
                  qr_filtered_tag_list_new.push(res.data?.result?._id);
                  // console.log(qr_filtered_tag_list_new);
                }
                // qr_newtag_id = ',' + res.data?.result?._id;
              } else if (res.data.status === 'conflict') {
                if (qr_filtered_tag_list_new.indexOf(res.data?.result?.details._id) === -1) {
                  qr_filtered_tag_list_new.push(res.data?.result?.details._id);
                  // console.log(qr_filtered_tag_list_new);
                }
                // qr_newtag_id = ',' + res.data?.result?.details._id;
              }
              const qrcode_upDetails = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                qr_title: this.state.qr_title,
                auto_response: this.state.qr_message,
                description: this.state.qr_description,
                unique_id: this.state.atcharryDet_cmb.length != 0 ? Rnunique_id_cmb : '',
                attachment_url: this.state.atcharryDet_cmb.length != 0 ? true : false,
                tags: qr_filtered_tag_list_new.toString(),
                qr_code: Rnunique_id_cmb,
                qr_code_image: file_url,
              };
              console.log('inside', qrcode_upDetails);
              axios
                .post(API_CALL_ENDPOINT + '/business/qrcodes/create', qrcode_upDetails)
                .then((res) => {
                  if (res.data.status == 'success') {
                    console.log('outside', res.data);
                    window.scrollTo(0, 0);
                    this.setState(() => ({
                      QRGrtMsg: res.data.message,
                      QRGrtcls: 'alert alert-success',
                    }));

                    setTimeout(() => {
                      this.setState(() => ({
                        QRGrtMsg: '',
                        QRGrtcls: '',
                        qr_title: '',
                        qr_description: '',
                        qr_message: '',
                        qr_filtered_tag_list: [],
                        qr_filtered_tag_list_up: [],
                        qr_newtag: '',
                        qr_addtag_save: false,
                        atcharryDet_cmb: [],
                      }));
                      Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
                      this.qrcode_listclick();
                      $('.qrcodelist_div').show();
                      $('.addqrcode_div').hide();
                      $('.qr_codedisplay').hide();
                      $('.generate_qr_btn').show();
                    }, 5000);
                  } else if (res.data.error) {
                    this.setState(() => ({
                      QRGrtMsg: res.data.error.message,
                      QRGrtcls: 'alert alert-danger',
                    }));
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                      this.setState(() => ({
                        QRGrtMsg: '',
                        QRGrtcls: '',
                      }));
                    }, 5000);
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      this.setState(() => ({
                        QRGrtMsg: error.response.data.error.message,
                        QRGrtcls: 'alert alert-danger',
                      }));
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        this.setState(() => ({
                          QRGrtMsg: '',
                          QRGrtcls: '',
                        }));
                      }, 5000);
                    }
                  }
                });
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.status === 'conflict') {
                  if (qr_filtered_tag_list_new.indexOf(error.response.data?.result?.details._id) === -1) {
                    qr_filtered_tag_list_new.push(error.response.data?.result?.details._id);
                    // console.log(qr_filtered_tag_list_new);
                  }
                  // qr_newtag_id = ',' + res.data?.result?.details._id;
                }
              }
            });
        } else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
          toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const qrcode_upDetails = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            qr_title: this.state.qr_title,
            auto_response: this.state.qr_message,
            description: this.state.qr_description,
            unique_id: this.state.atcharryDet_cmb.length != 0 ? Rnunique_id_cmb : '',
            attachment_url: this.state.atcharryDet_cmb.length != 0 ? true : false,
            tags: qr_filtered_tag_list_new.toString(),
            qr_code: Rnunique_id_cmb,
            qr_code_image: file_url,
          };
          console.log('outside', qrcode_upDetails);
          axios
            .post(API_CALL_ENDPOINT + '/business/qrcodes/create', qrcode_upDetails)
            .then((res) => {
              if (res.data.status == 'success') {
                console.log('outside', res.data);

                this.setState(() => ({
                  QRGrtMsg: res.data.message,
                  QRGrtcls: 'alert alert-success',
                }));
                window.scrollTo(0, 0);
                setTimeout(() => {
                  this.setState(() => ({
                    QRGrtMsg: '',
                    QRGrtcls: '',
                    qr_title: '',
                    qr_description: '',
                    qr_message: '',
                    qr_filtered_tag_list: [],
                    qr_filtered_tag_list_up: [],
                    qr_newtag: '',
                    qr_addtag_save: false,
                    atcharryDet_cmb: [],
                  }));
                  Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
                  this.qrcode_listclick();
                  $('.qrcodelist_div').show();
                  $('.addqrcode_div').hide();
                  $('.qr_codedisplay').hide();
                  $('.generate_qr_btn').show();
                }, 5000);
              } else if (res.data.error) {
                this.setState(() => ({
                  QRGrtMsg: res.data.error.message,
                  QRGrtcls: 'alert alert-danger',
                }));
                window.scrollTo(0, 0);
                setTimeout(() => {
                  this.setState(() => ({
                    QRGrtMsg: '',
                    QRGrtcls: '',
                  }));
                }, 5000);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.error) {
                  this.setState(() => ({
                    QRGrtMsg: error.response.data.error.message,
                    QRGrtcls: 'alert alert-danger',
                  }));
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    this.setState(() => ({
                      QRGrtMsg: '',
                      QRGrtcls: '',
                    }));
                  }, 5000);
                }
              }
            });
        }
      }
    });
  };

  onChangeprefix(e) {
    console.log('1212121212');
    this.setState({errors: []});
    const res = e.target.value.substring(1, 0);
    if (res == '1' || res == '0') {
      this.setState({prefix: e.target.value.substring(1)});
      const numberlist = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          pattern: e.target.value.substring(1),
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
          console.log(res.data.result.phone_numbers.length);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (error.response.data.status === 'conflict') {
              toast.dark(error.response.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    } else {
      this.setState({prefix: e.target.value});
      const numberlist = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          pattern: e.target.value,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          // console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          // console.log(this.state.plivolist)
          // console.log(this.state.plivolist.length)
          if (this.state.plivolist.length === 0) {
            const errors = [];
            errors.push("The area code provided doesn't have any phone numbers, please enter a different area code");
            this.setState({errors});
            // console.log(this.state.errors);
            setTimeout(() => {
              this.setState({errors: []});
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (error.response.data.status === 'conflict') {
              toast.dark(error.response.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }

    this.setState({prefix: e.target.value});
  }
  // show_trial_payment(){
  // 	$(".modal_upgrade").show();
  // }
  qrcodeonMessageEdit(qr_code_individual) {
    console.log('qr_code_individual', qr_code_individual);
    let result = qr_code_individual.tags.map((a) => a._id);
    // $('.qrcode_taglist option').removeAttr('selected');
    for (var i = 0; i < result.length; i++) {
      console.log('result[i];', result[i]);
      $('.qrcode_taglist option').filter(`[value=${result[i]}]`).attr('selected', 'selected');
      // $('div.id_100  option[value="val2"]').prop('selected', true);
    }
    this.setState({
      qr_title: qr_code_individual.qr_title,
      qr_description: qr_code_individual.description,
      qr_message: qr_code_individual.auto_response,
      qr_filtered_tag_list: result,
      qr_addtag_save: false,
      message_edit: true,
      Rnunique_id_cmb: qr_code_individual.qr_code,
      qr_code_image_edit: qr_code_individual.qr_code_image,
      qr_code_id_edit: qr_code_individual._id,
    });
    if (qr_code_individual.attachment_url === 'true') {
      const attachmentinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          unique_id: qr_code_individual.unique_id,
          // contact_id: conversation.conversation.contacts.toString()
        },
      };
      console.log(attachmentinfo);
      axios
        .get(API_CALL_ENDPOINT + '/attachment/edit/list', attachmentinfo)
        .then((res) => {
          console.log('EDIT', res.data);
          if (res.data.status === 'success') {
            this.setState({messageAttachd: true});
            var arrayLength = res.data?.result?.attachment_list.length;

            var OrderIncr1 = this.state.attachOrderIncrs_cmb + parseInt(arrayLength);
            this.setState({attachOrderIncrs_cmb: OrderIncr1});

            for (var i = 0; i < arrayLength; i++) {
              if (res.data?.result?.attachment_list[i].attachment_type == 'notes') {
                $('.attchLstShow_cmb').show();
                $('#notestypearea').val('');
                this.setState({
                  atcharryDet_cmb: [
                    {
                      attach_id: res.data?.result?.attachment_list[i]._id,
                      notes_content: res.data?.result?.attachment_list[i].text,
                      notes_content1: 'Your text goes here',
                    },
                    ...this.state.atcharryDet_cmb,
                  ],
                });
                this.colapse_all_attachments();
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'audio') {
                $('.attchLstShow_cmb').show();
                this.setState({
                  atcharryDet_cmb: [
                    {
                      _id: res.data?.result?.attachment_list[i].attachment[0]._id,
                      file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                      second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                      audio_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                      audio_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    },
                    ...this.state.atcharryDet_cmb,
                  ],
                });
                this.colapse_all_attachments();
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'video') {
                $('.attchLstShow_cmb').show();
                console.log('video_url: ' + res.data?.result?.attachment_list[i].attachment[0].file_url);
                this.setState({
                  atcharryDet_cmb: [
                    {
                      video_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                      video_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                      second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                      file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                    },
                    ...this.state.atcharryDet_cmb,
                  ],
                });
                this.colapse_all_attachments();
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'documents') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;
                this.colapse_all_attachments();
                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                  });
                  this.colapse_all_attachments();
                  if (k == arrayLength1) {
                    var fileary = {
                      documents: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                    });
                  }
                  k++;
                  this.colapse_all_attachments();
                }
                this.colapse_all_attachments();
                $('.attchLstShow_cmb').show();
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'images') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;
                this.colapse_all_attachments();
                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                  });
                  this.colapse_all_attachments();
                  if (k == arrayLength1) {
                    var fileary = {
                      images: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                    });
                  }
                  k++;
                  this.colapse_all_attachments();
                }
                this.colapse_all_attachments();
                $('.attchLstShow_cmb').show();
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              }
            }
            this.colapse_all_attachments();
          }
          this.colapse_all_attachments();
          // console.log(this.state.attachment_list);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    $('.qr_codedisplay').show();
    $('.generate_qr_btn').hide();
    $('.qrcodecreate_div').hide();
    $('.addqrcode_div').show();
    $('.qrcodelist_div').hide();
  }
  downloadQR = () => {
    const canvas = document.getElementById('123456');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = Rnunique_id_cmb + '.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  generate_qr = () => {
    $('.qr_codedisplay').show();
    $('.generate_qr_btn').hide();
  };
  qrcode_delete = (qrcode_det) => {
    const qr_delete = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      qrcode_id: qrcode_det._id,
    };
    axios
      .post(API_CALL_ENDPOINT + '/business/qrcodes/delete', qr_delete)
      .then((res) => {
        if (res.data) {
          if (res.data.status == 'success') {
            this.setState(() => ({
              QRGrtMsg: res.data.message,
              QRGrtcls: 'alert alert-success text-center',
            }));
            this.qrcode_listclick();
            setTimeout(() => {
              this.setState(() => ({
                QRGrtMsg: '',
                QRGrtcls: '',
              }));
            }, 5000);
          } else if (res.data.error) {
            this.setState(() => ({
              QRGrtMsg: res.data.error.message,
              QRGrtcls: 'alert alert-danger text-center',
            }));
            setTimeout(() => {
              this.setState(() => ({
                QRGrtMsg: '',
                QRGrtcls: '',
              }));
            }, 5000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              QRGrtMsg: error.response.data.error.message,
              QRGrtcls: 'alert alert-danger text-center',
            }));
            setTimeout(() => {
              this.setState(() => ({
                QRGrtMsg: '',
                QRGrtcls: '',
              }));
            }, 5000);
          }
        }
      });
  };
  tags_list_click = () => {
    const tagLst_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    console.log(tagLst_details);
    axios.get(API_CALL_ENDPOINT + '/tags/list', tagLst_details).then((res) => {
      console.log(res.data);
      if (res.data.result) {
        this.setState({tagList: res.data.result, tag_list: res.data.result});
      }
    });
  };
  qrcode_listclick = () => {
    const qrcode_list_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/qrcodes/list', qrcode_list_details)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({qr_code_list: res.data.result});
          if (res?.data?.result?.length != 0) {
            $('.qrcodelist_div').show();
            $('.addqrcode_div').hide();
            $('.qrcodecreate_div').hide();
          }
        } else {
          this.setState({qr_code_list: []});
          $('.qrcodelist_div').hide();
          $('.addqrcode_div').hide();
          $('.qrcodecreate_div').show();
        }
      })
      .catch((error) => {
        console.log('error found');
        this.setState({qr_code_list: []});
      });
    this.tags_list_click();
  };

  sendmsgattach_clkNotes2_cmb = () => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      this.setState(() => ({
        QRGrtMsg: `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        QRGrtcls: 'alert alert-danger',
      }));
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.setState(() => ({
          QRGrtMsg: '',
          QRGrtcls: '',
        }));
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkNotes2_cmb').show();
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    }
  };

  notesLetter2_cmb = (value) => {
    if (value.length <= 1200) {
      this.setState({
        notesValue2_cmb: value,
        chars_left_notes_cmb: this.state.max_chars_notes_cmb - value.length,
      });
    }
  };

  saveNotes2_cmb = () => {
    clearTimeout(this.timer);
    clearTimeout(this.savetimer);
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      toast.dark(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      clearTimeout(this.savetimer);
      clearTimeout(this.timer);
      // this.setState(() => ({
      //   QRGrtMsg: `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      //   QRGrtcls: 'alert alert-danger',
      // }));
      // window.scrollTo(0, 0);
      // setTimeout(() => {
      //   this.setState(() => ({
      //     QRGrtMsg: '',
      //     QRGrtcls: '',
      //   }));
      // }, 5000);
    } else {
      console.log(this.state.notesValue2_cmb);
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsg_attachmentdiv2_cmb').hide();
      $('.attachLoading_cmb').show();
      $('.attchLstShow_cmb').show();
      this.setState({lodingicon_cmb: 'far fa-sticky-note bg_notes'});

      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        unique_id:
          this.state.message_edit === false
            ? Rnunique_id_cmb
            : this.state.Rnunique_id_cmb
            ? this.state.Rnunique_id_cmb
            : Rnunique_id_cmb,
        text: '<p></p>',
        order: '' + this.state.attachOrderIncrs_cmb + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/create', notes_details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            // clearTimeout(this.savetimer);
            this.setState({messageAttachd_cmb: true});
            this.setState({
              cmb_notesedit: true,
              active_edit_id: res.data?.result?._id,
              notesValue2_cmb: '<p></p>',
              atcharryDet_cmb: [
                {
                  attach_id: res.data?.result?._id,
                  notes_content: '<p></p>',
                  notes_content1: 'Your text goes here',
                },
                ...this.state.atcharryDet_cmb,
              ],
            });
            var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
            this.setState({attachOrderIncrs_cmb: OrderIncr});
            clearTimeout(this.timer);
            clearTimeout(this.savetimer);
            setTimeout(() => {
              // this.setState({notesValue2: '', chars_left_notes_compose: 0});
              this.setState({savingprogress: '✔ Saved'});
              this.setState({savingprogress: ''});
            }, 1000);
            setTimeout(() => {
              // this.setState({notesValue2_cmb: '', chars_left_notes_cmb: 0});
              $('.editor_cmb_' + res.data?.result?._id).show();
              $('.notecontent_cmb_' + res.data?.result?._id).hide();
              console.log(this.state.atcharryDet_cmb);
            }, 150);

            $('#notestypearea_cmb').val('');
            $('.sendmsgattach_clkNotes2_cmb').hide();
            $('.sendmsg_attachmentdiv2_cmb').hide();
            $('.attachLoading_cmb').hide();
            // $('.attachment_colapsed').show();
            //     $('.attachment_expanded').hide();
            $('.attachment_colapsed').show();
            $('.attachment_colapsed_' + res.data?.result?._id).hide();
            $('.attachment_expanded').hide();
            $('.attachment_expanded_' + res.data?.result?._id).show();
            $('.attchLstShow_cmb').show();
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2_cmb').show();
              $('.sendmsg_attachmentdiv2_cmb').hide();
              $('.attachment_colapsed').show();
              $('.attachment_expanded').hide();
              $('.attachLoading_cmb').hide();
              $('.attchLstShow_cmb').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              if (error.response.data.error.message === 'Text required') {
                $('.sendmsgattach_clkNotes2_cmb').show();
                $('.sendmsg_attachmentdiv2_cmb').hide();
                $('.attachment_colapsed').show();
                $('.attachment_expanded').hide();
                $('.attachLoading_cmb').hide();
                $('.attchLstShow_cmb').show();
                const errors = [];
                errors.push("Notes can't be empty.");
                this.setState({errors});

                setTimeout(() => {
                  this.setState({errors: []});
                }, 3000);
              }
            }
          }
        });
    }
  };

  autoupdateNotes2_cmb = () => {
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => {
    //   if (this.state.cmb_notesedit === true) {
    if (this.state.notesValue2_cmb.length !== 0) {
      // this.setState({savingprogress: 'Saving...'});
      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        attachment_id: this.state.active_edit_id,
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id_cmb
            : this.state.Rnunique_id_cmb
            ? this.state.Rnunique_id_cmb
            : Rnunique_id_cmb,
        text: this.state.notesValue2_cmb ? this.state.notesValue2_cmb : '<p></p>',
        order: '' + this.state.attachOrderIncrs_cmb + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            // this.setState({messageAttachd_cmb: true});
            let objIndex = 0;
            let myArray = this.state.atcharryDet_cmb;
            objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id);
            if (myArray[objIndex]?.notes_content) {
              myArray[objIndex].notes_content = this.state.notesValue2_cmb ? this.state.notesValue2_cmb : '';
              myArray[objIndex].notes_content1 = 'Your text goes here!';
            }

            this.setState({
              atcharryDet_cmb: myArray,
            });
            // this.setState({savingprogress: '✔ Saved'});
            // setTimeout(() => {
            //   this.setState({savingprogress: ''});
            // }, 1000);
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              if (error.response.data.error.message === 'Text required') {
                const errors = [];
                errors.push("Notes can't be empty.");
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                }, 3000);
              }
            }
          }
        });
    }
    //   }
    // }, 2000);
  };
  updateNotes2_cmb = () => {
    console.log(this.state.notesValue2_cmb);

    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv2_cmb').hide();
    // $('.attachLoading_cmb').show();
    $('.attchLstShow_cmb').show();
    this.setState({lodingicon_cmb: 'far fa-sticky-note bg_notes'});

    const notes_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_type: 'notes',
      attachment_id: this.state.active_edit_id,
      unique_id:
        this.state.message_edit == false
          ? Rnunique_id_cmb
          : this.state.Rnunique_id_cmb
          ? this.state.Rnunique_id_cmb
          : Rnunique_id_cmb,
      text: this.state.notesValue2_cmb ? this.state.notesValue2_cmb : '<p></p>',
      order: '' + this.state.attachOrderIncrs_cmb + '',
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState({messageAttachd_cmb: true});
          let objIndex = 0;
          let myArray = this.state.atcharryDet_cmb;
          objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id);
          if (myArray[objIndex].notes_content) {
            myArray[objIndex].notes_content = this.state.notesValue2_cmb;
            myArray[objIndex].notes_content1 = 'Your text goes here';
          }

          this.setState({
            atcharryDet_cmb: myArray,
          });
          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
          this.setState({attachOrderIncrs_cmb: OrderIncr});

          setTimeout(() => {
            // this.setState({notesValue2_cmb: '', chars_left_notes_cmb: 0});

            console.log(this.state.atcharryDet_cmb);
          }, 150);

          $('#notestypearea_cmb').val('');
          // $('.attachLoading_cmb').hide();
          if (this.state.active_edit_id) {
            $('.editor_cmb_' + this.state.active_edit_id).hide();
            $('.notecontent_cmb_' + this.state.active_edit_id).show();
          }
        }
        if (res.data.error) {
          if (res.data.error.message === 'Text required') {
            $('.sendmsgattach_clkNotes2_cmb').show();
            $('.sendmsg_attachmentdiv2_cmb').hide();
            // $('.attachLoading_cmb').hide();
            $('.attchLstShow_cmb').show();
            const errors = [];
            errors.push("Notes can't be empty.");
            this.setState({errors});

            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            if (error.response.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2_cmb').show();
              $('.sendmsg_attachmentdiv2_cmb').hide();
              // $('.attachLoading_cmb').hide();
              $('.attchLstShow_cmb').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        }
      });
  };
  oneditnotecmb = (attachment) => {
    if (this.state.active_edit_id) {
      $('.editor_cmb_' + this.state.active_edit_id).hide();
      $('.notecontent_cmb_' + this.state.active_edit_id).show();
    }
    let objIndex = 0;
    let myArray = this.state.atcharryDet_cmb;
    objIndex = myArray.findIndex((obj) => obj.attach_id == attachment.attach_id);
    if (myArray[objIndex]?.notes_content) {
      myArray[objIndex].notes_content = attachment.notes_content ? attachment.notes_content : '';
      myArray[objIndex].notes_content1 = 'Your text goes here!';
    }

    this.setState({
      atcharryDet_cmb: myArray,
    });
    $('.sendmsg_attachmentdiv2_cmb').hide();
    $('.sendmsgattach_clkNotes2_cmb').hide();
    $('.sendmsgattach_clkcalender_cmb').hide();
    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    $('.editor_cmb_' + attachment.attach_id).show();
    $('.notecontent_cmb_' + attachment.attach_id).hide();
    this.setState({cmb_notesedit: true});
    // window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({active_edit_id: attachment.attach_id, notesValue2_cmb: attachment.notes_content});
    this.autoupdateNotes2_cmb();
  };
  sendmsgattach_clkAudio2_cmb = () => {
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      toast.dark(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      // this.setState(() => ({
      //   QRGrtMsg: `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      //   QRGrtcls: 'alert alert-danger',
      // }));
      // window.scrollTo(0, 0);
      // setTimeout(() => {
      //   this.setState(() => ({
      //     QRGrtMsg: '',
      //     QRGrtcls: '',
      //   }));
      // }, 5000);
      // const errors = [];
      // errors.push(
      //   `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      // );
      // this.setState({errors});

      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkAudio2_cmb').show();
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    }
  };

  sendmsgattach_clkVideo2_cmb = () => {
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      toast.dark(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      // this.setState(() => ({
      //   QRGrtMsg: `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      //   QRGrtcls: 'alert alert-danger',
      // }));
      // window.scrollTo(0, 0);
      // setTimeout(() => {
      //   this.setState(() => ({
      //     QRGrtMsg: '',
      //     QRGrtcls: '',
      //   }));
      // }, 5000);
      // const errors = [];
      // errors.push(
      //   `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      // );
      // this.setState({errors});

      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkVideo2_cmb').show();
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
    }
  };
  sendmsgattach_clkDocmnts2_cmb = () => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      this.setState(() => ({
        QRGrtMsg: `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        QRGrtcls: 'alert alert-danger',
      }));
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.setState(() => ({
          QRGrtMsg: '',
          QRGrtcls: '',
        }));
      }, 5000);
      // const errors = [];
      // errors.push(
      //   `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      // );
      // this.setState({errors});

      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkDocmnts2_cmb').show();
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    }
  };
  fileGetDetails_cmb_images = (event) => {
    if (event.target.files.length !== 0) {
      this.setState(
        {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (event.target.files.length - 1)},
        () => {
          console.log('attachmentdoc_count_cmb in callback log1', this.state.attachmentdoc_count_cmb);
        },
      );
      docAttachmentsCount_cmb += event.target.files.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        event.target.files.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState(
          {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (event.target.files.length - 1)},
          () => {
            console.log('attachmentdoc_count_cmb in callback log2', this.state.attachmentdoc_count_cmb);
          },
        );
        docAttachmentsCount_cmb -= event.target.files.length;
        console.log('restrict', this.state.atcharryDet_cmb.length - this.state.attachmentdoc_count_cmb);
        toast.dark(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
          {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
        // this.setState(() => ({
        //   QRGrtMsg: `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        //   QRGrtcls: 'alert alert-danger',
        // }));
        // window.scrollTo(0, 0);
        // setTimeout(() => {
        //   this.setState(() => ({
        //     QRGrtMsg: '',
        //     QRGrtcls: '',
        //   }));
        // }, 5000);
        this.colapse_all_attachments();
        // const errors = [];
        // errors.push(
        //   `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        // );
        // this.setState({errors});

        // setTimeout(() => {
        //   this.setState({errors: []});
        // }, 3000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('.attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-image bg_gallery '});
        console.log(event.target.files);

        this.setState({DcfilNm_cmb: event.target.files});

        var file_length = event.target.files.length;
        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();

          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'admin');

          if (event.target.files.length != 0) {
            this.colapse_all_attachments();
            console.log('First I value: ' + i);
            var k = 1;
            axios.post(API_CALL_ENDPOINT + '/file/upload', file_detailsd).then((res) => {
              console.log(res);
              console.log(res.data.message);

              if (res.data.status == 'success') {
                this.colapse_all_attachments();
                console.log('File_URL :' + res.data?.result?.files[0].file_url);
                console.log('ID_num :' + res.data?.result?.files[0]._id);
                var file_name = res.data?.result?.files[0].display_name;

                var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data?.result?.files[0]._id);
                this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                  id: res.data?.result?.files[0]._id,
                  file_name: file_name,
                });
                this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});
                this.colapse_all_attachments();
                flDc_id.push(res.data?.result?.files[0]._id);
                flDc_type.push(res.data?.result?.files[0].file_type);

                flDc_detils.push({
                  file_id: res.data?.result?.files[0]._id,
                  file_name: file_name,
                  file_url: res.data?.result?.files[0].file_url,
                  file_path: res.data?.result?.files[0].file_path,
                  thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                  file_size: res.data?.result?.files[0].file_size,
                });
                this.colapse_all_attachments();
                if (k == file_length) {
                  setTimeout(() => {
                    console.log(this.state.atcharryDet_cmb);
                  }, 200);

                  const doct_attcId = flDc_id.filter((value) => value);
                  const atchId_docnt = doct_attcId.toString();
                  console.log(atchId_docnt);

                  const document_details = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                    attachment: atchId_docnt,
                    attachment_type: 'images',
                    attachment_file_type: flDc_type.toString(),
                    unique_id:
                      this.state.message_edit == false
                        ? Rnunique_id_cmb
                        : this.state.Rnunique_id_cmb
                        ? this.state.Rnunique_id_cmb
                        : Rnunique_id_cmb,
                    order: '' + this.state.attachOrderIncrs_cmb + '',
                  };

                  axios.post(API_CALL_ENDPOINT + '/text/attachment/create', document_details).then((res) => {
                    console.log(res);
                    console.log(res.data.message);

                    if (res.data.status == 'success') {
                      setTimeout(() => {
                        this.colapse_all_attachments();
                      }, 1000);
                      setTimeout(() => {
                        this.colapse_all_attachments();
                      }, 2000);
                      setTimeout(() => {
                        this.colapse_all_attachments();
                      }, 3000);
                      this.setState({messageAttachd: true});
                      $('#fileDocUsvBtn').hide();
                      var fileary = {
                        images: flDc_detils,
                        attach_id: res.data?.result?._id,
                      };
                      this.setState({
                        atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                      });
                      var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                      this.setState({attachOrderIncrs_cmb: OrderIncr});
                    }
                    if (res.data.error) {
                    }
                  });

                  this.setState({
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  // $('.attachment_colapsed').show();
                  // $('.attachment_colapsed_'+res.data?.result?._id).hide();
                  // $('.attachment_expanded').hide();
                  // $('.attachment_expanded_'+res.data?.result?._id).show();
                  this.colapse_all_attachments();
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attachLoading_cmb').hide();
                }
                k++;
              }
              if (res?.data?.error?.error_type == 'invalid_file_type') {
                let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                this.setState({
                  attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                });
                toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // const errors = [];
                // errors.push('Sorry, this file is invalid. Please try using a different file.');
                // this.setState({errors});

                // setTimeout(() => {
                //   this.setState({errors: []});
                // }, 3000);

                this.setState({
                  cmb_notesedit: false,
                  notesValue2_cmb: '',
                  max_chars_notes_cmb: 1200,
                  chars_left_notes_cmb: 1200,
                });
                clearTimeout(this.savetimer);
                clearTimeout(this.timer);

                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                $('.sendmsg_attachmentdiv2_cmb').hide();
                $('.attachLoading_cmb').hide();
                this.colapse_all_attachments();
              }
              $('#file-input3')[0].value = '';
            });
          }
        }
      }
    }
  };
  fileGetDetails_cmb = (event) => {
    if (event.target.files.length !== 0) {
      this.setState(
        {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (event.target.files.length - 1)},
        () => {
          console.log('attachmentdoc_count_cmb in callback log1', this.state.attachmentdoc_count_cmb);
        },
      );
      docAttachmentsCount_cmb += event.target.files.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        event.target.files.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState(
          {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (event.target.files.length - 1)},
          () => {
            console.log('attachmentdoc_count_cmb in callback log2', this.state.attachmentdoc_count_cmb);
          },
        );
        docAttachmentsCount_cmb -= event.target.files.length;
        console.log('restrict', this.state.atcharryDet_cmb.length - this.state.attachmentdoc_count_cmb);
        toast.dark(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
          {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
        // this.setState(() => ({
        //   QRGrtMsg: `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        //   QRGrtcls: 'alert alert-danger',
        // }));
        // window.scrollTo(0, 0);
        // this.colapse_all_attachments()
        // setTimeout(() => {
        //   this.setState(() => ({
        //     QRGrtMsg: '',
        //     QRGrtcls: '',
        //   }));
        // }, 5000);
        // const errors = [];
        // errors.push(
        //   `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        // );
        // this.setState({errors});

        // setTimeout(() => {
        //   this.setState({errors: []});
        // }, 3000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('.attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-file-alt bg_documents'});
        console.log(event.target.files);

        this.setState({DcfilNm_cmb: event.target.files});

        var file_length = event.target.files.length;
        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();

          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event.target.files[i]);
          file_detailsd.append('reference', 'text');

          if (event.target.files.length != 0) {
            console.log('First I value: ' + i);
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd)
              .then((res) => {
                if (res.data.status === 'success') {
                  console.log('File_URL :' + res.data?.result?.files[0].file_url);
                  console.log('ID_num :' + res.data?.result?.files[0]._id);
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});

                  flDc_id.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments();
                  if (k == file_length) {
                    setTimeout(() => {
                      console.log(this.state.atcharryDet_cmb);
                    }, 200);

                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    console.log(atchId_docnt);
                    this.colapse_all_attachments();
                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'documents',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit === false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs_cmb + '',
                    };

                    axios.post(API_CALL_ENDPOINT + '/text/attachment/create', document_details).then((res) => {
                      console.log(res);
                      console.log(res.data.message);

                      if (res.data.status == 'success') {
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 1000);
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 2000);
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 3000);
                        this.setState({messageAttachd: true});
                        $('#fileDocUsvBtn').hide();
                        var fileary = {
                          documents: flDc_detils,
                          attach_id: res.data?.result?._id,
                        };
                        this.setState({
                          atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                        });
                        var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                        this.setState({attachOrderIncrs_cmb: OrderIncr});
                      }
                      if (res.data.error) {
                      }
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    // this.colapse_all_attachments()
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();
                    this.colapse_all_attachments();
                  }
                  k++;
                }
                if (res?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                  this.setState({
                    attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                  });
                  // const errors = [];
                  // errors.push('Sorry, this file is invalid. Please try using a different file.');
                  // this.setState({errors});

                  // setTimeout(() => {
                  //   this.setState({errors: []});
                  // }, 3000);
                  toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                    position: 'top-right',
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  this.setState({
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  // $('.attachment_colapsed').show();
                  // // $('.attachment_colapsed_'+res.data?.result?.files[0]._id).hide();
                  // $('.attachment_expanded').hide();
                  // // $('.attachment_expanded_'+res.data?.result?.files[0]._id).show();

                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attachLoading_cmb').hide();
                }
                $('#file-input')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({
                      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                    });
                    // const errors = [];
                    // errors.push('Sorry, this file is invalid. Please try using a different file.');
                    // this.setState({errors});

                    // setTimeout(() => {
                    //   this.setState({errors: []});
                    // }, 3000);
                    toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    // $('.attachment_colapsed').show();
                    // // $('.attachment_colapsed_'+res.data?.result?.files[0]._id).hide();
                    // $('.attachment_expanded').hide();
                    // // $('.attachment_expanded_'+res.data?.result?.files[0]._id).show();

                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();
                  }
                }
              });
          }
        }
        this.colapse_all_attachments();
      }
    }
  };

  DocLclDlete_cmb = (event) => {
    console.log(this.state.atcharryDet_cmb);
    console.log(event.target.id);

    /*const data = this.state.atcharryDet.filter(
      i => i.documents !== event.target.id 
    )
    this.setState({ atcharryDet: data }); */

    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i].documents) {
        for (var j = 0; j < array[i].documents.length; j++) {
          console.log(array[i].documents[j]);
          if (array[i].documents[j].file_id === event.target.id) {
            array[i].documents.splice(j, 1);
            docAttachmentsCount_cmb -= 1;
          }
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    setTimeout(() => {
      console.log(this.state.atcharryDet_cmb);
    }, 100);

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      file_id: event.target.id,
      attachment_id: event.target.dataset.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
      }
      if (res.data.error) {
      }
    });
  };
  audioAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }
    this.colapse_all_attachments();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        this.colapse_all_attachments();
      }
      if (res.data.error) {
        this.colapse_all_attachments();
      }
    });
  };
  notesAtchDelte_cmb_edit = (id) => {
    $('.editor_cmb_' + id).hide();
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    $('.notecontent_cmb_show').show();
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          // $('.sendmsg_attachmentdiv2_cmb').hide();
          // $('.sendmsgattach_clkNotes2_cmb').hide();
          // $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
          // $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
          this.setState({cmb_notesedit: false});
          // window.scrollTo({top: 0, behavior: 'smooth'});
          this.setState({active_edit_id: '', notesValue2_cmb: ''});
          clearTimeout(this.savetimer);
          clearTimeout(this.timer);
          this.colapse_all_attachments();
        }
        if (res.data.error) {
          this.colapse_all_attachments();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments();
          }
        }
      });
  };
  notesAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          if (this.state.active_edit_id) {
            $('.editor_cmb_' + this.state.active_edit_id).hide();
            $('.notecontent_cmb_' + this.state.active_edit_id).show();
          }

          $('.sendmsg_attachmentdiv2_cmb').hide();
          $('.sendmsgattach_clkNotes2_cmb').hide();
          $('.sendmsgattach_clkcalender_cmb').hide();
          $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
          $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
          this.setState({cmb_notesedit: false});
          window.scrollTo({top: 0, behavior: 'smooth'});
          this.setState({active_edit_id: '', notesValue2_cmb: ''});
          clearTimeout(this.timer);
          clearTimeout(this.savetimer);
          this.colapse_all_attachments();
        }
        if (res.data.error) {
          this.colapse_all_attachments();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments();
          }
        }
      });
  };
  videAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }
    this.colapse_all_attachments();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        this.colapse_all_attachments();
      }
      if (res.data.error) {
        this.colapse_all_attachments();
      }
    });
  };

  videoLclDelte_cmb = () => {
    $('.sendmsgattach_clkVideo2_cmb').hide();
    $('.sendmsg_attachmentdiv2_cmb').hide();
  };
  documntAtchDelte_cmb = (event, attachment) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({
      atcharryDet_cmb: array,
      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - attachment.length + 1,
    });
    docAttachmentsCount_cmb -= attachment.length - 1;
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        this.colapse_all_attachments();
      }
      if (res.data.error) {
        this.colapse_all_attachments();
      }
    });
  };
  notesLclDelte_cmb = () => {
    $('.sendmsgattach_clkNotes2_cmb').hide();
    $('.sendmsg_attachmentdiv2_cmb').hide();
    this.setState({cmb_notesedit: false});
    // window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({active_edit_id: '', notesValue2_cmb: ''});
    clearTimeout(this.savetimer);
  };
  audioLclDelte_cmb = () => {
    $('.sendmsgattach_clkAudio2_cmb').hide();
    $('.sendmsg_attachmentdiv2_cmb').hide();
  };
  audioLclDelte_cmb_nft = () => {
    $('.sendmsgattach_clkAudio2_cmb').hide();
    $('.sendmsg_attachmentdiv2_cmb').hide();
  };
  dragStart_cmb(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }
  dragEnd_cmb(e) {
    this.dragged.style.display = 'block';
    // this.dragged.parentNode.removeChild(placeholder);

    // update state
    var data = this.state.atcharryDet_cmb;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over?.dataset?.id);
    if (from < to) to--;
    if (from > to) from--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({atcharryDet_cmb: data});
    console.log('Drag drop', this.state.atcharryDet_cmb);
    var array = data;
    var arrayLength = data.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        const Attachment_update_order = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          order: i + 1,
          attachment_id: array[i].attach_id,
        };

        console.log(Attachment_update_order);

        axios.post(API_CALL_ENDPOINT + '/text/attachment/update', Attachment_update_order).then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            console.log('Done');
          }
          if (res.data.error) {
          }
        });
      }
    }
  }
  dragOver_cmb(e) {
    e.preventDefault();
    this.dragged.style.display = 'none';
    if (e.target.className === 'placeholder_cmb') return;
    this.over = e.target;
    e.target.parentNode.insertBefore(placeholder_cmb, e.target);
  }
  closeLightbox = () => {
    console.log('Clicked here');
    this.setState({Lightbox_open: false});
    // $('#btnTrigger').click();
  };
  closeLightbox_video = () => {
    this.setState({Lightbox_open_video: false});
    // $('#btnTrigger').click();
  };
  view_presignedurl = (image) => {
    console.log('image', image);
    const url = new URL(image);
    if (url.searchParams.get('object_key')) {
      const object_key = url.searchParams.get('object_key');
      const document_url_detail = {
        params: {
          object_key: object_key ? object_key : undefined,
        },
      };
      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
        if (res.data) {
          console.log('/file/presigned/url', res.data);
          this.setState({qr_download_link: res.data});
        }
        if (res.data.error) {
        }
      });
    }
  };
  document_read(attachment) {
    console.log('attachment_detail', attachment);
    var fileType = attachment.file_path.split('.');
    const document_url_detail = {
      params: {
        object_key: attachment.file_path ? attachment.file_path : undefined,
      },
    };

    console.log(document_url_detail);
    console.log('fileType', fileType[fileType.length - 1]);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          document_url: res.data,
          Lightbox_open:
            fileType[fileType.length - 1] === 'jpg' ||
            fileType[fileType.length - 1] === 'jpeg' ||
            fileType[fileType.length - 1] === 'png'
              ? true
              : false,
        });

        // fileType[fileType.length - 1] === "jpg" ||
        // fileType[fileType.length - 1] === "jpeg" ||
        // fileType[fileType.length - 1] === "png"
        //   ? null
        //   : fileType[fileType.length - 1] === "pdf" ||
        //     fileType[fileType.length - 1] === "PDF"
        //   ? AsyncStorage.setItem("PDF_View_url", res.data) &&
        //     window.open(
        //       "http://" + window.location.href.split("/")[2] + "/pdf_viewer/",
        //       "_blank"
        //     )
        //   : window.open(res.data, "_blank");
        if (
          fileType[fileType.length - 1] === 'jpg' ||
          fileType[fileType.length - 1] === 'jpeg' ||
          fileType[fileType.length - 1] === 'png'
        ) {
          // null
        } else if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
          AsyncStorage.setItem('PDF_View_url', res.data);
          window.open('http://' + window.location.href.split('/')[2] + '/pdf_viewer/', '_blank');
        } else {
          window.open(res.data, '_blank');
        }
        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
  }
  document_read_auto(attachment) {
    console.log('attachment_detail', attachment);
    var fileType = attachment.file_path.split('.');
    const document_url_detail = {
      params: {
        object_key: attachment.file_path ? attachment.file_path : undefined,
      },
    };

    console.log(document_url_detail);
    console.log('fileType', fileType[fileType.length - 1]);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          document_url: res.data,
          Lightbox_open:
            fileType[fileType.length - 1] === 'jpg' ||
            fileType[fileType.length - 1] === 'jpeg' ||
            fileType[fileType.length - 1] === 'png'
              ? true
              : false,
        });

        // fileType[fileType.length - 1] === "jpg" ||
        // fileType[fileType.length - 1] === "jpeg" ||
        // fileType[fileType.length - 1] === "png"
        //   ? null
        //   : fileType[fileType.length - 1] === "pdf" ||
        //     fileType[fileType.length - 1] === "PDF"
        //   ? AsyncStorage.setItem("PDF_View_url", res.data) &&
        //     window.open(
        //       "http://" + window.location.href.split("/")[2] + "/pdf_viewer/",
        //       "_blank"
        //     )
        //   : window.open(res.data, "_blank");
        if (
          fileType[fileType.length - 1] === 'jpg' ||
          fileType[fileType.length - 1] === 'jpeg' ||
          fileType[fileType.length - 1] === 'png'
        ) {
          // null
          $('.auto_txt_model_close').click();
          // $('.modal_template_confirmation_close').click();
        } else if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
          AsyncStorage.setItem('PDF_View_url', res.data);
          window.open('http://' + window.location.href.split('/')[2] + '/pdf_viewer/', '_blank');
        } else {
          window.open(res.data, '_blank');
        }
        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
  }

  audioPly_temp = (attachment) => {
    if (this.state.audio_play_id) {
      this.audioStop();
    }
    console.log('attachment_detail', attachment);

    var PlyIdn = attachment._id;

    var playx = document.getElementById('audio_temp_' + attachment._id);
    const audio_url_detail = {
      params: {
        object_key: attachment.file_path,
        // attachment.file_path.split(".")[1] === "mp3" ||
        // attachment.file_path.split(".")[1] === "mp4"
        //   ? attachment.file_path
        //   : attachment.second_file_path,
      },
    };

    console.log(audio_url_detail);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          play_audio_url: res.data,
          audio_play_id: attachment._id,
        });
        playx.play();
        $('#audio_temp_' + attachment._id).show();
        $('.adiPlyICn_' + attachment._id).hide();
      }
      if (res.data.error) {
      }
    });

    $('.adiPusIcn_' + attachment._id).hide();
    playx.onended = function () {
      $('.adiPusIcn_' + PlyIdn).hide();
      $('.adiPlyICn_' + PlyIdn).show();
      $('#audio_temp_' + PlyIdn).hide();
    };
  };
  audioPly_work = (attachment) => {
    if (this.state.audio_play_id) {
      this.audioStop();
    }
    console.log('attachment_detail', attachment);

    var PlyIdn = attachment._id;

    var playx = document.getElementById('audio_work_' + attachment._id);
    const audio_url_detail = {
      params: {
        object_key: attachment.file_path,
        // attachment.file_path.split(".")[1] === "mp3" ||
        // attachment.file_path.split(".")[1] === "mp4"
        //   ? attachment.file_path
        //   : attachment.second_file_path,
      },
    };

    console.log(audio_url_detail);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          play_audio_url: res.data,
          audio_play_id: attachment._id,
        });
        playx.play();
        $('#audio_work_' + attachment._id).show();
        $('.adiPlyICn_' + attachment._id).hide();
      }
      if (res.data.error) {
      }
    });

    $('.adiPusIcn_' + attachment._id).hide();
    playx.onended = function () {
      $('.adiPusIcn_' + PlyIdn).hide();
      $('.adiPlyICn_' + PlyIdn).show();
      $('#audio_work_' + PlyIdn).hide();
    };
  };
  audioPly = (attachment) => {
    if (this.state.audio_play_id) {
      this.audioStop();
    }
    console.log('attachment_detail', attachment);

    var PlyIdn = attachment._id;

    var playx = document.getElementById('audio_' + attachment._id);
    const audio_url_detail = {
      params: {
        object_key: attachment.file_path,
        // attachment.file_path.split(".")[1] === "mp3" ||
        // attachment.file_path.split(".")[1] === "mp4"
        //   ? attachment.file_path
        //   : attachment.second_file_path,
      },
    };

    console.log(audio_url_detail);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          play_audio_url: res.data,
          audio_play_id: attachment._id,
        });
        playx.play();
        $('#audio_' + attachment._id).show();
        $('.adiPlyICn_' + attachment._id).hide();
      }
      if (res.data.error) {
      }
    });

    $('.adiPusIcn_' + attachment._id).hide();
    playx.onended = function () {
      $('.adiPusIcn_' + PlyIdn).hide();
      $('.adiPlyICn_' + PlyIdn).show();
      $('#audio_' + PlyIdn).hide();
    };
  };

  audioPaue = (e) => {
    var pausex = document.getElementById('audio_' + e.target.id);
    pausex.pause();
    $('.adiPusIcn_' + e.target.id).hide();
    $('.adiPlyICn_' + e.target.id).show();
    $('#audio_' + e.target.id).hide();
  };
  audioStop() {
    if (this.state.audio_play_id) {
      //   var pausex = document.getElementById("audio_" + this.state.audio_play_id);
      //   pausex.pause();
      $('.adiPusIcn_tostop').hide();
      $('.adiPlyICn_toshow').show();
      $('#audio_' + this.state.audio_play_id).hide();
    }
  }
  Video_play_url(attachment) {
    console.log('attachment_detail', attachment);
    const document_url_detail = {
      params: {
        object_key: attachment.file_path,
        // attachment.file_path.split(".")[1] == "mp4"
        //   ? attachment.file_path
        //   : attachment.second_file_path,
      },
    };

    console.log(document_url_detail);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          video_play_link: res.data,
          Lightbox_open_video: true,
        });

        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
  }
  tagonChange_send = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);

    this.setState({
      qr_filtered_tag_list: values,
    });
  };
  tagonChange_chat = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);

    this.setState({
      chat_filtered_tag_list: values,
    });
  };
  chkAllTem_chat = () => {
    if ($('#allteam_chat').is(':checked')) {
      for (var i in this.state.TemIdsMebrArry_chat) {
        $('.chatTmIds_' + this.state.TemIdsMebrArry_chat[i]).prop('checked', true);
      }
    } else {
      for (var i in this.state.TemIdsMebrArry_chat) {
        $('.chatTmIds_' + this.state.TemIdsMebrArry_chat[i]).prop('checked', false);
      }
    }
  };
  pschat_addtagname = () => {
    $('.pschat_entertagname_drpdwn_div').hide();
    $('.pschat_entertagname_txtbx_div').show();
    this.setState({chat_addtag_save: true});
  };

  pschat_closeaddtagname = () => {
    $('.pschat_entertagname_txtbx_div').hide();
    $('.pschat_entertagname_drpdwn_div').show();
    this.setState({chat_addtag_save: false});
  };

  copy_chat_link = () => {
    let textarea = document.getElementById('chat_script_link');
    textarea.select();
    document.execCommand('copy');
    this.setState(() => ({
      ChatGrtMsg: 'Link Copied Successfully',
      ChatGrtcls: 'alert alert-success text-center',
    }));
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState(() => ({
        ChatGrtMsg: '',
        ChatGrtcls: '',
      }));
    }, 5000);
  };

  save_chat_details = () => {
    // console.log(this.state.TemIdsMebrArry_chat);
    var tmArry = [];
    for (var i in this.state.TemIdsMebrArry_chat) {
      if ($('.chatTmIds_' + this.state.TemIdsMebrArry_chat[i]).is(':checked')) {
        var chdTmids = $('.chatTmIds_' + this.state.TemIdsMebrArry_chat[i]).attr('id');
        // console.log(chdTmids.split('_')[1]);
        tmArry.push(chdTmids.split('_')[1]);
      }
    }

    // console.log('Checked Team List: ' + tmArry);
    var chkTmIdDe = tmArry.filter((value) => value);
    var chkTmIdDetails = chkTmIdDe.toString();
    let chat_filtered_tag_list_new = this.state.chat_filtered_tag_list;
    if (this.state.chat_addtag_save === true) {
      // console.log("newtagname",this.state.newtagname)
      const createtag = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tag_name: this.state.chat_newtag,
        tag_source: 'contacts',
      };
      console.log('createtag', createtag);
      axios
        .post(API_CALL_ENDPOINT + '/tags/create', createtag)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            if (chat_filtered_tag_list_new.indexOf(res.data?.result?._id) === -1) {
              chat_filtered_tag_list_new.push(res.data?.result?._id);
              // console.log(qr_filtered_tag_list_new);
            }
            // qr_newtag_id = ',' + res.data?.result?._id;
          } else if (res.data.status === 'conflict') {
            if (chat_filtered_tag_list_new.indexOf(res.data?.result?.details._id) === -1) {
              chat_filtered_tag_list_new.push(res.data?.result?.details._id);
              // console.log(qr_filtered_tag_list_new);
            }
            // qr_newtag_id = ',' + res.data?.result?.details._id;
          }
          const chat_upDetails = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            tags: chat_filtered_tag_list_new.toString(),
            team_id: chkTmIdDetails,
            nick_name: undefined,
            greeting: this.state.chat_grtmsg,
            reply_message: this.state.chat_replymsg,
            new_contact_message: this.state.chat_newcontactmsg,
            existing_contact_message: this.state.chat_existcontactmsg,
          };
          console.log('inside', chat_upDetails);
          axios
            .post(API_CALL_ENDPOINT + '/chat/widget/update', chat_upDetails)
            .then((res) => {
              if (res.data.status == 'success') {
                console.log('outside', res.data);
                window.scrollTo(0, 0);
                this.setState(() => ({
                  ChatGrtMsg: res.data.message,
                  ChatGrtcls: 'alert alert-success',
                }));

                setTimeout(() => {
                  this.setState(() => ({
                    ChatGrtMsg: '',
                    ChatGrtcls: '',
                  }));
                }, 5000);
              } else if (res.data.error) {
                this.setState(() => ({
                  ChatGrtMsg: res.data.error.message,
                  ChatGrtcls: 'alert alert-danger',
                }));
                window.scrollTo(0, 0);
                setTimeout(() => {
                  this.setState(() => ({
                    ChatGrtMsg: '',
                    ChatGrtcls: '',
                  }));
                }, 5000);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.error) {
                  this.setState(() => ({
                    ChatGrtMsg: error.response.data.error.message,
                    ChatGrtcls: 'alert alert-danger',
                  }));
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    this.setState(() => ({
                      ChatGrtMsg: '',
                      ChatGrtcls: '',
                    }));
                  }, 5000);
                }
              }
            });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              if (chat_filtered_tag_list_new.indexOf(error.response.data?.result?.details._id) === -1) {
                chat_filtered_tag_list_new.push(error.response.data?.result?.details._id);
                // console.log(qr_filtered_tag_list_new);
              }
              // qr_newtag_id = ',' + res.data?.result?.details._id;
            }
          }
        });
    } else {
      const chat_upDetails = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tags: chat_filtered_tag_list_new.toString(),
        team_id: chkTmIdDetails,
        nick_name: undefined,
        greeting: this.state.chat_grtmsg,
        reply_message: this.state.chat_replymsg,
        new_contact_message: this.state.chat_newcontactmsg,
        existing_contact_message: this.state.chat_existcontactmsg,
      };
      console.log('outside', chat_upDetails);
      axios
        .post(API_CALL_ENDPOINT + '/chat/widget/update', chat_upDetails)
        .then((res) => {
          if (res.data.status == 'success') {
            console.log('outside', res.data);

            this.setState(() => ({
              ChatGrtMsg: res.data.message,
              ChatGrtcls: 'alert alert-success',
            }));
            window.scrollTo(0, 0);
            setTimeout(() => {
              this.setState(() => ({
                ChatGrtMsg: '',
                ChatGrtcls: '',
              }));
            }, 5000);
          } else if (res.data.error) {
            this.setState(() => ({
              ChatGrtMsg: res.data.error.message,
              ChatGrtcls: 'alert alert-danger',
            }));
            window.scrollTo(0, 0);
            setTimeout(() => {
              this.setState(() => ({
                ChatGrtMsg: '',
                ChatGrtcls: '',
              }));
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                ChatGrtMsg: error.response.data.error.message,
                ChatGrtcls: 'alert alert-danger',
              }));
              window.scrollTo(0, 0);
              setTimeout(() => {
                this.setState(() => ({
                  ChatGrtMsg: '',
                  ChatGrtcls: '',
                }));
              }, 5000);
            }
          }
        });
    }
  };
  getExtensionFromUrl = (url = '') => {
    if (url) {
      let urlPathArray = url.split('/');
      let extArr = urlPathArray[urlPathArray.length - 1].split('.');
      let extension = extArr[extArr.length - 1];
      return extension;
    }
    return null;
  };
  bytesToKb = (bytes) => {
    let KB = bytes / Math.pow(1024, 1);
    let KBSplit = KB.toString().split('.')[0];
    return KBSplit;
  };
  toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  };
  dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
  };
  handleJoyrideCallback = (data) => {
    AsyncStorage.setItem('tour_firsttime', '');
    const {action, index, status, type} = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      if (index + (action === ACTIONS.PREV ? -1 : 1) === 4) {
        $('#custom-tabs-general-tab').removeClass('active');
        $('#custom-tabs-integrations-tab').removeClass('active');
        $('#custom-tabs-autoresponse-tab').addClass('active');
        $('#custom-tabs-general').removeClass('active show');
        $('#custom-tabs-integrations').removeClass('active show');
        $('#custom-tabs-autoresponse').addClass('active show');
        $('#custom-tabs-packs-tab').removeClass('active');
        $('#custom-tabs-packs').removeClass('active');
        $('#custom-tabs-workflow').removeClass('active show');
        $('#custom-tabs-workflow-tab').removeClass('active');
        $('#custom-tabs-templates-tab').removeClass('active');
        $('#custom-tabs-templates').removeClass('active');
      }
      this.setState({stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)});
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({run: false});
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };
  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };

  cart_update = (value) => {
    if (value === 'true') {
      const cart_list = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list).then((res) => {
        console.log('/booster/cart/list', res.data);
        if (res.data.result) {
          let price_quantity_list = [...this.state.price_quantity_list];
          let order_amount = 0;
          let order_doller = 0;
          let exist_cart_id = [];
          for (let i = 0; i < res.data?.result?.length; i++) {
            const item = res.data.result[i];

            let selectedQlist = price_quantity_list.find((x) => x._id === item.master_pack_id);
            selectedQlist.quantity = item.pack_quantity;

            var removeIndex = price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
            price_quantity_list.splice(removeIndex, 1);
            price_quantity_list = [...price_quantity_list, selectedQlist];
            // order_amount = order_amount.push("'" + res.data?.result[i]?.order_amount + "'");

            order_amount += res.data?.result[i]?.order_amount;
            order_doller += parseInt(res.data?.result[i]?.order_doller);
            // order_amount = order_amount.join(', ');
            // exist_cart_id = exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
            exist_cart_id = [...exist_cart_id, res.data?.result[i]?._id];
            // exist_cart_id = exist_cart_id.join(', ');
          }
          console.log('price_quantity_list', price_quantity_list);
          this.setState({
            price_quantity_list,
            order_amount,
            exist_cart_id,
            order_doller,
          });

          this.setState({packcart_list: res.data.result});
        } else if (res.data.status == 'no_record') {
          this.setState({
            pack_quantity_message: 1,
            order_data_count: '',
            order_doller: '',
            packcart_list: [],
            exist_cart_id: '',
          });
          $('.choosepackdtls_div').show();
          $('.creditcarddtls_div').hide();
        }
      });
    }
  };

  save_email_integrations = () => {
    var tmArry = [];
    for (var i in this.state.TemIdsMebrArry) {
      if ($('.emailteam' + this.state.TemIdsMebrArry[i]).is(':checked')) {
        tmArry.push(this.state.TemIdsMebrArry[i]);
      }
    }

    var chkTmIdDe = tmArry.filter((value) => value);
    var chkTmIdDetails = chkTmIdDe.toString();
    console.log('Checked Team List: ' + chkTmIdDetails);
    const emailint_Details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      email_address: this.state.business_email_name + APP_EMAIL_ENDPOINT,
      setup: 'integration_email',
      team_id: chkTmIdDetails,
    };

    console.log(emailint_Details);
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', emailint_Details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState({business_email_address: this.state.business_email_name + APP_EMAIL_ENDPOINT});

          $('.profemailaddr_dsplydiv').show();
          $('.profemailaddr_upddiv').hide();
          this.setState(() => ({
            EmlAlrtMsg: res.data.message,
            EmlAlrtCls: 'alert alert-success',
          }));

          setTimeout(() => {
            this.setState(() => ({
              EmlAlrtMsg: '',
              EmlAlrtCls: '',
            }));
          }, 5000);
        } else if (res.data.error) {
          this.setState(() => ({
            EmlAlrtMsg: res.data.error.message,
            EmlAlrtCls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              EmlAlrtMsg: '',
              EmlAlrtCls: '',
            }));
          }, 5000);
        } else if (res.data.status == 'conflict') {
          this.setState(() => ({
            EmlAlrtMsg: res.data.message,
            EmlAlrtCls: 'alert alert-danger',
          }));

          setTimeout(() => {
            this.setState(() => ({
              EmlAlrtMsg: '',
              EmlAlrtCls: '',
            }));
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'conflict') {
            this.setState(() => ({
              EmlAlrtMsg: error.response.data.message,
              EmlAlrtCls: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                EmlAlrtMsg: '',
                EmlAlrtCls: '',
              }));
            }, 5000);
          }
        }
      });
  };
  onChangebusiness = (business) => {
    console.log(business.business._id);
    AsyncStorage.setItem('business_id', business.business._id);
    window.location.reload(true);
  };
  getQuantity = (pack, _id) => {
    if (this.state.price_quantity_list?.length > 0) {
      let selectedQlist = this.state.price_quantity_list.find((x) => x?._id === _id);
      return selectedQlist?.quantity;
    } else {
      return 1;
    }
  };
  getQuantity_cart = (pack, _id) => {
    if (this.state.price_quantity_list?.length > 0) {
      let selectedQlist = this.state.price_quantity_list.find((x) => x?._id === _id);

      return selectedQlist?.quantity;
    } else {
      return 1;
    }
  };
  onupdatecart_add = (pack, _id) => {
    this.setState({
      boosterpack_active_id: _id,
    });
    if (this.state.boosterpack_active_id === _id) {
      let price_quantity_list = [...this.state.price_quantity_list];
      let selectedQlist = price_quantity_list.find((x) => x._id === _id);
      selectedQlist.quantity = selectedQlist.quantity + 1;

      var removeIndex = price_quantity_list.map((item) => item._id).indexOf(_id);
      price_quantity_list.splice(removeIndex, 1);
      price_quantity_list = [...price_quantity_list, selectedQlist];

      this.setState({
        pack_quantity_message: this.state.pack_quantity_message + 1,
        price_quantity_list,
      });
    }
  };
  onupdatecart_delete = (pack, _id) => {
    this.setState({
      boosterpack_active_id: _id,
    });
    if (this.state.boosterpack_active_id === _id) {
      let price_quantity_list = [...this.state.price_quantity_list];
      let selectedQlist = price_quantity_list.find((x) => x._id === _id);
      selectedQlist.quantity = selectedQlist.quantity !== 1 ? selectedQlist.quantity - 1 : selectedQlist.quantity;

      var removeIndex = price_quantity_list.map((item) => item._id).indexOf(_id);
      price_quantity_list.splice(removeIndex, 1);
      price_quantity_list = [...price_quantity_list, selectedQlist];

      this.setState({
        pack_quantity_message:
          this.state.pack_quantity_message !== 1
            ? this.state.pack_quantity_message - 1
            : this.state.pack_quantity_message,
        price_quantity_list,
      });
    }
  };
  onupdatecart_add_cart = (pack, _id) => {
    this.onupdatecart_add(pack, _id);
    const Add_cart = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      master_pack_id: _id,
      pack_quantity: this.getQuantity_cart(pack, _id),
    };

    axios.post(API_CALL_ENDPOINT + '/booster/add/cart', Add_cart).then((res) => {
      if (res.data.status == 'success') {
        console.log('res.data', res.data);
        const cart_list = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios.get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list).then((res) => {
          console.log('/booster/cart/list', res.data);
          if (res.data.result) {
            let price_quantity_list = [...this.state.price_quantity_list];
            let order_amount = 0;
            let order_doller = 0;
            let exist_cart_id = [];
            for (let i = 0; i < res.data?.result?.length; i++) {
              const item = res.data.result[i];

              let selectedQlist = price_quantity_list.find((x) => x._id === item.master_pack_id);
              selectedQlist.quantity = item.pack_quantity;

              var removeIndex = price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
              price_quantity_list.splice(removeIndex, 1);
              price_quantity_list = [...price_quantity_list, selectedQlist];
              // order_amount = order_amount.push("'" + res.data?.result[i]?.order_amount + "'");

              order_amount += res.data?.result[i]?.order_amount;
              order_doller += parseInt(res.data?.result[i]?.order_doller);
              // order_amount = order_amount.join(', ');
              // exist_cart_id = exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
              exist_cart_id = [...exist_cart_id, res.data?.result[i]?._id];
              // exist_cart_id = exist_cart_id.join(', ');
            }
            console.log('price_quantity_list', price_quantity_list);
            this.setState({
              price_quantity_list,
              order_amount,
              exist_cart_id,
              order_doller,
            });
            // for (var i in res.data?.result) {
            //   if (res.data?.result[i]?.pack_type === 'message') {
            //     this.setState({
            //       pack_quantity_message: res.data?.result[i]?.pack_quantity,
            //       order_data_count: res.data?.result[i]?.order_data_count,
            //       order_doller: res.data?.result[i]?.order_doller,
            //       order_amount: res.data?.result[i]?.order_amount,
            //       exist_cart_id: res.data?.result[i]?._id,
            //     });
            //   }
            // }

            this.setState({packcart_list: res.data.result});
          } else if (res.data.status == 'no_record') {
          }
        });
      }
      if (res.data.error) {
        console.log('res.data.error', res.data.error);
      }
    });
  };
  onupdatecart_delete_cart = (pack, _id) => {
    this.onupdatecart_delete(pack, _id);
    const Add_cart = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      master_pack_id: _id,
      pack_quantity: this.getQuantity_cart(pack, _id),
    };

    axios.post(API_CALL_ENDPOINT + '/booster/add/cart', Add_cart).then((res) => {
      if (res.data.status == 'success') {
        console.log('res.data', res.data);
        const cart_list = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios.get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list).then((res) => {
          console.log('/booster/cart/list', res.data);
          if (res.data.result) {
            let price_quantity_list = [...this.state.price_quantity_list];
            let order_amount = 0;
            let order_doller = 0;
            let exist_cart_id = [];
            for (let i = 0; i < res.data?.result?.length; i++) {
              const item = res.data.result[i];

              let selectedQlist = price_quantity_list.find((x) => x._id === item.master_pack_id);
              selectedQlist.quantity = item.pack_quantity;

              var removeIndex = price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
              price_quantity_list.splice(removeIndex, 1);
              price_quantity_list = [...price_quantity_list, selectedQlist];
              // order_amount = order_amount.push("'" + res.data?.result[i]?.order_amount + "'");

              order_amount += res.data?.result[i]?.order_amount;
              order_doller += parseInt(res.data?.result[i]?.order_doller);
              // order_amount = order_amount.join(', ');
              // exist_cart_id = exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
              exist_cart_id = [...exist_cart_id, res.data?.result[i]?._id];
              // exist_cart_id = exist_cart_id.join(', ');
            }
            console.log('price_quantity_list', price_quantity_list);
            this.setState({
              price_quantity_list,
              order_amount,
              exist_cart_id,
              order_doller,
            });
            // for (var i in res.data?.result) {
            //   if (res.data?.result[i]?.pack_type === 'message') {
            //     this.setState({
            //       pack_quantity_message: res.data?.result[i]?.pack_quantity,
            //       order_data_count: res.data?.result[i]?.order_data_count,
            //       order_doller: res.data?.result[i]?.order_doller,
            //       order_amount: res.data?.result[i]?.order_amount,
            //       exist_cart_id: res.data?.result[i]?._id,
            //     });
            //   }
            // }

            this.setState({packcart_list: res.data.result});
          } else if (res.data.status == 'no_record') {
          }
        });
      }
      if (res.data.error) {
        console.log('res.data.error', res.data.error);
      }
    });
  };
  add_to_cart(pack, _id) {
    console.log('add_to_cart', pack);
    const Add_cart = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      master_pack_id: _id,
      pack_quantity: this.getQuantity(pack, _id),
    };
    console.log('Add_cart', Add_cart);

    axios.post(API_CALL_ENDPOINT + '/booster/add/cart', Add_cart).then((res) => {
      if (res.data.status == 'success') {
        if (res.data.message === 'Addtocart updated successfully') {
        } else {
          toast.dark(pack.pack_name + ' ' + 'has been added to your cart successfully', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        console.log('res.data', res.data);
        const cart_list = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios.get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list).then((res) => {
          console.log('/booster/cart/list', res.data);
          if (res.data.result) {
            let price_quantity_list = [...this.state.price_quantity_list];
            let order_amount = 0;
            let order_doller = 0;
            let exist_cart_id = [];
            for (let i = 0; i < res.data?.result?.length; i++) {
              const item = res.data.result[i];

              let selectedQlist = price_quantity_list.find((x) => x._id === item.master_pack_id);
              selectedQlist.quantity = item.pack_quantity;

              var removeIndex = price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
              price_quantity_list.splice(removeIndex, 1);
              price_quantity_list = [...price_quantity_list, selectedQlist];
              // order_amount = order_amount.push("'" + res.data?.result[i]?.order_amount + "'");

              order_amount += res.data?.result[i]?.order_amount;
              order_doller += parseInt(res.data?.result[i]?.order_doller);
              // order_amount = order_amount.join(', ');
              // exist_cart_id = exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
              exist_cart_id = [...exist_cart_id, res.data?.result[i]?._id];
              // exist_cart_id = exist_cart_id.join(', ');
            }
            console.log('price_quantity_list', price_quantity_list);
            this.setState({
              price_quantity_list,
              order_amount,
              exist_cart_id,
              order_doller,
            });
            // for (var i in res.data?.result) {
            //   if (res.data?.result[i]?.pack_type === 'message') {
            //     this.setState({
            //       pack_quantity_message: res.data?.result[i]?.pack_quantity,
            //       order_data_count: res.data?.result[i]?.order_data_count,
            //       order_doller: res.data?.result[i]?.order_doller,
            //       order_amount: res.data?.result[i]?.order_amount,
            //       exist_cart_id: res.data?.result[i]?._id,
            //     });
            //   }
            // }

            this.setState({packcart_list: res.data.result});
          } else if (res.data.status == 'no_record') {
          }
        });
      }
      if (res.data.error) {
        console.log('res.data.error', res.data.error);
      }
    });
  }

  delete_cart_pack = (pack) => {
    const delete_cart = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      cart_id: pack._id,
    };

    axios.post(API_CALL_ENDPOINT + '/booster/cart/delete', delete_cart).then((res) => {
      if (res.data.status == 'success') {
        const cart_list = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list)
          .then((res) => {
            console.log('/booster/cart/list', res.data);
            if (res.data.result) {
              let price_quantity_list = [...this.state.price_quantity_list];
              let order_amount = 0;
              let order_doller = 0;
              let exist_cart_id = [];
              for (let i = 0; i < res.data?.result?.length; i++) {
                const item = res.data.result[i];

                let selectedQlist = price_quantity_list.find((x) => x._id === item.master_pack_id);
                selectedQlist.quantity = item.pack_quantity;

                var removeIndex = price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
                price_quantity_list.splice(removeIndex, 1);
                price_quantity_list = [...price_quantity_list, selectedQlist];
                // order_amount = order_amount.push("'" + res.data?.result[i]?.order_amount + "'");

                order_amount += res.data?.result[i]?.order_amount;
                order_doller += parseInt(res.data?.result[i]?.order_doller);
                // order_amount = order_amount.join(', ');
                // exist_cart_id = exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
                exist_cart_id = [...exist_cart_id, res.data?.result[i]?._id];
                // exist_cart_id = exist_cart_id.join(', ');
              }
              console.log('price_quantity_list', price_quantity_list);
              this.setState({
                price_quantity_list,
                order_amount,
                exist_cart_id,
                order_doller,
              });

              this.setState({packcart_list: res.data.result});
            } else if (res.data.status == 'no_record') {
              this.setState({
                pack_quantity_message: 1,
                order_data_count: '',
                order_doller: '',
                packcart_list: [],
                exist_cart_id: '',
              });
              $('.choosepackdtls_div').show();
              $('.creditcarddtls_div').hide();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status == 'no_record') {
                this.setState({
                  pack_quantity_message: 1,
                  order_data_count: '',
                  order_doller: '',
                  packcart_list: [],
                  exist_cart_id: '',
                });
                $('.choosepackdtls_div').show();
                $('.creditcarddtls_div').hide();
              }
            }
          });
      }
      if (res.data.error) {
        console.log('res.data.error', res.data.error);
      }
    });
  };
  proceed_checkout = () => {
    $('.choosepackdtls_div').hide();
    $('.creditcarddtls_div').show();
  };
  onChangecard_id = (e) => {
    if (e.target.value == 'Select') {
      this.setState({existcard_id: ''});
    } else {
      this.setState({existcard_id: e.target.value});
    }
  };
  onChangecardnumber = (e) => {
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({card_number: e.target.value});
  };
  onChangecardname = (e) => {
    // const errors = [];
    var regx = /^[a-zA-Z\s]*$/;
    if (regx.test(e.target.value)) {
      this.setState({card_name: e.target.value});
    }
    // e.target.value = e.target.value.replace(/^[A-Za-z ]+$/,'').replace(/[^\d\/]/g,'').trim();
    // e.target.value = e.target.value.replace(/^([a-zA-Z0-9 _-]+)$/, '').trim();
    // this.setState({ card_name: e.target.value })
  };
  onChangeexpdate = (e) => {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();
    this.setState({exp_date: e.target.value});
  };
  onChangecvc = (e) => {
    // const re = /^[0-9\b]+$/;
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({cvc: e.target.value});
  };
  onPayexistCard = (e) => {
    e.preventDefault();
    const existcardpayment = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      // card_id: this.state.existcard_id,
      cart_id: this.state.exist_cart_id.toString(),
      invoice_amount: this.state.order_amount.toString(),
    };
    // console.log(existcardpayment);
    // return;

    axios
      .post(API_CALL_ENDPOINT + '/booster/payment/create', existcardpayment)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          window.location.href = res?.data?.result?.url;
          this.setState({
            isLoading: false,
            cartmsg: res.data.message,
            cartcls: 'alert alert-success text-center',
          });
          setTimeout(() => {
            this.setState({cartmsg: '', cartcls: ''});
            this.setState({
              pack_quantity_message: 1,
              order_data_count: '',
              order_doller: '',
              packcart_list: [],
              exist_cart_id: '',
            });
            this.setState({cart_updated_count: this.state.cart_updated_count + 1});
            $('.choosepackdtls_div').show();
            $('.creditcarddtls_div').hide();
          }, 5000);
        } else if (res.data.error) {
          this.setState({
            isLoading: false,
            cartmsg: res.data.error.message,
            cartcls: 'alert alert-danger text-center',
          });
          setTimeout(() => {
            this.setState({cartmsg: '', cartcls: ''});
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState({
              isLoading: false,
              cartmsg: error.response.data.error.message,
              cartcls: 'alert alert-danger text-center',
            });
            setTimeout(() => {
              this.setState({cartmsg: '', cartcls: ''});
            }, 5000);
          }
        }
      });
  };

  render_emoji = (append_id, index, _id) => {
    if ($('#' + append_id).css('display') === 'block') {
      $('.emoji_disable').hide();
      $('.emoji_close_all').hide();
      $('.emoji_show_all').show();
      $('.' + append_id + '_show').show();
      $('.' + append_id + '_close').hide();
    } else {
      $('.emoji_disable').hide();
      $('.emoji_close_all').hide();
      $('.emoji_show_all').show();
      $('#' + append_id).show();
      $('.' + append_id + '_show').hide();
      $('.' + append_id + '_close').show();
      ReactDOM.render(
        <Picker
          theme={$('body').hasClass('dark-theme') ? 'dark' : 'light'}
          title="Pick your emoji"
          emoji="point_up"
          style={{width: 'inherit'}}
          onSelect={(emoji) => this.onchange_emoji(emoji, append_id, index, _id)}
        />,
        document.getElementById(append_id),
      );
    }
  };
  onchange_emoji = (emoji, append_id, index, _id) => {
    if (append_id === 'Auto_txt_inbound') {
      var message_length = $('#get_data_for_inbound').val() + emoji.native;
      var if_check = 300 - message_length.length;
      if (if_check >= 0) {
        if (_id) {
          let AutoTxtArea = [...this.state.AutoTxtArea];
          AutoTxtArea[index] = {text_response: $('#get_data_for_inbound').val() + emoji.native, _id: _id};
          this.setState({AutoTxtArea});
        } else {
          var value = $('#get_data_for_inbound').val();
          var value2 = $('#get_data_for_inbound').val(value + emoji.native);
          value = $('#get_data_for_inbound').val();
          let AutoTxtArea = [...this.state.AutoTxtArea];
          AutoTxtArea[index] = value;
          this.setState({AutoTxtArea});
        }
      }
    } else if (append_id === 'Auto_txt_unsupport') {
      var message_length1 = this.state.unsupportedtext + emoji.native;
      var if_check1 = 300 - message_length1.length;
      if (if_check1 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          unsupportedtext: this.state.unsupportedtext + emoji.native,
        });
      }
    } else if (append_id === 'Auto_txt_inbound_calls') {
      var message_length2 = this.state.LeavAutoVceMsgVle + emoji.native;
      var if_check2 = 300 - message_length2.length;
      if (if_check2 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          LeavAutoVceMsgVle: this.state.LeavAutoVceMsgVle + emoji.native,
        });
      }
    } else if (append_id === 'Auto_txt_voice_greetings') {
      var message_length3 = this.state.TxtVoiceGrtngvlue + emoji.native;
      var if_check3 = 300 - message_length3.length;
      if (if_check3 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          TxtVoiceGrtngvlue: this.state.TxtVoiceGrtngvlue + emoji.native,
        });
      }
    } else if (append_id === 'Auto_txt_birth_greetings') {
      var message_length4 = this.state.birthdyTxtValue + emoji.native;
      var if_check4 = 300 - message_length4.length;
      if (if_check4 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          birthdyTxtValue: this.state.birthdyTxtValue + emoji.native,
        });
      }
    } else if (append_id === 'chat_widgit_greeting') {
      var message_length5 = this.state.chat_grtmsg + emoji.native;
      var if_check5 = 300 - message_length5.length;
      if (if_check5 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          chat_grtmsg: this.state.chat_grtmsg + emoji.native,
        });
      }
    } else if (append_id === 'chat_widgit_reply') {
      var message_length6 = this.state.chat_replymsg + emoji.native;
      var if_check6 = 300 - message_length6.length;
      if (if_check6 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          chat_replymsg: this.state.chat_replymsg + emoji.native,
        });
      }
    } else if (append_id === 'chat_widgit_new_con') {
      var message_length7 = this.state.chat_newcontactmsg + emoji.native;
      var if_check7 = 300 - message_length7.length;
      if (if_check7 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          chat_newcontactmsg: this.state.chat_newcontactmsg + emoji.native,
        });
      }
    } else if (append_id === 'chat_widgit_exist_con') {
      var message_length8 = this.state.chat_existcontactmsg + emoji.native;
      var if_check8 = 300 - message_length8.length;
      if (if_check8 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          chat_existcontactmsg: this.state.chat_existcontactmsg + emoji.native,
        });
      }
    } else if (append_id === 'template_message_text') {
      var message_length9 = this.state.templ_msg + emoji.native;
      var if_check9 = this.state.max_chars_templ_msg - message_length9.length;
      if (if_check9 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          templ_msg: this.state.templ_msg + emoji.native,
          chars_left_templ_msg: this.state.max_chars_templ_msg - message_length9.length,
        });
      }
    } else if (append_id === 'workflow_message') {
      var message_length10 = this.state.workflow_msg + emoji.native;
      var if_check10 = this.state.max_chars_wfmsg - message_length10.length;
      if (if_check10 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          workflow_msg: this.state.workflow_msg + emoji.native,
          chars_left_wfmsg: this.state.max_chars_wfmsg - message_length10.length,
        });
      }
    } else if (append_id === 'QR_code_response') {
      var message_length11 = this.state.qr_message + emoji.native;
      var if_check11 = 300 - message_length11.length;
      if (if_check11 >= 0) {
        // this.setState({unsupportedtext: e.target.value});
        this.setState({
          qr_message: this.state.qr_message + emoji.native,
        });
      }
    }
  };
  hide_emoji = () => {
    $('.emoji_disable').hide();
    $('.emoji_show_all').show();
    $('.emoji_close_all').hide();
  };

  colapsed_attach = (id) => {
    this.setState({cmb_notesedit: false});
    $('.attachment_colapsed').show();
    $('.attachment_colapsed_' + id).hide();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).show();
  };
  expanded_attach = (id) => {
    $('.attachment_colapsed').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).hide();
  };
  colapse_all_attachments = () => {
    $('.attachment_colapsed').show();
    $('.attachment_expanded').hide();
  };

  status_hide = (class_name) => {
    $('.' + class_name).hide();
  };
  validate_notes = (arr) => {
    var arrayLength = arr.length;

    var count = 0;
    if (arr.length !== 0) {
      for (var i = 0; i < arrayLength; i++) {
        if (arr[i].notes_content1) {
          var string = arr[i].notes_content.replace(/(<([^>]+)>)/gi, '');
          if (string.length == 0) {
            count += 1;
          }
        }
      }
    }
    return count;
  };

  enableParaphrase = () => {
    $('.paraphrase_installing').show();
    $('.paraphrase_install').hide();
    const paraphrase_enabling = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'paraphrase',
    };
    axios.post(API_CALL_ENDPOINT + '/business/profile/settings', paraphrase_enabling).then((res) => {
      if (res?.data?.status == 'success') {
        this.getBusinessDetails('paraphrase');
      }
      if (res.data.error) {
      }
    });
  };

  render() {
    console.log(
      'this.state.business_info_array?.business?.fungible_token?.status',
      this.state.business_info_array?.business?.fungible_token?.status,
    );
    console.log(
      'this.state.business_info_array?.business?.fungible_token?.token_available',
      this.state.business_info_array?.business?.fungible_token?.token_available,
    );
    console.log(
      'this.state.business_info_array?.business?.fungible_token?.token_limit',
      this.state.business_info_array?.business?.fungible_token?.token_limit,
    );
    var moment = require('moment');
    const {business_id} = this.state;
    const {run, steps} = this.state;
    return (
      <div className="hold-transition  accent-custom">
        <div className="wrapper">
          <ToastContainer
            transition={Flip}
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Joyride
            callback={this.handleJoyrideCallback}
            steps={this.state.steps}
            continuous={true}
            run={run}
            scrollToFirstStep={true}
            // showProgress={true}
            hideBackButton={true}
            showSkipButton={true}
            styles={{
              options: {
                arrowColor: '#ffffff',
                backgroundColor: '#ffffff',
                // overlayColor: '#1f41378c',
                primaryColor: '#e50038',
                textColor: '#000000',
                beaconSize: 60,
                // width: 900,
                zIndex: 10000,
              },
            }}
            // styles={{
            //   options: {
            //     zIndex: 10000,
            //   },
            // }}
          />
          {/* Navbar */}
          <span onClick={() => this.hide_emoji()}>
            <Header_component
              redirectToPage={this.redirectToPage.bind(this)}
              data={this.update.bind(this)}
              cart_data={this.cart_update.bind(this)}
              cart_access={this.state.packcart_list}
              cart_updated_count={this.state.cart_updated_count}
              trigger={this.without_refresh.bind(this)}
            />
          </span>
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <div className="header_pagetitle d-block d-sm-block d-md-none">
                      <div className="text-center pt-2">
                        <div className="allheader_profile_drpdwn crsr_pntr">
                          <h4 className="d-inline-block">
                            <span className="fmaroon" id="business_list_header">
                              {this.state.business_name1}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>
            <div className="content">
              <div className="container-fluid">
                <div className="row mt-3 position-relative">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                    <div className="card flex-lg-row acctstngs_profstngs_card profilesettings_card shadow-none">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="all_contentflex_div main_content_div app_div">
                          <div className="card w-100 shadow-none">
                            <div className="card-body p-0 mt-3 mb-3">
                              <div className="appsmktplc_installed">
                                <h4 className="text-center fmaroon"> Installed Apps</h4>
                                <div className="apps_marketplace all_contentflex_div pt-4  pb-3 justify-content-center">
                                  {this.state.business_info_array?.qr_codes?.length != undefined &&
                                  this.state.business_info_array?.qr_codes?.length != '0' ? (
                                    <>
                                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                          {this.state.business_info_array?.qr_codes?.length != 0 &&
                                          this.state.business_info_array?.qr_codes?.length != undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                            </div>
                                          ) : (
                                            ''
                                          )}

                                          <div className="card-body box-profile">
                                            <a href="/qrcode">
                                              {' '}
                                              <img
                                                className="img-fluid"
                                                src={qr_code}
                                                alt="User profile picture"
                                              />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">QR Code Marketing App</h5>
                                                <p>
                                                  QR Code is a viable technology to power your proximity marketing...{' '}
                                                  <a href="/qrcode">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 763 Users
                                                </p> */}
                                                {this.state.business_info_array?.qr_codes?.length != undefined &&
                                                this.state.business_info_array?.qr_codes?.length != '0' ? (
                                                  <a
                                                    href="/appsdetails?integrations_list_qrcode=true"
                                                    className="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    GET
                                                  </a>
                                                ) : this.state.business_info_array?.qr_codes?.length == undefined ? (
                                                  ''
                                                ) : (
                                                  <a
                                                    href="/appsdetails?integrations_list_qrcode=true"
                                                    className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Install
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.business_info_array?.business?.nft?.status != '' &&
                                  this.state.business_info_array?.business?.nft?.status != undefined ? (
                                    <>
                                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                          {this.state.business_info_array?.business?.nft?.status == 'requested' &&
                                          this.state.business_info_array?.business?.nft?.status != undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                            </div>
                                          ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                            this.state.business_info_array?.business?.nft?.status != undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                            </div>
                                          ) : (
                                            ''
                                          )}

                                          <div className="card-body box-profile">
                                            <a href="/nftapp">
                                              {' '}
                                              <img className="img-fluid" src={nft} alt="User profile picture" />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">NFT Generator</h5>
                                                <p>
                                                  A NFT or non-fungible token is a new type of digital...{' '}
                                                  <a href="/nftapp">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 158 Users
                                                </p> */}
                                                {this.state.business_info_array?.business?.nft?.status == 'requested' &&
                                                this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                  <a
                                                    href="/nftapp"
                                                    className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Installing
                                                  </a>
                                                ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                                  this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                  <a
                                                    href="/appsdetails?nft_lists=true"
                                                    class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    GET
                                                  </a>
                                                ) : this.state.business_info_array?.business?.nft?.status ==
                                                  undefined ? (
                                                  ''
                                                ) : (
                                                  <a
                                                    href="/appsdetails?nft_lists=true"
                                                    class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Install
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.business_info_array?.business?.fungible_token?.status != '' &&
                                  this.state.business_info_array?.business?.fungible_token?.status != undefined ? (
                                    <>
                                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                          {this.state.business_info_array?.business?.fungible_token?.status ==
                                            'requested' &&
                                          this.state.business_info_array?.business?.fungible_token?.status !=
                                            undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                            </div>
                                          ) : this.state.business_info_array?.business?.fungible_token?.status != '' &&
                                            this.state.business_info_array?.business?.fungible_token?.status !=
                                              undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                          {/* /.card-header */}
                                          <div className="card-body box-profile">
                                            <a href="/ftapp">
                                              {' '}
                                              <img className="img-fluid" src={ft} alt="User profile picture" />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">Fungible Token Creator</h5>
                                                <p>
                                                  Tokens in a blockchain are assets that allow...{' '}
                                                  <a href="/ftapp">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 325 Users
                                                </p> */}
                                                {this.state.business_info_array?.business?.fungible_token?.status ==
                                                  'requested' &&
                                                this.state.business_info_array?.business?.fungible_token?.status !=
                                                  undefined ? (
                                                  <a
                                                    href="/appsdetails?ft_lists=true"
                                                    className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Installing
                                                  </a>
                                                ) : this.state.business_info_array?.business?.fungible_token?.status !=
                                                    '' &&
                                                  this.state.business_info_array?.business?.fungible_token?.status !=
                                                    undefined ? (
                                                  <a
                                                    href="/appsdetails?ft_lists=true"
                                                    class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    GET
                                                  </a>
                                                ) : this.state.business_info_array?.business?.fungible_token?.status ==
                                                  undefined ? (
                                                  ''
                                                ) : (
                                                  <a
                                                    href="/appsdetails?ft_lists=true"
                                                    class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Install
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {/* /.col */}
                                  {this.state.business_info_array?.business?.paraphrase == 'enabled' ? (
                                    <>
                                      <div
                                        className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 para_phrase_div"
                                        id="para_phrase_div">
                                        <div className="card bx_shadow_sm">
                                          <div className="ribbon-wrapper ribbon-lg">
                                            <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                          </div>
                                          {/* /.card-header */}
                                          <div className="card-body box-profile">
                                            <a href="/paraphraseapp">
                                              {' '}
                                              <img
                                                className="img-fluid"
                                                src={paraphrase}
                                                alt="User profile picture"
                                              />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">The Redtie Paraphraser</h5>
                                                <p>
                                                  The Redtie Paraphraser is a fast and easy-to-use...{' '}
                                                  <a href="/paraphraseapp">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 354 Users
                                                    </p> */}
                                                <a
                                                  href="/board?compose_messages=true"
                                                  class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                  GET
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                              <div className="appsmktplc_available">
                                <h4 className="text-center fmaroon mt-5">Available Apps</h4>
                                <div className="apps_marketplace all_contentflex_div pt-4  pb-3 justify-content-center">
                                  {this.state.business_info_array?.business?.paraphrase == 'disabled' ||
                                  !this.state.business_info_array?.business?.paraphrase ? (
                                    <>
                                      <div
                                        className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 para_phrase_div"
                                        id="para_phrase_div">
                                        <div className="card bx_shadow_sm">
                                          {/* /.card-header */}
                                          <div className="card-body box-profile">
                                            <a href="/paraphraseapp">
                                              {' '}
                                              <img
                                                className="img-fluid"
                                                src={paraphrase}
                                                alt="User profile picture"
                                              />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">The Redtie Paraphraser</h5>
                                                <p>
                                                  The Redtie Paraphraser is a fast and easy-to-use...{' '}
                                                  <a href="/paraphraseapp">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 354 Users
                                                    </p> */}
                                                <a
                                                  onClick={() => this.enableParaphrase()}
                                                  href="javascript:void(0)"
                                                  className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right paraphrase_install">
                                                  {' '}
                                                  Install
                                                </a>
                                                <a
                                                  style={{display: 'none'}}
                                                  href="javascript:void(0)"
                                                  className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right paraphrase_installing">
                                                  {' '}
                                                  Installing
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.business_info_array?.qr_codes?.length != undefined &&
                                  this.state.business_info_array?.qr_codes?.length == '0' ? (
                                    <>
                                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                          {this.state.business_info_array?.qr_codes?.length != 0 &&
                                          this.state.business_info_array?.qr_codes?.length != undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                            </div>
                                          ) : (
                                            ''
                                          )}

                                          <div className="card-body box-profile">
                                            <a href="/qrcode">
                                              {' '}
                                              <img
                                                className="img-fluid"
                                                src={qr_code}
                                                alt="User profile picture"
                                              />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">QR Code Marketing App</h5>
                                                <p>
                                                  QR Code is a viable technology to power your proximity marketing...{' '}
                                                  <a href="/qrcode">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 763 Users
                                                </p> */}
                                                {this.state.business_info_array?.qr_codes?.length != undefined &&
                                                this.state.business_info_array?.qr_codes?.length != '0' ? (
                                                  <a
                                                    href="/appsdetails?integrations_list_qrcode=true"
                                                    className="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    GET
                                                  </a>
                                                ) : this.state.business_info_array?.qr_codes?.length == undefined ? (
                                                  ''
                                                ) : (
                                                  <a
                                                    href="/appsdetails?integrations_list_qrcode=true"
                                                    className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Install
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {(this.state.business_info_array?.business?.nft?.status == 'requested' &&
                                    this.state.business_info_array?.business?.nft?.status != undefined) ||
                                  (this.state.business_info_array?.business?.nft?.status == '' &&
                                    this.state.business_info_array?.business?.nft?.status != undefined) ? (
                                    <>
                                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                          {this.state.business_info_array?.business?.nft?.status == 'requested' &&
                                          this.state.business_info_array?.business?.nft?.status != undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                            </div>
                                          ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                            this.state.business_info_array?.business?.nft?.status != undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                            </div>
                                          ) : (
                                            ''
                                          )}

                                          <div className="card-body box-profile">
                                            <a href="/nftapp">
                                              {' '}
                                              <img className="img-fluid" src={nft} alt="User profile picture" />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">NFT Generator</h5>
                                                <p>
                                                  A NFT or non-fungible token is a new type of digital...{' '}
                                                  <a href="/nftapp">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 158 Users
                                                </p> */}
                                                {this.state.business_info_array?.business?.nft?.status == 'requested' &&
                                                this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                  <a
                                                    href="/nftapp"
                                                    className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Installing
                                                  </a>
                                                ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                                  this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                  <a
                                                    href="/appsdetails?nft_lists=true"
                                                    class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    GET
                                                  </a>
                                                ) : this.state.business_info_array?.business?.nft?.status ==
                                                  undefined ? (
                                                  ''
                                                ) : (
                                                  <a
                                                    href="/appsdetails?nft_lists=true"
                                                    class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Install
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {(this.state.business_info_array?.business?.fungible_token?.status == '' &&
                                    this.state.business_info_array?.business?.fungible_token?.status != undefined) ||
                                  (this.state.business_info_array?.business?.fungible_token?.status == 'requested' &&
                                    this.state.business_info_array?.business?.fungible_token?.status != undefined) ? (
                                    <>
                                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                          {this.state.business_info_array?.business?.fungible_token?.status ==
                                            'requested' &&
                                          this.state.business_info_array?.business?.fungible_token?.status !=
                                            undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                            </div>
                                          ) : this.state.business_info_array?.business?.fungible_token?.status != '' &&
                                            this.state.business_info_array?.business?.fungible_token?.status !=
                                              undefined ? (
                                            <div className="ribbon-wrapper ribbon-lg">
                                              <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                          {/* /.card-header */}
                                          <div className="card-body box-profile">
                                            <a href="/ftapp">
                                              {' '}
                                              <img className="img-fluid" src={ft} alt="User profile picture" />{' '}
                                            </a>
                                          </div>
                                          {/* /.card-body */}
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">Fungible Token Creator</h5>
                                                <p>
                                                  Tokens in a blockchain are assets that allow...{' '}
                                                  <a href="/ftapp">Know more</a>
                                                </p>
                                                {/* <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 325 Users
                                                </p> */}
                                                {this.state.business_info_array?.business?.fungible_token?.status ==
                                                  'requested' &&
                                                this.state.business_info_array?.business?.fungible_token?.status !=
                                                  undefined ? (
                                                  <a
                                                    href="/appsdetails?ft_lists=true"
                                                    className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Installing
                                                  </a>
                                                ) : this.state.business_info_array?.business?.fungible_token?.status !=
                                                    '' &&
                                                  this.state.business_info_array?.business?.fungible_token?.status !=
                                                    undefined ? (
                                                  <a
                                                    href="/appsdetails?ft_lists=true"
                                                    class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                    GET
                                                  </a>
                                                ) : this.state.business_info_array?.business?.fungible_token?.status ==
                                                  undefined ? (
                                                  ''
                                                ) : (
                                                  <a
                                                    href="/appsdetails?ft_lists=true"
                                                    class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                    {' '}
                                                    Install
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                        {/* /.card */}
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <div className="card bx_shadow_sm">
                                      <div className="card-body box-profile">
                                        <a href="/androidapp">
                                          {' '}
                                          <img className="img-fluid" src={android} alt="User profile picture" />{' '}
                                        </a>
                                      </div>
                                      <div className="card-footer bg-transparent border-top">
                                        <div className="small-box bdr_sad_none">
                                          <div className="inner">
                                            <h5 className="pb-2">Android Application</h5>
                                            <p>
                                              App available in Google play store... <a href="/androidapp">Know more</a>
                                            </p>
                                            <a
                                              href="https://play.google.com/store/apps/details?id=com.ephron.redtie.admin"
                                              className="btn app_instal crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                              {' '}
                                              Install
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <div className="card bx_shadow_sm">
                                      <div className="card-body box-profile">
                                        <a href="/iosapp">
                                          {' '}
                                          <img className="img-fluid" src={app_store} alt="User profile picture" />{' '}
                                        </a>
                                      </div>
                                      <div className="card-footer bg-transparent border-top">
                                        <div className="small-box bdr_sad_none">
                                          <div className="inner">
                                            <h5 className="pb-2">iOS Application</h5>
                                            <p>
                                              App available in App store... <a href="/iosapp">Know more</a>
                                            </p>
                                            <a
                                              a
                                              href="https://apps.apple.com/us/app/redtie-biz/id1543530724"
                                              className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                              {' '}
                                              Install
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="all_contentflex_div main_content_div qr_code_div" style={{display: 'none'}}>
                          <div className="card w-100 bx_shadow_sm">
                            {this.state.business_info_array?.business?.plan_id?.plan_name === 'Trial' ||
                            this.state.business_info_array?.business?.plan_id?.plan_name === 'Free' ? (
                              <div className="card-body">
                                <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-3 pb-2 mt-5 mb-5">
                                  <div className="f_sz16 w-100 d-inline-block pb-2">
                                    To utilize <span className="fmaroon font-weight-semibold">Qr Codes</span>, please
                                    upgrade.
                                  </div>

                                  <a
                                    class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm"
                                    onClick={() =>
                                      this.upgradeWorkspace(
                                        '/create-profile?bid=' + this.state.business_info_array?.business?._id,
                                      )
                                    }>
                                    Upgrade
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <>
                                <small className="text-red d-inline-block mt-1 ml-2">
                                  <b>
                                    Note: Create QR code campaigns and send text messages to the users who scan your QR
                                    code.
                                  </b>
                                </small>
                                <div className="qrcodecreate_div">
                                  <div className="card w-100 shadow-none">
                                    <div className="form-horizontal formbiglbl">
                                      <div className="card-body">
                                        <div className="form-group row mt-5 mb-4">
                                          <h5 className="col-12 col-sm-12 text-center fmaroon">QR Codes</h5>
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="text-center mt-3">
                                              <span
                                                className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm qrcodecreate_clk"
                                                onClick={this.qrcodecreate_clk}>
                                                Create
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="qrcodelist_div" style={{display: 'none'}}>
                                  <div className="card w-100 shadow-none">
                                    <div className="card-header d-flex flex-row flex-wrap">
                                      <div className="col-6 col-sm-6 col-md-6 pl-0">
                                        <h5 className="fmaroon pt-2">QR Codes</h5>
                                      </div>
                                      <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                        <a
                                          className="btn btn-black crsr_pntr btn-sm mt-1 qrcodecreate_clk"
                                          onClick={this.qrcodecreate_clk}>
                                          Add New
                                        </a>
                                      </div>
                                    </div>
                                    <div className="card-body p-0">
                                      <div className={this.state.QRGrtcls}>{this.state.QRGrtMsg}</div>
                                      {this.state.qr_code_list.map((qr_code_individual, index) => {
                                        return (
                                          <>
                                            <div>
                                              <div className="w-100 d-flex flex-row flex-wrap mb-3">
                                                <div className="col-12 col-sm-12 col-md-9 col-lg-10 pl-0">
                                                  <div className="d-table qrcodedtls">
                                                    <div className="d-table-row">
                                                      <div className="d-table-cell align-middle">{index + 1}.</div>
                                                      <div className="d-table-cell">
                                                        <div className="d-inline-block f_sz14 w-100">
                                                          <span className="d-inline-block w-100 font-weight-semibold pb-2">
                                                            {qr_code_individual.qr_title}
                                                          </span>
                                                          <span className="d-inline-block w-100 pb-2">
                                                            <i className="far fa-comment-alt mr-3 postop_2 forange f_sz15" />
                                                            {qr_code_individual.auto_response}
                                                          </span>
                                                          <span className="d-inline-block w-100">
                                                            <i className="fas fa-tag mr-3 img-size-16"></i>
                                                            {qr_code_individual?.tags?.map((tags, index) => {
                                                              return (
                                                                <span className="badge badge-info f_sz12 mr-1">
                                                                  {tags?.tag_name}
                                                                </span>
                                                              );
                                                            })}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                                                  <div className="f_sz16 mt-2 mt-md-5 text-right text-md-center mr-3">
                                                    <span className="d-inline-block fmaroon crsr_pntr">
                                                      <a
                                                        data-toggle="modal"
                                                        data-target="#modal_qrcodedownload"
                                                        data-backdrop="static"
                                                        className="crsr_pntrmt-1 mr-2"
                                                        onClick={() => {
                                                          this.view_presignedurl(qr_code_individual.qr_code_image);
                                                        }}>
                                                        <i className="far fa-eye" />
                                                      </a>
                                                    </span>
                                                    <span
                                                      onClick={() => {
                                                        this.qrcodeonMessageEdit(qr_code_individual);
                                                      }}
                                                      className="d-inline-block fmaroon crsr_pntr mr-3">
                                                      <i className="fas fa-pencil-alt" />
                                                    </span>

                                                    <span className="d-inline-block fmaroon qrcodehistory_tgl1 crsr_pntr mr-3">
                                                      <i
                                                        className="fas fa-history"
                                                        onClick={() => {
                                                          this.qrcodehistory_tgl1(qr_code_individual);
                                                        }}
                                                      />
                                                    </span>
                                                    <span className="d-inline-block fmaroon crsr_pntr">
                                                      <i
                                                        className="far fa-trash-alt"
                                                        onClick={() => {
                                                          this.qrcode_delete(qr_code_individual);
                                                        }}
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                id={'qr_his_' + qr_code_individual._id}
                                                className="qrcodehistory_tgldiv1"
                                                style={{display: 'none'}}>
                                                <div className="all_contentflex_div">
                                                  <div className="card w-100 mt-2">
                                                    <div className="card-header">
                                                      <h5 className="fmaroon f_sz15">History</h5>
                                                    </div>
                                                    <div className="card-body p-0">
                                                      {this.state?.qr_code_history ? (
                                                        <div className="table-responsive">
                                                          <table
                                                            className="table table-hover tbl_valignmdle tbl_qrcodehistory border-bottom"
                                                            id="qrcodehistory_tbl1"
                                                            cellPadding={0}
                                                            cellSpacing={0}
                                                            style={{width: '100%'}}>
                                                            <thead>
                                                              <tr>
                                                                <th>Date Time</th>
                                                                <th>Location</th>
                                                                <th>Contact</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {this.state?.qr_code_history.map((history, index) => {
                                                                return (
                                                                  <tr>
                                                                    <td>
                                                                      <span className="time">
                                                                        {moment(history?.created_date).format(
                                                                          'MMMM DD,YYYY',
                                                                        )}
                                                                        <span className="ml-2">
                                                                          {moment(history?.created_date).format(
                                                                            'hh:mma',
                                                                          )}
                                                                        </span>
                                                                      </span>
                                                                    </td>
                                                                    <td>{history?.location}</td>
                                                                    <td>
                                                                      {history?.contacts?.phone_number[0]
                                                                        .toString()
                                                                        .substring(1, 11)
                                                                        .replace(/[^0-9]/g, '')
                                                                        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                        .trim()}
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              })}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      ) : (
                                                        <h5 className="fmaroon f_sz15 text-center">
                                                          ---No Records Found Yet---
                                                        </h5>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className="addqrcode_div" style={{display: 'none'}}>
                                  <div className="card w-100 shadow-none">
                                    <div
                                      className="card-header d-flex flex-row flex-wrap pb-0 pt-3"
                                      onClick={() => {
                                        this.hide_emoji();
                                      }}>
                                      <h5>
                                        <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">
                                          Create QR Code
                                        </span>
                                        <span className="addqrcode_close mr-2 mt-1" onClick={this.addqrcode_close}>
                                          <i className="fas fa-times" onClcik={this.addqrcode_close} />
                                        </span>
                                      </h5>
                                    </div>
                                    <div className="form-horizontal formbiglbl">
                                      <div className="card-body px-0">
                                        {/*Element to be Looped*/}
                                        <div className={this.state.QRGrtcls}>{this.state.QRGrtMsg}</div>
                                        <div>
                                          <div
                                            className="form-group mt-3 mb-4"
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter the Title"
                                                value={this.state.qr_title}
                                                onChange={(e) => {
                                                  this.setState({qr_title: e.target.value});
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            className="form-group mt-3 mb-4"
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter the Description"
                                                value={this.state.qr_description}
                                                onChange={(e) => {
                                                  this.setState({qr_description: e.target.value});
                                                }}
                                              />
                                            </div>
                                          </div>

                                          <div className="form-group mt-3 mb-4">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <textarea
                                                className="form-control textarea_hgt100 txtarea_resizenone"
                                                placeholder="Enter Auto Response Message"
                                                value={this.state.qr_message}
                                                maxLength={300}
                                                onChange={(e) => {
                                                  this.setState({qr_message: e.target.value});
                                                }}
                                                onClick={() => {
                                                  this.hide_emoji();
                                                }}
                                              />
                                              <div className="col-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap">
                                                <div
                                                  className="col-4 col-sm-4 col-md-4 pl-0 QR_code_response_show emoji_show_all"
                                                  onClick={() => {
                                                    this.render_emoji('QR_code_response');
                                                  }}>
                                                  <i className="far fa-smile flightgrey3 f_sz18 mt-1 crsr_pntr position-relative" />
                                                </div>
                                                <div
                                                  className="col-4 col-sm-4 col-md-4 pl-0 QR_code_response_close emoji_close_all"
                                                  style={{display: 'none'}}
                                                  onClick={() => {
                                                    this.render_emoji('QR_code_response');
                                                  }}>
                                                  <i
                                                    className={
                                                      'fas fa-times flightgrey3 f_sz18 mt-1 crsr_pntr position-relative'
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap position-relative">
                                                <span
                                                  className="flex-row flex-wrap emoji_position emoji_disable"
                                                  style={{
                                                    display: 'none',
                                                    position: 'inherit',
                                                    width: 'inherit',
                                                    zIndex: '10',
                                                  }}
                                                  id="QR_code_response"></span>
                                              </div>
                                            </div>
                                            <div
                                              className="sendmessage_btnsdiv"
                                              onClick={() => {
                                                this.hide_emoji();
                                              }}>
                                              <div className="dropdown attachment_drpdwn d-inline-block">
                                                <span data-toggle="dropdown" className="sendmessage_btnsspan">
                                                  <span className="btn btn-lg btn-black crsr_pntr">
                                                    <i className="fas fa-paperclip" />
                                                  </span>
                                                </span>
                                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                                  <div className="dropdown-item">
                                                    <div className="overallattachment_div">
                                                      <div
                                                        className="overallattach_item qrcodesendmsgattach_clk"
                                                        onClick={
                                                          this.state.savingprogress === '...'
                                                            ? null
                                                            : this.saveNotes2_cmb
                                                        }>
                                                        <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                          <i className="far fa-sticky-note" />
                                                        </div>
                                                        <div className="fgrey">Notes</div>
                                                      </div>
                                                      <div className="overallattach_item qrcodesendmsgattach_clk">
                                                        <label for="file-input">
                                                          <div
                                                            className="overallattach_icon bg_documents bx_shadow_sm"
                                                            id="fdupld">
                                                            <i className="far fa-file-alt" />
                                                            <input
                                                              id="file-input"
                                                              type="file"
                                                              name="DcfilNm"
                                                              onChange={this.fileGetDetails_cmb}
                                                              // multiple
                                                            />
                                                          </div>
                                                        </label>
                                                        <div className="fgrey">Documents</div>
                                                      </div>
                                                      <div className="overallattach_item qrcodesendmsgattach_clk">
                                                        <label htmlFor="file-input3">
                                                          <div
                                                            className="overallattach_icon bg_gallery bx_shadow_sm"
                                                            id="fdupld_2">
                                                            <i
                                                              className="far fa-image"
                                                              data-place="bottom"
                                                              data-tip="Images"
                                                            />
                                                            <input
                                                              id="file-input3"
                                                              type="file"
                                                              name="DcfilNm"
                                                              accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,video/mpeg,video/quicktime,video/x-msvideo,video/*,image/*,video/quicktime,video/mp4,.mov,.mkv"
                                                              onChange={(event) =>
                                                                this.fileGetDetails_cmb_images(event)
                                                              }
                                                              // multiple
                                                            />
                                                          </div>
                                                          {/* <ReactTooltip /> */}
                                                        </label>
                                                        <div className="fgrey">Images</div>
                                                      </div>
                                                      <div
                                                        className="overallattach_item qrcodesendmsgattach_clk"
                                                        onClick={this.sendmsgattach_clkAudio2_cmb}>
                                                        <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                          <i className="fas fa-microphone-alt" />
                                                        </div>
                                                        <div className="fgrey">Audio</div>
                                                      </div>
                                                      <div
                                                        className="overallattach_item qrcodesendmsgattach_clk"
                                                        onClick={this.sendmsgattach_clkVideo2_cmb}>
                                                        <div className="overallattach_icon bg_video bx_shadow_sm">
                                                          <i className="fas fa-film" />
                                                        </div>
                                                        <div className="fgrey">Video</div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="sendmsg_attachmentdiv_sm sendmsg_attachmentdiv2_cmb"
                                            style={{display: 'none'}}
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}>
                                            <div className="row position-relative">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="attachment_timelinediv">
                                                  {/* The time line */}
                                                  <div className="timeline">
                                                    {/* timeline item */}
                                                    <div
                                                      style={{display: 'none'}}
                                                      className="sendmsgattach_clkAudio2_cmb">
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell align-middle">
                                                                <span className="ml-2">
                                                                  <span className="crsr_pntr">
                                                                    <i
                                                                      className="fas fa-record-vinyl fa-2x fbred"
                                                                      onClick={this.audio_start_cmb}
                                                                    />
                                                                    <span
                                                                      className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                      onClick={this.audio_start_cmb}>
                                                                      Start Recording
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            onClick={this.audioLclDelte_cmb}>
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}} className="audioRecordLoading_cmb">
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell align-middle">
                                                                <span>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i
                                                                        className="far fa-stop-circle f_sz30 fbred"
                                                                        onClick={this.audio_stop_cmb}></i>
                                                                      <span
                                                                        className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                        onClick={this.audio_stop_cmb}>
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24"></i>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div
                                                      style={{display: 'none'}}
                                                      className="sendmsgattach_clkNotes2_cmb">
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <textarea
                                                            className="form-control notesbox textarea_hgt100 txtarea_resizenone"
                                                            placeholder="Enter the notes"
                                                            value={this.state.notesValue2_cmb}
                                                            onChange={this.notesLetter2_cmb}
                                                            id="notestypearea"
                                                          />
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a
                                                            className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                            onClick={this.saveNotes2_cmb}>
                                                            Save
                                                          </a>
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            onClick={this.notesLclDelte_cmb}>
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{display: 'none'}}
                                                      className="sendmsgattach_clkVideo2_cmb">
                                                      <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell align-middle">
                                                                <div className="col-11 col-sm-12 col-md-12 col-lg-12 videohgt">
                                                                  <VideoRecorder
                                                                    replayVideoAutoplayAndLoopOff={true}
                                                                    timeLimit={120000}
                                                                    onRecordingComplete={(
                                                                      videoBlob,
                                                                      thumbnailBlob,
                                                                      videoUrl,
                                                                    ) => {
                                                                      $('.sendmsgattach_clkVideo2_cmb').hide();
                                                                      $('.sendmsg_attachmentdiv2_cmb').hide();

                                                                      $('.attachLoading_cmb').show();
                                                                      $('.attchLstShow_cmb').show();
                                                                      this.setState({
                                                                        lodingicon_cmb: 'fas fa-film bg_video',
                                                                      });

                                                                      // Do something with the video...
                                                                      console.log(
                                                                        'videoBloburl',
                                                                        window.URL.createObjectURL(videoBlob),
                                                                      );
                                                                      console.log('videoBlob', videoBlob);
                                                                      console.log('thumbnailBlob', thumbnailBlob);
                                                                      console.log('videoUrl', videoUrl);
                                                                      var reader = new FileReader();
                                                                      reader.readAsDataURL(videoBlob);
                                                                      reader.onloadend = function () {
                                                                        var base64data = reader.result;
                                                                        // console.log(base64data);
                                                                      };
                                                                      var filename = new Date().toISOString();
                                                                      const data = new FormData();
                                                                      var mp4file = new File(
                                                                        [videoBlob],
                                                                        filename + '.mp4',
                                                                        {
                                                                          type: 'video/mp4',
                                                                          lastModified: new Date(),
                                                                        },
                                                                      );

                                                                      data.append(
                                                                        'apikey',
                                                                        'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                                                                      );
                                                                      data.append('secret', 'R1eqD2twI3E4');
                                                                      data.append('user_id', this.state.user_id);
                                                                      data.append(
                                                                        'business_id',
                                                                        this.state.business_id,
                                                                      );
                                                                      data.append('file', videoBlob, filename);
                                                                      data.append('reference', 'text');
                                                                      // data.append('type', 'video');

                                                                      axios
                                                                        .post(API_CALL_ENDPOINT + '/file/upload', data)
                                                                        .then((res) => {
                                                                          // console.log(res);
                                                                          // console.log(res.data.message);
                                                                          // console.log(res.data?.result?.files);

                                                                          if (res.data.status == 'success') {
                                                                            var file_url = '';
                                                                            var file_path = '';
                                                                            var file_url2 = '';
                                                                            var second_file_path = '';
                                                                            var file_url3 = '';
                                                                            var file_url4 = '';
                                                                            var file_id = '';
                                                                            for (var i in res.data.result.files) {
                                                                              console.log(
                                                                                res.data.result.files[i].file_url,
                                                                              );
                                                                              file_url +=
                                                                                res.data.result.files[i].file_url;
                                                                              file_path +=
                                                                                res.data.result.files[i].file_path;
                                                                              file_url2 +=
                                                                                res.data.result.files[i]
                                                                                  .second_file_url;
                                                                              second_file_path +=
                                                                                res.data.result.files[i]
                                                                                  .second_file_path;
                                                                              file_url3 +=
                                                                                res.data.result.files[i].third_file_url;
                                                                              file_url4 +=
                                                                                res.data.result.files[i]
                                                                                  .fourth_file_url;
                                                                              file_id += res.data.result.files[i]._id;
                                                                              var joined_videoId =
                                                                                this.state.attchmnt_videoId_cmb.concat(
                                                                                  res.data.result.files[i]._id,
                                                                                );
                                                                              this.setState({
                                                                                attchmnt_videoId_cmb: joined_videoId,
                                                                              });
                                                                            }
                                                                            const atchId_recrd =
                                                                              res.data.result.files[0]._id;
                                                                            console.log(atchId_recrd);

                                                                            setTimeout(() => {
                                                                              console.log(this.state.atcharryDet_cmb);
                                                                            }, 150);

                                                                            $('.attachLoading_cmb').hide();
                                                                            $('#attachLoading_cmb_video').hide();

                                                                            const video_details = {
                                                                              apikey: API_KEY,
                                                                              secret: API_SECRET,
                                                                              user_id: this.state.user_id,
                                                                              business_id: this.state.business_id,
                                                                              attachment: atchId_recrd,
                                                                              attachment_type: 'video',
                                                                              unique_id:
                                                                                this.state.message_edit === false
                                                                                  ? Rnunique_id_cmb
                                                                                  : this.state.Rnunique_id_cmb.append
                                                                                  ? this.state.Rnunique_id_cmb
                                                                                  : Rnunique_id_cmb,
                                                                              order:
                                                                                '' +
                                                                                this.state.attachOrderIncrs_cmb +
                                                                                '',
                                                                            };

                                                                            axios
                                                                              .post(
                                                                                API_CALL_ENDPOINT +
                                                                                  '/text/attachment/create',
                                                                                video_details,
                                                                              )
                                                                              .then((res) => {
                                                                                console.log(res);
                                                                                console.log(res.data.message);

                                                                                if (res.data.status == 'success') {
                                                                                  this.setState({
                                                                                    messageAttachd_cmb: true,
                                                                                  });
                                                                                  this.setState({
                                                                                    atcharryDet_cmb: [
                                                                                      {
                                                                                        file_path: file_path,
                                                                                        second_file_path:
                                                                                          second_file_path,
                                                                                        video_id: file_id,
                                                                                        video_url: file_url,
                                                                                        video_url2: file_url2,
                                                                                        video_url3: file_url3,
                                                                                        video_url4: file_url4,
                                                                                        attach_id: res.data.result._id,
                                                                                      },
                                                                                      ...this.state.atcharryDet_cmb,
                                                                                    ],
                                                                                  });
                                                                                  var OrderIncr =
                                                                                    this.state.attachOrderIncrs_cmb +
                                                                                    parseInt(1);
                                                                                  this.setState({
                                                                                    attachOrderIncrs_cmb: OrderIncr,
                                                                                  });
                                                                                }
                                                                                if (res.data.error) {
                                                                                }
                                                                              });

                                                                            console.log(file_url);
                                                                          }
                                                                        });
                                                                    }}
                                                                    setVideo={(blob) => {
                                                                      let blobUrl = URL.createObjectURL(blob);
                                                                      console.log(blobUrl);
                                                                      // this.setState({ captureVideoUrl: blobUrl })
                                                                    }}
                                                                  />
                                                                  {/* </div> */}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            onClick={this.videoLclDelte_cmb}>
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div style={{display: 'none'}}>
                                                      <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell w-70 align-top">
                                                                <span className="attachrecord_icon bg_video crsr_pntr">
                                                                  <i className="fas fa-film" />
                                                                </span>
                                                              </div>
                                                              <div className="d-table-cell align-middle">
                                                                <div className="f_sz16">
                                                                  <span>
                                                                    <i className="fas fa-spinner fa-spin f_sz24" />
                                                                  </span>
                                                                  <span
                                                                    className="ml-2"
                                                                    style={{
                                                                      display: 'none',
                                                                    }}>
                                                                    0.10
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                                defaultValue={10}
                                                                disabled
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    <div id="attachLoading_cmb_video">
                                                      <i
                                                        className="fas fa-circle bg-gray"
                                                        style={{fontSize: 'x-small'}}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="attchLstShow_cmb"
                                            style={{display: 'none'}}
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                                              <div className="attachment_timelinediv">
                                                <div className="timeline" id="timelineprepend">
                                                  <div
                                                    id="attachLoading_cmb"
                                                    className="attachLoading_cmb"
                                                    style={{display: 'none'}}>
                                                    <i
                                                      className={this.state.lodingicon_cmb + ' attachtype_timelineicon'}
                                                    />
                                                    <div className="timeline-item">
                                                      <div className="timeline-body">
                                                        <div className="attachmentbody_div d-table">
                                                          <span>
                                                            <span className="d-inline-block">
                                                              <span>
                                                                <i className="fas fa-spinner fa-spin f_sz24 mr-2" />
                                                              </span>
                                                              <span className="postop_5up">
                                                                This might take few mins, please hold on while we
                                                                process your attachment
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="timeline-footer"></div>
                                                    </div>
                                                  </div>
                                                  {this.state.atcharryDet_cmb.map((attachment, index) => {
                                                    return attachment.notes_content1 ? (
                                                      <>
                                                        <div
                                                          className={'editor_cmb_' + attachment.attach_id}
                                                          style={{display: 'none'}}>
                                                          <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                          <div className="timeline-item ">
                                                            <div
                                                              className={
                                                                'timeline-body attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }
                                                              style={{wordWrap: 'break-word'}}>
                                                              <div className="attachmentbody_div">
                                                                <CKEditor
                                                                  editor={ClassicEditor}
                                                                  data={this.state.notesValue2_cmb}
                                                                  id="mytext"
                                                                  config={editorConfiguration}
                                                                  onReady={(editor) => {
                                                                    // You can store the "editor" and use when it is needed.
                                                                    // console.log( 'Editor is ready to use!', editor );
                                                                  }}
                                                                  onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    // console.log( { event, editor, data } );
                                                                    // if (data.length <= 1200) {
                                                                    this.setState({
                                                                      notesValue2_cmb: data,
                                                                      chars_left_notes_cmb:
                                                                        this.state.max_chars_notes_cmb - data.length,
                                                                    });
                                                                    // }
                                                                  }}
                                                                  onBlur={(event, editor) => {
                                                                    // console.log( 'Blur.', editor );
                                                                  }}
                                                                  onFocus={(event, editor) => {
                                                                    // console.log( 'Focus.', editor );
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                'timeline-footer attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }>
                                                              <a
                                                                className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                                onClick={() => {
                                                                  this.updateNotes2_cmb();
                                                                  this.expanded_attach(attachment.attach_id);
                                                                }}>
                                                                {/* {this.state.savingprogress === "" ? "Save" : this.state.savingprogress} */}
                                                                Save
                                                              </a>
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                onClick={() =>
                                                                  this.notesAtchDelte_cmb_edit(attachment.attach_id)
                                                                }>
                                                                Delete
                                                              </a>
                                                              <a
                                                                className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                onClick={() => {
                                                                  this.expanded_attach(attachment.attach_id);
                                                                }}>
                                                                <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-item ">
                                                            <div
                                                              className={
                                                                'timeline-body attachment_colapsed attachment_colapsed_' +
                                                                attachment.attach_id
                                                              }
                                                              style={{display: 'none'}}>
                                                              <div className="attachmentbody_div">
                                                                Notes Attached
                                                                <a
                                                                  className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                  onClick={() => {
                                                                    this.colapsed_attach(attachment.attach_id);
                                                                    this.oneditnotecmb(attachment);
                                                                  }}>
                                                                  <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div
                                                          data-id={index}
                                                          key={index}
                                                          className={
                                                            'notecontent_cmb_show notecontent_cmb_' +
                                                            attachment.attach_id
                                                          }>
                                                          <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                          <div className="timeline-item ">
                                                            <div
                                                              className={
                                                                'timeline-body attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }
                                                              onClick={() => {
                                                                this.oneditnotecmb(attachment);
                                                              }}
                                                              style={{wordWrap: 'break-word'}}>
                                                              <div className="attachmentbody_div">
                                                                <Linkify
                                                                  componentDecorator={(
                                                                    decoratedHref,
                                                                    decoratedText,
                                                                    key,
                                                                  ) => (
                                                                    <a target="blank" href={decoratedHref} key={key}>
                                                                      {decoratedText}
                                                                    </a>
                                                                  )}>
                                                                  <div
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: attachment.notes_content,
                                                                    }}
                                                                  />
                                                                </Linkify>
                                                              </div>
                                                            </div>

                                                            <div
                                                              className={
                                                                'timeline-footer attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }>
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                id={attachment.attach_id}
                                                                onClick={this.notesAtchDelte_cmb}>
                                                                Delete
                                                              </a>
                                                              <a
                                                                className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                onClick={() => {
                                                                  this.expanded_attach(attachment.attach_id);
                                                                }}>
                                                                <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-item ">
                                                            <div
                                                              className={
                                                                'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                attachment.attach_id
                                                              }
                                                              style={{display: 'none'}}>
                                                              <div className="attachmentbody_div">
                                                                Notes Attached
                                                                <a
                                                                  className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                  onClick={() => {
                                                                    this.colapsed_attach(attachment.attach_id);
                                                                    this.oneditnotecmb(attachment);
                                                                  }}>
                                                                  <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                </a>
                                                                {/* </span> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : attachment.documents ? (
                                                      attachment.documents.length > 0 ? (
                                                        <div data-id={index} key={index}>
                                                          <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                          <div className="timeline-item ">
                                                            {/* <span className={"attachment_expanded attachment_expanded_"+attachment.attach_id}> */}
                                                            <div
                                                              className={
                                                                'timeline-body attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }>
                                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                <span className="font-weight-bold">Documents:</span>
                                                                <br />

                                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                  {attachment.documents.map((documentList, index) => {
                                                                    var fileType = documentList.file_name.split('.');
                                                                    return (
                                                                      <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                        <a
                                                                          onClick={() =>
                                                                            this.document_read(documentList)
                                                                          }>
                                                                          {documentList?.thumbnail_file_url ? (
                                                                            <div className="attachdoc_thumb">
                                                                              <img
                                                                                src={documentList?.thumbnail_file_url}
                                                                                border={0}
                                                                              />
                                                                            </div>
                                                                          ) : (
                                                                            <div className="attachdoc_type text-center">
                                                                              <i className="far fa-file-alt flightgrey1"></i>
                                                                            </div>
                                                                          )}

                                                                          <div className="attachdoc_filenmesze py-1 px-2">
                                                                            <table
                                                                              width="100%"
                                                                              cellPadding={0}
                                                                              cellSpacing={0}>
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td>
                                                                                    {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                    {fileType[fileType.length - 1] ===
                                                                                    'pdf' ? (
                                                                                      <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'docx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'doc' ? (
                                                                                      <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'jpg' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'jpeg' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'png' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'csv' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xls' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xlsx' ? (
                                                                                      <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'pptx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'ppt' ? (
                                                                                      <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                    ) : (
                                                                                      <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                    )}

                                                                                    <span className="f_sz13 mr-2">
                                                                                      {/* {documentList.file_name} */}
                                                                                      {documentList?.file_name?.length >
                                                                                      5
                                                                                        ? documentList?.file_name.substring(
                                                                                            0,
                                                                                            5,
                                                                                          ) +
                                                                                          '...' +
                                                                                          this.getExtensionFromUrl(
                                                                                            documentList?.file_name,
                                                                                          )
                                                                                        : documentList?.file_name}
                                                                                    </span>
                                                                                  </td>
                                                                                  <td align="right">
                                                                                    <span className="f_sz12 flightgrey">
                                                                                      {this.bytesToKb(
                                                                                        documentList?.file_size,
                                                                                      )}{' '}
                                                                                      kB
                                                                                    </span>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                          </div>
                                                                        </a>
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                'timeline-footer attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }>
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                id={attachment.attach_id}
                                                                onClick={(e) =>
                                                                  this.documntAtchDelte_cmb(e, attachment.documents)
                                                                }>
                                                                Delete
                                                              </a>
                                                              <a
                                                                className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                onClick={() => {
                                                                  this.expanded_attach(attachment.attach_id);
                                                                }}>
                                                                <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-item ">
                                                            <div
                                                              className={
                                                                'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                attachment.attach_id
                                                              }
                                                              style={{display: 'none'}}>
                                                              <div className="attachmentbody_div">
                                                                Document Attached
                                                                <a
                                                                  className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                  onClick={() => {
                                                                    this.colapsed_attach(attachment.attach_id);
                                                                  }}>
                                                                  <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                </a>
                                                              </div>
                                                            </div>
                                                            {/* </span> */}
                                                          </div>
                                                        </div>
                                                      ) : undefined
                                                    ) : attachment.images ? (
                                                      attachment.images.length > 0 ? (
                                                        <div data-id={index} key={index}>
                                                          <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                          <div className="timeline-item ">
                                                            {/* <span className={"attachment_expanded attachment_expanded_"+attachment.attach_id}> */}
                                                            <div
                                                              className={
                                                                'timeline-body attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }>
                                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                {/* <span className="font-weight-bold">Images:</span> */}
                                                                <br />

                                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                  {attachment.images.map((documentList, index) => {
                                                                    var fileType = documentList.file_name.split('.');
                                                                    return (
                                                                      <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                        <a
                                                                          onClick={() =>
                                                                            this.document_read(documentList)
                                                                          }>
                                                                          {documentList?.thumbnail_file_url ? (
                                                                            <div className="attachdoc_thumb">
                                                                              <img
                                                                                src={documentList?.thumbnail_file_url}
                                                                                border={0}
                                                                              />
                                                                            </div>
                                                                          ) : (
                                                                            <div className="attachdoc_type text-center">
                                                                              <i className="far fa-file-alt flightgrey1"></i>
                                                                            </div>
                                                                          )}

                                                                          <div className="attachdoc_filenmesze py-1 px-2">
                                                                            <table
                                                                              width="100%"
                                                                              cellPadding={0}
                                                                              cellSpacing={0}>
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td>
                                                                                    {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                    {fileType[fileType.length - 1] ===
                                                                                    'pdf' ? (
                                                                                      <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'docx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'doc' ? (
                                                                                      <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'jpg' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'jpeg' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'png' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'csv' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xls' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xlsx' ? (
                                                                                      <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'pptx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'ppt' ? (
                                                                                      <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                    ) : (
                                                                                      <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                    )}

                                                                                    <span className="f_sz13 mr-2">
                                                                                      {/* {documentList.file_name} */}
                                                                                      {documentList?.file_name?.length >
                                                                                      5
                                                                                        ? documentList?.file_name.substring(
                                                                                            0,
                                                                                            5,
                                                                                          ) +
                                                                                          '...' +
                                                                                          this.getExtensionFromUrl(
                                                                                            documentList?.file_name,
                                                                                          )
                                                                                        : documentList?.file_name}
                                                                                    </span>
                                                                                  </td>
                                                                                  <td align="right">
                                                                                    <span className="f_sz12 flightgrey">
                                                                                      {this.bytesToKb(
                                                                                        documentList?.file_size,
                                                                                      )}{' '}
                                                                                      kB
                                                                                    </span>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                          </div>
                                                                        </a>
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                'timeline-footer attachment_expanded attachment_expanded_' +
                                                                attachment.attach_id
                                                              }>
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                id={attachment.attach_id}
                                                                onClick={(e) =>
                                                                  this.documntAtchDelte_cmb(e, attachment.images)
                                                                }>
                                                                Delete
                                                              </a>
                                                              <a
                                                                className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                onClick={() => {
                                                                  this.expanded_attach(attachment.attach_id);
                                                                }}>
                                                                <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-item ">
                                                            <div
                                                              className={
                                                                'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                attachment.attach_id
                                                              }
                                                              style={{display: 'none'}}>
                                                              <div className="attachmentbody_div">
                                                                Image Attached
                                                                <a
                                                                  className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                  onClick={() => {
                                                                    this.colapsed_attach(attachment.attach_id);
                                                                  }}>
                                                                  <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                </a>
                                                              </div>
                                                            </div>
                                                            {/* </span> */}
                                                          </div>
                                                        </div>
                                                      ) : undefined
                                                    ) : attachment.audio_url ? (
                                                      <div data-id={index} key={index}>
                                                        <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                        <div className="timeline-item ">
                                                          <div
                                                            className={
                                                              'timeline-body attachment_expanded attachment_expanded_' +
                                                              attachment.audio_id
                                                            }>
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                <div className="d-table-cell align-middle">
                                                                  <span>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr">
                                                                        <i
                                                                          className={
                                                                            'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                            attachment.audio_id
                                                                          }
                                                                          onClick={() => {
                                                                            this.audioPly(attachment);
                                                                          }}
                                                                          id={attachment.audio_id}
                                                                        />
                                                                        <i
                                                                          className={
                                                                            'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                            attachment.audio_id
                                                                          }
                                                                          onClick={this.audioPaue}
                                                                          id={attachment.audio_id}
                                                                          style={{
                                                                            display: 'none',
                                                                          }}></i>
                                                                        <span
                                                                          className="ml-2 d-inline-block postop_5up"
                                                                          // style={{
                                                                          //   display: 'none',
                                                                          // }}
                                                                        ></span>
                                                                        <audio
                                                                          className={
                                                                            'audioplayer123 audio_' +
                                                                            attachment.audio_id
                                                                          }
                                                                          style={{width: '258px', display: 'none'}}
                                                                          src={
                                                                            this.state.play_audio_url
                                                                              ? this.state.play_audio_url
                                                                              : undefined
                                                                          }
                                                                          controls="controls"
                                                                          controlsList="nodownload"
                                                                          // hidden
                                                                          id={'audio_' + attachment.audio_id}
                                                                        />
                                                                        {/* <audio
                                                                          className="audioplayer123"
                                                                          style={{
                                                                            width: '258px',
                                                                            display: 'none',
                                                                          }}
                                                                          src={
                                                                            this.state.play_audio_url
                                                                              ? this.state.play_audio_url
                                                                              : undefined
                                                                          }
                                                                          controls="controls"
                                                                          controlsList="nodownload"
                                                                          //hidden
                                                                          id={'audio_temp_' + attachment.audio_id}
                                                                        /> */}
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className={
                                                              'timeline-footer attachment_expanded attachment_expanded_' +
                                                              attachment.audio_id
                                                            }>
                                                            <a
                                                              className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                              id={attachment.attach_id}
                                                              onClick={this.audioAtchDelte_cmb}>
                                                              Delete
                                                            </a>
                                                            <a
                                                              className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                              onClick={() => {
                                                                this.expanded_attach(attachment.audio_id);
                                                              }}>
                                                              <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-item ">
                                                          <div
                                                            className={
                                                              'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                              attachment.audio_id
                                                            }
                                                            style={{display: 'none'}}>
                                                            <div className="attachmentbody_div">
                                                              Audio Attached
                                                              <a
                                                                className={
                                                                  'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                }
                                                                onClick={() => {
                                                                  this.colapsed_attach(attachment.audio_id);
                                                                }}>
                                                                <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                              {/* </span> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.video_url ? (
                                                      <div data-id={index} key={index}>
                                                        <i className="fas fa-film bg_video attachtype_timelineicon"></i>
                                                        <div className="timeline-item">
                                                          {/* <span className={"attachment_expanded attachment_expanded_"+attachment.attach_id}> */}
                                                          <div
                                                            className={
                                                              'timeline-body attachment_expanded attachment_expanded_' +
                                                              attachment.attach_id
                                                            }>
                                                            <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                              <span className="font-weight-bold">Video:</span>
                                                              <br />
                                                              <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                <a onClick={() => this.Video_play_url(attachment)}>
                                                                  <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                                    <div className="attachvideo_thumb d-flex align-items-center justify-content-center">
                                                                      {/* <img src={} border={0} /> */}
                                                                      <i className="fas fa-video fa-3x" />
                                                                    </div>
                                                                    <div className="attachvideo_gif">
                                                                      {/* <img src={} border={0} /> */}
                                                                      Click here to play video
                                                                    </div>
                                                                  </div>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className={
                                                              'timeline-footer attachment_expanded attachment_expanded_' +
                                                              attachment.attach_id
                                                            }>
                                                            <a
                                                              className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                              id={attachment.attach_id}
                                                              onClick={this.videAtchDelte_cmb}>
                                                              Delete
                                                            </a>
                                                            <a
                                                              className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                              onClick={() => {
                                                                this.expanded_attach(attachment.attach_id);
                                                              }}>
                                                              <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-item ">
                                                          <div
                                                            className={
                                                              'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                              attachment.attach_id
                                                            }
                                                            style={{display: 'none'}}>
                                                            <div className="attachmentbody_div">
                                                              Video Attached
                                                              <a
                                                                className={
                                                                  'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                }
                                                                onClick={() => {
                                                                  this.colapsed_attach(attachment.attach_id);
                                                                }}>
                                                                <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                              {/* </span> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null;
                                                  })}

                                                  <div>
                                                    <i
                                                      className="fas fa-circle bg-gray"
                                                      style={{fontSize: 'x-small'}}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="form-group mt-3 mb-4"
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-12">
                                              <div className="psqrcode_entertagname_drpdwn_div">
                                                <span
                                                  // onClick={() => this.tags_list_click()}
                                                  className="allselectdropdwn qrcode_taglist">
                                                  <select
                                                    className="selectpicker w-100 border-bottom f_sz16"
                                                    onChange={(e) => {
                                                      this.tagonChange_send(e);
                                                    }}
                                                    multiple
                                                    data-live-search="true">
                                                    {/* <option selected="selected">--Choose Tag--</option> */}
                                                    {this.state.tagList.map((tag, index) => {
                                                      return (
                                                        <option key={index} value={tag._id}>
                                                          {tag.tag_name}
                                                        </option>
                                                      );
                                                    })}
                                                  </select>
                                                </span>
                                                <div className="col-12 px-0 text-right pt-2">
                                                  <span
                                                    className="fmaroon f_sz13 psqrcode_addtagname crsr_pntr"
                                                    onClick={() => this.psqrcode_addtagname()}>
                                                    <i className="fas fa-plus f_sz13 mr-2" />
                                                    Add New Tag
                                                  </span>
                                                </div>
                                              </div>
                                              <div
                                                className="psqrcode_entertagname_txtbx_div"
                                                style={{display: 'none'}}>
                                                <div className="input-group">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter the new tag name"
                                                    value={this.state.qr_newtag}
                                                    onChange={(e) => {
                                                      this.setState({qr_newtag: e.target.value});
                                                    }}
                                                  />
                                                  <div className="input-group-append">
                                                    <span className="input-group-text nobrdr_bg">
                                                      <span
                                                        className="psqrcode_closeaddtagname crsr_pntr"
                                                        onClick={() => this.psqrcode_closeaddtagname()}>
                                                        <i className="fas fa-times f_sz13" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 pt-3 pb-3 py-sm-2  generate_qr_btn"
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}>
                                            <a
                                              onClick={() => {
                                                this.generate_qr();
                                              }}
                                              className="btn btn-black crsr_pntr m-2 btnpdng_sm">
                                              Generate QR Code
                                            </a>
                                          </div>
                                          <span
                                            className="qr_codedisplay col-12 col-sm-12 col-md-12 col-lg-12 pt-3 pb-3 py-sm-2  brdrbtm_dashed"
                                            style={{display: 'none', left: '22px'}}
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}>
                                            <div className="qrcode_img">
                                              <QRCode
                                                id="123456"
                                                value={
                                                  APP_ENDPOINT +
                                                  '/qrcode_form?user=' +
                                                  this.state.user_id +
                                                  '&profile=' +
                                                  this.state.business_id +
                                                  '&qr_code=' +
                                                  Rnunique_id_cmb
                                                }
                                                size={200}
                                                level={'L'}
                                                includeMargin={false}
                                              />
                                            </div>
                                            <div className="qrcode_btn pt-3">
                                              <a
                                                className="btn btn-black crsr_pntr m-2 btnpdng_sm"
                                                onClick={() => this.downloadQR()}>
                                                Download QR Code
                                              </a>
                                            </div>
                                          </span>
                                        </div>
                                        {/*Element to be Looped*/}
                                      </div>
                                      <div
                                        className="card-footer bg-transparent  pt-3 pb-5 py-sm-3"
                                        onClick={() => {
                                          this.hide_emoji();
                                        }}>
                                        <a
                                          className="btn btn-black crsr_pntr m-2 btnpdng_sm"
                                          onClick={() => {
                                            this.state.message_edit === true
                                              ? this.update_qrcode_details()
                                              : this.save_qrcode_details();
                                          }}>
                                          Save
                                        </a>
                                        <a class="btn btn-black crsr_pntr m-2 btnpdng_sm">Cancel</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="all_contentflex_div main_content_div nft_div" style={{display: 'none'}}>
                          <div className="card w-100 bx_shadow_sm">
                            <div
                              className="nftcreate_div"
                              style={{
                                display:
                                  this.state.nft_tokens?.length === 0 && this.state.nft_total_count === 0
                                    ? 'inline-block'
                                    : 'none',
                              }}>
                              <div className="card w-100 shadow-none">
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body">
                                    <div className="psnftstepwise_div">
                                      <div className="row justify-content-center">
                                        <div className="col-12 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                                          <div className="card px-0 pt-4 pb-0 mt-3 mb-3 shadow-none">
                                            <h5 id="heading">NFT's</h5>
                                            <form id="msform">
                                              <ul id="progressbar">
                                                <li className="active" id="nftcontract">
                                                  <strong>NFT Contract</strong>
                                                </li>
                                                <li id="nftmint">
                                                  <strong>Mint an NFT</strong>
                                                </li>
                                                <li id="nftconfirm">
                                                  <strong>Confirm</strong>
                                                </li>
                                              </ul>
                                              <fieldset id="nft_step_1">
                                                <div className="form-card">
                                                  <div className="row">
                                                    <div className="col-7">
                                                      <h2 className="fs-title">Set Up NFT Minting Contract</h2>
                                                    </div>
                                                    <div className="col-5">
                                                      <h2 className="steps">Step 1 - 3</h2>
                                                    </div>
                                                  </div>
                                                  <div className="mt-4">
                                                    <div
                                                      className="nftrequest"
                                                      style={{
                                                        display:
                                                          this.state.nft_details.status == '' ||
                                                          this.state.nft_details.status == 'deleted'
                                                            ? 'block'
                                                            : 'none',
                                                      }}>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Token Name"
                                                        id="nft_token_name"
                                                      />
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Token Symbol"
                                                        id="nft_token_symbol"
                                                      />
                                                      <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Maximum Number of Tokens"
                                                        id="token_limits"
                                                        min="1"
                                                      />
                                                      <div className="nftrequest_div">
                                                        <div className="w-100 d-flex flex-row flex-wrap pb-4 keys0">
                                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <input
                                                              type="text"
                                                              className="form-control key_vals"
                                                              data-id="0"
                                                              placeholder="Key"
                                                            />
                                                          </div>
                                                          <div className="col-5 col-sm-5 col-md-4 col-lg-4">
                                                            <div className="icheck-darkgrey d-inline-block pt-1 pt-md-3">
                                                              <input type="checkbox" id="keyrequired0" />
                                                              <label htmlFor="keyrequired0">Required</label>
                                                            </div>
                                                          </div>
                                                          <div className="col-7 col-sm-7 col-md-2 col-lg-2 pr-0 pt-1 pt-md-3">
                                                            <a>
                                                              <i className="fas fa-plus-circle mt-2 f_sz16 crsr_pntr fmaroon addmore_nft_keys" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div align="center" className="mt-3">
                                                        <a className="btn btn-black crsr_pntr btnpdng_sm nftrequest_btn">
                                                          Request NFT Contract
                                                        </a>
                                                        <h6
                                                          className="w-100 mb-3 btn_confirmation"
                                                          style={{display: 'none'}}>
                                                          Are you sure, NFT contract will be created with the above
                                                          token name and token symbol? Once created, it cannot be
                                                          reverted.
                                                        </h6>
                                                        <a
                                                          className="btn btn-black crsr_pntr btnpdng_sm nftrequest_confirm_btn"
                                                          onClick={() => this.contractRequest()}
                                                          style={{display: 'none'}}>
                                                          Confirm NFT Contract Request
                                                        </a>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="text-center nftprocess"
                                                      style={{
                                                        display:
                                                          this.state.nft_details.status == 'requested'
                                                            ? 'block'
                                                            : 'none',
                                                      }}>
                                                      <h5>
                                                        Your request for NFT creation will be reviewed and approved
                                                        within 24 hours.
                                                      </h5>
                                                      <div align="center" className="mt-5">
                                                        <span className="btn btn-black btnpdng_sm nftprocess_btn">
                                                          Requested
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="text-center nftapproved"
                                                      style={{
                                                        display:
                                                          this.state.nft_details.status == 'approved'
                                                            ? 'block'
                                                            : 'none',
                                                      }}>
                                                      <h5>NFT contract created. You are now ready to mint.</h5>
                                                    </div>
                                                  </div>
                                                </div>
                                                <input
                                                  type="button"
                                                  name="next"
                                                  className="next action-button mt-4 nftapproved_next"
                                                  defaultValue="Next"
                                                  style={{
                                                    display:
                                                      this.state.nft_details.status == 'approved'
                                                        ? 'inline-block'
                                                        : 'none',
                                                  }}
                                                  onClick={() => this.contractNextStep(2)}
                                                />
                                              </fieldset>
                                              <fieldset id="nft_step_2">
                                                <div className="form-card">
                                                  <div className="row">
                                                    <div className="col-7">
                                                      <h2 className="fs-title">Mint an NFT:</h2>
                                                    </div>
                                                    <div className="col-5">
                                                      <h2 className="steps">Step 2 - 3</h2>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="form-group mt-3 mb-4">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Enter the NFT Name"
                                                          id="nft_name"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="form-group mt-3 mb-4">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <textarea
                                                          className="form-control textarea_hgt125 txtarea_resizenone"
                                                          placeholder="Enter Description"
                                                          defaultValue={''}
                                                          id="nft_decription"
                                                        />
                                                      </div>
                                                      <input type="hidden" id="nft_file_name" />
                                                      <input type="hidden" id="nft_file_url" />
                                                    </div>
                                                    <div className="form-group row mt-3 mb-4 m-2 upload_div">
                                                      <div className="col-3 col-sm-2 col-md-2 col-lg-2">
                                                        <label className="col-form-label">Upload</label>
                                                      </div>
                                                      <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                                        <div className="d-inline-block postop_3up">
                                                          <div className="select_style">
                                                            <select className="form-control chooseupload">
                                                              <option value="">--Select--</option>
                                                              <option value="chooseimage">Image</option>
                                                              {/* <option value="chooseaudio">Audio</option> */}
                                                              <option value="choosegif">GIF</option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="chooseimage chupld" style={{display: 'none'}}>
                                                      <div className="form-group row mt-3 mb-4 m-2">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">Upload the image</label>
                                                          <div className="d-inline-block ml-4">
                                                            <label className="btn-bs-file">
                                                              <span className="btn btn-black btnpdng_sm crsr_pntr">
                                                                Upload
                                                              </span>
                                                              <input
                                                                type="file"
                                                                name="DcfilNm"
                                                                accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,image/*"
                                                                onChange={(event) =>
                                                                  this.fileGetDetails_cmb_imagesnft(event)
                                                                }
                                                                className="nft_img_uploads"
                                                              />
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="col-12 col-sm-12 col-md-12 col-lg-12"
                                                          style={{display: 'none'}}
                                                          id="uploaded_image">
                                                          <div className="nftimage_div mt-3">
                                                            <img
                                                              src="../dist/img/sample_painting1.jpg"
                                                              id="uploaded_img_src"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="chooseaudio chupld" style={{display: 'none'}}>
                                                      <div className="form-group row mt-3 mb-4 m-2">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">Upload the audio</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="all_contentflex_div icheck_lbl">
                                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                                              <div className="icheck-darkgrey d-inline-block py-2">
                                                                <input
                                                                  type="radio"
                                                                  name="nft_uploadaudio"
                                                                  id="nft_record"
                                                                  defaultValue={1}
                                                                  className="audio_select"
                                                                />
                                                                <label htmlFor="nft_record">Record</label>
                                                              </div>
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                              <div className="icheck-darkgrey d-inline-block py-2">
                                                                <input
                                                                  type="radio"
                                                                  name="nft_uploadaudio"
                                                                  id="nft_upload"
                                                                  defaultValue={2}
                                                                  className="audio_select"
                                                                />
                                                                <label htmlFor="nft_upload">Upload</label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div
                                                            className="nftvoicedesc"
                                                            id="nftvoicemsg1"
                                                            style={{display: 'none'}}>
                                                            {/* <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" /> */}
                                                            <div className="sendmsgattach_clkAudio2_cmb_nft">
                                                              <div className="timeline-item">
                                                                <div className="timeline-body">
                                                                  <div className="attachmentbody_div d-table">
                                                                    <div className="d-table-row">
                                                                      <div class="d-table-cell w-70 align-top">
                                                                        <span class="attachrecord_icon bg_audio crsr_pntr">
                                                                          <i class="fas fa-microphone-alt"></i>
                                                                        </span>
                                                                      </div>
                                                                      <div className="d-table-cell align-middle">
                                                                        <span className="ml-2">
                                                                          <span className="crsr_pntr">
                                                                            <i
                                                                              className="fas fa-record-vinyl fa-2x fbred"
                                                                              onClick={this.audio_start_cmb_nft}
                                                                            />
                                                                            <span
                                                                              className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                              onClick={this.audio_start_cmb_nft}>
                                                                              Start Recording
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                                <div className="timeline-footer">
                                                                  {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                                  <a
                                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                    onClick={this.audioLclDelte_cmb_nft}>
                                                                    Delete
                                                                  </a>
                                                                  <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {this.state.atcharryDet_cmb.map((attachment, index) => {
                                                              return attachment.audio_url ? (
                                                                <div
                                                                  data-id={index}
                                                                  key={index}
                                                                  // draggable="true"
                                                                  // onDragEnd={(e) => this.dragEnd_cmb(e)}
                                                                  // onDragStart={(e) => this.dragStart_cmb(e)}
                                                                >
                                                                  <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                                  <div className="timeline-item ">
                                                                    {/* <span className={"attachment_expanded attachment_expanded_"+attachment.audio_id}> */}
                                                                    <div
                                                                      className={
                                                                        'timeline-body attachment_expanded_nft attachment_expanded_' +
                                                                        attachment.audio_id
                                                                      }>
                                                                      <div className="attachmentbody_div d-table">
                                                                        <div className="d-table-row">
                                                                          <div className="d-table-cell align-middle">
                                                                            <span>
                                                                              <span className="d-inline-block">
                                                                                <span className="crsr_pntr">
                                                                                  <i
                                                                                    className={
                                                                                      'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                                      attachment.audio_id
                                                                                    }
                                                                                    onClick={() => {
                                                                                      this.audioPly_temp(attachment);
                                                                                    }}
                                                                                    id={attachment.audio_id}
                                                                                  />
                                                                                  <i
                                                                                    className={
                                                                                      'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                                      attachment.audio_id
                                                                                    }
                                                                                    onClick={this.audioPaue}
                                                                                    id={attachment.audio_id}
                                                                                    style={{
                                                                                      display: 'none',
                                                                                    }}></i>
                                                                                  <span
                                                                                    className="ml-2 d-inline-block postop_5up"
                                                                                    // style={{
                                                                                    //   display: 'none',
                                                                                    // }}
                                                                                  ></span>
                                                                                  <audio
                                                                                    className="audioplayer123"
                                                                                    style={{
                                                                                      width: '258px',
                                                                                      display: 'none',
                                                                                    }}
                                                                                    src={
                                                                                      this.state.play_audio_url
                                                                                        ? this.state.play_audio_url
                                                                                        : undefined
                                                                                    }
                                                                                    controls="controls"
                                                                                    controlsList="nodownload"
                                                                                    //hidden
                                                                                    id={
                                                                                      'audio_temp_' +
                                                                                      attachment.audio_id
                                                                                    }
                                                                                  />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={
                                                                        'timeline-footer attachment_expanded_nft attachment_expanded_' +
                                                                        attachment.audio_id
                                                                      }>
                                                                      <a
                                                                        className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                        id={attachment.attach_id}
                                                                        onClick={this.audioAtchDelte_cmb}>
                                                                        Delete
                                                                      </a>
                                                                      <a
                                                                        className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                        onClick={() => {
                                                                          this.expanded_attach(attachment.audio_id);
                                                                        }}>
                                                                        <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                  <div className="timeline-item ">
                                                                    <div
                                                                      className={
                                                                        'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                        attachment.audio_id
                                                                      }
                                                                      style={{display: 'none'}}>
                                                                      <div className="attachmentbody_div">
                                                                        Audio Attached
                                                                        <a
                                                                          className={
                                                                            'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                          }
                                                                          onClick={() => {
                                                                            this.colapsed_attach(attachment.audio_id);
                                                                          }}>
                                                                          <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                        </a>
                                                                        {/* </span> */}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                ''
                                                              );
                                                            })}
                                                            ;
                                                            <div
                                                              style={{display: 'none'}}
                                                              className="audioRecordLoading_cmb_nft">
                                                              <div className="timeline-item">
                                                                <div className="timeline-body">
                                                                  <div className="attachmentbody_div d-table">
                                                                    <div className="d-table-row">
                                                                      <div class="d-table-cell w-70 align-top">
                                                                        <span class="attachrecord_icon bg_audio crsr_pntr">
                                                                          <i class="fas fa-microphone-alt"></i>
                                                                        </span>
                                                                      </div>
                                                                      <div className="d-table-cell align-middle">
                                                                        <span>
                                                                          <span className="d-inline-block">
                                                                            <span className="crsr_pntr">
                                                                              <i
                                                                                className="far fa-stop-circle f_sz30 fbred"
                                                                                onClick={this.audio_stop_cmb_nft}></i>
                                                                              <span
                                                                                className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                                onClick={this.audio_stop_cmb_nft}>
                                                                                Stop Recording
                                                                              </span>
                                                                            </span>
                                                                            <span className="f_sz16">
                                                                              <span>
                                                                                <i className="fas fa-spinner fa-spin f_sz24"></i>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="timeline-footer">
                                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.audio_stop}>Stop</a> */}
                                                                  <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                                    Delete
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="nftvoicedesc"
                                                            id="nftvoicemsg2"
                                                            style={{display: 'none'}}>
                                                            <div className="all_contentflex_div">
                                                              <div className="card w-100 mt-2 mb-4 shadow-none">
                                                                <div className="card-header">
                                                                  <h5 className="fmaroon f_sz15">Upload Audio</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                  <div className="w-100 d-flex flex-flow flex-row flex-wrap justify-content-center mt-3">
                                                                    <label className="btn-bs-file">
                                                                      <span className="btn btn-black btnpdng_sm crsr_pntr">
                                                                        Upload Audio
                                                                      </span>
                                                                      <input type="file" />
                                                                    </label>
                                                                  </div>
                                                                  <div className="uploaded_audiodiv text-center mt-3">
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                      <i className="fas fa-play-circle fa-4x mr-2 fblue" />
                                                                      <span className="font-weight-semibold">
                                                                        sample_audio.mp3
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="choosegif chupld" style={{display: 'none'}}>
                                                      <div className="form-group row mt-3 mb-4 m-2">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">Upload GIF</label>
                                                          <div className="d-inline-block ml-4">
                                                            <label className="btn-bs-file">
                                                              <span className="btn btn-black btnpdng_sm crsr_pntr">
                                                                Upload
                                                              </span>
                                                              <input
                                                              id="file-input4"
                                                                type="file"
                                                                name="DcfilNm"
                                                                accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,image/*"
                                                                onChange={(event) =>
                                                                  this.fileGetDetails_cmb_imagesnftgiff(event)
                                                                }
                                                                className="nft_img_uploads"
                                                              />
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="col-12 col-sm-12 col-md-12 col-lg-12"
                                                          style={{display: 'none'}}
                                                          id="uploaded_image_giff">
                                                          <div className="nftimage_div mt-3">
                                                            <img
                                                              src="../dist/img/sample_painting1.jpg"
                                                              id="uploaded_img_src_giff"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      {this.state.business_info_array?.business?.nft?.token_key
                                                        ?.length != 0
                                                        ? this.state.business_info_array?.business?.nft?.token_key?.map(
                                                            (data, index) => (
                                                              <div className="form-group row mt-3 mb-2 m-2">
                                                                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                                                  <label className="col-form-label">{data.key}</label>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control cnftkeyvalues"
                                                                    data-key={data.key}
                                                                    data-required={data.required}
                                                                  />
                                                                </div>
                                                              </div>
                                                            ),
                                                          )
                                                        : ''}
                                                    </div>
                                                  </div>
                                                </div>
                                                <input
                                                  type="button"
                                                  name="next"
                                                  className="next action-button mt-4 nft_next_btn"
                                                  defaultValue="Mint an NFT"
                                                  onClick={() => this.contractNextStep(3)}
                                                />
                                                <input
                                                  style={{display: 'none'}}
                                                  type="button"
                                                  name="next"
                                                  className="next action-button mt-4 isdisabled nft_next_btn_disabled"
                                                  defaultValue="Mint an NFT"
                                                />
                                              </fieldset>
                                              <fieldset id="nft_step_3">
                                                <div className="form-card">
                                                  <div className="row">
                                                    <div className="col-7">
                                                      <h2 className="fs-title">Confirm:</h2>
                                                    </div>
                                                    <div className="col-5">
                                                      <h2 className="steps">Step 3 - 3</h2>
                                                    </div>
                                                  </div>{' '}
                                                  <br />
                                                  <div>
                                                    <div className="form-group row mt-3 mb-4">
                                                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                        <label className="col-form-label">NFT Name</label>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                                        <div className="pdngtop_10">{this.state.nft_name}</div>
                                                      </div>
                                                    </div>
                                                    <div className="form-group row mt-3 mb-4">
                                                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                        <label className="col-form-label">Description</label>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                                        <div className="pdngtop_10">{this.state.nft_decription}</div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="form-group row mt-3 mb-4 m-2"
                                                      style={{
                                                        display: this.state.nft_file_type !== 'image/gif' ? '' : 'none',
                                                      }}>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label className="col-form-label">Image</label>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="nftimage_div mt-3">
                                                          <img src={this.state.nft_file_url} />
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="form-group row mt-3 mb-4 m-2"
                                                      style={{
                                                        display: this.state.nft_file_type === 'image/gif' ? '' : 'none',
                                                      }}>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <label className="col-form-label">GIF</label>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="nftimage_div mt-3">
                                                          <img src={this.state.nft_file_url} />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="mt-4">
                                                      {this.state.nft_keys?.length != 0
                                                        ? this.state.nft_keys?.map((data, index) => (
                                                            <div className="form-group row mt-3 mb-2 m-2">
                                                              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                                                <label className="col-form-label">{data.key}</label>
                                                              </div>
                                                              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                                                <div className="mt-2">{data.value}</div>
                                                              </div>
                                                            </div>
                                                          ))
                                                        : ''}
                                                    </div>
                                                    <div className="form-group row mt-5 mb-4 m-2">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="icheck-darkgrey d-inline-block py-1">
                                                          <input type="checkbox" id="nftagree" />
                                                          <label htmlFor="nftagree">
                                                            I agree to the{' '}
                                                            <a href="#" className="fmaroon">
                                                              terms of service
                                                            </a>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <input
                                                  type="button"
                                                  name="previous"
                                                  className="previous action-button-previous"
                                                  defaultValue="Previous"
                                                  onClick={() => this.contractNextStep(2)}
                                                />
                                                <input
                                                  type="button"
                                                  name="next"
                                                  className="action-button mt-4 nftconfirm_receipt_clk"
                                                  defaultValue="Confirm"
                                                  onClick={() => this.contractNextStep(4)}
                                                />
                                                <input
                                                  style={{display: 'none'}}
                                                  type="button"
                                                  name="next"
                                                  className="action-button mt-4 isdisabled nftconfirm_receipt_clk_diabled"
                                                  defaultValue="Confirm"
                                                />
                                              </fieldset>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="addnft_div"
                              style={{
                                display:
                                  this.state.nft_tokens?.length === 0 || !this.state.table_view
                                    ? 'none'
                                    : 'inline-block',
                              }}>
                              <div className="card w-100 bx_shadow_sm">
                                <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                  <h5>
                                    <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">Add NFT</span>
                                    <span className="addnewnft_close mr-2 mt-1">
                                      <i className="fas fa-times" onClick={() => this.closeReceipt()} />
                                    </span>
                                  </h5>
                                </div>
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body px-0 pb-3">
                                    <div>
                                      <div className="psnftstepwise_div addnewnftstepwise_div">
                                        <div className="row justify-content-center">
                                          <div className="col-12 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                                            <div className="card px-0 pt-4 pb-0 mt-3 mb-3 shadow-none">
                                              <form id="msform">
                                                <ul id="progressbar">
                                                  <li className="active" id="nftmint">
                                                    <strong>Mint an NFT</strong>
                                                  </li>
                                                  <li id="nftconfirm" className="nftconfirm_minted">
                                                    <strong>Confirm</strong>
                                                  </li>
                                                </ul>
                                                <fieldset id="nftminted_step_1">
                                                  <div className="form-card">
                                                    <div className="row">
                                                      <div className="col-7">
                                                        <h2 className="fs-title">Mint an NFT:</h2>
                                                      </div>
                                                      <div className="col-5">
                                                        <h2 className="steps">Step 1 - 2</h2>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <div className="form-group mt-3 mb-4">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter the NFT Name"
                                                            id="nft_name_minted"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group mt-3 mb-4">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <textarea
                                                            className="form-control textarea_hgt125 txtarea_resizenone"
                                                            placeholder="Enter Description"
                                                            defaultValue={''}
                                                            id="nft_decription_minted"
                                                          />
                                                        </div>
                                                        <input type="hidden" id="nft_file_name_minted" />
                                                        <input type="hidden" id="nft_file_url_minted" />
                                                        <input type="hidden" id="nft_thumb_file_url_minted" />
                                                      </div>
                                                      <div className="form-group row mt-3 mb-4 m-2 upload_div">
                                                        <div className="col-3 col-sm-2 col-md-2 col-lg-2">
                                                          <label className="col-form-label">Upload</label>
                                                        </div>
                                                        <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                                          <div className="d-inline-block postop_3up">
                                                            <div className="select_style">
                                                              <select className="form-control chooseupload_nft_minted">
                                                                <option value="">--Select--</option>
                                                                <option value="chooseimage">Image</option>
                                                                {/* <option value="chooseaudio">Audio</option> */}
                                                                <option value="choosegif">GIF</option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="chooseimage chupld" style={{display: 'none'}}>
                                                        <div className="form-group row mt-3 mb-4 m-2">
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <label className="col-form-label">Upload the image</label>
                                                            <div className="d-inline-block ml-4">
                                                              <label className="btn-bs-file">
                                                                <span className="btn btn-black btnpdng_sm crsr_pntr">
                                                                  Upload
                                                                </span>
                                                                <input
                                                                id="file-input3"
                                                                  type="file"
                                                                  name="DcfilNm"
                                                                  accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,image/*"
                                                                  onChange={(event) =>
                                                                    this.fileGetDetails_cmb_imagesnft_mint(event)
                                                                  }
                                                                  className="nft_img_uploads"
                                                                />
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="col-12 col-sm-12 col-md-12 col-lg-12"
                                                            style={{display: 'none'}}
                                                            id="uploaded_image_minted">
                                                            <div className="nftimage_div mt-3">
                                                              <img
                                                                src="../dist/img/sample_painting1.jpg"
                                                                id="uploaded_image_minted_src"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="chooseaudio chupld" style={{display: 'none'}}>
                                                        <div className="form-group row mt-3 mb-4 m-2">
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <label className="col-form-label">Upload the audio</label>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <div className="all_contentflex_div icheck_lbl">
                                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                                                <div className="icheck-darkgrey d-inline-block py-2">
                                                                  <input
                                                                    type="radio"
                                                                    name="nft_uploadaudio"
                                                                    id="nft_record"
                                                                    defaultValue={1}
                                                                    className="audio_select"
                                                                  />
                                                                  <label htmlFor="nft_record">Record</label>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                                <div className="icheck-darkgrey d-inline-block py-2">
                                                                  <input
                                                                    type="radio"
                                                                    name="nft_uploadaudio"
                                                                    id="nft_upload"
                                                                    defaultValue={2}
                                                                    className="audio_select"
                                                                  />
                                                                  <label htmlFor="nft_upload">Upload</label>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <div
                                                              className="nftvoicedesc"
                                                              id="nftvoicemsg1"
                                                              style={{display: 'none'}}>
                                                              <div className="sendmsgattach_clkAudio2_cmb_nft">
                                                                <div className="timeline-item">
                                                                  <div className="timeline-body">
                                                                    <div className="attachmentbody_div d-table">
                                                                      <div className="d-table-row">
                                                                        <div class="d-table-cell w-70 align-top">
                                                                          <span class="attachrecord_icon bg_audio crsr_pntr">
                                                                            <i class="fas fa-microphone-alt"></i>
                                                                          </span>
                                                                        </div>
                                                                        <div className="d-table-cell align-middle">
                                                                          <span className="ml-2">
                                                                            <span className="crsr_pntr">
                                                                              <i
                                                                                className="fas fa-record-vinyl fa-2x fbred"
                                                                                onClick={this.audio_start_cmb_nft}
                                                                              />
                                                                              <span
                                                                                className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                                onClick={this.audio_start_cmb_nft}>
                                                                                Start Recording
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  <div className="timeline-footer">
                                                                    <a
                                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                      onClick={this.audioLclDelte_cmb_nft}>
                                                                      Delete
                                                                    </a>
                                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              {this.state.atcharryDet_cmb.map((attachment, index) => {
                                                                return attachment.audio_url ? (
                                                                  <div data-id={index} key={index}>
                                                                    <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                                    <div className="timeline-item ">
                                                                      {/* <span className={"attachment_expanded attachment_expanded_"+attachment.audio_id}> */}
                                                                      <div
                                                                        className={
                                                                          'timeline-body attachment_expanded_nft attachment_expanded_' +
                                                                          attachment.audio_id
                                                                        }>
                                                                        <div className="attachmentbody_div d-table">
                                                                          <div className="d-table-row">
                                                                            <div className="d-table-cell align-middle">
                                                                              <span>
                                                                                <span className="d-inline-block">
                                                                                  <span className="crsr_pntr">
                                                                                    <i
                                                                                      className={
                                                                                        'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                                        attachment.audio_id
                                                                                      }
                                                                                      onClick={() => {
                                                                                        this.audioPly_temp(attachment);
                                                                                      }}
                                                                                      id={attachment.audio_id}
                                                                                    />
                                                                                    <i
                                                                                      className={
                                                                                        'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                                        attachment.audio_id
                                                                                      }
                                                                                      onClick={this.audioPaue}
                                                                                      id={attachment.audio_id}
                                                                                      style={{
                                                                                        display: 'none',
                                                                                      }}></i>
                                                                                    <span
                                                                                      className="ml-2 d-inline-block postop_5up"
                                                                                      // style={{
                                                                                      //   display: 'none',
                                                                                      // }}
                                                                                    ></span>
                                                                                    <audio
                                                                                      className="audioplayer123"
                                                                                      style={{
                                                                                        width: '258px',
                                                                                        display: 'none',
                                                                                      }}
                                                                                      src={
                                                                                        this.state.play_audio_url
                                                                                          ? this.state.play_audio_url
                                                                                          : undefined
                                                                                      }
                                                                                      controls="controls"
                                                                                      controlsList="nodownload"
                                                                                      //hidden
                                                                                      id={
                                                                                        'audio_temp_' +
                                                                                        attachment.audio_id
                                                                                      }
                                                                                    />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        className={
                                                                          'timeline-footer attachment_expanded_nft attachment_expanded_' +
                                                                          attachment.audio_id
                                                                        }>
                                                                        <a
                                                                          className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                          id={attachment.attach_id}
                                                                          onClick={this.audioAtchDelte_cmb}>
                                                                          Delete
                                                                        </a>
                                                                        <a
                                                                          className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                          onClick={() => {
                                                                            this.expanded_attach(attachment.audio_id);
                                                                          }}>
                                                                          <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                        </a>
                                                                      </div>
                                                                    </div>
                                                                    <div className="timeline-item ">
                                                                      <div
                                                                        className={
                                                                          'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                          attachment.audio_id
                                                                        }
                                                                        style={{display: 'none'}}>
                                                                        <div className="attachmentbody_div">
                                                                          Audio Attached
                                                                          <a
                                                                            className={
                                                                              'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                            }
                                                                            onClick={() => {
                                                                              this.colapsed_attach(attachment.audio_id);
                                                                            }}>
                                                                            <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                          </a>
                                                                          {/* </span> */}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  ''
                                                                );
                                                              })}
                                                              ;
                                                              <div
                                                                style={{display: 'none'}}
                                                                className="audioRecordLoading_cmb_nft">
                                                                <div className="timeline-item">
                                                                  <div className="timeline-body">
                                                                    <div className="attachmentbody_div d-table">
                                                                      <div className="d-table-row">
                                                                        <div class="d-table-cell w-70 align-top">
                                                                          <span class="attachrecord_icon bg_audio crsr_pntr">
                                                                            <i class="fas fa-microphone-alt"></i>
                                                                          </span>
                                                                        </div>
                                                                        <div className="d-table-cell align-middle">
                                                                          <span>
                                                                            <span className="d-inline-block">
                                                                              <span className="crsr_pntr">
                                                                                <i
                                                                                  className="far fa-stop-circle f_sz30 fbred"
                                                                                  onClick={this.audio_stop_cmb_nft}></i>
                                                                                <span
                                                                                  className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                                  onClick={this.audio_stop_cmb_nft}>
                                                                                  Stop Recording
                                                                                </span>
                                                                              </span>
                                                                              <span className="f_sz16">
                                                                                <span>
                                                                                  <i className="fas fa-spinner fa-spin f_sz24"></i>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="timeline-footer">
                                                                    <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                                      Delete
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="nftvoicedesc"
                                                              id="nftvoicemsg2"
                                                              style={{display: 'none'}}>
                                                              <div className="all_contentflex_div">
                                                                <div className="card w-100 mt-2 mb-4 shadow-none">
                                                                  <div className="card-header">
                                                                    <h5 className="fmaroon f_sz15">Upload Audio</h5>
                                                                  </div>
                                                                  <div className="card-body">
                                                                    <div className="w-100 d-flex flex-flow flex-row flex-wrap justify-content-center mt-3">
                                                                      <label className="btn-bs-file">
                                                                        <span className="btn btn-black btnpdng_sm crsr_pntr">
                                                                          Upload Audio
                                                                        </span>
                                                                        <input type="file" />
                                                                      </label>
                                                                    </div>
                                                                    <div className="uploaded_audiodiv text-center mt-3">
                                                                      <div className="d-flex align-items-center justify-content-center">
                                                                        <i className="fas fa-play-circle fa-4x mr-2 fblue" />
                                                                        <span className="font-weight-semibold">
                                                                          sample_audio.mp3
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="choosegif chupld" style={{display: 'none'}}>
                                                        <div className="form-group row mt-3 mb-4 m-2">
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <label className="col-form-label">Upload GIF</label>
                                                            <div className="d-inline-block ml-4">
                                                              <label className="btn-bs-file">
                                                                <span className="btn btn-black btnpdng_sm crsr_pntr">
                                                                  Upload
                                                                </span>
                                                                <input
                                                                  type="file"
                                                                  name="DcfilNm"
                                                                  accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,image/*"
                                                                  onChange={(event) =>
                                                                    this.fileGetDetails_cmb_imagesnftgiff_mint(event)
                                                                  }
                                                                  className="nft_img_uploads"
                                                                />
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="col-12 col-sm-12 col-md-12 col-lg-12"
                                                            style={{display: 'none'}}
                                                            id="uploaded_image_giff_minted">
                                                            <div className="nftimage_div mt-3">
                                                              <img
                                                                src="../dist/img/sample_painting1.jpg"
                                                                id="uploaded_img_src_giff_minted"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        {this.state.business_info_array?.business?.nft?.token_key
                                                          ?.length != 0
                                                          ? this.state.business_info_array?.business?.nft?.token_key?.map(
                                                              (data, index) => (
                                                                <div className="form-group row mt-3 mb-2 m-2">
                                                                  <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                                                    <label className="col-form-label">{data.key}</label>
                                                                  </div>
                                                                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control nftkeyvalues"
                                                                      data-key={data.key}
                                                                      data-required={data.required}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              ),
                                                            )
                                                          : ''}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <input
                                                    type="button"
                                                    name="next"
                                                    className="next action-button mt-4 nftapproved_next nft_next_btn"
                                                    defaultValue="Next"
                                                    style={{
                                                      display:
                                                        this.state.nft_details.status == 'approved'
                                                          ? 'inline-block'
                                                          : 'none',
                                                    }}
                                                    onClick={() => this.mintingNextStep(3)}
                                                  />
                                                  <input
                                                    style={{display: 'none'}}
                                                    type="button"
                                                    name="next"
                                                    className="next action-button mt-4 nftapproved_next nft_next_btn_disabled isdisabled"
                                                    defaultValue="Next"
                                                  />
                                                </fieldset>
                                                <fieldset id="nftminted_step_2">
                                                  <div className="form-card">
                                                    <div className="row">
                                                      <div className="col-7">
                                                        <h2 className="fs-title">Confirm:</h2>
                                                      </div>
                                                      <div className="col-5">
                                                        <h2 className="steps">Step 2 - 2</h2>
                                                      </div>
                                                    </div>{' '}
                                                    <br />
                                                    <div>
                                                      <div className="form-group row mt-3 mb-4">
                                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                          <label className="col-form-label">NFT Name</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                                          <div className="pdngtop_10">{this.state.nft_name}</div>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row mt-3 mb-4">
                                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                          <label className="col-form-label">Description</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                                          <div className="pdngtop_10">{this.state.nft_decription}</div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="form-group row mt-3 mb-4 m-2"
                                                        style={{
                                                          display:
                                                            this.state.nft_file_type !== 'image/gif' ? '' : 'none',
                                                        }}>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">Image</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="nftimage_div mt-3">
                                                            <img src={this.state.nft_file_url} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="mt-4">
                                                        {this.state.nft_keys?.length != 0
                                                          ? this.state.nft_keys?.map((data, index) => (
                                                              <div className="form-group row mt-3 mb-2 m-2">
                                                                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                                                  <label className="col-form-label">{data.key}</label>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                                                  <div className="mt-2">{data.value}</div>
                                                                </div>
                                                              </div>
                                                            ))
                                                          : ''}
                                                      </div>
                                                      <div
                                                        className="form-group row mt-3 mb-4 m-2"
                                                        style={{
                                                          display:
                                                            this.state.nft_file_type === 'image/gif' ? '' : 'none',
                                                        }}>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">GIF</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="nftimage_div mt-3">
                                                            <img src={this.state.nft_file_url} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row mt-5 mb-4 m-2">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="icheck-darkgrey d-inline-block py-1">
                                                            <input type="checkbox" id="nftagree_minted" />
                                                            <label htmlFor="nftagree_minted">
                                                              I agree to the{' '}
                                                              <a href="#" className="fmaroon">
                                                                terms of service
                                                              </a>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <input
                                                    type="button"
                                                    name="previous"
                                                    className="previous action-button-previous"
                                                    defaultValue="Previous"
                                                    onClick={() => this.mintingNextStep(2)}
                                                  />
                                                  <input
                                                    type="button"
                                                    name="next"
                                                    className="action-button mt-4 nftconfirm_receipt_clk"
                                                    defaultValue="Confirm"
                                                    onClick={() => this.mintingNextStep(4)}
                                                  />
                                                  <input
                                                    style={{display: 'none'}}
                                                    type="button"
                                                    name="next"
                                                    className="action-button mt-4 isdisabled nftconfirm_receipt_clk_diabled"
                                                    defaultValue="Confirm"
                                                  />
                                                </fieldset>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="nftmintedlist_div"
                              style={{
                                display:
                                  this.state.nft_tokens?.length !== 0 || this.state.nft_total_count !== 0
                                    ? 'inline-block'
                                    : 'none',
                              }}>
                              <div className="card w-100 shadow-none">
                                <div className="card-header d-flex flex-row flex-wrap">
                                  <div className="col-6 col-sm-6 col-md-6 pl-0">
                                    <h5 className="fmaroon pt-2">NFT's</h5>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                    {this.state.business_info_array?.business?.nft?.token_count !=
                                    this.state.business_info_array?.business?.nft?.token_limit ? (
                                      <a
                                        className="btn btn-black crsr_pntr btn-sm mt-1 addnewnft_clk"
                                        onClick={() => this.mintingNextStep(2)}>
                                        Mint New
                                      </a>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <div className="card-body p-0 workflow_cardbdy">
                                  <div className="tabbable-responsive scrollbar_style1">
                                    <div className="tabbable">
                                      <ul
                                        className="nav nav-tabs border-bottom"
                                        id="custom-tabs-nftlist-tab1"
                                        role="tablist">
                                        <li className="nav-item nft_minted_li" data-name="Available">
                                          <a
                                            className="nav-link active"
                                            id="custom-tabs-nftavailable-tab"
                                            data-toggle="pill"
                                            href="#custom-tabs-nftavailable"
                                            role="tab"
                                            aria-controls="custom-tabs-nftavailable"
                                            aria-selected="true">
                                            <span className="d-inline-block">Available</span>
                                          </a>
                                        </li>
                                        <li className="nav-item nft_minted_li" data-name="Sent">
                                          <a
                                            className="nav-link"
                                            id="custom-tabs-nftinprocess-tab"
                                            data-toggle="pill"
                                            href="#custom-tabs-nftinprocess"
                                            role="tab"
                                            aria-controls="custom-tabs-nftinprocess"
                                            aria-selected="false">
                                            <span className="d-inline-block">Sent</span>
                                          </a>
                                        </li>
                                        <li className="nav-item nft_minted_li" data-name="Accepted">
                                          <a
                                            className="nav-link"
                                            id="custom-tabs-nftsent-tab"
                                            data-toggle="pill"
                                            href="#custom-tabs-nftsent"
                                            role="tab"
                                            aria-controls="custom-tabs-nftsent"
                                            aria-selected="false">
                                            <span className="d-inline-block">Accepted</span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="tab-content" id="custom-tabs-nftlist-tabContent1">
                                    <div
                                      className="tab-pane fade active show"
                                      id="custom-tabs-nftavailable"
                                      role="tabpanel"
                                      aria-labelledby="custom-tabs-nftavailable-tab">
                                      <div className="all_contentflex_div">
                                        <div className="card w-100 p-0 shadow-none mb-0">
                                          <div className="card-header py-0 border-bottom-0" id="minted_search">
                                            <div className="all_contentcntr_300">
                                              <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-3 mb-3">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text search_inptgrptxt search_ctsinptgrptxt">
                                                    <button className="btn btn-sm" type="submit">
                                                      <i className="fas fa-search" />
                                                    </button>
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control search_frmcntrl3 bgclr1 minted_nft_search nft_minted_search"
                                                  placeholder="Search NFT"
                                                  data-type="minted"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body p-0">
                                            <div className="nftgallery_boxes all_contentflex_div pt-1 pb-3 justify-content-center availble_nft_tokens">
                                              {this.state?.nft_minted_tokens_lists.length !== 0 ? (
                                                this.state?.nft_minted_tokens_lists?.map(
                                                  (nft_tokens_details, index) => {
                                                    if (nft_tokens_details?.status === 'minted') {
                                                      return (
                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                                          <div
                                                            className={
                                                              index % 2
                                                                ? 'card bx_shadow_sm brdrtop_green1'
                                                                : 'card bx_shadow_sm brdrtop_orange'
                                                            }>
                                                            <div className="card-header border-bottom-0 position-relative">
                                                              <div className="card-title w-100 text-center position-relative">
                                                                <div className="postop_abs15uprgt f_sz12 flightgrey">
                                                                  Token #: {nft_tokens_details?.token_id}
                                                                </div>
                                                                <h3 className="f_sz15 mb-2 pb-1">
                                                                  {nft_tokens_details?.nft_name}
                                                                </h3>
                                                                <div>
                                                                  <span className="flightgrey f_sz13">
                                                                    {moment(nft_tokens_details?.created_date).format(
                                                                      'MMM DD, YYYY, h:mm a',
                                                                    )}
                                                                  </span>{' '}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="card-body fgrey pt-0 pb-1 text-center">
                                                              <div className="nftpreviewimage_div">
                                                                <img
                                                                  src={
                                                                    nft_tokens_details?.thumbnail_file_url.length != 0
                                                                      ? nft_tokens_details?.thumbnail_file_url
                                                                      : nft_tokens_details?.file_url
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="card-footer text-center bg-transparent">
                                                              <div className="tbl_actionbtns text-center">
                                                                <a
                                                                  onClick={() =>
                                                                    this.nftFunctionalities(
                                                                      nft_tokens_details,
                                                                      'view_receipt',
                                                                    )
                                                                  }
                                                                  //  className="nftavailable_receiptview_clk"
                                                                  data-id={nft_tokens_details?.token_hash}
                                                                  data-type={nft_tokens_details?.status}>
                                                                  <i
                                                                    className="far fa-eye fmaroon crsr_pntr"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title
                                                                    data-original-title="View"
                                                                  />
                                                                </a>
                                                                {this.state.business_info_array?.business?.nft
                                                                  ?.token_count !=
                                                                this.state.business_info_array?.business?.nft
                                                                  ?.token_limit ? (
                                                                  <a
                                                                    onClick={() =>
                                                                      this.nftFunctionalities(
                                                                        nft_tokens_details,
                                                                        'copy',
                                                                      )
                                                                    }
                                                                    //  className="nftavailable_copy_clk"
                                                                    data-id={nft_tokens_details?.token_hash}
                                                                    data-nft_name={nft_tokens_details?.nft_name}
                                                                    data-nft_description={
                                                                      nft_tokens_details?.nft_description
                                                                    }
                                                                    data-file_url={nft_tokens_details?.file_url}
                                                                    data-file_name={nft_tokens_details?.file_name}
                                                                    data-upload_type={nft_tokens_details?.upload_type}>
                                                                    <i
                                                                      className="far fa-copy fmaroon crsr_pntr"
                                                                      data-toggle="tooltip"
                                                                      data-placement="bottom"
                                                                      title
                                                                      data-original-title="Copy"
                                                                    />
                                                                  </a>
                                                                ) : (
                                                                  ''
                                                                )}
                                                                <a
                                                                  onClick={() =>
                                                                    this.nftFunctionalities(
                                                                      nft_tokens_details,
                                                                      'delete',
                                                                    )
                                                                  }
                                                                  // className="revert_nft"
                                                                  data-type="delete"
                                                                  data-id={nft_tokens_details?._id}
                                                                  data-cid={nft_tokens_details?.sent_to}>
                                                                  <i
                                                                    className="far fa-trash-alt"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title
                                                                    data-original-title="Delete"
                                                                  />
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  },
                                                )
                                              ) : (
                                                <>
                                                  <div
                                                    style={{
                                                      display: this.state.nft_load_more ? 'inline-block' : 'none',
                                                    }}
                                                    className="mt-3 w-100 text-center">
                                                    <i className="fas fa-spinner fa-pulse fa-2x fmaroon" />
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: !this.state.nft_load_more ? 'inline-block' : 'none',
                                                    }}
                                                    className="text-center pt-5 pb-3">
                                                    No NFTs to show
                                                  </div>
                                                </>
                                              )}
                                              {this.state?.nft_minted_tokens_lists.length !== 0 &&
                                              this.state.nft_available_load_more ? (
                                                <>
                                                  <div className="mt-3 w-100 text-center nft_minted_tokens_lists_load_more">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={
                                                        !this.state.nft_load_more
                                                          ? () => this.LoadMoreNft('available')
                                                          : ''
                                                      }
                                                      className="btn btn-black btn-sm1 crsr_pntr f_sz16">
                                                      <span className="d-none d-md-inline-block">
                                                        {!this.state.nft_load_more ? 'Load More' : 'Processing...'}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </>
                                              ) : (
                                                ' '
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-tabs-nftinprocess"
                                      role="tabpanel"
                                      aria-labelledby="custom-tabs-nftinprocess-tab">
                                      <div className="all_contentflex_div">
                                        <div className="card w-100 p-0 shadow-none mb-0">
                                          <div className="card-header py-0 border-bottom-0" id="sent_search">
                                            <div className="all_contentcntr_300">
                                              <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-3 mb-3">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text search_inptgrptxt search_ctsinptgrptxt">
                                                    <button className="btn btn-sm" type="submit">
                                                      <i className="fas fa-search" />
                                                    </button>
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control search_frmcntrl3 bgclr1 minted_nft_search nft_sent_search"
                                                  placeholder="Search NFT"
                                                  data-type="sent"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body p-0">
                                            <div className="nftgallery_boxes all_contentflex_div pt-1 pb-3 justify-content-center sent_nft_tokens">
                                              {this.state?.nft_sent_tokens_lists.length !== 0 ? (
                                                this.state?.nft_sent_tokens_lists?.map((nft_tokens_details, index) => {
                                                  if (nft_tokens_details?.status === 'sent') {
                                                    return (
                                                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                                        <div
                                                          className={
                                                            index % 2
                                                              ? 'card bx_shadow_sm brdrtop_green1'
                                                              : 'card bx_shadow_sm brdrtop_orange'
                                                          }>
                                                          <div className="card-header border-bottom-0 position-relative">
                                                            <div className="card-title w-100 text-center position-relative">
                                                              <div className="postop_abs15uprgt f_sz12 flightgrey">
                                                                Token #: {nft_tokens_details?.token_id}
                                                              </div>
                                                              <h3 className="f_sz15 mb-2 pb-1">
                                                                {nft_tokens_details?.nft_name}
                                                              </h3>
                                                              <div>
                                                                <span className="flightgrey f_sz13">
                                                                  {moment(nft_tokens_details?.updated_date).format(
                                                                    'MMM DD, YYYY, h:mm a',
                                                                  )}
                                                                </span>{' '}
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="card-body fgrey pt-0 pb-1 text-center">
                                                            <div className="nftpreviewimage_div">
                                                              <img
                                                                src={
                                                                  nft_tokens_details?.thumbnail_file_url.length != 0
                                                                    ? nft_tokens_details?.thumbnail_file_url
                                                                    : nft_tokens_details?.file_url
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="card-footer text-center bg-transparent">
                                                            <div className="tbl_actionbtns text-center">
                                                              <a
                                                                onClick={() =>
                                                                  this.nftFunctionalities(
                                                                    nft_tokens_details,
                                                                    'view_receipt',
                                                                  )
                                                                }
                                                                data-id={nft_tokens_details?.token_hash}
                                                                data-type={nft_tokens_details?.status}>
                                                                <i
                                                                  className="far fa-eye fmaroon crsr_pntr"
                                                                  data-toggle="tooltip"
                                                                  data-placement="bottom"
                                                                  title
                                                                  data-original-title="View"
                                                                />
                                                              </a>
                                                              <a
                                                                onClick={() =>
                                                                  this.nftFunctionalities(nft_tokens_details, 'revert')
                                                                }
                                                                data-type="revert"
                                                                data-id={nft_tokens_details?._id}
                                                                data-cid={nft_tokens_details?.sent_to}>
                                                                <i
                                                                  className="fas fa-share fa-flip-horizontal fmaroon crsr_pntr"
                                                                  data-toggle="tooltip"
                                                                  data-placement="bottom"
                                                                  title
                                                                  data-original-title="Revert"
                                                                />
                                                              </a>
                                                              <a
                                                                onClick={() =>
                                                                  this.nftFunctionalities(nft_tokens_details, 'delete')
                                                                }
                                                                // className="revert_nft"
                                                                data-type="delete"
                                                                data-id={nft_tokens_details?._id}
                                                                data-cid={nft_tokens_details?.sent_to}>
                                                                <i
                                                                  className="far fa-trash-alt"
                                                                  data-toggle="tooltip"
                                                                  data-placement="bottom"
                                                                  title
                                                                  data-original-title="Delete"
                                                                />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })
                                              ) : (
                                                <>
                                                  <div
                                                    style={{
                                                      display: this.state.nft_load_more ? 'inline-block' : 'none',
                                                    }}
                                                    className="mt-3 w-100 text-center">
                                                    <i className="fas fa-spinner fa-pulse fa-2x fmaroon" />
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: !this.state.nft_load_more ? 'inline-block' : 'none',
                                                    }}
                                                    className="text-center pt-5 pb-3">
                                                    No NFTs to show
                                                  </div>
                                                </>
                                              )}
                                              {this.state?.nft_sent_tokens_lists.length !== 0 &&
                                              this.state?.nft_sent_load_more ? (
                                                <>
                                                  <div className="mt-3 w-100 text-center">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={
                                                        !this.state.nft_load_more ? () => this.LoadMoreNft('sent') : ''
                                                      }
                                                      className="btn btn-black btn-sm1 crsr_pntr f_sz16">
                                                      <span className="d-none d-md-inline-block">
                                                        {!this.state.nft_load_more ? 'Load More' : 'Processing...'}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </>
                                              ) : (
                                                ' '
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-tabs-nftsent"
                                      role="tabpanel"
                                      aria-labelledby="custom-tabs-nftsent-tab">
                                      <div className="all_contentflex_div">
                                        <div className="card w-100 p-0 shadow-none mb-0">
                                          <div className="card-header py-0 border-bottom-0" id="accept_search">
                                            <div className="all_contentcntr_300">
                                              <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-3 mb-3">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text search_inptgrptxt search_ctsinptgrptxt">
                                                    <button className="btn btn-sm" type="submit">
                                                      <i className="fas fa-search" />
                                                    </button>
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control search_frmcntrl3 bgclr1 minted_nft_search nft_accept_search"
                                                  placeholder="Search NFT"
                                                  data-type="accepted"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body p-0">
                                            <div className="nftgallery_boxes all_contentflex_div pt-1 pb-3 justify-content-center accept_nft_tokens">
                                              {this.state?.nft_accept_tokens_lists.length !== 0 ? (
                                                this.state?.nft_accept_tokens_lists?.map(
                                                  (nft_tokens_details, index) => {
                                                    if (nft_tokens_details?.status === 'accepted') {
                                                      return (
                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                                          <div
                                                            className={
                                                              index % 2
                                                                ? 'card bx_shadow_sm brdrtop_green1'
                                                                : 'card bx_shadow_sm brdrtop_orange'
                                                            }>
                                                            <div className="card-header border-bottom-0 position-relative">
                                                              <div className="card-title w-100 text-center position-relative">
                                                                <div className="postop_abs15uprgt f_sz12 flightgrey">
                                                                  Token #: {nft_tokens_details?.token_id}
                                                                </div>
                                                                <h3 className="f_sz15 mb-2 pb-1">
                                                                  {nft_tokens_details?.nft_name}
                                                                </h3>
                                                                {/* <ReactTooltip id={'toolTip2' + index} /> */}
                                                                <div>
                                                                  <span className="flightgrey f_sz13">
                                                                    {moment(nft_tokens_details?.updated_date).format(
                                                                      'MMM DD, YYYY, h:mm a',
                                                                    )}
                                                                  </span>{' '}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="card-body fgrey pt-0 pb-1 text-center">
                                                              <div className="nftpreviewimage_div">
                                                                <img
                                                                  src={
                                                                    nft_tokens_details?.thumbnail_file_url.length != 0
                                                                      ? nft_tokens_details?.thumbnail_file_url
                                                                      : nft_tokens_details?.file_url
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="card-footer text-center bg-transparent">
                                                              <div className="tbl_actionbtns text-center">
                                                                <a
                                                                  onClick={() =>
                                                                    this.nftFunctionalities(
                                                                      nft_tokens_details,
                                                                      'view_receipt',
                                                                    )
                                                                  }
                                                                  // className="nftavailable_receiptview_clk"
                                                                  data-id={nft_tokens_details?.token_hash}
                                                                  data-type={nft_tokens_details?.status}>
                                                                  <i
                                                                    className="far fa-eye fmaroon crsr_pntr"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title
                                                                    data-original-title="View"
                                                                  />
                                                                </a>
                                                                {this.state.business_info_array?.business?.nft
                                                                  ?.token_count !=
                                                                this.state.business_info_array?.business?.nft
                                                                  ?.token_limit ? (
                                                                  <a
                                                                    onClick={() =>
                                                                      this.nftFunctionalities(
                                                                        nft_tokens_details,
                                                                        'copy',
                                                                      )
                                                                    }
                                                                    //  className="nftavailable_copy_clk"
                                                                    data-id={nft_tokens_details?.token_hash}
                                                                    data-nft_name={nft_tokens_details?.nft_name}
                                                                    data-nft_description={
                                                                      nft_tokens_details?.nft_description
                                                                    }
                                                                    data-file_url={nft_tokens_details?.file_url}
                                                                    data-file_name={nft_tokens_details?.file_name}
                                                                    data-upload_type={nft_tokens_details?.upload_type}>
                                                                    <i
                                                                      className="far fa-copy fmaroon crsr_pntr"
                                                                      data-toggle="tooltip"
                                                                      data-placement="bottom"
                                                                      title
                                                                      data-original-title="Copy"
                                                                    />
                                                                  </a>
                                                                ) : (
                                                                  ''
                                                                )}
                                                                <a
                                                                  onClick={() =>
                                                                    this.nftFunctionalities(
                                                                      nft_tokens_details,
                                                                      'delete',
                                                                    )
                                                                  }
                                                                  // className="revert_nft"
                                                                  data-type="delete"
                                                                  data-id={nft_tokens_details?._id}
                                                                  data-cid={nft_tokens_details?.sent_to}>
                                                                  <i
                                                                    className="far fa-trash-alt"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title
                                                                    data-original-title="Delete"
                                                                  />
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  },
                                                )
                                              ) : (
                                                <>
                                                  <div
                                                    style={{
                                                      display: this.state.nft_load_more ? 'inline-block' : 'none',
                                                    }}
                                                    className="mt-3 w-100 text-center">
                                                    <i className="fas fa-spinner fa-pulse fa-2x fmaroon" />
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: !this.state.nft_load_more ? 'inline-block' : 'none',
                                                    }}
                                                    className="text-center pt-5 pb-3">
                                                    No NFTs to show
                                                  </div>
                                                </>
                                              )}
                                              {this.state?.nft_accept_tokens_lists.length !== 0 &&
                                              this.state?.nft_accept_load_more ? (
                                                <>
                                                  <div className="mt-3 w-100 text-center">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={
                                                        !this.state.nft_load_more
                                                          ? () => this.LoadMoreNft('accept')
                                                          : ''
                                                      }
                                                      className="btn btn-black btn-sm1 crsr_pntr f_sz16">
                                                      <span className="d-none d-md-inline-block">
                                                        {!this.state.nft_load_more ? 'Load More' : 'Processing...'}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </>
                                              ) : (
                                                ' '
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="receiptnft_div" style={{display: 'none'}}>
                              <div className="card w-100 bx_shadow_sm">
                                <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                  <h5>
                                    <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">Receipt </span>
                                    <span
                                      className="receiptnft_close mr-2 mt-1"
                                      data-name={
                                        this.state.nft_token?.status === 'minted'
                                          ? 'available'
                                          : this.state.nft_token?.status
                                      }
                                      onClick={() =>
                                        this.closeReceipt(
                                          this.state.nft_token?.status === 'minted'
                                            ? 'available'
                                            : this.state.nft_token?.status,
                                        )
                                      }>
                                      <i className="fas fa-times" />
                                    </span>
                                  </h5>
                                </div>
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body px-0 pb-3">
                                    <div>
                                      <div className="form-group mb-4 mx-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Owner:</label>
                                          <div className="ml-3 d-inline-block" style={{textTransform: 'capitalize'}}>
                                            {typeof this.state.nft_token?.user_id?.first_name !== 'undefined' &&
                                            this.state.nft_token?.user_id?.first_name != null &&
                                            this.state.nft_token?.user_id?.first_name?.length !== 0
                                              ? this.state.nft_token?.user_id?.first_name +
                                                ' ' +
                                                this.state.nft_token?.user_id?.last_name
                                              : this.state.nft_token?.user_id?.email_address}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-4 mx-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">State:</label>
                                          <div
                                            className="ml-3 d-inline-block fgreen4 font-weight-semibold"
                                            style={{textTransform: 'capitalize'}}>
                                            {this.state.nft_token?.status}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">QR Code</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftqrcode_img">
                                            <QRCode
                                              //id="qrcode"
                                              value={
                                                APP_ENDPOINT +
                                                '/Nft_Receipt?qrcode=true&trans_id=' +
                                                this.state.nft_token?.token_hash +
                                                '&user_id=' +
                                                this.state.user_id +
                                                '&business_id=' +
                                                this.state.business_id
                                              }
                                              size={200}
                                              level={'L'}
                                              includeMargin={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mt-3 mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">IPFS</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <a
                                            target="_blank"
                                            href={'https://ipfs.io/ipfs/' + this.state.nft_token?.ipfs}
                                            className="fmaroon">
                                            {this.state.nft_token?.ipfs}
                                          </a>
                                        </div>
                                      </div>
                                      <div className="form-group mt-3 mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Description</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          {this.state.nft_token?.nft_description}
                                        </div>
                                      </div>
                                      <div
                                        className="form-group mt-3 mb-4"
                                        style={{
                                          display: this.state.nft_token?.upload_type !== 'image/gif' ? '' : 'none',
                                        }}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Image</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftimage_div">
                                            <img src={this.state.nft_token?.file_url} />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="form-group row mt-3 mb-4 m-2"
                                        style={{
                                          display: this.state.nft_token?.upload_type === 'image/gif' ? '' : 'none',
                                        }}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">GIF</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftimage_div mt-3">
                                            <img src={this.state.nft_token?.file_url} />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mt-4">
                                        {this.state.nft_token?.token_key?.length != 0
                                          ? this.state.nft_token?.token_key?.map((data, index) => (
                                              <div className="form-group row mt-3 mb-2 m-2">
                                                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                                  <label className="col-form-label">{data.key}</label>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                  <div className="mt-2">{data.value}</div>
                                                </div>
                                              </div>
                                            ))
                                          : ''}
                                      </div>

                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mt-4 mb-1">
                                          Your art is available as NFT as of{' '}
                                          {moment(this.state.nft_token?.created_date).format('MMM DD,YYYY, h:mm a')}
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Wallet Address:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/address?addr_addr=' +
                                              this.state.nft_token?.original_wallet_address
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.original_wallet_address}
                                          </a>
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Transaction Hash:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/hash?hash=' +
                                              this.state.nft_token?.token_hash +
                                              '&project=redtie_live'
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.token_hash}
                                          </a>
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Token Transfer Hash:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/hash?hash=' +
                                              this.state.nft_token?.token_transfer_hash +
                                              '&project=redtie_live'
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.token_transfer_hash}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sentreceiptnft_div" style={{display: 'none'}}>
                              <div className="card w-100 bx_shadow_sm">
                                <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                  <h5>
                                    <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">Receipt </span>
                                    <span
                                      className="sentreceiptnft_close mr-2 mt-1"
                                      onClick={() =>
                                        this.closeReceipt(
                                          this.state.nft_token?.status === 'minted'
                                            ? 'available'
                                            : this.state.nft_token?.status,
                                        )
                                      }>
                                      <i className="fas fa-times" />
                                    </span>
                                  </h5>
                                </div>
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body px-0 pb-3">
                                    <div>
                                      <div className="form-group mb-4 mx-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Owner:</label>
                                          <div className="ml-3 d-inline-block" style={{textTransform: 'capitalize'}}>
                                            {typeof this.state.nft_token?.user_id?.first_name !== 'undefined' &&
                                            this.state.nft_token?.user_id?.first_name != null &&
                                            this.state.nft_token?.user_id?.first_name?.length !== 0
                                              ? this.state.nft_token?.user_id?.first_name +
                                                ' ' +
                                                this.state.nft_token?.user_id?.last_name
                                              : this.state.nft_token?.user_id?.email_address}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-4 mx-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">State:</label>
                                          <div
                                            className="ml-3 d-inline-block fgreen4 font-weight-semibold"
                                            style={{textTransform: 'capitalize'}}>
                                            {this.state.nft_token?.status}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">QR Code</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftqrcode_img">
                                            <QRCode
                                              //id="qrcode"
                                              value={
                                                APP_ENDPOINT +
                                                '/Nft_Receipt?qrcode=true&trans_id=' +
                                                this.state.nft_token?.token_hash +
                                                '&user_id=' +
                                                this.state.user_id +
                                                '&business_id=' +
                                                this.state.business_id
                                              }
                                              size={200}
                                              level={'L'}
                                              includeMargin={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mt-3 mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">IPFS</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <a
                                            target="_blank"
                                            href={'https://ipfs.io/ipfs/' + this.state.nft_token?.ipfs}
                                            className="fmaroon">
                                            {this.state.nft_token?.ipfs}
                                          </a>
                                        </div>
                                      </div>
                                      <div className="form-group mt-3 mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Description</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          {this.state.nft_token?.nft_description}
                                        </div>
                                      </div>
                                      <div
                                        className="form-group mt-3 mb-4"
                                        style={{
                                          display: this.state.nft_token?.upload_type !== 'image/gif' ? '' : 'none',
                                        }}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Image</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftimage_div">
                                            <img src={this.state.nft_token?.file_url} />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="form-group row mt-3 mb-4 m-2"
                                        style={{
                                          display: this.state.nft_token?.upload_type === 'image/gif' ? '' : 'none',
                                        }}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">GIF</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftimage_div mt-3">
                                            <img src={this.state.nft_token?.file_url} />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mt-4">
                                        {this.state.nft_token?.token_key?.length != 0
                                          ? this.state.nft_token?.token_key?.map((data, index) => (
                                              <div className="form-group row mt-3 mb-2 m-2">
                                                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                                  <label className="col-form-label">{data.key}</label>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                  <div className="mt-2">{data.value}</div>
                                                </div>
                                              </div>
                                            ))
                                          : ''}
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mt-4 mb-1">
                                          Your art is available as NFT as of{' '}
                                          {moment(this.state.nft_token?.created_date).format('MMM DD,YYYY, h:mm a')}
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Wallet Address:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/address?addr_addr=' +
                                              this.state.nft_token?.original_wallet_address
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.original_wallet_address}
                                          </a>
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Transaction Hash:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/hash?hash=' +
                                              this.state.nft_token?.token_hash +
                                              '&project=redtie_live'
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.token_hash}
                                          </a>
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Token Transfer Hash:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/hash?hash=' +
                                              this.state.nft_token?.token_transfer_hash +
                                              '&project=redtie_live'
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.token_transfer_hash}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="inprocessreceiptnft_div" style={{display: 'none'}}>
                              <div className="card w-100 bx_shadow_sm">
                                <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                  <h5>
                                    <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">Receipt </span>
                                    <span
                                      className="inprocessreceiptnft_close mr-2 mt-1"
                                      onClick={() =>
                                        this.closeReceipt(
                                          this.state.nft_token?.status === 'minted'
                                            ? 'available'
                                            : this.state.nft_token?.status,
                                        )
                                      }>
                                      <i className="fas fa-times" />
                                    </span>
                                  </h5>
                                </div>
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body px-0 pb-3">
                                    <div>
                                      <div className="form-group mb-4 mx-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Owner:</label>
                                          <div className="ml-3 d-inline-block" style={{textTransform: 'capitalize'}}>
                                            {typeof this.state.nft_token?.user_id?.first_name !== 'undefined' &&
                                            this.state.nft_token?.user_id?.first_name != null &&
                                            this.state.nft_token?.user_id?.first_name?.length !== 0
                                              ? this.state.nft_token?.user_id?.first_name +
                                                ' ' +
                                                this.state.nft_token?.user_id?.last_name
                                              : this.state.nft_token?.user_id?.email_address}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-4 mx-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">State:</label>
                                          <div
                                            className="ml-3 d-inline-block fgreen4 font-weight-semibold"
                                            style={{textTransform: 'capitalize'}}>
                                            {this.state.nft_token?.status}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">QR Code</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftqrcode_img">
                                            <QRCode
                                              //id="qrcode"
                                              value={
                                                APP_ENDPOINT +
                                                '/Nft_Receipt?qrcode=true&trans_id=' +
                                                this.state.nft_token?.token_hash +
                                                '&user_id=' +
                                                this.state.user_id +
                                                '&business_id=' +
                                                this.state.business_id
                                              }
                                              size={200}
                                              level={'L'}
                                              includeMargin={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group mt-3 mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">IPFS</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <a
                                            target="_blank"
                                            href={'https://ipfs.io/ipfs/' + this.state.nft_token?.ipfs}
                                            className="fmaroon">
                                            {this.state.nft_token?.ipfs}
                                          </a>
                                        </div>
                                      </div>
                                      <div className="form-group mt-3 mb-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Description</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          {this.state.nft_token?.nft_description}
                                        </div>
                                      </div>
                                      <div
                                        className="form-group mt-3 mb-4"
                                        style={{
                                          display: this.state.nft_token?.upload_type !== 'image/gif' ? '' : 'none',
                                        }}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">Image</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftimage_div">
                                            <img src={this.state.nft_token?.file_url} />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="form-group row mt-3 mb-4 m-2"
                                        style={{
                                          display: this.state.nft_token?.upload_type === 'image/gif' ? '' : 'none',
                                        }}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <label className="col-form-label">GIF</label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="nftimage_div mt-3">
                                            <img src={this.state.nft_token?.file_url} />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mt-4">
                                        {this.state.nft_token?.token_key?.length != 0
                                          ? this.state.nft_token?.token_key?.map((data, index) => (
                                              <div className="form-group row mt-3 mb-2 m-2">
                                                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                                  <label className="col-form-label">{data.key}</label>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                  <div className="mt-2">{data.value}</div>
                                                </div>
                                              </div>
                                            ))
                                          : ''}
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mt-4 mb-1">
                                          Your art is available as NFT as of{' '}
                                          {moment(this.state.nft_token?.created_date).format('MMM DD,YYYY, h:mm a')}
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Wallet Address:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/address?addr_addr=' +
                                              this.state.nft_token?.to_wallet_address
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.to_wallet_address}
                                          </a>
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Transaction Hash:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/hash?hash=' +
                                              this.state.nft_token?.token_hash +
                                              '&project=redtie_live'
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.token_hash}
                                          </a>
                                        </div>
                                        <div className="mt-3 mb-1">
                                          <span className="font-weight-bold">Token Transfer Hash:</span>{' '}
                                          <a
                                            target="_blank"
                                            href={
                                              'http://explorer.ephrontech.com/hash?hash=' +
                                              this.state.nft_token?.token_transfer_hash +
                                              '&project=redtie_live'
                                            }
                                            className="fmaroon">
                                            {this.state.nft_token?.token_transfer_hash}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="all_contentflex_div main_content_div ft_div" style={{display: 'none'}}>
                          <div className="card w-100 bx_shadow_sm">
                            <div
                              className="ftcreate_div"
                              style={{
                                display:
                                  this.state.business_info_array?.business?.fungible_token?.token_available ===
                                  this.state.business_info_array?.business?.fungible_token?.token_limit
                                    ? 'inline-block'
                                    : 'none',
                              }}>
                              <div className="card w-100 shadow-none">
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body">
                                    <div className="psnftstepwise_div">
                                      <div className="row justify-content-center">
                                        <div className="col-12 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                                          <div className="card px-0 pt-4 pb-0 mt-3 mb-3 shadow-none">
                                            <h5 id="heading" className="d-md-none">
                                              Fungible Tokens
                                            </h5>
                                            <form id="msform">
                                              <fieldset>
                                                <div
                                                  className="form-card"
                                                  style={{
                                                    display:
                                                      this.state.business_info_array?.business?.fungible_token.status ==
                                                        '' ||
                                                      this.state.business_info_array?.business?.fungible_token.status ==
                                                        'requested' ||
                                                      this.state.business_info_array?.business?.fungible_token.status ==
                                                        'approved'
                                                        ? 'block'
                                                        : 'none',
                                                  }}>
                                                  <div className="row">
                                                    <div className="col-12">
                                                      <h2 className="fs-title text-center">
                                                        Set Up Fungible Token Contract
                                                      </h2>
                                                    </div>
                                                  </div>
                                                  <div className="mt-4">
                                                    <div
                                                      className="ftrequest"
                                                      style={{
                                                        display:
                                                          this.state.business_info_array?.business?.fungible_token
                                                            .status == ''
                                                            ? 'inline-block'
                                                            : 'none',
                                                      }}>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Token Name"
                                                        id="ft_token_name"
                                                      />
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Token Symbol"
                                                        id="ft_token_symbol"
                                                      />
                                                      <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Maximum Number of Tokens"
                                                        id="ft_token_number"
                                                        min="1"
                                                        max="999999999"
                                                      />
                                                      <div align="center" className="mt-3">
                                                        <a
                                                          href="javascript:void(0)"
                                                          onClick={this.requestFungibleToken}
                                                          className="btn btn-black crsr_pntr btnpdng_sm ftrequest_btn">
                                                          Request Fungible Token Contract
                                                        </a>
                                                        <h6
                                                          className="w-100 mb-5 ftrequest_confirm_message"
                                                          style={{display: 'none'}}>
                                                          Are you sure, Fungible Token contract will be created with the
                                                          above token name, token symbol and maximum number of tokens?
                                                          Once created, it cannot be reverted.
                                                        </h6>
                                                        <a
                                                          href="javascript:void(0)"
                                                          onClick={this.requestFungibleTokenConfirm}
                                                          className="btn btn-black crsr_pntr btnpdng_sm ftrequest_confirm_btn"
                                                          style={{display: 'none'}}>
                                                          Confirm Fungible Token Contract Request
                                                        </a>
                                                      </div>
                                                    </div>

                                                    <div
                                                      data-val={
                                                        this.state.business_info_array?.business?.fungible_token.status
                                                      }
                                                      className="text-center ftprocess"
                                                      style={{
                                                        display:
                                                          this.state.business_info_array?.business?.fungible_token
                                                            .status == 'requested' &&
                                                          this.state.business_info_array?.business?.fungible_token
                                                            .status !== null &&
                                                          this.state.business_info_array?.business?.fungible_token
                                                            .status !== undefined &&
                                                          this.state.business_info_array?.business?.fungible_token
                                                            .status !== ''
                                                            ? 'block'
                                                            : 'none',
                                                      }}>
                                                      <h5>
                                                        Your request for Fungible Token creation will be reviewed and
                                                        approved within 24 hours.
                                                      </h5>
                                                      <div align="center" className="mt-5">
                                                        <span className="btn btn-black btnpdng_sm ftprocess_btn">
                                                          Requested
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="text-center ftapproved"
                                                      style={{
                                                        display:
                                                          this.state.business_info_array?.business?.fungible_token
                                                            .status == 'approved' &&
                                                          this.state.business_info_array?.business?.fungible_token
                                                            ?.token_available ===
                                                            this.state.business_info_array?.business?.fungible_token
                                                              ?.token_limit
                                                            ? 'block'
                                                            : 'none',
                                                      }}>
                                                      <h5>
                                                        Fungible Token contract created. You are now ready to use.
                                                      </h5>
                                                    </div>
                                                  </div>
                                                </div>
                                                <input
                                                  type="button"
                                                  name="next"
                                                  className="action-button mt-4 ftapproved_next ftdone_next_clk"
                                                  defaultValue="Done"
                                                  style={{
                                                    display:
                                                      this.state.business_info_array?.business?.fungible_token.status ==
                                                        'approved' &&
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.token_available ===
                                                        this.state.business_info_array?.business?.fungible_token
                                                          ?.token_limit
                                                        ? 'inline-block'
                                                        : 'none',
                                                  }}
                                                />
                                              </fieldset>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="ftlist_div"
                              style={{
                                display:
                                  this.state.business_info_array?.business?.fungible_token?.token_available !==
                                  this.state.business_info_array?.business?.fungible_token?.token_limit
                                    ? 'inline-block'
                                    : 'none',
                              }}>
                              <div className="card w-100 shadow-none">
                                <div className="card-header d-flex flex-row flex-wrap">
                                  <div className="col-12 col-sm-12 col-md-12 pl-0">
                                    <h5 className="fmaroon pt-2">
                                      {this.state.business_info_array?.business?.fungible_token?.token_name}
                                    </h5>
                                  </div>
                                </div>
                                <div className="card-body p-0">
                                  <div className="my-3 text-center f_sz16">
                                    <span className="fmaroon font-weight-bold"></span> You have used{' '}
                                    <span className="fgreen4 font-weight-bold">
                                      {this.state.business_info_array?.business?.fungible_token?.token_limit -
                                        this.state.business_info_array?.business?.fungible_token?.token_available}
                                    </span>{' '}
                                    from the total tokens{' '}
                                    <span className="fgreen4 font-weight-bold">
                                      {this.state.business_info_array?.business?.fungible_token?.token_limit}
                                    </span>
                                  </div>
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover tbl_valignmdle tbl_ftsent border-bottom"
                                      id="ftsent_tbl"
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{width: '100%'}}>
                                      <thead>
                                        <tr>
                                          <th>Receiver</th>
                                          <th>Sent</th>
                                          <th>Accepted</th>
                                          <th>No. of Tokens</th>
                                        </tr>
                                      </thead>
                                      <tbody>{/* {this.state.ft_table_details?.length } */}</tbody>
                                    </table>
                                    <div
                                      className="mt-3 w-100 text-center"
                                      style={{
                                        display: this.state.ft_load_more_button ? 'inline-block' : 'none',
                                      }}>
                                      <a
                                        href="javascript:void(0)"
                                        onClick={!this.state.ft_load_more ? () => this.ft_table_data('cmb') : ''}
                                        className="btn btn-black btn-sm1 crsr_pntr f_sz16">
                                        <span className="d-none d-md-inline-block">
                                          {!this.state.ft_load_more ? 'Load More' : 'Processing...'}
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
              {this.state.Lightbox_open ? (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'imageModal',
                  }}
                  open={true}
                  onClose={this.closeLightbox}
                  center>
                  <img src={this.state.document_url} />
                </Modal>
              ) : (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'imageModal',
                  }}
                  open={false}
                  onClose={this.closeLightbox}
                  center>
                  <img src={this.state.document_url} />
                </Modal>
              )}
              {this.state.Lightbox_open_video ? (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'videoModal',
                  }}
                  open={true}
                  onClose={this.closeLightbox_video}
                  center>
                  <video controls playsInline disablePictureInPicture autoPlay muted controlsList="download">
                    <source
                      src={
                        this.state.video_play_link // {video_play_link_demo}
                      }
                      type="video/mp4"
                    />
                  </video>
                </Modal>
              ) : (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'videoModal',
                  }}
                  open={false}
                  onClose={this.closeLightbox_video}
                  center>
                  <video controls playsInline disablePictureInPicture autoPlay muted controlsList="download">
                    <source
                      src={
                        this.state.video_play_link // {video_play_link_demo}
                      }
                      type="video/mp4"
                    />
                  </video>
                </Modal>
              )}
            </div>
            <div className="corSticky_container" style={{display: 'none'}}>
              <div className="corSticky" style={{display: 'none'}}>
                <div className="corSticky-wrap">
                  <div className="row">
                    <div className="col-sm-12 file_upload_append">
                      <div
                        className="alert alert-warning alert-dismissible fade show file_upload_alert_class"
                        role="alert">
                        <span className="up-item-tex">File Uploading</span>
                        <button type="button" className="close">
                          {/* <span className="hide_show" aria-hidden="true">
                            -
                          </span>{' '}
                          &nbsp;<span className="close_file_upload">×</span> */}
                        </button>
                      </div>
                      <div id="attachement_progress_div" style={{height: 65}}>
                        <div className="added_class alert alert-light alert_hide completed_cls1" role="alert">
                          world.topo.bathy.200407.3x21600x10800.jp...
                          <div className="progress whole_progres1">
                            <div
                              className="progress-bar progress_bar_color_cls progres_cnt1"
                              role="progressbar"
                              style={{width: '20%'}}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}>
                              15%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="modal_qrcodedownload">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title fmaroon">Download QR Code</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pt-0">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 pt-3 pb-3 py-sm-2 text-center brdrbtm_dashed">
                    <div className="qrcode_img">
                      <img src={this.state.qr_download_link} alt="Redtie" className="brand-image" />
                      {/* <canvas height="250" width="250" id="123456" style="height: 200px; width: 200px;"></canvas> */}
                    </div>
                    {/* <div class="qrcode_btn pt-3">
                                  <a class="btn btn-black crsr_pntr m-2 btnpdng_sm">Download QR Code</a>
                                </div>    */}
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a
                    href={this.state.qr_download_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="btn btn-black btn-lg btnpdng_sm crsr_pntr">
                    Download QR Code
                  </a>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          <div>
            <BusinessVerification />
          </div>
        </div>
      </div>
    );
  }
}

export default AppsDetails;
