import React, { useEffect, Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/innerpages_customstyle.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import useScript, { loadCSS, removeCSS } from '../utils/useScript';
import redtie_logo from '../assets/img/redtie_logo.png';
import no_image from '../assets/img/no_image.png';
import transparent from '../assets/img/transparent.png';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import $ from 'jquery';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

function validate(business_name, team_emails) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  var regx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
  if (business_name.length === 0) {
    errors.push('Please enter the Workspace name');
    return errors;
  }

  // if (team_emails[0].length != 0) {
  // for (var i = 0; i < team_emails.length; i++) {
  //   console.log(team_emails.length)
  //   console.log(team_emails[i])
  //   var email_test = regx.test(team_emails[i])
  //   console.log(email_test)
  //   if (email_test == true) {
  //     console.log("no issue ")
  //   }else{
  //     console.log("iam in email test")
  //     errors.push((team_emails[i]) + " is not a valid email address")
  //     return errors;

  //   }
  // }
  // }
  return errors;
}
export default class CreateProfile2 extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    this.logout = this.logout.bind(this);
    this.onClickButtonAdder = this.onClickButtonAdder.bind(this);
    this.onClickButtonSubmit = this.onClickButtonSubmit.bind(this);
    this.onChangebusinessname = this.onChangebusinessname.bind(this);
    // this.onChangephonenumber = this.onChangephonenumber.bind(this);
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      errors: [],
      user_email: '',
      user_id: '',
      business_id: '',
      plivo_number: '',
      team_members: '',
      plan_id: '',
      team_emails: [''],
      business_name: '',
      isLoading: false,

      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 14 / 12,
      },
      croppedImage: '',
      prfImag: '',
      run: false,
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  onChangebusinessname(e) {
    this.setState({ business_name: e.target.value });
  }
  onClickButtonAdder(e) {
    e.preventDefault();
    if (this.state.team_emails.length < this.state.team_members) {
      console.log('added');
      this.setState({
        team_emails: ['', ...this.state.team_emails],
      });
    } else {
      var errors = [];
      errors.push('To add more contacts, please upgrade your plan');
      this.setState({ errors });
      // console.log(this.state.errors);
      setTimeout(() => {
        this.setState({ errors: [] });
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
    }
    if (this.state.team_emails.length + 1 == this.state.team_members) console.log('limit');
  }

  onClickFormGroupButton(index, e) {
    e.preventDefault();
    let team_emails = [...this.state.team_emails];
    team_emails.splice(index, 1);
    this.setState({ team_emails });
  }

  onChangeFormGroupInput(index, e) {
    let team_emails = [...this.state.team_emails];
    team_emails[index] = e.target.value;
    // var regx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    // if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(team_emails[index])){
    //   this.setState({ team_emails });
    // }
    console.log(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(team_emails[index]));

    this.setState({ team_emails });
  }
  onChangeFormGroupInput1(e) {
    let team_emails = [...this.state.team_emails];
    team_emails[0] = e.target.value;
    this.setState({ team_emails });
  }

  onClickButtonSubmit(e) {
    this.setState({ isLoading: true });
    e.preventDefault();
    const { business_name, team_emails } = this.state;

    const errors = validate(business_name, team_emails);
    if (errors.length > 0) {
      this.setState({ errors });
      this.setState({ isLoading: false });
      setTimeout(() => {
        this.setState({ errors: [] });
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 5000);
      return;
    } else {
      const filteredValues = this.state.team_emails.filter((value) => value);
      const filteredstring = filteredValues.toString();
      // if(filteredstring.includes(this.state.user_email)){ 
      //   this.setState({isLoading: false});      
      // errors.push('Account email cannot be added as a team member, please try a different one.');
      // this.setState({errors});      
      // setTimeout(() => {
      //   this.setState({errors: []});      
      // }, 5000);
      // }else{
      const businessdetails = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        assigned_id: filteredstring,
        business_name: this.state.business_name,
        setup: 'name',
      };
      console.log(businessdetails);
      //     this.setState({ isLoading: false });
      // return
      axios
        .post(API_CALL_ENDPOINT + '/business/profile/update', businessdetails)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.setState({ isLoading: false });
            AsyncStorage.setItem('business_name', res.data.result.business_name);
            this.props.history.push('/create-profile3');
          }
          if (res.data.status === 'conflict') {
            this.setState({ isLoading: false });
            var errors = [];
            errors.push("This Workspace name is not available since it's already in use, please try a new one.");
            this.setState({ errors });
            // console.log(this.state.errors);
            setTimeout(() => {
              this.setState({ errors: [] });
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              this.setState({ isLoading: false });
              var errors = [];
              errors.push("This Workspace name is not available since it's already in use, please try a new one.");
              this.setState({ errors });
              // console.log(this.state.errors);
              setTimeout(() => {
                this.setState({ errors: [] });
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
          }
        });
      // }
      console.log(filteredValues);
    }
  }

  logout() {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  }
  // onChangephonenumber(e) {
  //   var cleaned = ('' + e.target.value).replace(/\D/g, '')
  //   var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  //   if (match) {
  //     this.setState({ plivo_number: '(' + match[1] + ') ' + match[2] + '-' + match[3] })
  //     console.log('(' + match[1] + ') ' + match[2] + '-' + match[3])
  //   }

  //   // console.log(this.state.plivo_number)
  // }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({ user_email });
        console.log('email');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({ business_id });
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('plan_id').then((plan_id) => {
      if (plan_id) {
        this.setState({ plan_id });
        console.log(' plan_id');
        console.log(plan_id);
      }
    });
    AsyncStorage.getItem('plivo_number').then((plivo_number) => {
      if (plivo_number) {
        const res = plivo_number.substring(1, 0);
        if (res == '1' || res == '0') {
          plivo_number = plivo_number.substring(1);
          plivo_number = plivo_number
            .replace(/[^\dA-Z]/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
            .trim();

          this.setState({ plivo_number });
        } else {
          plivo_number = plivo_number
            .replace(/[^\dA-Z]/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
            .trim();

          this.setState({ plivo_number });
        }
        console.log(plivo_number);

        // this.setState({ plivo_number });
        console.log('plivo_number');
        // console.log(plivo_number)
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({ user_id });
        console.log('User_id');
        console.log(this.state.user_id);
      }
    });
    AsyncStorage.getItem('business_name').then((business_name) => {
      if (business_name) {
        this.setState({ business_name });
      }
    });


    setTimeout(() => {
      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                console.log(res.data);
                console.log(
                  'res.data.result.business.business_name.length',
                  res.data.result.business.business_name.length,
                );
                if (res.data.status === 'success') {
                  this.setState({ prfImag: res?.data?.result?.business?.profile_image });
                  if (res.data.result.business.payment_status === 'payment_failed') {
                    console.log('phone empty');
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data.result.business.phone_number.length > 0 &&
                    res.data.result.business.business_name.length == 0
                  ) {
                    console.log('business empty');
                  }
                  if (
                    res.data.result.business.phone_number.length > 0 &&
                    res.data.result.business.business_name.length > 0
                  ) {
                    console.log('phone and business not empty');
                    console.log(res.data.result.business.phone_number);
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_name', res.data.result.business.business_name);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile3');
                  }
                  if (
                    res.data.result.business.business_name.length == 0 &&
                    res.data.result.business.phone_number.length == 0
                  ) {
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile1');
                    console.log('name empty');
                  }
                  // this.setState({business_name1: res.data.result.business.business_name})
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (res.data.status === 'no_record') {
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              this.props.history.push('/create-profile');
            }
          }
        });
      const planInfo = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          plan_id: this.state.plan_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/stripe/plan/info', planInfo)
        .then((res) => {
          console.log(res.data);
          this.setState({ team_members: res.data.result.team_members });
          console.log(this.state.team_members);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
  }

  onFileChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
    }

    $('.popup_new').attr('style', 'display');

    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0],
    });
    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', e.target.files[0]);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log('file : ', e.target.files[0]);
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, 'cropped.jpg');
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  togglePopup = () => {
    $('.popup_new').attr('style', 'display');
  };

  save_imageCrop = () => {
    $('.popup_new').attr('style', 'display:none');

    console.log(this.state.croppedImage);

    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', this.state.croppedImage);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log(this.state.business_id);

    axios.post(API_CALL_ENDPOINT + '/file/profile/upload', data).then((res) => {
      console.log(res);

      var file_url = '';

      if (res.data.status == 'success') {
        for (var i in res.data.result.files) {
          console.log(res.data.result.files[i].file_url);
          file_url += res.data.result.files[i].file_url;
        }

        console.log(file_url);
        this.setState({
          prfImag: file_url,
        });

        const business_upDetails = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          setup: 'profile',
          profile_image: file_url,
        };

        axios.post(API_CALL_ENDPOINT + '/business/update', business_upDetails).then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            // window.location.reload(true);
          }
        });
      }
    });
  };

  close_imageCrop = () => {
    $('.popup_new').hide();
  };
  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
      console.log('updated');
    }
  };
  render() {
    const initial = this.state.user_email.substring(1, 0);
    const firstinitial = initial.toUpperCase();
    const isFormGroupDeletionAllowed = this.state.team_emails.length > 0 ? true : false;
    const addicon =
      this.state.team_emails.length == this.state.team_members
        ? undefined
        : 'fas fa-plus-circle mt-2 f_sz17 crsr_pntr fmaroon';
    // console.log(this.state.user_id)
    const { errors } = this.state;
    if (this.state.loggedIn === false) {
      return <Redirect to="/signin" />;
    }

    return (
      <>
        <Helmet>
          <title>Redtie - Create Workspace</title>
        </Helmet>
        <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
          <div className="wrapper">
            {/* Navbar */}
            <Header_component data={this.update.bind(this)} cart_data={this.cart_update.bind(this)} />
            {/* /.navbar */}
            {/* Main Sidebar Container */} {/*Can be used when needed*/}
            <aside className="main-sidebar sidebar-light-gray-dark elevation-3" style={{ display: 'none' }}>
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-2 pt-3 mb-3 d-flex flex-wrap text-center elevation-1">
                  <div className="image w-100 pl-0 pb-2">
                    <img src="../dist/img/no_image.png" className="img-circle elevation-2" alt="User Image" />
                  </div>
                  <div className="info w-100">
                    <h6 className="d-block font-weight-semibold text-white">
                      <span>{/*Alexander Pierce*/}alexander....@gmail.com</span>{' '}
                      <i className="fas fa-angle-down crsr_pntr user_menutgl f_sz18 postop_2" />
                    </h6>
                  </div>
                  <div className="user_menu w-100 all_link bg-white" style={{ display: 'none' }}>
                    <a href className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-user-edit" title="Edit Account" />
                      </span>
                    </a>
                    <a href className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-file-invoice-dollar" title="Billing" />
                      </span>
                    </a>
                    <a href className="mx-4 my-3 d-inline-block fmaroon">
                      <span className="fmaroon">
                        <i className="fas fa-sign-out-alt" title="Logout" />
                      </span>
                    </a>
                  </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class
       with font-awesome or any other icon font library */}
                    <li className="nav-item">
                      <a href className="nav-link active">
                        {/*<i class="nav-icon far fa-building"></i>*/}
                        <img src="../dist/img/transparent.png" className="m_createprofile" />
                        <p>Create Workspace</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href className="nav-link">
                        {/*<i class="nav-icon fas fa-chart-line"></i>*/}
                        <img src="../dist/img/transparent.png" className="m_board isdisabled" />
                        <p>Board</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href className="nav-link">
                        {/*<i class="nav-icon fas fa-sliders-h"></i>*/}
                        <img src="../dist/img/transparent.png" className="m_settings isdisabled" />
                        <p>Settings</p>
                      </a>
                    </li>
                  </ul>
                </nav>
                {/* /.sidebar-menu */}
              </div>
              {/* /.sidebar */}
            </aside>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <div className="header_pagetitle d-block d-sm-block d-md-none">
                        <div className="text-center pt-2">
                          <h4>Create a Workspace</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="all_contentcntr_800">
                        <div className="all_contentflex_div mt-0 mt-sm-3 mb-5 align-items-center brdr_radius_div">
                          <div className="col-12 col-sm-12 col-md-12 px-0 brdr_radius_div">
                            <div className="allpage_title text-center pt-2 pt-sm-4">
                              <h4>
                                <span className="fmaroon">Step 3 of 3:</span> <span>Give a Name</span>
                              </h4>
                            </div>
                            <div className="all_content_div mt-3">
                              <div className="card w-100 mb-0 bx_shadow_sm">
                                {/* form start */}
                                <form className="form-horizontal formbiglbl" onSubmit={this.onClickButtonSubmit}>
                                  <div className="card-body">
                                    <div className="form-group row mb-5">
                                      <label className="col-12 col-sm-12 col-md-12 col-form-label text-center">
                                        Phone Number
                                      </label>
                                      <div className="col-12 col-sm-12 col-md-12 text-center">
                                        <h1 className="forange mt-0 mb-0 cprofile_phno">{this.state.plivo_number}</h1>
                                        {/* <input onChange={this.onChangephonenumber} value={this.state.plivo_number} /> */}
                                        <small className="text-gray d-inline-block">
                                          This is your primary contact number to send and receive messages.
                                        </small>
                                        {errors.map((error) => (
                                          <div id="error" className="alert alert-danger text-center" key={error}>
                                            {error}
                                          </div>
                                        ))}
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 text-center">
                                        <div className="image w-100 pl-0 pb-1 pt-5">
                                          <div className="ctsprofpiccntr">
                                            <div className="ctsaddprofpic">
                                              {this.state.prfImag != '' ? (
                                                <span>
                                                  <img
                                                    src={this.state.prfImag}
                                                    style={{
                                                      width: '80px',
                                                      height: '80px',
                                                      WebkitBorderRadius: '50%',
                                                      borderRadius: '50%',
                                                    }}
                                                    border={0}
                                                  />
                                                </span>
                                              ) : (
                                                <span className="usercircle_lg"></span>
                                              )}

                                              <div className="s_profileupld clearfix">
                                                <label className="btn-bs-file btn btn-black">
                                                  {/*Upload*/}
                                                  <i className="fas fa-camera" />
                                                  <input
                                                    type="file"
                                                    name="file"
                                                    onChange={(e) => this.onFileChangeHandler(e)}
                                                  />
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                      <label className="col-12 col-sm-12 col-md-4 col-form-label">Workspace Name</label>
                                      <div className="col-12 col-sm-12 col-md-8">
                                        <input
                                          value={this.state.business_name}
                                          onChange={this.onChangebusinessname}
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter the Workspace Name"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-12 col-sm-12 col-md-4 col-form-label mt-2">
                                        {this.state.team_members == 1 ? 'Invite Team Member' : 'Invite Team Member(s)'}{' '}
                                      </label>
                                      <div className="col-12 col-sm-12 col-md-8 mt-2 px-0">
                                        {/* <div>
  <div className="w-100 d-flex flex-row mb-3">
    <div className="col-10 col-sm-10 col-md-10"><input type="text" className="form-control" placeholder="Enter the email address" /></div>
    <div className="col-2 col-sm-2 col-md-2"><a><i className="fas fa-trash-alt mt-2 f_sz17 crsr_pntr fmaroon" /></a></div>
  </div> 
  <div className="w-100 d-flex flex-row mb-3">
    <div className="col-10 col-sm-10 col-md-10"><input type="text" className="form-control" placeholder="Enter the email address" /></div>
    <div className="col-2 col-sm-2 col-md-2"><a><i className="fas fa-trash-alt mt-2 f_sz17 crsr_pntr fmaroon" /></a></div>
  </div>
</div> */}
                                        {/* <div className="w-100 d-flex flex-row mb-3">
                                <div className="col-10 col-sm-10 col-md-10"><input value={this.state.team_emails[0]} onChange={this.onChangeFormGroupInput1.bind(this)} type="text" className="form-control" placeholder="Enter the email address" /></div>
                                <div className="col-2 col-sm-2 col-md-2"><a><i onClick={this.onClickButtonAdder} className="fas fa-plus-circle mt-2 f_sz17 crsr_pntr fmaroon" /></a></div>
                              </div> */}
                                        {this.state.team_emails.map((value, index) => (
                                          <FormGroup
                                            inputChange={this.onChangeFormGroupInput.bind(this, index)}
                                            buttonClick={
                                              index === 0
                                                ? this.onClickButtonAdder
                                                : this.onClickFormGroupButton.bind(this, index)
                                            }
                                            buttonDisabled={
                                              this.state.team_members == 1 ? { display: 'none' } : { display: 'block' }
                                            }
                                            buttonIcon={
                                              index === 0
                                                ? 'fas fa-plus-circle mt-2 f_sz17 crsr_pntr fmaroon'
                                                : 'fas fa-trash-alt mt-2 f_sz17 crsr_pntr fmaroon'
                                            }
                                            value={value}
                                            key={index}
                                          />
                                        ))}
                                        {/* <div className="w-100 d-flex flex-row mb-3">
                                <div className="col-10 col-sm-10 col-md-10"><input type="text" className="form-control" placeholder="Enter the email address" /></div>
                                <div className="col-2 col-sm-2 col-md-2"><a><i className="fas fa-plus-circle mt-2 f_sz17 crsr_pntr fmaroon" /></a></div>
                              </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  {/* /.card-body */}

                                  <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                    <button disabled={this.state.isLoading} className="btn btn-lg btnpdng_md btn-black">
                                      {this.state.isLoading ? 'Processing...' : 'Continue'}
                                    </button>
                                  </div>
                                  {/* /.card-footer */}
                                </form>
                                <div className="popup_new" style={{ display: 'none' }}>
                                  <div className="popup_inner">
                                    <div className="popup_header">
                                      <h5 className="fmaroon w-100 text-center">Upload Your Photo</h5>
                                    </div>

                                    <div className="popup_body">
                                      {this.state.src && (
                                        <ReactCrop
                                          src={this.state.src}
                                          crop={this.state.crop}
                                          ruleOfThirds
                                          onImageLoaded={this.onImageLoaded}
                                          onComplete={this.onCropComplete}
                                          onChange={this.onCropChange}
                                        />
                                      )}
                                    </div>

                                    <div align="center" className="mt-3">
                                      <button className="btn btn-black btnpdng_sm mr-2" onClick={this.save_imageCrop}>
                                        Save
                                      </button>

                                      <button
                                        className="btn btn-secondary crsr_pntr btnpdng_sm"
                                        onClick={this.close_imageCrop}>
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* Main Footer */}
            {/* <Footer_component /> */}
          </div>

          {/* ./wrapper */}
          {/* REQUIRED SCRIPTS */}
          {/* jQuery */}
          {/**/}
          {/* Bootstrap 4 */}
          {/**/}
          {/* AdminLTE App */}
        </div>
      </>
    );
  }
}
function FormGroup(props) {
  return (
    <div className="w-100 d-flex flex-row mb-3">
      <div className="col-10 col-sm-10 col-md-10">
        <input
          value={props.value}
          onChange={props.inputChange}
          className="form-control"
          placeholder="Enter the email address"
          pattern="^[a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$"
          oninvalid="this.setCustomValidity('Please enter a valid email address (example@example.com)')"
        />
      </div>
      <div className="col-2 col-sm-2 col-md-2">
        <a>
          <i style={props.buttonDisabled} onClick={props.buttonClick} tabIndex="-1" className={props.buttonIcon} />
        </a>
      </div>
    </div>
  );
}
