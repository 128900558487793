import React, {useEffect, Component, dangerouslySetInnerHTML} from 'react';
import * as ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import AsyncStorage from '@callstack/async-storage';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import 'react-phone-input-2/lib/style.css';
import $ from 'jquery';
import Header_component from '../includes/Header_component';
import qr_code from '../assets/img/app/qr_code.png';
import nft from '../assets/img/app/nft.png';
import ft from '../assets/img/app/ft.png';
import android from '../assets/img/app/android.jpg';
import app_store from '../assets/img/app/app_store.jpg';
import paraphrase from '../assets/img/app/paraphrase.png';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import {askForPermissioToReceiveNotifications, findingTokens} from '../../push-notification';
import queryString from 'query-string';
import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';
const notification_token = localStorage.getItem('notification_token');
const moment = extendMoment(Moment);
var today = new Date();
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;
class Apps extends Component {
  state = {
    dates: null,
    value: '',
    states: '',
    setYear: new Date().getFullYear(),
    setMonth: new Date().getMonth(),
    date: new Date(),
    start: '',
    end: '',
    showNewMonth: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      business_id: '',
      prfImag: '',
      errors: [],
      success: [],
      business_info_array: [],
    };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    var that = this;
    const socket = socketIOClient(ENDPOINT);
    console.log('notification_token', notification_token);
    var Notification = window.Notification || window.mozNotification || window.webkitNotification;
    console.log('Notification', Notification.permission);
    if (Notification?.permission) {
      if (
        (Notification?.permission === 'granted' || Notification?.permission === 'denied') &&
        notification_token != 'notification_token_removed'
      ) {
        $('.dsktopnotify_div').hide();
      } else {
        $('.dsktopnotify_div').show();
      }
    }

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
      }
    });

    setTimeout(() => {
      const onchangebusiness_name = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
        .then((res) => {
          if (res.data.status === 'success') {
            console.log('AAA');
            this.setState({
              business_info_array: res.data?.result,
              // business_name1: res.data?.result?.business.business_name,
              // business_color_code: res.data?.result?.business.color_code,
              // view_archive: res.data?.result?.business.view_archive,
              // prfImag: res?.data?.result?.business?.profile_image,
              // busines_info_details: res.data?.result,
              // business_user_id: res.data?.result?.business.user_id,
            });
            // this.monthDiff(new Date(),res.data?.result?.business?.created_date);
            var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
              (usr) => usr.user_id === this.state.user_id,
            );
            if (assigned_role_restrict) {
              this.user_roles_info(assigned_role_restrict.role_id);
            } else {
              console.log('assigned_role_restrict not found', assigned_role_restrict);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
          }
        });
    }, 100);

    setTimeout(() => {
      let url = this.props.location.search;
      let params = queryString.parse(url);
      if (params.paraphrase) {
        $('html, body').animate(
          {
            scrollTop: $('#para_phrase_div').offset().top,
          },
          2000,
        );
        // $('.para_phrase_div')[0].scrollIntoView(true);
        this.props.history.push('/apps');

        // document.getElementById('para_phrase_div').scrollIntoView(true);
        //   $('html,body').animate({
        //     scrollTop: $('.para_phrase_div').offset().top
        // }, 1000);
        //       $('html, body').animate({
        //         scrollTop: $(window.location.hash).offset().top - 60
        // }, 0);
        //   $('html, body').animate({
        //     'scrollBottom' : $(".para_phrase_div").position().top
        // });
        // window.scroll(0, findPosition(document.getElementById("ele")));
        // window.scrollTo(0, document.querySelector(".para_phrase_div").scrollHeight);
        // $('.para_phrase_div').scrollTop(true);
      }
    }, 500);

    // this.start_fn();
    this.setState({
      cssLoading: false,
      // run: true,
    });
    AsyncStorage.getItem('tour_firsttime').then((firsttime) => {
      if (firsttime === 'true') {
        this.setState({run: true});
      }
    });
    var that = this;
  }
  without_refresh() {
    this.setState({
      user_id: '',
      business_id: '',
      prfImag: '',
      errors: [],
      success: [],
      business_info_array: [],
    });

    setTimeout(() => {
      AsyncStorage.getItem('business_id').then((business_id) => {
        if (business_id) {
          this.setState({business_id});
        }
      });
      AsyncStorage.getItem('user_email').then((user_email) => {
        if (user_email) {
          this.setState({user_email});
        }
      });
      AsyncStorage.getItem('user_id').then((user_id) => {
        if (user_id) {
          this.setState({user_id});
        }
      });
    }, 100);
    setTimeout(() => {
      this.Business_info('');
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {}

  // Grid View
  handleButtonPress = () => {
    this.buttonPressTimer = setTimeout(() => console.log('long press activated'), 1500);
  };

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  };

  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
    }
  };

  onEnablenotification = async () => {
    const token = await askForPermissioToReceiveNotifications();
    if (token) {
      const notification_update_token = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        browser_token: token,
      };
      axios.post(API_CALL_ENDPOINT + '/notification/token/create', notification_update_token).then((res) => {
        if (res?.data?.status == 'success') {
          localStorage.setItem('notification_token', 'notification_token_added');
          this.onclosenotification();
        }
        if (res.data.error) {
        }
      });
    }
  };
  enableParaphrase = () => {
    $('.paraphrase_installing').show();
    $('.paraphrase_install').hide();
    const paraphrase_enabling = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'paraphrase',
    };
    axios.post(API_CALL_ENDPOINT + '/business/profile/settings', paraphrase_enabling).then((res) => {
      if (res?.data?.status == 'success') {
        this.Business_info('paraphrase');
      }
      if (res.data.error) {
      }
    });
  };
  Business_info = (setup) => {
    const onchangebusiness_name = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
      .then((res) => {
        if (res.data.status === 'success') {
          console.log('BBB');
          this.setState({
            business_info_array: res.data?.result,
          });
          if (setup == 'paraphrase') {
            setTimeout(() => {
              $('html, body').animate(
                {
                  scrollTop: $('#para_phrase_div').offset().top,
                },
                2000,
              );
            }, 150);
          }
          var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
            (usr) => usr.user_id === this.state.user_id,
          );
          if (assigned_role_restrict) {
            this.user_roles_info(assigned_role_restrict.role_id);
          } else {
          }
        }
      })
      .catch((error) => {});
  };

  goto_account_dashboard = (contact) => {
    if (!$('.sidebar-mini').hasClass('sidebar-collapse')) {
      $('.sidebar-mini').addClass('sidebar-closed sidebar-collapse');
    }

    this.props.history.push('/account_dashboard');
  };
  onclosenotification = () => {
    $('.dsktopnotify_div').hide();
  };

  redirectToPage = (value) => {
    this.props.history.push(value);
  };

  render() {
    const {errors, success, convcontact, run, steps} = this.state;
    console.log('this.state.business_info_array?.qr_codes?.length', this.state.business_info_array?.qr_codes?.length);
    return (
      <>
        <Helmet>
          <title>Redtie - Dashboard</title>
        </Helmet>
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <div id="body" className="hold-transition  accent-custom">
            <div className="wrapper">
              <Header_component
                redirectToPage={this.redirectToPage.bind(this)}
                data={this.update.bind(this)}
                cart_data={this.cart_update.bind(this)}
                trigger={this.without_refresh.bind(this)}
                title="true"
              />
              <ToastContainer
                transition={Flip}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Joyride
                callback={this.handleJoyrideCallback}
                steps={this.state.steps}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                // showProgress={true}
                hideBackButton={true}
                showSkipButton={true}
                styles={{
                  options: {
                    arrowColor: '#ffffff',
                    backgroundColor: '#ffffff',
                    // overlayColor: '#1f41378c',
                    primaryColor: '#e50038',
                    textColor: '#000000',
                    beaconSize: 60,
                    // width: 900,
                    zIndex: 10000,
                  },
                }}
              />
              <div className="dsktopnotify_div notifybg1">
                Redtie needs your permission to{' '}
                <a href="#" onClick={() => this.onEnablenotification()}>
                  enable desktop notifications
                </a>
                .
                <span onClick={() => this.onclosenotification()} className="notifyclose">
                  <i className="fas fa-times crsr_pntr" />
                </span>
              </div>

              <div className="content-wrapper" style={{minHeight: '368.438px'}}>
                {/* Main content */}
                <div className="content">
                  <div className="container-fluid">
                    <div className="row mt-3 position-relative">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                        <div className="card flex-lg-row acctstngs_profstngs_card profilesettings_card shadow-none">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="all_contentflex_div">
                              <div className="card w-100 shadow-none">
                                <div className="card-body p-0 mt-3 mb-3">
                                  <div className="appsmktplc_installed">
                                    <h4 className="text-center fmaroon"> Installed Apps</h4>
                                    <div className="apps_marketplace all_contentflex_div pt-4  pb-3 justify-content-center">
                                      {this.state.business_info_array?.qr_codes?.length != undefined &&
                                      this.state.business_info_array?.qr_codes?.length != '0' ? (
                                        <>
                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card bx_shadow_sm">
                                              {this.state.business_info_array?.qr_codes?.length != 0 &&
                                              this.state.business_info_array?.qr_codes?.length != undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                                </div>
                                              ) : (
                                                ''
                                              )}

                                              <div className="card-body box-profile">
                                                <a href="/qrcode">
                                                  {' '}
                                                  <img
                                                    className="img-fluid"
                                                    src={qr_code}
                                                    alt="User profile picture"
                                                  />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">QR Code Marketing App</h5>
                                                    <p>
                                                    QR Code is a viable technology to power your proximity marketing... {' '}
                                                      <a href="/qrcode">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 763 Users
                                                    </p> */}
                                                    {this.state.business_info_array?.qr_codes?.length != undefined &&
                                                    this.state.business_info_array?.qr_codes?.length != '0' ? (
                                                      <a
                                                        href="/appsdetails?integrations_list_qrcode=true"
                                                        className="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        GET
                                                      </a>
                                                    ) : this.state.business_info_array?.qr_codes?.length ==
                                                      undefined ? (
                                                      ''
                                                    ) : (
                                                      <a
                                                        href="/appsdetails?integrations_list_qrcode=true"
                                                        className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Install
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {this.state.business_info_array?.business?.nft?.status != '' ||
                                      this.state.business_info_array?.business?.nft?.status == 'requested' ||
                                      (this.state.business_info_array?.business?.nft?.status == 'approved' &&
                                        this.state.business_info_array?.business?.nft?.status != undefined) ? (
                                        <>
                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card bx_shadow_sm">
                                              {this.state.business_info_array?.business?.nft?.status == 'requested' &&
                                              this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                                </div>
                                              ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                                this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                                </div>
                                              ) : (
                                                ''
                                              )}

                                              <div className="card-body box-profile">
                                                <a href="/nftapp">
                                                  {' '}
                                                  <img
                                                    className="img-fluid"
                                                    src={nft}
                                                    alt="User profile picture"
                                                  />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">NFT Generator</h5>
                                                    <p>
                                                    A NFT or non-fungible token is a new type of digital...{' '}
                                                      <a href="/nftapp">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 158 Users
                                                    </p> */}
                                                    {this.state.business_info_array?.business?.nft?.status ==
                                                      'requested' &&
                                                    this.state.business_info_array?.business?.nft?.status !=
                                                      undefined ? (
                                                      <a
                                                        href="/appsdetails?nft_lists=true"
                                                        className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Installing
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                                      this.state.business_info_array?.business?.nft?.status !=
                                                        undefined ? (
                                                      <a
                                                        href="/appsdetails?nft_lists=true"
                                                        class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        GET
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.nft?.status ==
                                                      undefined ? (
                                                      ''
                                                    ) : (
                                                      <a
                                                        href="/appsdetails?nft_lists=true"
                                                        class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Install
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {this.state.business_info_array?.business?.fungible_token?.status != '' ||
                                      this.state.business_info_array?.business?.fungible_token?.status == 'requested' ||
                                      (this.state.business_info_array?.business?.fungible_token?.status == 'approved' &&
                                        this.state.business_info_array?.business?.fungible_token?.status !=
                                          undefined) ? (
                                        <>
                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card bx_shadow_sm">
                                              {this.state.business_info_array?.business?.fungible_token?.status ==
                                                'requested' &&
                                              this.state.business_info_array?.business?.fungible_token?.status !=
                                                undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                                </div>
                                              ) : this.state.business_info_array?.business?.fungible_token?.status !=
                                                  '' &&
                                                this.state.business_info_array?.business?.fungible_token?.status !=
                                                  undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                              {/* /.card-header */}
                                              <div className="card-body box-profile">
                                                <a href="/ftapp">
                                                  {' '}
                                                  <img className="img-fluid" src={ft} alt="User profile picture" />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">Fungible Token Creator</h5>
                                                    <p>
                                                    Tokens in a blockchain are assets that allow...{' '}
                                                      <a href="/ftapp">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 325 Users
                                                    </p> */}
                                                    {this.state.business_info_array?.business?.fungible_token?.status ==
                                                      'requested' &&
                                                    this.state.business_info_array?.business?.fungible_token?.status !=
                                                      undefined ? (
                                                      <a
                                                        href="/appsdetails?ft_lists=true"
                                                        className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Installing
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.fungible_token
                                                        ?.status != '' &&
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.status != undefined ? (
                                                      <a
                                                        href="/appsdetails?ft_lists=true"
                                                        class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        GET
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.fungible_token
                                                        ?.status == undefined ? (
                                                      ''
                                                    ) : (
                                                      <a
                                                        href="/appsdetails?ft_lists=true"
                                                        class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Install
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}

                                      {this.state.business_info_array?.business?.paraphrase == 'enabled' ? (
                                        <>
                                          <div
                                            className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 para_phrase_div"
                                            id="para_phrase_div">
                                            <div className="card bx_shadow_sm">
                                              <div className="ribbon-wrapper ribbon-lg">
                                                <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                              </div>
                                              {/* /.card-header */}
                                              <div className="card-body box-profile">
                                                <a href="/paraphraseapp">
                                                  {' '}
                                                  <img
                                                    className="img-fluid"
                                                    src={paraphrase}
                                                    alt="User profile picture"
                                                  />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">The Redtie Paraphraser</h5>
                                                    <p>
                                                      The Redtie Paraphraser is a fast and easy-to-use... {' '}
                                                      <a href="/paraphraseapp">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 354 Users
                                                    </p> */}
                                                    <a
                                                      href="/board?compose_messages=true"
                                                      class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                      GET
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {/* /.col */}
                                    </div>
                                  </div>
                                  <div className="appsmktplc_available">
                                    <h4 className="text-center fmaroon mt-5">Available Apps</h4>
                                    <div className="apps_marketplace all_contentflex_div pt-4  pb-3 justify-content-center">
                                      {this.state.business_info_array?.business?.paraphrase == 'disabled' ||
                                      !this.state.business_info_array?.business?.paraphrase ? (
                                        <>
                                          <div
                                            className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 para_phrase_div"
                                            id="para_phrase_div">
                                            <div className="card bx_shadow_sm">
                                              {/* /.card-header */}
                                              <div className="card-body box-profile">
                                                <a href="/paraphraseapp">
                                                  {' '}
                                                  <img
                                                    className="img-fluid"
                                                    src={paraphrase}
                                                    alt="User profile picture"
                                                  />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">The Redtie Paraphraser</h5>
                                                    <p>
                                                      The Redtie Paraphraser is a fast and easy-to-use... {' '}
                                                      <a href="/paraphraseapp">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 354 Users
                                                    </p> */}
                                                    <a
                                                      onClick={() => this.enableParaphrase()}
                                                      href="javascript:void(0)"
                                                      className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right paraphrase_install">
                                                      {' '}
                                                      Install
                                                    </a>
                                                    <a
                                                      style={{display: 'none'}}
                                                      href="javascript:void(0)"
                                                      className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right paraphrase_installing">
                                                      {' '}
                                                      Installing
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {this.state.business_info_array?.qr_codes?.length != undefined &&
                                      this.state.business_info_array?.qr_codes?.length == '0' ? (
                                        <>
                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card bx_shadow_sm">
                                              {this.state.business_info_array?.qr_codes?.length != 0 &&
                                              this.state.business_info_array?.qr_codes?.length != undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                                </div>
                                              ) : (
                                                ''
                                              )}

                                              <div className="card-body box-profile">
                                                <a href="/qrcode">
                                                  {' '}
                                                  <img
                                                    className="img-fluid"
                                                    src={qr_code}
                                                    alt="User profile picture"
                                                  />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">QR Code Marketing App</h5>
                                                    <p>
                                                    QR Code is a viable technology to power your proximity marketing... {' '}
                                                      <a href="/qrcode">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 763 Users
                                                    </p> */}
                                                    {this.state.business_info_array?.qr_codes?.length != undefined &&
                                                    this.state.business_info_array?.qr_codes?.length != '0' ? (
                                                      <a
                                                        href="/appsdetails?integrations_list_qrcode=true"
                                                        className="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        GET
                                                      </a>
                                                    ) : this.state.business_info_array?.qr_codes?.length ==
                                                      undefined ? (
                                                      ''
                                                    ) : (
                                                      <a
                                                        href="/appsdetails?integrations_list_qrcode=true"
                                                        className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Install
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {(this.state.business_info_array?.business?.nft?.status != 'requested' &&
                                        this.state.business_info_array?.business?.nft?.status != 'approved' &&
                                        this.state.business_info_array?.business?.nft?.status != undefined) ||
                                      (this.state.business_info_array?.business?.nft?.status == '' &&
                                        this.state.business_info_array?.business?.nft?.status != undefined) ? (
                                        <>
                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card bx_shadow_sm">
                                              {this.state.business_info_array?.business?.nft?.status == 'requested' &&
                                              this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                                </div>
                                              ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                                this.state.business_info_array?.business?.nft?.status != undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                                </div>
                                              ) : (
                                                ''
                                              )}

                                              <div className="card-body box-profile">
                                                <a href="/nftapp">
                                                  {' '}
                                                  <img
                                                    className="img-fluid"
                                                    src={nft}
                                                    alt="User profile picture"
                                                  />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">NFT Generator</h5>
                                                    <p>
                                                    A NFT or non-fungible token is a new type of digital...{' '}
                                                      <a href="/nftapp">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 158 Users
                                                    </p> */}
                                                    {this.state.business_info_array?.business?.nft?.status ==
                                                      'requested' &&
                                                    this.state.business_info_array?.business?.nft?.status !=
                                                      undefined ? (
                                                      <a
                                                        href="/appsdetails?nft_lists=true"
                                                        className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Installing
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.nft?.status != '' &&
                                                      this.state.business_info_array?.business?.nft?.status !=
                                                        undefined ? (
                                                      <a
                                                        href="/appsdetails?nft_lists=true"
                                                        class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        GET
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.nft?.status ==
                                                      undefined ? (
                                                      ''
                                                    ) : (
                                                      <a
                                                        href="/appsdetails?nft_lists=true"
                                                        class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Install
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {(this.state.business_info_array?.business?.fungible_token?.status == '' &&
                                        this.state.business_info_array?.business?.fungible_token?.status !=
                                          undefined) ||
                                      (this.state.business_info_array?.business?.fungible_token?.status !=
                                        'requested' &&
                                        this.state.business_info_array?.business?.fungible_token?.status !=
                                          'approved' &&
                                        this.state.business_info_array?.business?.fungible_token?.status !=
                                          undefined) ? (
                                        <>
                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="card bx_shadow_sm">
                                              {this.state.business_info_array?.business?.fungible_token?.status ==
                                                'requested' &&
                                              this.state.business_info_array?.business?.fungible_token?.status !=
                                                undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-warning text-lg rib_txt">Pending</div>
                                                </div>
                                              ) : this.state.business_info_array?.business?.fungible_token?.status !=
                                                  '' &&
                                                this.state.business_info_array?.business?.fungible_token?.status !=
                                                  undefined ? (
                                                <div className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon text-lg rib_installed rib_txt">Installed</div>
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                              {/* /.card-header */}
                                              <div className="card-body box-profile">
                                                <a href="/ftapp">
                                                  {' '}
                                                  <img className="img-fluid" src={ft} alt="User profile picture" />{' '}
                                                </a>
                                              </div>
                                              {/* /.card-body */}
                                              <div className="card-footer bg-transparent border-top">
                                                <div className="small-box bdr_sad_none">
                                                  <div className="inner">
                                                    <h5 className="pb-2">Fungible Token Creator</h5>
                                                    <p>
                                                    Tokens in a blockchain are assets that allow...{' '}
                                                      <a href="/ftapp">Know more</a>
                                                    </p>
                                                    {/* <p className="small-box-footer">
                                                      {' '}
                                                      <i className="fas fa-user" /> 325 Users
                                                    </p> */}
                                                    {this.state.business_info_array?.business?.fungible_token?.status ==
                                                      'requested' &&
                                                    this.state.business_info_array?.business?.fungible_token?.status !=
                                                      undefined ? (
                                                      <a
                                                        href="/appsdetails?ft_lists=true"
                                                        className="justify-content-end btn app_instal_rmg crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Installing
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.fungible_token
                                                        ?.status != '' &&
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.status != undefined ? (
                                                      <a
                                                        href="/appsdetails?ft_lists=true"
                                                        class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                        GET
                                                      </a>
                                                    ) : this.state.business_info_array?.business?.fungible_token
                                                        ?.status == undefined ? (
                                                      ''
                                                    ) : (
                                                      <a
                                                        href="/appsdetails?ft_lists=true"
                                                        class="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                        {' '}
                                                        Install
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                         <div className="card-body box-profile">
                                            <a href="/androidapp">
                                              {' '}
                                              <img
                                                className="img-fluid"
                                                src={android}
                                                alt="User profile picture"
                                              />{' '}
                                            </a>
                                          </div>
                                          <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">Android Application</h5>
                                                <p>
                                                  App available in Google play store...{' '}
                                                  <a href="/androidapp">Know more</a>
                                                </p>
                                                <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 261 Users
                                                </p>
                                                <a
                                                  href="https://play.google.com/store/apps/details?id=com.ephron.redtie.admin"
                                                  className="btn app_instal crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                                  {' '}
                                                  Install
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div className="card bx_shadow_sm">
                                          <div className="card-body box-profile">
                                            <a href="/iosapp">
                                              {' '}
                                              <img
                                                className="img-fluid"
                                                src={app_store}
                                                alt="User profile picture"
                                              />{' '}
                                            </a>
                                          </div>
                                         <div className="card-footer bg-transparent border-top">
                                            <div className="small-box bdr_sad_none">
                                              <div className="inner">
                                                <h5 className="pb-2">iOS Application</h5>
                                                <p>
                                                  App available in App store... <a href="/iosapp">Know more</a>
                                                </p>
                                                <p className="small-box-footer">
                                                  {' '}
                                                  <i className="fas fa-user" /> 354 Users
                                                </p>
                                                <a
                                                  a
                                                  href="https://apps.apple.com/us/app/redtie-biz/id1543530724"
                                                  className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right">
                                                  {' '}
                                                  Install
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}

                                      {/* /.col */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Apps;
