import React from 'react';
import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StripePaymentElement = (props) => {
  const stripe = useStripe();
  const elements = useElements();


  const handleSubmit = async (event) => {
    event.preventDefault();
    $(".pay_nw_btn").text("Processing...");
    $(".pay_nw_btn").prop('disabled', true);
    if (!stripe || !elements) {
      return;
    }
    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://devrv4.redtie.co/create-profile',
      },
    });
    if (error) {
      toast.dark(error.message, {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      $(".pay_nw_btn").text("Pay Now");
      $(".pay_nw_btn").prop('disabled', false);
      console.log("error pay",error.message);
    } else {
      props.onSubmitStripe();
    }

  }

  return (
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <div className="brdrradius_btm text-center bg-transparent mb-2">
          <div>
            <button
                // disabled={state.isLoading}
                className="btn btn-lg btnpdng_md btn-black pay_nw_btn mt-4">
              Pay Now
              {/*{state.isLoading ? 'Processing...' : 'Pay Now'}*/}
            </button>
          </div>
          <div className="mt-3 all_lin  k">
            <a onClick={props.isBack} className="changeplan_clk crsr_pntr">
              <span className="f_sz15">
                <i className="fas fa-long-arrow-alt-left mr-2"></i>
                Change Plan
              </span>
            </a>
          </div>
        </div>
      </form>

  );
};
// export const StripeCardElement = (props, ref) => {
//   const stripe = useStripe();
//   const elements = useElements();

//   return <CardElement />;
// };
