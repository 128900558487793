import React,{ Component } from 'react';
import {appendScript} from '../utils/appendScript';

class Footer_component extends Component {
	
	componentDidMount () {
		//appendScript("../plugins/select2/js/select2.full.min.js");
		//appendScript("../dist/js/adminlte.min.js");
		//appendScript("../plugins/bootstrap/js/bootstrap.bundle.min.js");
		//appendScript("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.1/js/bootstrap-select.min.js");
	}
	
	render() {
		return (
			<footer className="main-footer">
				<strong>Copyright &copy; 2020 <a href="" className="fmaroon">Redtie</a>.</strong> All rights reserved.
			</footer>
		);
	}
	
	
}

export default Footer_component;
