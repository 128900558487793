export { default as CreateProfile } from './CreateProfile';
export { default as createProfileServiceProvider } from './createProfileServiceProvider';
export { default as CreateProfile1 } from './CreateProfile1';
export { default as CreateProfile2 } from './CreateProfile2';
export { default as CreateProfile3 } from './CreateProfile3';
export { default as ForgotPassword } from './ForgotPassword';
export { default as ForgotPasswordMessage } from './ForgotPasswordMessage';
export { default as ResetPassword } from './ResetPassword';
export { default as SignUp } from './SignUp';
export { default as SignUpEmailcode } from './SignUpEmailcode';
export { default as SignUpsetpassword } from './SignUpsetpassword';
export { default as SignIn } from './SignIn';
export { default as Addon_login } from './Addon_login';
export { default as Onboard } from './Onboard';
export { default as Onboard1 } from './Onboard1';
//export {default as Video} from './Video';
//export {default as Audio} from './Audio';
//export {default as Audio_rec} from './Audio_rec';
export { default as Intel } from './Intel';
export { default as Attach_receiver } from './Attach_receiver';
//export {default as Video_demo} from './Video_demo';
//export {default as vedio_new_test} from './vedio_new_test';
export { default as Manageaccount } from './Manageaccount';
export { default as Invoice_details } from './Invoice_details';
export { default as Profile_settings } from './Profile_settings';
export { default as Profile_settings1 } from './Profile_settings1';
export { default as Receiver_phonepin } from './Receiver_phonepin';
export { default as Receiver_securitypreferences } from './Receiver_securitypreferences';
export { default as Receiver_standardpin } from './Receiver_standardpin';
export { default as Receiver_dynamicpin } from './Receiver_dynamicpin';
export { default as Receiver_forgot_pin_reset } from './Receiver_forgot_pin_reset';
export { default as Receiver_manageaccount } from './Receiver_manageaccount';
export { default as PDF_Viewer } from './PDF_Viewer';
export { default as Qrcode_Form } from './Qrcode_Form';
export { default as Custom_SignIn } from './Custom_SignIn';
export { default as Custom_SignUp } from './Custom_SignUp';
export { default as Pack_Invoice } from './Pack_Invoice';
export { default as Dashboard } from './Dashboard';
export { default as Dashboard1 } from './Dashboard1';
export { default as Account_Dashboard } from './Account_Dashboard';
export { default as PaymentHandle } from './PaymentHandle';
export { default as RenewProfile } from './RenewProfile';
export { default as Gumroad } from './Gumroad';
export { default as Paraphrase } from './Paraphrase';
export { default as NotificationRedirect } from './NotificationRedirect';
export { default as Apps } from './Apps';
export { default as AppsNew } from './AppsNew';
export { default as AppsDetails } from './AppsDetails';
export { default as QrCode } from './QrCode';
export { default as NftApp } from './NftApp';
export { default as FtApp } from './FtApp';
export { default as AndroidApp } from './AndroidApp';
export { default as IosApp } from './IosApp';
export { default as ParaphraseApp } from './ParaphraseApp';



