import React, {useEffect, Component, dangerouslySetInnerHTML} from 'react';
import * as ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import socketIOClient from 'socket.io-client';

import AsyncStorage from '@callstack/async-storage';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';

import 'react-phone-input-2/lib/style.css';

import $ from 'jquery';
import Header_component from '../includes/Header_component';

import transparent from '../assets/img/transparent.png';
import alert_plan from '../assets/img/alert_plan.png';
import dashboard_appsumo from '../assets/img/dashbrd_appsumodeal.png';
import alert_search from '../assets/img/alert_search.png';
import send_icon from '../assets/img/send_icon.png';
import send_icon_green from '../assets/img/send_icon_green.png';
import alert_conversation from '../assets/img/alert_conversation.png';
import imgalert_contact from '../assets/img/alert_contact.png';
import redtie_bot1 from '../assets/img/Redtie-AI.gif';
import redtie_bot from '../assets/img/redtie_bot.png';
import redtie_logo from '../assets/img/redtie_logo.png';
import nft_green from '../assets/img/nft_green.png';
import ft_green from '../assets/img/ft_green.png';
import prof_webhooks from '../assets/img/prof_webhooks.png';
import MicRecorder from 'mic-recorder-to-mp3';
import './multiselect/styles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import TrialPayment from './TrialPayment';
// import TrialPaymentButton from './TrialPaymentButton';
// import useScript, {loadCSS, removeCSS} from '../utils/useScript';
// import '../assets/css/innerpages_customstyle.css';
// import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from 'react-video-recorder';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
// import TrialPayment from './TrialPayment';
// import TrialPaymentButton from './TrialPaymentButton';
import {askForPermissioToReceiveNotifications} from '../../push-notification';
import Linkify from 'react-linkify';
// import FileUploadProgress from 'react-fileupload-progress';
import queryString from 'query-string';
import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
// import 'mediaelement';
// import 'mediaelement/build/mediaelementplayer.css';
// import ReactTooltip from 'react-tooltip';
import TextareaAutosize from 'react-autosize-textarea';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import {Chart, registerables} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {id} from 'date-fns/locale';
import addDays from 'date-fns/addDays';
import ConversionHelpers from '@ckeditor/ckeditor5-engine/src/conversion/conversionhelpers';

import DateRangePicker from 'react-daterange-picker';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';
import Calendar from 'react-calendar';
import DataTable, {createTheme} from 'react-data-table-component';
import plivo_logo from '../assets/img/plivo_logo.png';
import twilio_logo from '../assets/img/twilio_logo.png';
import bandwidth_logo from '../assets/img/bandwidth_logo.png';
const notification_token = localStorage.getItem('notification_token');
var packs_details = 0;
var appsumo_packs_details = 0;
function randDarkColor() {
  var lum = -0.25;
  var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
}
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
};

var tdata = [];

const tcolumns = [
  // {
  //   name: 'S.No',
  //   selector: 'SNo',
  //   sortable: true,
  //   filterable: true,
  //   left: true,
  // },
  {
    name: 'Name',
    selector: 'Name',
    sortable: true,
    left: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    left: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    left: true,
  },
];

const teamcolumns = [
  {
    name: 'Photo',
    selector: 'photo',
    sortable: false,
    left: true,
  },
  {
    name: 'Email Address',
    selector: 'email',
    sortable: true,
    left: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    left: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    left: true,
  },
];
//import "./DatePicker.css";
const moment = extendMoment(Moment);
const stateDefinitions = {
  available: {
    color: null,
    label: 'Available',
  },
  enquire: {
    color: '#ffd200',
    label: 'Enquire',
  },
  booking: {
    color: '#3BEFDF',
    label: 'Booking',
  },
  waiting: {
    color: '#FF4D4D',
    label: 'Waiting',
  },
  registration: {
    color: '#B0B0F7',
    label: 'Registration',
  },
  delivery: {
    color: '#EFAD9F',
    label: 'Delivery',
  },
  testing: {
    color: '#66ff66',
    label: 'Testing',
  },
  upgrade: {
    color: '#BB6FCD',
    label: 'Upgrade',
  },
  trip: {
    color: '#5066F2',
    label: 'Trip',
  },
  renovation: {
    color: '#F3B230',
    label: 'Renovation',
  },
  cleaning: {
    color: '#ff4d4d',
    label: 'Cleaning',
  },
  carSale: {
    color: '#8bd2da',
    label: 'Car Sale',
  },
  newCar: {
    color: '#40bf80',
    label: 'New Car',
  },
  unavailable: {
    // selectable: false,
    color: '#ff80ff',
    label: 'Unavailable',
  },
};
const dateRanges = [
  {
    state: 'enquire',
    range: moment.range(moment('2019-10-01'), moment('2019-10-31')),
  },
  {
    state: 'unavailable',
    range: moment.range(moment('2019-11-01'), moment('2019-11-30')),
  },
  {
    state: 'booking',
    range: moment.range(moment('2019-12-01'), moment('2019-12-31')),
  },
  {
    state: 'waiting',
    range: moment.range(moment('2020-01-01'), moment('2020-01-31')),
  },
  {
    state: 'registration',
    range: moment.range(moment('2020-02-01'), moment('2020-02-29')),
  },
  {
    state: 'delivery',
    range: moment.range(moment('2020-03-01'), moment('2020-03-31')),
  },
  {
    state: 'testing',
    range: moment.range(moment('2020-04-01'), moment('2020-04-30')),
  },
  {
    state: 'upgrade',
    range: moment.range(moment('2020-05-01'), moment('2020-05-31')),
  },
  {
    state: 'trip',
    range: moment.range(moment('2020-06-01'), moment('2020-06-30')),
  },
  {
    state: 'renovation',
    range: moment.range(moment('2020-07-01'), moment('2020-07-31')),
  },
  {
    state: 'cleaning',
    range: moment.range(moment('2020-08-01'), moment('2020-08-31')),
  },
  {
    state: 'carSale',
    range: moment.range(moment('2020-09-01'), moment('2020-09-30')),
  },
  {
    state: 'newCar',
    range: moment.range(moment('2020-10-01'), moment('2020-10-30')),
  },
];
var today = new Date();
var end_date;
var start_date;
var ival = 0;
var chart_labels = [];
var WorkFlowTable = '';
var WorkFlowTablestep = true;
var contactVl_id = [];
var tablearr = [];
var tablearrcount = 1;
var TemDtatable = '';
var AddMoreCount = 0;
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const qs = require('qs');
//var moment = require('moment');
const Mp3Recorder = new MicRecorder({bitRate: 128});
var placeholder = document.createElement('div');
placeholder.className = 'placeholder';
var placeholder_cmb = document.createElement('div');
placeholder.className = 'placeholder_cmb';
const rand = () => Math.floor(Math.random() * 255);
const rand1 = () => Math.floor(Math.random() * 50);
const ctypes = ['Sent', 'Received', 'Notes', 'Audio', 'Video', 'Images', 'Meeting', 'Documents'];
// const [startDate, setStartDate] = useState(new Date("2014/02/08"));
// const [endDate, setEndDate] = useState(new Date("2014/02/10"));
const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Auguest', 'September'],
  datasets: [
    {
      type: 'line',
      label: 'Sent',
      borderColor: `#60c8c8`,
      borderWidth: 2,
      fill: false,
      data: ['15', '10', '25', '20', '50', '75', '35', '55', '90'],
    },
    {
      type: 'line',
      label: 'Received',
      borderColor: `#ffa245`,
      borderWidth: 2,
      fill: false,
      data: [rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1()],
    },

    {
      type: 'bar',
      label: 'Notes',
      backgroundColor: `#ffb1c1`,
      stack: 1,
      data: ['15', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Audio',
      stack: 1,
      backgroundColor: `#9ad0f5`,
      data: ['10', '15', '35', '30', '90', '80', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Video',
      stack: 1,
      backgroundColor: `#ffcf9f`,
      data: ['25', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Images',
      stack: 1,
      backgroundColor: `#ccb2ff`,
      data: ['10', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Meeting',
      stack: 1,
      backgroundColor: `#a5dfdf`,
      data: ['15', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
  ],
};

const options = {
  responsive: true,
  legend: {
    display: false,
  },
  type: 'bar',
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

let Rnunique_id = Math.random().toString(36).substr(2, 16);
let Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);

let docAttachmentsCount = 0;
let docAttachmentsCount_cmb = 0;
let checkedValue_one_multiselect = [];
var first_emoji = false;
function validate(first_name, last_name, phone_number) {
  const errors = [];
  if (first_name.length === 0) {
    errors.push("First name can't be empty");
    return errors;
  }

  if (phone_number.length === 0) {
    errors.push("Phone Number can't be empty");
    return errors;
  }

  return errors;
}

class Account_Dashboard extends Component {
  state = {
    dates: null,
    value: '',
    states: '',
    setYear: new Date().getFullYear(),
    setMonth: new Date().getMonth(),
    date: new Date(),
    start: '',
    end: '',
    showNewMonth: true,
  };
  dateRangePickerSelect = (range, states, dates, start, end, value) => {
    console.log('datesdates', dates);
    this.setState({dates});
    this.setState({
      value: range,
      states: states,
    });
    setTimeout(() => {
      let selectedStartDate = this.state.value.start.format('DD/MMM/YYYY');
      let selectedEndDate = this.state.value.end.format('DD/MMM/YYYY');
      console.log('Selected Start:  ', selectedStartDate);
      console.log('Selected End:    ', selectedEndDate);
      var diffInMs = Math.abs(new Date(selectedStartDate) - new Date(selectedEndDate));
      var diffindays = diffInMs / (1000 * 60 * 60 * 24);
      if (diffindays > 1) {
        this.setState({hoursDisabled: true});
      } else {
        this.setState({hoursDisabled: false});
      }
      // $("#buttons_div").
      var default_dateval =
        moment(this.state.value.start).format('MMM DD, yyyy') +
        ' - ' +
        moment(this.state.value.end).format('MMM DD, yyyy');
      $('#default_dateval').val(default_dateval);
      this.getAllBusinessDetails(
        moment(this.state.value.start).format('MM-DD-yyyy'),
        moment(this.state.value.end).format('MM-DD-yyyy'),
        this.state.selectedBtnType,
      );
      this.setState({
        start: selectedStartDate,
        end: selectedEndDate,
      });
    }, 1000);
  };

  constructor(props) {
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'blockQuote',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo',
        ],
      },

      language: 'en',
    };
    super(props);
    this.state = {
      user_id: '',
      business_id: '',
      chart_data: [],
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      errors: [],
      success: [],
      contacts: [],
      contact_id: [],
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      business_team_details: [],
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      tag_list: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      ...props,
      showSearchComponent: false,
      run: false,
      stepIndex: 0,
      counter: 0,
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      template_view: false,
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      dcontact_det: {},
      newtagname_dtls: '',
      property_list_array: [],
      startDate: new Date(),
      endDate: '',
      worflow_details: [],
      teammembers_details: [],
      triggeredchange: false,
      dates: null,
      value: '',
      states: '',
      setYear: new Date().getFullYear(),
      setMonth: new Date().getMonth(),
      date: new Date(),
      start: '',
      end: '',
      showNewMonth: true,
      selectedBtnType: 3,
      hoursDisabled: true,
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      prflTeamLmt: '',
      assigned_user: [],
      assigned_count: '',
      asgBsnNam: '',
      emailAssgnClss: '',
      emailAssgnMSg: '',
      user_email: '',
      business_user_id: '',
      busines_info_details: {},
      qr_code_list: {},
      appsumo_redeem: '0',
      user_views_list_arr: [],
      rbot_conversation: [],
      dashboard_extra_details: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    //console.log(this.state.startDate)
  }

  status_hide(id, text_id) {
    // $('.TimeLinDivLs_Contacts_view_'+id).removeClass('show');
    $('.TimeLinDivLs_Contacts_view_' + id).hide();
    $('.resend_list_' + text_id).hide();
    // this.setState({timeLineListDetls:[]})
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // removeCSS("../assets/css/innerpages_customstyle.css")
  }

  componentDidMount() {
    var that = this;
    $(document).on('paste change', '.team_email', function () {
      $('.team_email').each(function () {
        var $self = $(this);
        setTimeout(function () {
          console.log('$(this).val()', $self.val());
          var pattern = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test($self.val())) {
            that.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: 'Please enter valid email address.',
            }));
            $('.add_team_members_btn').css({'pointer-events': 'none'});
          } else {
            that.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
            $('.add_team_members_btn').css({'pointer-events': 'auto'});
          }
        }, 100);
      });
      setTimeout(() => {
        that.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
        }));
      }, 2500);
    });

    $(document).on('click', '.addMoreEmail', function () {
      var teamfiled = 0;
      $('.team_email').each(function () {
        teamfiled++;
      });
      var total = parseInt(that.state.prflTeamLmt);
      var assigned = parseInt(that.state.assigned_count);
      var sub = parseInt(total - (assigned + teamfiled));
      console.log('sub', sub);
      console.log('teamfiled', teamfiled);
      if (sub > 0) {
        AddMoreCount++;
        var content =
          '<div class="w-100 d-flex flex-row mb-3 tema_email_more_' +
          AddMoreCount +
          ' team_email_common"><div class="col-11 col-sm-11 col-md-11"><input type="email" pattern="^([a-zA-Z0-9_-.]+)@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(([a-zA-Z0-9-]+.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$" class="form-control team_email" placeholder="Enter the email address" /></div><div class="col-1 col-sm-1 col-md-1"><a class="remove_team_email" data-id="' +
          AddMoreCount +
          '"><i class="fas fa-trash-alt mt-3 f_sz17 crsr_pntr fmaroon" /></a></div></div>';
        $('.add_team_aapend_div').append(content);
      } else {
        $('.addMoreEmail').hide();
      }
    });
    $(document).on('click', '.remove_team_email', function () {
      var dataid = $(this).data('id');
      $('.tema_email_more_' + dataid + '').remove();
      var teamfiled = 0;
      $('.team_email').each(function () {
        teamfiled++;
      });
      var total = parseInt(that.state.prflTeamLmt);
      var assigned = parseInt(that.state.assigned_count);
      var sub = parseInt(total - (assigned + teamfiled));
      if (sub > 0) {
        $('.addMoreEmail').show();
      } else {
        $('.addMoreEmail').hide();
      }
    });
    $(document).on('click', '.resendTeam', function () {
      var dataid = $(this).data('id');
      that.asignResnd(dataid);
    });
    $(document).on('click', '.deleteTeam', function () {
      var dataid = $(this).data('id');
      that.asignEmlDelt(dataid);
    });
    $(document).on('click', '.integration_details', function () {
      var dataid = $(this).data('id');
      that.integrationDetails(dataid);
    });
    $(document).on('click', '.view_workflow', function () {
      var dataid = $(this).data('id');
      that.workFlowDetails(dataid);
      // window.location.href = '/profile_settings?workflow=true&workflow_id=' + dataid + '';
    });
    $('.chart_dropdown').on('click', function (e) {
      e.stopPropagation();
    });
    $('.date_range_filter').on('click', function () {
      $('.dashchartfilterdrpdwn_pos').toggle();
    });

    $('#default_dateval').on('click', function () {
      $('.dashchartfiltercalendardrpdwn_pos').toggle();
    });

    today = new Date();
    end_date = today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    var start = new Date('1/1/' + new Date().getFullYear());
    start_date = start.getMonth() + 1 + '-' + start.getDate() + '-' + start.getFullYear();
    var default_dateval = moment(start).format('MMM DD, yyyy') + ' - ' + moment(today).format('MMM DD, yyyy');
    $('#default_dateval').val(default_dateval);
    window.scrollTo(0, 0);
    //();
    window.addEventListener('resize', this.handleResize);
    this.setState({window_height_conv: window.innerHeight - 200, window_height_cont: window.innerHeight - 200});
    $('.cmb_sendmessage_btn').hide();

    const socket = socketIOClient(ENDPOINT);

    var Notification = window.Notification || window.mozNotification || window.webkitNotification;
    if (Notification?.permission) {
      if (
        (Notification?.permission === 'granted' || Notification?.permission === 'denied') &&
        notification_token != 'notification_token_removed'
      ) {
        $('.dsktopnotify_div').hide();
      } else {
        $('.dsktopnotify_div').show();
      }
    }

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });

    setTimeout(() => {
      this.getAllBusinessDetails();
    }, 100);

    this.setState({
      cssLoading: false,
      // run: true,
    });
    AsyncStorage.getItem('tour_firsttime').then((firsttime) => {
      if (firsttime === 'true') {
        this.setState({run: true});
      }
    });
    //this.user_views_list();
    var that = this;
    $('.cmb_schedule').click(function () {
      that.scheduledatetime_tgl1();
    });
    $('.cmb_save').click(function () {
      that.msg_save_cmb();
    });
    $('.comp_schedule').click(function () {
      that.scheduledatetime_tgl();
    });
    $('.comp_save').click(function () {
      this.msg_save();
    });
    $('.comp_update').click(function () {
      this.msg_update();
    });
    /**********Contacts Popup**********/

    $('.addcontact_modalclk').click(function () {
      $('.searchcontacts_modaldiv').hide();
      $('.addcontacts_modaldiv').show();
      $('.addcontacts_modaltxt').hide();
      $('.searchcontacts_modaltxt').show();
    });

    $('.searchcontact_modalclk').click(function () {
      $('.addcontacts_modaldiv').hide();
      $('.searchcontacts_modaldiv').show();
      $('.searchcontacts_modaltxt').hide();
      $('.addcontacts_modaltxt').show();
    });

    /**********Template Popup**********/

    $('.addtemplate_modalclk').click(function () {
      $('.searchtemplates_modaldiv').hide();
      $('.addtemplate_modaldiv').show();
      $('.addtemplates_modaltxt').hide();
      $('.searchtemplates_modaltxt').show();
    });

    // $('.searchtemplate_modalclk').click(function () {
    //   $('.addtemplate_modaldiv').hide();
    //   $('.searchtemplates_modaldiv').show();
    //   $('.searchtemplates_modaltxt').hide();
    //   $('.addtemplates_modaltxt').show();
    // });

    /**********Tag Popup**********/

    $('.addtag_modalclk').click(function () {
      $('.searchtag_modaldiv').hide();
      $('.addtag_modaldiv').show();
      $('.addtags_modaltxt').hide();
      $('.searchtags_modaltxt').show();
    });

    $('.searchtag_modalclk').click(function () {
      $('.addtag_modaldiv').hide();
      $('.searchtag_modaldiv').show();
      $('.searchtags_modaltxt').hide();
      $('.addtags_modaltxt').show();
    });

    /**********Mobile Preview**********/

    $('.viewsentmessage_phone_close').click(function () {
      $('.preview_txtmsg_wideview').hide();
      $('.preview_txtmsg').show();
    });
    /**********add property**********/
    $('.addproperty_clk').on('click', function () {
      $('.addproperty_div').show();
    });
    //disable copy
    // $('body').bind('copy', function (e) {
    //   e.preventDefault();
    //   return false;
    // });
    setTimeout(() => {
      $('.cmb_buttons_hide').show();
    }, 1000);

    //console.log("Output", this.state.businessprofiledetails);
    // $('body').css({overflow: 'hidden'});
  }
  without_refresh() {
    this.setState({
      user_id: '',
      business_id: '',
      chart_data: [],
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      errors: [],
      success: [],
      contacts: [],
      contact_id: [],
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      business_team_details: [],
      businessTeamDetails: {},
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      tag_list: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      showSearchComponent: false,
      run: false,
      stepIndex: 0,
      counter: 0,
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      template_view: false,
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      dcontact_det: {},
      newtagname_dtls: '',
      property_list_array: [],
      startDate: '',
      endDate: '',
      worflow_details: [],
      teammembers_details: [],
      selectedBtnType: 3,
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      prflTeamLmt: '',
      assigned_user: [],
      assigned_count: '',
      asgBsnNam: '',
      emailAssgnClss: '',
      emailAssgnMSg: '',
      user_email: '',
      business_user_id: '',
      busines_info_details: {},
      qr_code_list: {},
      // appsumo_redeem: '0'
      user_views_list_arr: [],
      rbot_conversation: [],
      dashboard_extra_details: '',
    });
    today = new Date();
    end_date = today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    //console.log(start_date);
    var start = new Date('1/1/' + new Date().getFullYear());
    start_date = start.getMonth() + 1 + '-' + start.getDate() + '-' + start.getFullYear();
    var default_dateval = moment(start).format('MMM DD, yyyy') + ' - ' + moment(today).format('MMM DD, yyyy');
    $('#default_dateval').val(default_dateval);
    setTimeout(() => {
      AsyncStorage.getItem('business_id').then((business_id) => {
        if (business_id) {
          this.setState({business_id});
        }
      });
      AsyncStorage.getItem('user_email').then((user_email) => {
        if (user_email) {
          this.setState({user_email});
          console.log('email code');
          console.log(user_email);
        }
      });
      AsyncStorage.getItem('user_id').then((user_id) => {
        if (user_id) {
          this.setState({user_id});
        }
      });
    }, 100);
    // this.user_views_list();
    setTimeout(() => {
      this.getAllBusinessDetails(start_date, end_date, 3);
      //this.Business_info();
      // const taglist = {
      //   params: {
      //     apikey: API_KEY,
      //     secret: API_SECRET,
      //     user_id: this.state.user_id,
      //     business_id: this.state.business_id,
      //   },
      // };
      // axios
      //   .get(API_CALL_ENDPOINT + '/tags/list', taglist)
      //   .then((res) => {
      //     this.setState({
      //       tag_list: res.data.result,
      //       newtagname_dtls: '',
      //     });
      //     this.setState({quick_contact_id: res.data.result[0]._id});
      //     var tag_remove = res.data.result.length;
      //     for (var i = 0; i < tag_remove; i++) {
      //       document.getElementById('tagdtls_' + res?.data?.result[i]?._id).checked = false;
      //     }

      //     var tag_arrayLength = this.state.dtls_contact_tag_list.length;
      //     for (var j = 0; j < tag_arrayLength; j++) {
      //       document.getElementById('tagdtls_' + this.state?.dtls_contact_tag_list[j]).checked = true;
      //     }
      //   })
      //   .catch((error) => {});
    }, 1000);
    // WorkFlowTable = '';
    // WorkFlowTablestep = true;
  }

  teamMeberReload = (val) => {
    if (val === '0') {
      $('.addMoreEmail').hide();
    } else {
      $('.addMoreEmail').show();
    }
    const assigned_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    console.log(assigned_details);
    axios
      .get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState(() => ({
            prflTeamLmt: res.data.result.team_members,
            assigned_user: res.data.result.assigned_user,
            assigned_count: res.data.result.assigned_count,
          }));
          $('.team_email_common').remove();
          $('.team_email').val('');
          if (res.data.result?.assigned_user.length != 0) {
            console.log('res.data.result?.assigned_user', res.data.result?.assigned_user);
            var tdataarr = [];
            $.each(res.data.result?.assigned_user, function (index, values) {
              var frst_ltr = values.user_info[0].email_address.charAt(0);
              var frstLtrCaps = frst_ltr.toUpperCase();
              var randomClr = randDarkColor();
              // incval++;
              if (values.user_info[0].profile_image != '') {
                var photo = (
                  <span className="usercircleimg_md">
                    <img src={values.user_info[0].profile_image} border="0" />
                  </span>
                );
              } else {
                var photo = (
                  <span className="usercircle_md bg_teammember1" style={{backgroundColor: randomClr}}>
                    {frstLtrCaps}
                  </span>
                );
              }
              if (values.user_info[0].status === 'assigned') {
                var ststus = <span className="forange font-weight-bold">Invited</span>;
              } else {
                var ststus = <span className="fgreen4 font-weight-bold">Active</span>;
              }
              if (values.user_info[0].status === 'assigned') {
                var action = (
                  <a class="mr-2 mb-2 d-inline-block resendTeam" data-id={values._id.assigned_id} href={void 0}>
                    <i class="fas fa-redo-alt mr-1" />
                    Resend
                  </a>
                );
              } else {
                var action = (
                  <a href={void 0} className="deleteTeam" data-id={values._id.assigned_id}>
                    <i className="far fa-trash-alt fmaroon" onClick={this.asignEmlDelt} id={values._id.assigned_id} />
                  </a>
                );
              }
              var newdata = {
                id: values._id,
                photo: photo,
                email: values.user_info[0].email_address,
                Status: ststus,
                Action: action,
              };
              tdataarr.push(newdata);
              console.log("values.user_info[0].status === 'assigned'", values.user_info[0].status === 'assigned');
            });
            console.log('tdataarr', tdataarr);
            this.setState({
              teammembers_details: tdataarr,
            });
          } else {
            this.setState({
              teammembers_details: '',
            });
          }

          var total = parseInt(res.data.result.team_members);
          var assigned = parseInt(res.data.result.assigned_count);
          var sub = parseInt(total - assigned);
          console.log(total, 'total');
          console.log(assigned, 'assigned');
          console.log(sub, 'sub');
          if (sub > 0) {
            $('.addMoreEmail').show();
            $('.team_email').attr('readonly', false);
          } else {
            $('.addMoreEmail').hide();
            $('.team_email').attr('readonly', true);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
        }
      });

    setTimeout(() => {
      this.setState(() => ({
        emailAssgnClss: '',
        emailAssgnMSg: '',
        showModalTeamadd: false,
      }));
    }, 2500);
  };

  onClickButtonSubmit = (event) => {
    var values = [];
    $('.team_email').each(function () {
      if (this.value.length !== 0 && this.value !== '') {
        values.push(this.value);
      }
    });

    console.log(values);
    console.log('this.state.user_email', this.state.user_email);
    const assigned_email = values.toString();
    console.log('assigned_email', assigned_email);
    if (assigned_email.includes(this.state.user_email)) {
      this.setState(() => ({
        emailAssgnClss: 'alert alert-danger',
        emailAssgnMSg: 'Account email cannot be added as a team member, please try a different one.',
        fields: [''],
      }));
      setTimeout(() => {
        this.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
          showModalTeamadd: false,
        }));
      }, 5000);
      return false;
    }
    if (values.length !== 0) {
      var assignDetls = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        assigned_email: assigned_email,
      };

      console.log(assignDetls);

      axios
        .post(API_CALL_ENDPOINT + '/user/business/assign', assignDetls)
        .then((res) => {
          console.log(res);

          if (res.data.status == 'success') {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-success',
              emailAssgnMSg: res.data.message,
              fields: [''],
            }));
            var default_dateval = $('#default_dateval').val();
            var trainindIdArray = default_dateval.split('-');
            var bstartdate = moment(trainindIdArray[0]).format('MM-DD-yyyy');
            var benddate = moment(trainindIdArray[1]).format('MM-DD-yyyy');
            this.getAllBusinessDetails(bstartdate, benddate, '');
            this.teamMeberReload('1');
          }

          if (res.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
                showModalTeamadd: false,
              }));
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                emailAssgnClss: 'alert alert-danger',
                emailAssgnMSg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  emailAssgnClss: '',
                  emailAssgnMSg: '',
                  showModalTeamadd: false,
                }));
              }, 2500);
            }
          }
        });
    } else {
      this.setState(() => ({
        emailAssgnClss: 'alert alert-danger',
        emailAssgnMSg: 'Please enter a valid email address.',
      }));

      setTimeout(() => {
        this.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
        }));
      }, 2500);
    }
    // }
  };

  filterBtnClick = (val) => {
    var type = val;
    var chart;
    this.setState({selectedBtnType: type});
    var default_dateval = $('#default_dateval').val();
    var trainindIdArray = default_dateval.split('-');
    var bstartdate = moment(trainindIdArray[0]).format('MM-DD-yyyy');
    var benddate = moment(trainindIdArray[1]).format('MM-DD-yyyy');
    this.getAllBusinessDetails(bstartdate, benddate, type);
    console.log('trainindIdArray', bstartdate, benddate, chart);
  };
  smallCalenderChange = (date) => {
    this.setState({date});
    let newD = new Date(date);
    this.setState({
      setMonth: newD.getMonth(),
      setYear: newD.getFullYear(),
    });
  };

  upgradeWorkspace = (business_id, appstore_transaction_id) => {
    console.log("appstore_transaction_id",appstore_transaction_id);
    if (appstore_transaction_id != undefined && appstore_transaction_id != '') {
      $('.close').trigger('click');
      $('#popup_ios_modal_payment').trigger('click');
      return false;
    }
    window.location.href = '/create-profile?bid=' + business_id;
  };

  componentDidUpdate(prevProps, prevState) {
    //  if (this.state.setMonth !== _state.setMonth) {
    // this.setState({ showNewMonth: false }, () =>
    //   this.setState({ showNewMonth: true })
    // );
    //  }
    if (prevState !== this.state) {
      //
    }
    if (
      prevState.scSelectedContacts !== this.state.scSelectedContacts ||
      prevState.scSelectedCategories !== this.state.scSelectedCategories
    ) {
      this.scSearchConversation();
    }

    if (
      prevState.attachmentlimit_count !== this.state.attachmentlimit_count ||
      this.state.atcharryDet_cmb ||
      prevState.attachmentdoc_count_cmb !== this.state.attachmentdoc_count_cmb
    ) {
      //
    }
    if (prevState.notesValue2_cmb !== this.state.notesValue2_cmb) {
      if (this.state.cmb_notesedit === false) {
        if (this.state.notesValue2_cmb?.length >= 13) {
          this.saveNotes2_cmb();
        }
      }

      this.autoupdateNotes2_cmb();
    }
    if (prevState.notesValue2 !== this.state.notesValue2) {
      if (this.state.comp_notesedit === false) {
        if (this.state.notesValue2?.length >= 13) {
          this.saveNotes2();
        }
      }

      this.autoupdateNotes2();
    }
  }

  profilesettings_redirection = (business_id) => {
    AsyncStorage.setItem('business_id', business_id);
    this.props.history.push('/profile_settings?integrations_list=true');
    //window.location.reload(true);
  };

  dashboard_redirection = (business_id) => {
    AsyncStorage.setItem('business_id', business_id);
    this.props.history.push('/dashboard');
    //window.location.reload(true);
  };

  board_redirection = (business_id) => {
    AsyncStorage.setItem('business_id', business_id);
    this.props.history.push('/board');
    //window.location.reload(true);
  };

  profile_settings_redirection = (business_id) => {
    AsyncStorage.setItem('business_id', business_id);
    this.props.history.push('/profile_settings');
    //window.location.reload(true);
  };

  appsumoReedem = () => {
    this.props.history.push('/manage_account?bsid=true&bis_id=' + this.state.business_id + '');
    //window.location.reload(true);
  };
  integrationDetails = (type) => {
    this.props.history.push('/profile_settings?integrations=true&type=' + type + '');
    //window.location.reload(true);
  };

  workFlowDetails = (workflow_id) => {
    this.props.history.push('/profile_settings?workflow=true&workflow_id=' + workflow_id + '');
    //window.location.reload(true);
  };

  onChangesearchconv = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);
    // this.props.onChange(values);
    this.setState({search_status_conv: values});

    setTimeout(() => {
      this.contacmessage_search();
    }, 500);
  };
  onChangesearchtext = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({search_text_conv: e.target.value});
  };

  onChangeaddfname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({first_name: e.target.value});
  };
  onChangeaddlname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({last_name: e.target.value});
  };
  onChangeaddphone = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dphone_number: e.target.value});
    this.setState({phone_number: e.target.value});
  };

  onChangeupfname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dfirst_name: e.target.value});
  };
  onChangeuplname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dlast_name: e.target.value});
  };
  onChangeupphone = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dphone_number: e.target.value});
  };
  onChangeupemail = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({demail_address: e.target.value});
  };
  onChangeupdob = (e) => {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();

    this.setState({ddob: e.target.value});
  };
  onChangeCompanyid = (e) => {
    //console.log(e.target.value);
    this.setState({company_id: e.target.value});
  };
  onChangecompname = (e) => {
    this.setState({company_name: e.target.value});
  };

  addNewtagonchange = (e) => {
    this.setState({newtagname: e.target.value});
  };
  onChangebusiness = (business) => {
    AsyncStorage.setItem('business_id', business.business._id);
    window.location.reload(true);
  };
  scheduledChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  opnDrpwn() {
    $('.search-result').toggle();
  }
  closeLightbox = (e) => {
    this.setState({Lightbox_open: false});
    if (this.state.template_document_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({template_document_click: false});
    }
  };
  closeLightbox_video = () => {
    this.setState({Lightbox_open_video: false});
    if (this.state.template_video_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({template_video_click: false});
    }
  };

  // Grid View
  gridView = () => {
    $('#v-pills-list1').removeClass('active');
    $('#v-pills-list1').removeClass('show');
    $('#v-pills-grid1').addClass('show');
    $('#v-pills-grid1').addClass('active');
  };
  listView1 = () => {
    $('#v-pills-list2').addClass('active');
    $('#v-pills-list2').addClass('show');
    $('#v-pills-grid2').removeClass('show');
    $('#v-pills-grid2').removeClass('active');
  };

  // Grid View
  gridView1 = () => {
    $('#v-pills-list2').removeClass('active');
    $('#v-pills-list2').removeClass('show');
    $('#v-pills-grid2').addClass('show');
    $('#v-pills-grid2').addClass('active');
  };
  new_attach_message = () => {
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary1').show();
    $('#custom-tabs-medialibrary').hide();
    $('#custom-tabs-medialibrary1').addClass('active');
    $('#custom-tabs-medialibrary1').addClass('show');
    $('#custom-tabs-medialibrary').removeClass('show');
    $('#custom-tabs-medialibrary').removeClass('active');
  };
  lib_attach_message = () => {
    this.colapse_all_attachments_comp();
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary').show();
    $('#custom-tabs-medialibrary1').hide();
    $('#custom-tabs-medialibrary').addClass('active');
    $('#custom-tabs-medialibrary').addClass('show');
    $('#custom-tabs-medialibrary1').removeClass('show');
    $('#custom-tabs-medialibrary1').removeClass('active');
    this.attachment_admin_list();
    this.attachment_receiver_list();
  };

  handleButtonPress = () => {
    this.buttonPressTimer = setTimeout(() => console.log('long press activated'), 1500);
  };

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  };

  colapsed_attach = (id) => {
    $('.attachment_colapsed').show();
    $('.attachment_colapsed_' + id).hide();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).show();
  };
  expanded_attach = (id) => {
    $('.attachment_colapsed').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).hide();
  };
  colapse_all_attachments = () => {
    $('.attachment_colapsed').show();
    $('.attachment_expanded').hide();
  };
  colapsed_attach_comp = (id) => {
    $('.attachment_colapsed_comp').show();
    $('.attachment_colapsed_comp_' + id).hide();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).show();
  };
  expanded_attach_comp = (id) => {
    $('.attachment_colapsed_comp').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).hide();
  };
  colapse_all_attachments_comp = () => {
    $('.attachment_colapsed_comp').show();
    $('.attachment_expanded_comp').hide();
  };

  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
      //console.log('updated');
    }
  };

  contactDetails = (
    id,
    birthday,
    first_name,
    last_name,
    phone_number,
    phone_list,
    email_address,
    company,
    website,
    title,
  ) => {
    $('#f_name').val(first_name);
    $('#l_name').val(last_name);
    $('#phone_number').val(phone_number[0]);
    $('#email_address').val(email_address[0]);
    if (birthday.date != 0 && birthday.month != 0 && birthday.date != null && birthday.month != null) {
      $('#dob').val(birthday.month + '/' + birthday.date);
    }
  };

  tagonChange(event, tagID) {
    var tag_array = this.state.contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected = this.selected || {};
    if (event.target.checked) {
      this.selected[tagID] = true;
    } else {
      delete this.selected[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      filtered_tag_list_up: Object.keys(this.selected),
      contact_tag_list: tag_array,
    });
  }
  tagonChange_dtls(event, tagID) {
    var tag_array = this.state.dtls_contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected_dtls = this.selected_dtls || {};
    if (event.target.checked) {
      this.selected_dtls[tagID] = true;
    } else {
      delete this.selected_dtls[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      dtls_filtered_tag_list_up: Object.keys(this.selected_dtls),
      dtls_contact_tag_list: tag_array,
    });
  }
  asignEmlDelt = (event) => {
    var delEmlDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      assigned_id: event.target.id,
    };

    console.log(delEmlDetls);

    axios
      .post(API_CALL_ENDPOINT + '/user/assigned/remove', delEmlDetls)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-success',
            emailAssgnMSg: res.data.message,
          }));
          var default_dateval = $('#default_dateval').val();
          var trainindIdArray = default_dateval.split('-');
          var bstartdate = moment(trainindIdArray[0]).format('MM-DD-yyyy');
          var benddate = moment(trainindIdArray[1]).format('MM-DD-yyyy');
          this.getAllBusinessDetails(bstartdate, benddate, '');
          this.teamMeberReload('1');
        }

        if (res.data.error) {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-danger',
            emailAssgnMSg: res.data.error.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: error.response.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
              }));
            }, 2500);
          }
        }
      });
  };

  asignResnd = (event) => {
    var ReassignDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      assigned_id: event,
    };

    console.log(ReassignDetls);

    axios
      .post(API_CALL_ENDPOINT + '/user/assigned/email', ReassignDetls)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-success',
            emailAssgnMSg: res.data.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }

        if (res.data.error) {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-danger',
            emailAssgnMSg: res.data.error.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: error.response.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
              }));
            }, 2500);
          }
        }
      });
  };

  getAllBusinessDetails = () => {
    const businessprofiledetails = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/user/dashboard/details', businessprofiledetails)
      .then((res) => {
        if (res.data.status === 'success') {
          if (res.data.result) {
            this.setState({
              businessprofiledetails: res.data.result,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState({
              businessprofiledetails: [],
            });
          }
        }
      });
  };

  display_contcat_count = (count) => {
    if (count >= 1000) {
      var val = count / 1000;
      if (!Number.isInteger(val)) {
        return parseInt(val) + ' K+';
      } else {
        return parseInt(val) + ' K';
      }
    } else {
      return parseInt(count);
    }
  };

  copyToClipboard = (elementId) => {
    var copyText = document.getElementById(elementId);
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
    toast.dark('Copied successfully', {
      position: 'top-right',
      transition: Flip,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  onEnablenotification = async () => {
    const token = await askForPermissioToReceiveNotifications();
    if (token) {
      const notification_update_token = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        browser_token: token,
      };
      axios.post(API_CALL_ENDPOINT + '/notification/token/create', notification_update_token).then((res) => {
        if (res?.data?.status == 'success') {
          localStorage.setItem('notification_token', 'notification_token_added');
          this.onclosenotification();
        }
        if (res.data.error) {
        }
      });
    }
  };

  render() {
    let initialYear = this.state.setYear;
    let initialMonths = this.state.setMonth;
    let noOfMonths = 2;
    let smallCalenderMinDate = new Date(new Date().setFullYear(new Date().getFullYear() - 4));
    let smallCalenderMaxDate = new Date();
    new Date().setDate(new Date().getDate() - 1);
    let minimumDate = new Date(new Date().setFullYear(new Date().getFullYear() - 4));
    let maximumDate = this.state.date;
    new Date().setDate(new Date().getDate() - 1);
    var moment = require('moment');
    const {errors, success, convcontact, run, steps} = this.state;
    return (
      <>
        <Helmet>
          <title>Redtie - Account Dashboard</title>
        </Helmet>
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <div id="body" className="hold-transition  accent-custom">
            <div className="wrapper">
              <Header_component
                data={this.update.bind(this)}
                cart_data={this.cart_update.bind(this)}
                trigger={this.without_refresh.bind(this)}
                title="true"
              />
              <ToastContainer
                transition={Flip}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Joyride
                callback={this.handleJoyrideCallback}
                steps={this.state.steps}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                // showProgress={true}
                hideBackButton={true}
                showSkipButton={true}
                styles={{
                  options: {
                    arrowColor: '#ffffff',
                    backgroundColor: '#ffffff',
                    // overlayColor: '#1f41378c',
                    primaryColor: '#e50038',
                    textColor: '#000000',
                    beaconSize: 60,
                    // width: 900,
                    zIndex: 10000,
                  },
                }}
              />
              <div className="dsktopnotify_div notifybg1">
                Redtie needs your permission to{' '}
                <a href="#" onClick={() => this.onEnablenotification()}>
                  enable desktop notifications
                </a>
                .
                <span onClick={() => this.onclosenotification()} className="notifyclose">
                  <i className="fas fa-times crsr_pntr" />
                </span>
              </div>
              <div className="content-wrapper">
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-12">
                        <div className="header_pagetitle d-block d-sm-block d-md-none">
                          <div className="text-center pt-2">
                            <div className="allheader_profile_drpdwn crsr_pntr">
                              <h4
                                className="d-inline-block"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title
                                data-original-title="Ble Media Solutions"></h4>
                              <div className="dropdown d-inline-block">
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right scrollbar_style">
                                  <div className="dropdown-item">
                                    <div className="allheader_profile_drplist text-center">
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">EphronSystems Pvt.Ltd</a>
                                      </div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">
                                          EphronTech LLC.
                                          <br />
                                          <span className="f_sz13 fred">Cancelled</span>
                                        </a>
                                      </div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">
                                          ABC Incorporation LLC.
                                          <br />
                                          <span className="f_sz13 forange">Assigned</span>
                                        </a>
                                      </div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">
                                          <span className="fred">Payment Failed Profile</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="container-fluid">
                    <div className="row position-relative mx-0 mt-3">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                        <div className="all_dashboard_div">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                            <div className="w-100 d-flex flex-row flex-wrap">
                              {this.state.businessprofiledetails?.business_info?.map((business_data, index) => (
                                <>
                                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div
                                      className={
                                        index % 2
                                          ? 'card card-widget widget-user brdrbtm_yellow3 shadow-none brdr_dashboxes brdr_radius_div acctdashbrd_user acctdashbrd_div'
                                          : 'card card-widget widget-user brdrbtm_green1 shadow-none brdr_dashboxes brdr_radius_div acctdashbrd_user acctdashbrd_div'
                                      }>
                                      <div className="w-100 d-flex flex-row flex-wrap border-bottom">
                                        <div
                                          className="widget_wkspce_created"
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title
                                          data-original-title="Created: 5 June 2021 9:45 pm">
                                          Active for:{' '}
                                          {business_data?.active_date?.year !== undefined &&
                                          business_data?.active_date?.year !== 0
                                            ? business_data?.active_date?.year > 1
                                              ? business_data?.active_date?.year + ' Years'
                                              : business_data?.active_date?.year + ' Year'
                                            : business_data?.active_date?.month !== undefined &&
                                              business_data?.active_date?.month !== 0
                                            ? business_data?.active_date?.month > 1
                                              ? business_data?.active_date?.month + ' Months'
                                              : business_data?.active_date?.month + ' Month'
                                            : business_data?.active_date?.week !== undefined &&
                                              business_data?.active_date?.week !== 0
                                            ? business_data?.active_date?.week > 1
                                              ? business_data?.active_date?.week + ' Weeks'
                                              : business_data?.active_date?.week + ' Week'
                                            : business_data?.active_date?.day !== undefined &&
                                              business_data?.active_date?.day !== 0
                                            ? business_data?.active_date?.day > 1
                                              ? business_data?.active_date?.day + ' Days'
                                              : business_data?.active_date?.day + ' Day'
                                            : business_data?.active_date?.hour !== undefined &&
                                              business_data?.active_date?.hour !== 0
                                            ? business_data?.active_date?.hour > 1
                                              ? business_data?.active_date?.hour + ' Hours'
                                              : business_data?.active_date?.hour + ' Hour'
                                            : ''}
                                        </div>
                                        {business_data?.is_assigned === 1 ? (
                                          <div class="widget_assigned">
                                            <span class="badge badge-assigned f_sz12">Assigned</span>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                          <div className="widget-user-header">
                                            <h3 className="widget-user-username f_sz18">
                                              {' '}
                                              {business_data?.business_name ? business_data?.business_name : ''}
                                            </h3>
                                            <h5 className="widget-user-desc f_sz14 mt-1 fmaroon">
                                              {business_data?.phone_number
                                                ?.toString()
                                                .substring(1)
                                                .replace(/[^\dA-Z]/g, '')
                                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                .trim()}
                                              <input
                                                type="hidden"
                                                id={'mob_number' + index}
                                                value={business_data?.phone_number}
                                              />

                                              {business_data?.phone_number?.length > 0 ? (
                                                <span class="ml-3 crsr_pntr">
                                                  <i
                                                    onClick={() => this.copyToClipboard('mob_number' + index)}
                                                    class="far fa-copy fmaroon f_sz16"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title=""
                                                    data-original-title="Copy"></i>
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </h5>
                                            <div className="f_sz12" data-id={business_data?.renewed_in?.day}>
                                              {business_data?.renewed_in?.day < 0 ? (
                                                <span className="fred font-weight-bold">Cancelled</span>
                                              ) : (
                                                ' Next Renewal'
                                              )}
                                              : {moment(business_data?.expire_date).format('MM/DD/YYYY')}
                                            </div>
                                            <div className="f_sz12">
                                              <span className="forange">Last Activity:</span>{' '}
                                              {business_data?.last_activity !== undefined
                                                ? moment(business_data?.last_activity).format('MM/DD/YYYY')
                                                : ''}
                                            </div>
                                            <div className="w-100 mt-2">
                                              <a onClick={() => this.board_redirection(business_data?._id)}>
                                                <span className="btn btn-black-dark btn-xs btnpdng_sm2">Inbox</span>
                                              </a>
                                              <a onClick={() => this.profile_settings_redirection(business_data?._id)}>
                                                <span className="btn btn-black-dark btn-xs btnpdng_sm2">Settings</span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                          <div className="widget-user-image pt-3">
                                            <div className="dash_workspacelogo mt-3">
                                              {business_data?.profile_image?.length !== 0 ? (
                                                <img src={business_data?.profile_image} />
                                              ) : (
                                                <span
                                                  class="dashworkspacecircle"
                                                  style={{
                                                    // backgroundColor: business.color_code ? business.color_code : '#097952',
                                                    color: business_data?.color_code
                                                      ? business_data?.color_code
                                                      : '#097952',
                                                  }}>
                                                  {business_data?.business_name
                                                    ? business_data?.business_name.substring(1, 0).toUpperCase()
                                                    : 'I'}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="text-center w-100 d-block mt-3">
                                            <a
                                              onClick={() => this.dashboard_redirection(business_data?._id)}
                                              href="javascript:void(0);">
                                              <span className="btn btn-black-dark btn-xs btnpdng_sm2">Detail View</span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer pt-3">
                                        <div className="row mt-2">
                                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                            <div
                                              className="detailscount_wrapper bg_dashgreen mb-2"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              title={business_data?.contacts + '- Contacts'}
                                              data-original-title="Contacts">
                                              <span>
                                                {' '}
                                                {business_data?.contacts
                                                  ? this.display_contcat_count(business_data?.contacts)
                                                  : '0'}
                                              </span>
                                            </div>
                                            {/* <div class="f_sz12 text-center mt-1">Tot. Contacts</div> */}
                                          </div>
                                          {/* /.col */}
                                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                            <div
                                              className="detailscount_wrapper bg_dashorange mb-2"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              title="Frequently Used Contacts"
                                              data-original-title="Frequently Used Contacts">
                                              <span>
                                                {business_data?.frequent_contacts !== undefined
                                                  ? business_data?.frequent_contacts
                                                  : '0'}
                                              </span>
                                            </div>
                                            {/* <div class="f_sz12 text-center mt-1">Freq. used Contacts</div> */}
                                          </div>
                                          {/* /.col */}
                                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                            <div
                                              className="detailscount_wrapper bg_dashred mb-2"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              title="Team"
                                              data-original-title="Team">
                                              <span>
                                                {' '}
                                                {business_data?.team_count}
                                                /5
                                              </span>
                                            </div>
                                            {/* <div class="f_sz12 text-center mt-1">Team</div> */}
                                          </div>
                                          {/* /.col */}
                                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                            <div
                                              className="detailscount_wrapper bg_dashblue mb-2"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              title="Messages"
                                              data-original-title="Messages">
                                              <span>
                                                {business_data?.messages !== undefined
                                                  ? this.display_contcat_count(business_data?.messages)
                                                  : '0'}
                                              </span>
                                            </div>
                                            {/* <div class="f_sz12 text-center mt-1">Tot. Msgs</div> */}
                                          </div>
                                          {/* /.col */}
                                        </div>
                                        {/* /.row */}
                                        <div className="row mt-3">
                                          {business_data?.redtie_plan?.plan_name !== 'Free' ? (
                                            <>
                                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                                <div className="detailscount_wrapper">
                                                  <div
                                                    className={
                                                      business_data?.provider === 'plivo' ||
                                                      business_data?.provider === 'twilio' ||
                                                      business_data?.provider === 'bandwidth'
                                                        ? 'phnumberimg_smll'
                                                        : 'phnumberimg_lrge_md'
                                                    }
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    all_dashboard_div
                                                    title={
                                                      business_data?.provider === 'plivo'
                                                        ? 'Own Service Provider - Plivo'
                                                        : business_data?.provider === 'twilio'
                                                        ? 'Own Service Provider - Twilio'
                                                        : business_data?.provider === 'bandwidth'
                                                        ? 'Own Service Provider - Bandwith'
                                                        : 'Redtie'
                                                    }
                                                    data-original-title="Service Provider">
                                                    <img
                                                      src={
                                                        business_data?.provider === 'plivo'
                                                          ? plivo_logo
                                                          : business_data?.provider === 'twilio'
                                                          ? twilio_logo
                                                          : business_data?.provider === 'bandwidth'
                                                          ? bandwidth_logo
                                                          : redtie_logo
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="text-center w-100 d-block">
                                                  <span className="font-weight-bold">
                                                    {business_data?.flag === 'appsumo' ? 'Appsumo' : 'PAID'}
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div>
                                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                                <div
                                                  className="detailscount_wrapper"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Your Plan">
                                                  FREE
                                                </div>

                                                <div className="text-center w-100 d-block">
                                                  <span>
                                                    <a
                                                      class="btn btn-black-dark btn-xs btnpdng_sm1"
                                                      onClick={() =>
                                                        this.upgradeWorkspace(
                                                          business_data?._id,
                                                          business_data?.appstore_transaction_id,
                                                        )
                                                      }>
                                                      Upgrade
                                                    </a>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {/* <div class="f_sz12 text-center mt-1">Service Provider</div> */}

                                          {/* /.col */}
                                          <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                            <div className="dashintegrations mt-2">
                                              {business_data?.integration?.email === 1 ? (
                                                <div
                                                  className="dashintegration_icon"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title="Email"
                                                  data-original-title="Email">
                                                  <i className="far fa-envelope" />
                                                </div>
                                              ) : null}
                                              {business_data?.integration?.chat === 1 ? (
                                                <div
                                                  className="dashintegration_icon"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title="Chat"
                                                  data-original-title="Chat">
                                                  <i className="far fa-comment" />
                                                </div>
                                              ) : null}
                                              {business_data?.integration?.slack === 1 ? (
                                                <div
                                                  className="dashintegration_icon"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title="Slack"
                                                  data-original-title="Slack">
                                                  <i className="fab fa-slack" />
                                                </div>
                                              ) : null}
                                              {business_data?.integration?.webhook === 1 ? (
                                                <div
                                                  className="dashintegration_icon"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title="Webhooks"
                                                  data-original-title="Webhooks">
                                                  <img src={prof_webhooks} border={0} className="img-size-20" />
                                                </div>
                                              ) : null}
                                              {/* <div
                                            className="dashintegration_icon"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title
                                            data-original-title="Phrases">
                                            <i className="fas fa-quote-left" />
                                          </div>
                                          <div
                                            className="dashintegration_icon"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title
                                            data-original-title="Signature">
                                            <i className="fas fa-signature" />
                                          </div> */}
                                              {business_data?.integration?.meeting === 1 ? (
                                                <div
                                                  className="dashintegration_icon"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title="Google Meeting"
                                                  data-original-title="Google Meeting">
                                                  <i className="fas fa-video" />
                                                </div>
                                              ) : null}
                                              {/* <div
                                            className="dashintegration_icon"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title
                                            data-original-title="Payment">
                                            <i className="fas fa-dollar-sign" />
                                          </div> */}
                                              <a
                                                onClick={() => this.profilesettings_redirection(business_data?._id)}
                                                href="javascript:void(0);">
                                                <div
                                                  className="dashintegration_icon1"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Add">
                                                  <i className="fas fa-plus" />
                                                </div>
                                              </a>
                                            </div>
                                            {/* <div class="f_sz12 text-center mt-1">Integrations</div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="card card-widget widget-user brdrbtm_green1 shadow-none brdr_dashboxes brdr_radius_div acctdashbrd_user acctdashbrd_div d-flex flex-row flex-wrap align-items-center justify-content-center dash_addwrkspace">
                                  <a
                                    href="/create-profile?create=true"
                                    className="btn btn-green_outline crsr_pntr btn-lg m-2 btnpdng_sm">
                                    <i className="fas fa-plus mr-2" />
                                    New Workspace
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <TrialPayment /> */}
                </div>
              </div>
              {/* <TrialPayment /> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Account_Dashboard;
